import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventStrip from './EventStrip';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
import Lodash from 'lodash';
import EventCapacityView from './EventCapacityView';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});


class SaleEventTabInfo extends React.Component {

 
 
  render() {
    const { classes,event} = this.props;

    return (
      <React.Fragment>
            <Paper className={classes.paper}>
              <EventStrip show_debug_info={false} variant="sale" event={event}/>
            </Paper>
            <Typography variant="subheading"> Přehled kapacit zón  </Typography>
            {event.zones.map(ev=>{

              const zi = Lodash.find(event.capacity_info.zone_infos,{'event_zone_id':ev.id});

              return (
                <React.Fragment key={ev.id}>
                  <Typography variant="title"> {ev.name} </Typography>
                  <EventCapacityView cap_info={zi} />
                </React.Fragment>
              );
             
            })}


      </React.Fragment>
    );
  }
}

SaleEventTabInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
SaleEventTabInfo.defaultProps = {
  
};


export default withStyles(styles)(SaleEventTabInfo);