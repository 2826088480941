
const select = (state={
          folder_key:null,season_key:null,evi_group:null,
          place_id_for_zones:null,place_id_for_price_plans:null,zone_id_for_seats:null,
          place_id_for_zone_prices:null,zone_id_for_zone_prices:null,price_plan_id_for_zone_prices:null,
    }, action) => {
    switch (action.type) {
      case 'SET_FOLDER':
        return  Object.assign({}, state, {
          folder_key: action.folder_key
        })
      case 'SET_SEASON':
        return  Object.assign({}, state, {
          season_key: action.season_key
        })
      case 'SET_EVIGROUP':
      return  Object.assign({}, state, {
        evi_group: action.evi_group
      })

      case 'SET_PLACEID_FOR_ZONE':
      return  Object.assign({}, state, {
        place_id_for_zones: action.place_id
      })

      case 'SET_PLACEID_FOR_PRICE_PLAN':
      return  Object.assign({}, state, {
        place_id_for_price_plans: action.place_id
      })


      case 'SET_ZONEID_FOR_SEAT':
      return  Object.assign({}, state, {
        zone_id_for_seats: action.zone_id
      })

      case 'SET_ZONEID_FOR_ZONE_PRICE':
      return  Object.assign({}, state, {
        zone_id_for_zone_prices: action.zone_id
      })
      case 'SET_PLACEID_FOR_ZONE_PRICE':
      return  Object.assign({}, state, {
        place_id_for_zone_prices: action.place_id
      })
      case 'SET_PRICEPLANID_FOR_ZONE_PRICE':
      return  Object.assign({}, state, {
        price_plan_id_for_zone_prices: action.price_plan_id
      })

      default:
        return state
    }
  }
  
  export default select;