import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DebugObjectView from './Utils/DebugObjectView';
import EventPlanView from './EventPlanView';
import EventCostView from './EventCostView';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
});



class SetupEventTabPlan extends React.Component {

 
  
  render() {
    const { event,classes} = this.props;

    return (
      <React.Fragment>
           
            <DebugObjectView name="event" object={event} />

            
            <Grid container justify="center" alignContent="center" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <Typography variant="subheading"> Náhled plánku s zónama </Typography>
                <EventPlanView 
                  place={event.place} 
                  zones={event.zones}
                  seats={event.seats}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Typography variant="subheading"> Přehled cen </Typography>
                  <Paper className={classes.paper}>
                    <EventCostView event={event}/>
                  </Paper>
              </Grid>
            </Grid>

      </React.Fragment>
    );
  }
}

SetupEventTabPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
SetupEventTabPlan.defaultProps = {
  
};


export default withStyles(styles)(SetupEventTabPlan);