import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import ZonesTable from './ZonesTable';
import { Query } from "react-apollo";
import ZoneViewDialog from './ZoneViewDialog';
import ZoneDeleteDialog from './ZoneDeleteDialog';
import ZoneEditDialog from './ZoneEditDialog';
import ActionButton from './ActionButton';
import PlaceIDField from './Fields/PlaceIDField';
//import FloorPlanPreview from './FloorPlanPreview';
import { connect } from 'react-redux'
import { setPlaceIDForZone } from './../actions';
import BooleanField from './BooleanField';
import {GET_ZONES_WITH_PLACE_AND_SEATS } from './Fields/Queries';
//import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EventPlanView from './EventPlanView';
import DebugObjectView from './Utils/DebugObjectView'

const SAVE_ORDERING = gql`
    mutation zones_ordering($ids: [ID]!,$place_id:ID) {
        zones_ordering(ids:$ids,place_id:$place_id) 
    }
`;

const HIDE_ZONE = gql`
mutation hideZone($id:ID!) {
  zoneHide(id:$id) {
    id
  }
}
`;

const UPDATE_ZONE= gql`
mutation updateZone($id:ID!,$name:String,$long_name:String,$map_zone_key:String,$free_zone: Boolean, $place_id:ID,$primary_color:String,$secondary_color:String) {
  zoneUpdate(id:$id,name:$name,long_name:$long_name,map_zone_key:$map_zone_key,free_zone:$free_zone,place_id:$place_id,primary_color:$primary_color,secondary_color:$secondary_color) {
    id
  }
}
`;

const CREATE_ZONE = gql`
mutation createZone($name:String!,$long_name:String!,$map_zone_key:String!,$free_zone: Boolean, $place_id:ID!,$primary_color:String,$secondary_color:String) {
  zoneCreate(name:$name,long_name:$long_name,map_zone_key:$map_zone_key,free_zone:$free_zone,place_id:$place_id,primary_color:$primary_color,secondary_color:$secondary_color) {
    id
  }
}
`;

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 3,
  }
});


class Zones extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showSeats:false,
      show_zone:null,
      delete_zone:null,
      showUnattachedZones:false
    }
    this.table = React.createRef();
    this.edit = React.createRef();
    this.preview = React.createRef();
  }


  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
    if (this.preview.current) {
      this.preview.current.reload();
    }

  }

  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({name:null,long_name:null,map:null,map_zone_key:null,free_zone:false,place_id:this.props.place_id});
    }
  }


  handleEdit(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSave() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("zone update",doc)
        this.props.updateZone({
          variables: doc
        }).then(res=>{
          console.log("zone ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("zone create",doc)
        this.props.createZone({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }

  handleShow(item) {
    this.setState({show_zone:item});
  }
  closeShow() {
    this.setState({show_zone:null});
  }

  handleDelete(item) {
    this.setState({delete_zone:item});
  }
  closeDelete() {
    this.setState({delete_zone:null});
  }

  doDelete() {
    const id = this.state.delete_zone.id;
    this.setState({delete_zone:null});
    this.props.hideZone({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    const { place_id } = this.props; 
    this.props.saveOrdering({
      variables: {
        ids:ids,
        place_id
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  render() {
    const { auth, place_id} = this.props;
    const {showUnattachedZones,showSeats} = this.state;
    return (
      <React.Fragment>
        <PlaceIDField required error={place_id===null} value={place_id} onChange={(v)=>this.props.onChangePlaceID(v)}/>
        {place_id && (<ActionButton label={"Nová zóna"} onClick={()=>this.handleNew()}/>)}
        <BooleanField width={250} id="showUnattached" label="Zobrazit nepřipojené zóny -  nefunguje" value={showUnattachedZones} onChange={(v)=>this.setState({showUnattachedZones:v})} />
        <BooleanField width={250} id="showSeats" label="Zobrazit sezení" value={showSeats} onChange={(v)=>this.setState({showSeats:v})} />

        
        <ZoneViewDialog zone={this.state.show_zone}  open={this.state.show_zone!==null} onClose={()=>this.closeShow()} /> 
        <ZoneDeleteDialog zone={this.state.delete_zone}  open={this.state.delete_zone!==null} onDelete={()=>this.doDelete()} onClose={()=>this.closeDelete()} /> 
        <ZoneEditDialog innerRef = {this.edit} onSave={()=>this.doSave()} /> 
        
        {place_id && (
          <Query query={GET_ZONES_WITH_PLACE_AND_SEATS} variables={{place_id}} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <React.Fragment>
              <DebugObjectView object={data}/>
              <Grid container justify="center" alignContent="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                  <ZonesTable 
                      innerRef = {this.table}
                      auth={auth}
                      data={data.zones} 
                      refetch={refetch}
                      saveOrdering={(ids)=>this.saveOrder(ids)}
                      onEdit={(item)=>this.handleEdit(item)}
                      onShow={(item)=>this.handleShow(item)}
                      onDelete={(item)=>this.handleDelete(item)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <EventPlanView 
                      place={data.place} 
                      zones={data.zones}
                      seats={showSeats?data.seats:[]}
                    />
                </Grid>
              </Grid>
              </React.Fragment>
            );
          }}
          </Query>
        )}


      </React.Fragment>
    );
  }
}

Zones.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hideZone: PropTypes.func.isRequired,
  updateZone: PropTypes.func.isRequired,
  createZone: PropTypes.func.isRequired,
  place_id: PropTypes.string,
  onChangePlaceID: PropTypes.func.isRequired
};
Zones.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      place_id: state.select.place_id_for_zones,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePlaceID: id => {
      dispatch(setPlaceIDForZone(id))
    },
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_ZONE,{
    name: "hideZone",
  }),
  graphql(UPDATE_ZONE,{
    name: "updateZone",
  }),
  graphql(CREATE_ZONE,{
    name: "createZone",
  }),
)(Zones)
