import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lodash from 'lodash';
import CGroupsRow from './CGroupsRow';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
   // maxWidth: 1000,
   // background: theme.palette.background.paper,
  },
  tablebody: {
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  row: {
    //height:24,
   
  }
});

const CurrentCGroups = gql`
  query CGroups {
    cgroups {
        key
        name
        type
        course_keys
    }
  }
`;


const SaveOrdering = gql`
    mutation cgroups_ordering($keys: [String]!) {
        cgroups_ordering(keys:$keys) 
    }
`;


class CGroups extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      order:[],
      activeDrag:false
    }
  }
 
  onDrag(active) {
    this.setState({activeDrag:active})
  }  


 reorder(items) {
    const { order } = this.state;
    if (order.length !== items.length) {
      console.log("missing order")
      return items;
    }
    console.log("reodering");
    return order.map((k)=>{return Lodash.find(items,{key:k}) });
 }

  findRow(key) {
      const items = this.reorder(this.props.cgroups.cgroups);
      const item = items.filter(c => c.key === key)[0]
      const index = items.indexOf(item);
      const r =  {
        cgroup:item,
        index: index,
      };
      console.log("findRow",key,r)
      return r;
  }

  moveRow(key, atIndex) {
    console.log("moveRow",key,atIndex);
    const { index } = this.findRow(key)
    let order = []
    if (this.state.order.length !== this.props.cgroups.cgroups.length) {
       order = this.props.cgroups.cgroups.map(i=>{return i.key});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,key);
    this.setState({order:order});
  }
  
  saveOrder() {
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        keys:this.state.order
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }

  renderLoading() {
    return (
     
      <TableRow>
        <TableCell colSpan={6}>
        <CircularProgress />
        </TableCell>
      </TableRow>
     
    )
  }
  renderRows2(items) {
    const { auth } = this.props;
    return  this.reorder(items).map((i,idx)=>{
                return  (<CGroupsRow auth={auth} save={()=>this.saveOrder()} moveRow={(k,at)=>this.moveRow(k,at)} findRow={(k)=>this.findRow(k)}activeDrag={this.state.activeDrag} id={i.key} key={i.key} cgroup={i} onDrag={(active)=>this.onDrag(active)} />)
          });
  }

  render() {
    const { classes } = this.props;
   
    const rows = this.props.cgroups.cgroups?this.renderRows2(this.props.cgroups.cgroups):this.renderLoading();
    return (
      <Paper className={classes.root}>
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>řazení</TableCell>
                    <TableCell className={classes.cell}>název</TableCell>
                    <TableCell className={classes.cell}>typ</TableCell>
                    <TableCell className={classes.cell}>počet kurzů</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {rows}
            </TableBody>
          </Table>
      </Paper>
    );
  }
}

CGroups.propTypes = {
  classes: PropTypes.object.isRequired,
  cgroups: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

CGroups.defaultProps = {
 
};



export default compose(
  withStyles(styles),
  graphql(CurrentCGroups,{
      name: "cgroups",
  }),
  graphql(SaveOrdering,{
    name: "saveOrdering",
  }),
)(CGroups)
