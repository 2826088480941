import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { compose, withApollo } from "react-apollo";


var Api = require("../libs/Api");
const pWhilst = require('p-whilst');

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
   // display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit ,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  radio: {
    height: "20px",
    width: "20px",
  }
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  course_ids: PropTypes.arrayOf(PropTypes.string),
  onEmails: PropTypes.func
};

const defaultProps = {
  course_ids:[]
};

class BulkEmailsFetch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gender:'all',
      status_e: true,
      status_n: true,
      status_s: true,
      loading: false,
      completed: 0,
    };
  }


  handleChangeGender(event, value) {
    this.setState({ gender:value });
  };
  handleChangeStatus(name, value) {
    switch(name) {
      case 'n': this.setState({status_n:value}); break;
      case 'e': this.setState({status_e:value}); break;
      case 's': this.setState({status_s:value}); break;
      default:
    }
  };

  onClickLoad() {
    const apolloclient = this.props.client;
    this.setState({loading:true});
    const gender = this.state.gender;
    const course_ids = this.props.course_ids;
    var statuses = []
    if (this.state.status_n) { statuses.push("FILED")}
    if (this.state.status_e) { statuses.push("IN")}
    if (this.state.status_s) { statuses.push("SPARE")}


    let iter = 0;
    this.setState({completed:0});
    let all_emails = [];
    pWhilst(() => {
      return iter < course_ids.length;
    }, () => {
      let tt = this;
      let course_id = course_ids[iter];
      return new Promise(function(resolve,reject){
        var filter_g = null;
        switch(gender) {
          case 'male': filter_g="MALE"; break;
          case 'female': filter_g="FEMALE"; break;
          default: break;
        }

        Api.gqlEmailsFromCourse(apolloclient,course_id,filter_g,statuses).then(emails=>{
          iter++;
          all_emails.push(...emails)
          const pg = Math.round(((iter)*100) / course_ids.length);
          tt.setState({completed:pg});
          resolve()
        }).catch(reject);

      });
    }).then(() => {
      all_emails = [...new Set(all_emails)];
      //console.log("done",all_emails);
      this.setState({loading:false,completed:100});
      this.props.onEmails(all_emails);
    });

  }
 
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>
          <FormControl disabled={this.state.loading} component="fieldset" className={classes.formControl}>
            <FormLabel component="label">Podle pohlaví</FormLabel>
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              className={classes.group}
              value={this.state.gender}
              onChange={(e,v)=>this.handleChangeGender(e,v)}
            >
              <FormControlLabel value="all" disabled={this.state.loading} control={<Radio color="primary" />} label="Všechny" />
              <FormControlLabel value="male" disabled={this.state.loading} control={<Radio color="primary" />} label="Jen kluky" />
              <FormControlLabel value="female" disabled={this.state.loading} control={<Radio color="primary" />} label="Jen holky" />
            </RadioGroup>
            <FormHelperText>Vyberou se pouze emaily žáků zvoleného pohlaví</FormHelperText>
          </FormControl>
          <FormControl disabled={this.state.loading} component="fieldset" className={classes.formControl}>
            <FormLabel component="label">Podle stavu přihlášky</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.loading}
                    checked={this.state.status_e}
                    onChange={(e,v)=>{this.handleChangeStatus("e",v)}}
                    value="e"
                  />
                }
                label="Zapsaný"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.loading}
                    checked={this.state.status_n}
                    onChange={(e,v)=>{this.handleChangeStatus("n",v)}}
                    value="n"
                  />
                }
                label="Evidovaná přihláška"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={this.state.loading}
                    checked={this.state.status_s}
                    onChange={(e,v)=>{this.handleChangeStatus("s",v)}}
                    value="s"
                  />
                }
                label="Náhradník"
              />
            </FormGroup>
            <FormHelperText>Vyberou se pouze emaily žáků v daném stavu</FormHelperText>
          </FormControl>
        </div>
        <div>
          { this.props.course_ids.length===0 && 
            <Typography color='accent'> není vybrán žádný kurz </Typography>
          }
          <Button variant="raised" disabled={this.state.loading || this.props.course_ids.length===0}  className={classes.button} onClick={(e)=>this.onClickLoad(e)}> Načíst </Button>
          <LinearProgress variant="determinate" value={this.state.completed} />
        </div>
      </div>
    );
  }
}

BulkEmailsFetch.propTypes = propTypes;
BulkEmailsFetch.defaultProps = defaultProps;


export default compose(
  withStyles(styles),
  withApollo,
)(BulkEmailsFetch)
