import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImagesTable from './ImagesTable';
import { Query } from "react-apollo";
import ImageViewDialog from './ImageViewDialog';
import ImageDeleteDialog from './ImageDeleteDialog';
import ImageEditDialog from './ImageEditDialog';

import ImageUpload from './ImageUpload';

import {GET_IMAGES } from './Fields/Queries';




const SAVE_ORDERING = gql`
    mutation images_ordering($ids: [ID]!) {
        images_ordering(ids:$ids) 
    }
`;

const HIDE_IMAGE = gql`
mutation hideImage($id:ID!) {
  imageHide(id:$id) {
    id
  }
}
`;

const UPDATE_IMAGE = gql`
mutation updateImage($id:ID!,$name:String) {
  imageUpdate(id:$id,name:$name) {
    id
  }
}
`;


const styles = theme => ({
 
});


class Images extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_image:null,
      delete_image:null,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
  }


  renderUpload() {
    return (
      <ImageUpload 
        onUploaded={()=>this.reload()}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={500000}
      />
    )
  }

  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  }

  handleEditImage(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSaveImage() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();

      console.log("image update",doc)
      this.props.updateImage({
        variables: doc
      }).then(res=>{
        console.log("update ok",res);
        this.reload();
      }).catch(err=>{
        console.error(err);
      })
  
     
    }
  }

  handleShowImage(item) {
    this.setState({show_image:item});
  }
  closeShowImage() {
    this.setState({show_image:null});
  }

  handleDeleteImage(item) {
    this.setState({delete_image:item});
  }
  closeDeleteImage() {
    this.setState({delete_image:null});
  }

  doDeleteImage() {
    const id = this.state.delete_image.id;
    this.setState({delete_image:null});
    this.props.hideImage({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        ids:ids
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  render() {
    const { auth} = this.props;
    const up = this.renderUpload();
    return (
      <div className="foo">
      
        {up}
        <ImageViewDialog image={this.state.show_image}  open={this.state.show_image!==null} onClose={()=>this.closeShowImage()} /> 
        <ImageDeleteDialog image={this.state.delete_image}  open={this.state.delete_image!==null} onDelete={()=>this.doDeleteImage()} onClose={()=>this.closeDeleteImage()} /> 
        <ImageEditDialog innerRef = {this.edit} onSave={()=>this.doSaveImage()} /> 

        <Query query={GET_IMAGES} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <ImagesTable 
                  innerRef = {this.table}
                  auth={auth}
                  data={data.images} 
                  refetch={refetch}
                  saveOrdering={(ids)=>this.saveOrder(ids)}
                  onEdit={(item)=>this.handleEditImage(item)}
                  onShow={(item)=>this.handleShowImage(item)}
                  onDelete={(item)=>this.handleDeleteImage(item)}
              />
            );
          }}
      </Query>

      </div>
    );
  }
}

Images.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hideImage: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired,
};
Images.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_IMAGE,{
    name: "hideImage",
  }),
  graphql(UPDATE_IMAGE,{
    name: "updateImage",
  }),
)(Images)
