import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
//import EventStrip from './EventStrip';
//import DateView from './DateView';
//import Paper from '@material-ui/core/Paper';
//import Lodash from 'lodash';
//import { Query } from "react-apollo";
//import gql from 'graphql-tag';
//import CircularProgress from '@material-ui/core/CircularProgress';
//import DebugObjectView from './Utils/DebugObjectView';
import SaleEventShoppingCartList from './SaleEventShoppingCartList';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
    flexGrow:1
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});




class SaleEventShoppingCart extends React.Component {

  constructor(props) {
    super(props);
  }

  

  
  render() {
    const { event , classes, booking_ref,as_new,has_order,my_tickets} = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventShoppingCartList clerk_mode={true} as_order={as_new} has_order={has_order} info_booking_ref={booking_ref} innerRef={this.list} onDelete={this.props.onDelete} onBuy={this.props.onBuy} list={my_tickets} onModify={this.props.onModify} onReserve={this.props.onReserve} event_seats={event.seats} event_zones={event.zones}/>
      </Paper>
   );
  }
}

SaleEventShoppingCart.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onReserve: PropTypes.func.isRequired,
  as_new: PropTypes.bool.isRequired,
  has_order: PropTypes.bool.isRequired,
  booking_ref: PropTypes.string.isRequired,
  my_tickets: PropTypes.array.isRequired,
};
SaleEventShoppingCart.defaultProps = {
  
};


export default withStyles(styles)(SaleEventShoppingCart);