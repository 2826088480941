import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import ZonePricesTable from './ZonePricesTable';
import { Query } from "react-apollo";
import ZonePriceViewDialog from './ZonePriceViewDialog';
import ZonePriceDeleteDialog from './ZonePriceDeleteDialog';
import ZonePriceEditDialog from './ZonePriceEditDialog';
import ActionButton from './ActionButton';
import ZoneIDField from './Fields/ZoneIDField';
import PlaceIDField from './Fields/PlaceIDField'
import PricePlanIDField from './Fields/PricePlanIDField';
import { connect } from 'react-redux'
import { setZoneIDForZonePrice, setPlaceIDForZonePrice, setPricePlanIDForZonePrice } from './../actions';

import {GET_ZONE_PRICES } from './Fields/Queries';


const SAVE_ORDERING = gql`
    mutation zonePrices_ordering($ids: [ID]!,$zone_id:ID, $price_plan_id:ID) {
        zonePrices_ordering(ids:$ids,zone_id:$zone_id,price_plan_id:$price_plan_id) 
    }
`;

const HIDE_ZONE_PRICE = gql`
mutation hideZonePrice($id:ID!) {
  zonePriceHide(id:$id) {
    id
  }
}
`;



const UPDATE_ZONE_PRICE= gql`
mutation updateZonePrice($id:ID!,$zone_id:ID,$price_plan_id:ID,$name:String, $cost:Int, $sale_student: Boolean) {
  zonePriceUpdate(id:$id,zone_id:$zone_id,price_plan_id:$price_plan_id,name:$name,cost:$cost, sale_student:$sale_student) {
    id
  }
}
`;

const CREATE_ZONE_PRICE = gql`
mutation createZonePrice($zone_id:ID!,$price_plan_id:ID!,$name:String!, $cost:Int!, $sale_student: Boolean!) {
  zonePriceCreate(zone_id:$zone_id,price_plan_id:$price_plan_id,name:$name,cost:$cost,sale_student:$sale_student) {
    id
  }
}
`;

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 3,
  }
});



class ZonePrices extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_zone_price:null,
      delete_zone_price:null,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
  }


  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  }

  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({zone_id:this.props.zone_id,price_plan_id:this.props.price_plan_id,name:null,cost:null, sale_student:false});
    }
  }


  handleEdit(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSave() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("zone_price update",doc)
        this.props.updateZonePrice({
          variables: doc
        }).then(res=>{
          console.log("zone_price ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("zone_price create",doc)
        this.props.createZonePrice({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }

  handleShow(item) {
    this.setState({show_zone_price:item});
  }
  closeShow() {
    this.setState({show_zone_price:null});
  }

  handleDelete(item) {
    this.setState({delete_zone_price:item});
  }
  closeDelete() {
    this.setState({delete_zone_price:null});
  }

  doDelete() {
    const id = this.state.delete_zone_price.id;
    this.setState({delete_zone_price:null});
    this.props.hideZonePrice({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    const { zone_id, price_plan_id } = this.props; 
    this.props.saveOrdering({
      variables: {
        ids:ids,
        zone_id,
        price_plan_id
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  render() {
    const { auth, zone_id, place_id,price_plan_id} = this.props;
   
    return (
      <React.Fragment>
        <PlaceIDField required error={place_id===null} value={place_id} onChange={(v)=>{ this.props.onChangeZoneID(null); this.props.onChangePricePlanID(null); this.props.onChangePlaceID(v)} }/>
        <PricePlanIDField place_id={place_id} required error={price_plan_id===null} value={price_plan_id} onChange={(v)=>{ this.props.onChangePricePlanID(v)} }/>
        <ZoneIDField place_id={place_id} required error={zone_id===null} value={zone_id} onChange={(v)=>this.props.onChangeZoneID(v)}/>
        {place_id && price_plan_id && zone_id && (<ActionButton label={"Nová cena zóny"} onClick={()=>this.handleNew()}/>)}

        
        <ZonePriceViewDialog zone_price={this.state.show_zone_price}  open={this.state.show_zone_price!==null} onClose={()=>this.closeShow()} /> 
        <ZonePriceDeleteDialog zone_price={this.state.delete_zone_price}  open={this.state.delete_zone_price!==null} onDelete={()=>this.doDelete()} onClose={()=>this.closeDelete()} /> 
        <ZonePriceEditDialog innerRef = {this.edit} onSave={()=>this.doSave()} /> 
        
      
        {place_id && price_plan_id && (
          <Query query={GET_ZONE_PRICES} variables={{zone_id,price_plan_id}} fetchPolicy={"network-only"}>
            {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;
              return (
                <ZonePricesTable 
                    innerRef = {this.table}
                    auth={auth}
                    data={data.zonePrices} 
                    refetch={refetch}
                    saveOrdering={(ids)=>this.saveOrder(ids)}
                    onEdit={(item)=>this.handleEdit(item)}
                    onShow={(item)=>this.handleShow(item)}
                    onDelete={(item)=>this.handleDelete(item)}
                />
              );
            }}
        </Query>
        )}

      </React.Fragment>
    );
  }
}

ZonePrices.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hideZonePrice: PropTypes.func.isRequired,
  updateZonePrice: PropTypes.func.isRequired,
  createZonePrice: PropTypes.func.isRequired,
  zone_id: PropTypes.string,
  place_id: PropTypes.string,
  price_plan_id: PropTypes.string,
  onChangeZoneID: PropTypes.func.isRequired,
  onChangePlaceID: PropTypes.func.isRequired,
  onChangePricePlanID: PropTypes.func.isRequired
};
ZonePrices.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      place_id: state.select.place_id_for_zone_prices,
      price_plan_id: state.select.price_plan_id_for_zone_prices,
      zone_id: state.select.zone_id_for_zone_prices,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePricePlanID: id => {
      dispatch(setPricePlanIDForZonePrice(id))
    },
    onChangePlaceID: id => {
      dispatch(setPlaceIDForZonePrice(id))
    },
    onChangeZoneID: id => {
      dispatch(setZoneIDForZonePrice(id))
    },
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_ZONE_PRICE,{
    name: "hideZonePrice",
  }),
  graphql(UPDATE_ZONE_PRICE,{
    name: "updateZonePrice",
  }),
  graphql(CREATE_ZONE_PRICE,{
    name: "createZonePrice",
  }),
)(ZonePrices)
