import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventBuyTicketClerk from './SaleEventBuyTicketClerk';
import Paper from '@material-ui/core/Paper';
//import DebugObjectView from './Utils/DebugObjectView';
import SaleEventOrderAdminView from './SaleEventOrderAdminView';

const styles = theme => ({
  root: {
  //   border: "1px solid red",
     flexGrow: 1,
  },
});





class SaleEventOrder extends React.Component {

  constructor(props) {
    super(props);
  }
 
  render() {
    const { classes,event, order, as_reservation, public_order_id, refetch, onCreateOrder , onCreateReservation,onBackOrder, onBackReservation,onConfirmReservation, onConfirmSales} = this.props;


    const has_order = (public_order_id!=null) && (order!=null);
    const is_reservation = has_order?order.only_reservation:as_reservation;
    const is_done = has_order?(order.status && order.status!='INCOMPLETE'):false;
    if (is_done) {
      return (
        <SaleEventOrderAdminView refetch={refetch} event={event} order={order}  />
      )
    } else {
    return (
      <Paper className={classes.root}>
        {is_reservation?(
          <SaleEventBuyTicketClerk as_reservation={is_reservation} order={order} event={event} onBack={onBackReservation} onConfirm={onConfirmReservation} onCreate={onCreateReservation}  refetch={refetch} public_order_id={public_order_id} />
        ):(
          <SaleEventBuyTicketClerk as_reservation={is_reservation} order={order} event={event} onBack={onBackOrder} onConfirm={onConfirmSales} onCreate={onCreateOrder} refetch={refetch} public_order_id={public_order_id} />
        )}
      </Paper>
    );
    }
  }
}

SaleEventOrder.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  public_order_id: PropTypes.string,
  onCreateOrder: PropTypes.func.isRequired,
  onCreateReservation: PropTypes.func.isRequired,
  onBackOrder: PropTypes.func.isRequired,
  onBackReservation: PropTypes.func.isRequired,
  onConfirmSales: PropTypes.func.isRequired,
  onConfirmReservation: PropTypes.func.isRequired,
  order: PropTypes.object,
  as_reservation: PropTypes.bool.isRequired,
};
SaleEventOrder.defaultProps = {
  
};


export default withStyles(styles)(SaleEventOrder);