import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

//import EnumField from './EnumField';
import Lodash from 'lodash';

const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class SimpleEditDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      doc:null,
      open:false,
      dirty:false,
      valid:false,
    }
  }

  doEdit(doc) {
    const dc = Object.assign({},doc);
    const odc = Object.assign({},doc);
    this.setState({
        doc:dc,
        orig_doc:odc,
        doc_err: this.getDocErr(dc),
        open:true,
        dirty:false,
        valid:this.checkDoc(dc)
    })
  }
  getDoc() {
    return this.state.doc;
  }

  doClose() {
    this.setState({doc:null,doc_err:null,open:false})
  }


  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err,
      dirty:true,
      valid:this.checkDoc(doc)
    });
  }

  checkDocField(name,value) {
    return this.props.formFieldValidator(name,value);
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  handleOnSave() {
    this.props.onSave(this.state.doc);
  }
  

  render() {
    const {caption_edit,caption_new,renderForm} = this.props;
    const {open,doc,doc_err,valid,dirty} = this.state;
    const caption = (doc && doc.id)?caption_edit:caption_new;

    return (
      <Dialog maxWidth={false} open={open} onClose={()=>this.doClose()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {doc && (
            <React.Fragment>
              {renderForm(doc,doc_err,(field,value)=>this.handleDocChange(field,value))}
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>

        <Button onClick={()=>this.doClose()} color="primary">
          Zavřít
        </Button>
        <Button disabled={!valid || !dirty} onClick={()=>this.handleOnSave()} color="primary">
          Uložit
        </Button>

      </DialogActions>
    </Dialog>
    );
  }
}

SimpleEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  caption_edit: PropTypes.string,
  caption_new: PropTypes.string,
  renderForm: PropTypes.func.isRequired, 
  formFieldValidator: PropTypes.func.isRequired,
};
SimpleEditDialog.defaultProps = {
  caption_edit:"Editace položky",
  caption_new:"Nová položka"
};

export default withStyles(styles)(SimpleEditDialog);