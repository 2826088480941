import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TicketsHeaderTabs from './TicketsHeaderTabs';
import Events from './Events';
import EventsSearch from './EventsSearch';

const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class TicketsBoard extends React.Component {

  renderTabEvents() {
    const {auth} = this.props;
    return (
      <Events auth={auth} />
    )
  }

  renderTabSearch() {
    const {auth} = this.props;
    return (
      <EventsSearch auth={auth} />
    )
  }


  render() {
    const { classes , auth,page} = this.props;
    var tab=null;
    switch(page) {

     // case 'maps': tab = this.renderTabMaps(); break;
    //  case 'images': tab = this.renderTabImages(); break;
      case 'events': tab = this.renderTabEvents(); break;
      case 'search': tab = this.renderTabSearch(); break;
    //  case 'places': tab = this.renderTabPlaces(); break;
    //  case 'zones': tab = this.renderTabZones(); break;
    //  case 'priceplans': tab = this.renderTabPricePlans(); break;
    //  case 'zoneprices': tab = this.renderTabZonePrices(); break;
    //  case 'seats': tab = this.renderTabSeats(); break;
      default:
      break;
    }
   

    return (
      <div>
        <TicketsHeaderTabs  activeTab={page} auth={auth} />
        <div className={classes.tab_root}>{tab}</div>
      </div>
    );
  }
}

TicketsBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.oneOf(["events","search"]).isRequired
};
TicketsBoard.defaultProps = {
  
};

export default withStyles(styles)(TicketsBoard);