import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StringViewField from './StringViewField';
import Moment from 'moment';
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
require("moment/min/locales.min");
moment.locale('cs');
var momentPropTypes = require('react-moment-proptypes');


const styles = theme => ({
 
});


class DateViewField extends React.Component {

  render() {
    const { width,label,value,format} = this.props;
    const val = (value===null)?"":(moment(value).format(format));
    return (
        <StringViewField width={width} label={label} value={val}/>
    );
  }
}

DateViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date),momentPropTypes.momentObj,momentPropTypes.momentString]),
};
DateViewField.defaultProps = {
  format:"llll"
};

export default withStyles(styles)(DateViewField);