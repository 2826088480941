import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import StringField from './StringField';
import NumberField from './NumberField';
import ZoneIDViewField from './Fields/ZoneIDViewField';
import SeatTypeField from './Fields/SeatTypeField';

//import EnumField from './EnumField';
import Lodash from 'lodash';

const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class SeatEditDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      doc:null,
      open:false,
      dirty:false,
      valid:false,
    }
  }

  doEdit(doc) {
    const dc = Object.assign({},doc);
    this.setState({
        doc:dc,
        doc_err: this.getDocErr(dc),
        open:true,
        dirty:false,
        valid:this.checkDoc(dc)
    })
  }
  getDoc() {
    return this.state.doc;
  }

  doClose() {
    this.setState({doc:null,doc_err:null,open:false})
  }


  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err,
      dirty:true,
      valid:this.checkDoc(doc)
    });
  }

  checkDocField(name,value) {
    if (["name","zone_id","type","capacity","map_position_x","map_position_y"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  

  render() {
    const {onSave,caption_edit,caption_new} = this.props;
    const {open,doc,doc_err,valid,dirty} = this.state;
    const caption = (doc && doc.id)?caption_edit:caption_new;

    const box_mode = (doc && (doc.type === "BOX"));
    const line_mode = (doc && (doc.type === "LINE_V" || doc.type==="LINE_H"));
    return (
      <Dialog maxWidth={false} open={open} onClose={()=>this.doClose()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {doc && (
            <React.Fragment>
                <ZoneIDViewField value={doc.zone_id} />
                <StringField required width={300} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
                <SeatTypeField required width={250} value={doc.type} error={doc_err.type} onChange={(v)=>this.handleDocChange("type",v)} />
            
                <NumberField required width={100} id="map_position_x" label="mapa - X" value={doc.map_position_x} error={doc_err.map_position_x} onChange={(v)=>this.handleDocChange("map_position_x",v)} />
                <NumberField required width={100} id="map_position_y" label="mapa - Y" value={doc.map_position_y} error={doc_err.map_position_y} onChange={(v)=>this.handleDocChange("map_position_y",v)} />
                <NumberField width={100} id="map_position_angle" label="mapa - A" value={doc.map_position_angle} error={doc_err.map_position_angle} onChange={(v)=>this.handleDocChange("map_position_angle",v)} />
                <NumberField width={100} id="map_sector_angle" label="mapa - Sector A" value={doc.map_sector_angle} error={doc_err.map_sector_angle} onChange={(v)=>this.handleDocChange("map_sector_angle",v)} />
            
            
                <NumberField required width={100} id="capacity" label="Kapacita" value={doc.capacity} error={doc_err.capacity} onChange={(v)=>this.handleDocChange("capacity",v)} />
             
                {box_mode && (
                  <React.Fragment>
                      <NumberField  width={100} id="box_capacity_left" label="Levá" value={doc.box_capacity_left} error={doc_err.box_capacity_left} onChange={(v)=>this.handleDocChange("box_capacity_left",v)} />
                      <NumberField  width={100} id="box_capacity_right" label="Pravá" value={doc.box_capacity_right} error={doc_err.box_capacity_right} onChange={(v)=>this.handleDocChange("box_capacity_right",v)} />
                      <NumberField  width={100} id="box_capacity_top" label="Vršek" value={doc.box_capacity_top} error={doc_err.box_capacity_top} onChange={(v)=>this.handleDocChange("box_capacity_top",v)} />
                      <NumberField  width={100} id="box_capacity_bottom" label="Spodek" value={doc.box_capacity_bottom} error={doc_err.box_capacity_bottom} onChange={(v)=>this.handleDocChange("box_capacity_bottom",v)} />
                  </React.Fragment>
                )}
           
                {line_mode && (
                  <React.Fragment>
                      <NumberField  width={200} id="row_label_base" label="Počátek číslovaní řady" value={doc.row_label_base} error={doc_err.row_label_base} onChange={(v)=>this.handleDocChange("row_label_base",v)} />
                      <NumberField  width={200} id="col_label_base" label="Počátek číslovaní sloupce" value={doc.col_label_base} error={doc_err.col_label_base} onChange={(v)=>this.handleDocChange("col_label_base",v)} />
                  </React.Fragment>
                )}



    

            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>

        <Button onClick={()=>this.doClose()} color="primary">
          Zavřít
        </Button>
        <Button disabled={!valid || !dirty} onClick={onSave} color="primary">
          Uložit
        </Button>

      </DialogActions>
    </Dialog>
    );
  }
}

SeatEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  caption_edit: PropTypes.string,
  caption_new: PropTypes.string
};
SeatEditDialog.defaultProps = {
  caption_edit:"Editace sezení",
  caption_new:"Nové sezení"
};

export default withStyles(styles)(SeatEditDialog);