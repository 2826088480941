import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Query } from "react-apollo";
import {GET_SCHOOLS } from './Queries';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const styles = theme => ({
  root: {
    width:100,
    height:30
    //marginLeft:4
  }
});


class SchoolSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl:null
    }
  }
  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose(){
    this.setState({anchorEl:null});
  }
  handleSelect(school){
    this.handleClose();
    this.props.onChange(school);
  }

  render() {
   // const { value, classes} = this.props;
    const { anchorEl } = this.state;
    return (
      <React.Fragment>
        <Button  onClick={(e)=>this.handleClick(e)}>
        Vybrat školu
        </Button>
        <Query query={GET_SCHOOLS} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return (<React.Fragment></React.Fragment>);
            if (error) return `Error!: ${error}`;
            const pe = data.schools.map(s=>{
              return {key:s.id,label:s.name};
            });

            return ( 
              <Menu
              id="simple-school-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={()=>this.handleClose()}
             >
                 {pe.map(e=>{
                        return (
                            <MenuItem key={e.key} value={e.key} onClick={()=>this.handleSelect(e.label)}>{e.label}</MenuItem>
                        )
                    })}
           
            </Menu>

 
            );
          }}
          </Query>

          
       

      </React.Fragment>

    
    );
  }
}

SchoolSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
SchoolSelect.defaultProps = {
  value:null,
};

export default withStyles(styles)(SchoolSelect);