import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import StudentViewForm from './StudentViewForm';
import CircularProgress from '@material-ui/core/CircularProgress';
import ActionLink from './ActionLink';
import DebugObjectView from './Utils/DebugObjectView';
const styles = theme => ({
 
});

const GET_STUDENT = gql`
  query Student($student_key:String!){    
      student(key: $student_key) {
        key
        course_key
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        name
        surname
        email
        email2
        phone
        year
        school
        school_id
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        paid_amount
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
      }
  }
`;

class StudentView extends React.Component {

  render() {
    const {student_key,back_url,debug} = this.props;
    return (
      <div>
        <Query query={GET_STUDENT} variables={{ student_key }}>
        {({ loading, error, data }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          return (
            <React.Fragment>
            {debug && (<DebugObjectView object={{student:data.student}} />)}
            <StudentViewForm doc={data.student}/>
            </React.Fragment>
          )
        }}
      </Query>
      {this.props.back_url && (
            <ActionLink label="Zpět" href={back_url} />
      )}
      </div>
    );
  }
}

StudentView.propTypes = {  
  classes: PropTypes.object.isRequired,
  student_key:PropTypes.string.isRequired,
  back_url: PropTypes.string,
  debug: PropTypes.bool
};
StudentView.defaultProps = {
  debug:false
};

export default withStyles(styles)(StudentView);