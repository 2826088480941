import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classNames from 'classnames';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  root: {
    //width:"100%"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    textAlign: 'right'
  },
  
  b0: {
    borderBottom:0
  },

  bl: {
    borderLeft: `1px solid
    ${
      theme.palette.type === 'light'
        ? lighten(fade(theme.palette.divider, 1), 0.88)
        : darken(fade(theme.palette.divider, 1), 0.8)
    }`
  },

  row: {
    height:30,
  }
});


class CourseStatsTable extends React.Component {

  renderHeaderStatCat() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <TableCell className={classNames(classes.cell,classes.bl)}>p</TableCell>  
        <TableCell className={classes.cell}>d</TableCell>  
        <TableCell className={classes.cell}>c</TableCell>  
      </React.Fragment>
    )
  }

  renderRowStatCat(cat,key,only_total) {
    const {classes} = this.props;
    if (only_total) {
      return (
        <TableCell colSpan={3} className={classNames(classes.cell,classes.bl)}>{cat.total[key]}</TableCell>  
      )
    } else {
      return (
        <React.Fragment>
          <TableCell className={classNames(classes.cell,classes.bl)}>{cat.male[key]}</TableCell>  
          <TableCell className={classes.cell}>{cat.female[key]}</TableCell>  
          <TableCell className={classes.cell}>{cat.total[key]}</TableCell>  
        </React.Fragment>
      )
    } 
  }

  renderRowStat(key,only_total) {
      const {stats} = this.props;
      const r_in = stats.in?this.renderRowStatCat(stats.in,key,only_total):null;
      const r_filed = stats.filed?this.renderRowStatCat(stats.filed,key,only_total):null;
      const r_spare = stats.spare?this.renderRowStatCat(stats.spare,key,only_total):null;
      const r_kicked = stats.kicked?this.renderRowStatCat(stats.kicked,key,only_total):null;
      const r_total = stats.total?this.renderRowStatCat(stats.total,key,only_total):null;
      return (
        <React.Fragment>
                {r_in}
                {r_filed}
                {r_spare}
                {r_kicked}
                {r_total} 
        </React.Fragment>  
      )
  }
  render() {
    const {classes,stats} = this.props;
    const h_in = stats.in?this.renderHeaderStatCat():null;
    const h_filed = stats.filed?this.renderHeaderStatCat():null;
    const h_spare = stats.spare?this.renderHeaderStatCat():null;
    const h_kicked = stats.kicked?this.renderHeaderStatCat():null;
    const h_total = stats.total?this.renderHeaderStatCat():null;

    return (
      <Table className={classes.root}>
        <TableHead>
          <TableRow className={classes.row}>
                <TableCell className={classNames(classes.cell,classes.b0)}></TableCell>
                {stats.in && (<TableCell colSpan={3} className={classNames(classes.cell,classes.b0,classes.bl)}>zapsaní</TableCell>)}
                {stats.filed && (<TableCell colSpan={3} className={classNames(classes.cell,classes.b0,classes.bl)}>přihlášky</TableCell>)}
                {stats.spare && (<TableCell colSpan={3} className={classNames(classes.cell,classes.b0,classes.bl)}>náhradníci</TableCell>)}
                {stats.kicked && (<TableCell colSpan={3} className={classNames(classes.cell,classes.b0,classes.bl)}>vyřazení</TableCell>)}
                {stats.total && (<TableCell colSpan={3} className={classNames(classes.cell,classes.b0,classes.bl)}>vše</TableCell>)}
          </TableRow>
          <TableRow className={classes.row}>
                <TableCell className={classes.cell}></TableCell>
                {h_in}
                {h_filed}
                {h_spare}
                {h_kicked}
                {h_total} 
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.row}> 
            <TableCell className={classes.cell}>počet</TableCell>{this.renderRowStat("count",false)}
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell}>jen záloha</TableCell>{this.renderRowStat("deposit",false)}
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell}>zaplaceno</TableCell>{this.renderRowStat("paid",false)}
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell}>bez platby</TableCell>{this.renderRowStat("not_paid",false)}
          </TableRow>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell}>vybrané kurzovné</TableCell>{this.renderRowStat("paid_amount",true)}
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

CourseStatsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.any.isRequired
};
CourseStatsTable.defaultProps = {
  
};

export default withStyles(styles)(CourseStatsTable);