import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StudentEdit from './StudentEdit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';


import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    minWidth:800
  }
});


class StudentEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty:false,
      valid:true
    }
    this.edit = React.createRef();
  }

  onSave() {
    if (this.edit.current) {
      this.edit.current.wrappedInstance.doSave();
    }
  }

  onEnter() {
    this.setState({dirty:false,valid:true});
    //TODO reload?
  }

  onChange(d,v) {
    this.setState({dirty:d,valid:v});
  } 

  render() {
    const {student_key,open,onClose,caption,mode} = this.props;
    return (
      <Dialog disableBackdropClick maxWidth={false} open={open} onClose={onClose} onEnter={()=>this.onEnter()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {student_key && (<StudentEdit ref={this.edit} student_key={student_key} onClose={onClose} mode={mode}  onChange={(d,v)=>this.onChange(d,v)} />)}
      </DialogContent>
      <DialogActions>
      <Button onClick={onClose} color="primary">
          Neukládat
        </Button>
        <Button disabled={!this.state.valid} onClick={()=>this.onSave()} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

StudentEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  student_key:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
};
StudentEditDialog.defaultProps = {
  caption:"Detail žáka"
};

export default withStyles(styles)(StudentEditDialog);