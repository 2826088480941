import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StringViewField from './StringViewField';

const styles = theme => ({
 
});

function null2empty(v) {
  if ((v === null) || (v === undefined)) {return ""}
  return ""+v;
}

class NumberViewField extends React.Component {

  render() {
    const { width,label,value } = this.props;
    const val = null2empty(value);
    return (
        <StringViewField width={width} label={label} value={val}/>
    );
  }
}

NumberViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.number,
};
NumberViewField.defaultProps = {
  
};

export default withStyles(styles)(NumberViewField);