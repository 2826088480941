



const  EventTicketStatus_schema_enum = ['reservation','sold','selling','booked','cancelled','error'];
const  EventTicketStatus_gql_values =  { 
    RESERVATION:{value:"reservation"},
    SOLD:{value:"sold"},
    SELLING:{value:"selling"},
    BOOKED:{value:"booked"},
    CANCELLED:{value:"cancelled"},
    ERROR:{value:"error"},
};

const EventTicketStatus_field_enum = [
    {key:"RESERVATION",label:"rezervace"},
    {key:"SOLD",label:"prodaná"},
    {key:"SELLING",label:"čeká na platbu"},
    {key:"BOOKED",label:"v košíku"},
    {key:"CANCELLED",label:"stornovaný"},
    {key:"ERROR",label:"chyba"},
];
const EventTicketStatus_props_enum = ['RESERVATION','SOLD','SELLING','BOOKED','CANCELLED','ERROR'];


module.exports = {
    EventTicketStatus_schema_enum,
    EventTicketStatus_gql_values,
    EventTicketStatus_field_enum,
    EventTicketStatus_props_enum
};