import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux'



class AuthOnly extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state={visible:false};
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
    if (!nextProps.auth) {
      return {visible:false}
    }
    if (!nextProps.auth.user) {
      return {visible:false}
    }
    if (!nextProps.auth.user.role) {
      return {visible:false}
    }
    const role = nextProps.auth.user.role;
    //const is_view=["GUEST"].indexOf(role)!==-1;
    const is_admin=["ADMIN","SUPER_ADMIN"].indexOf(role)!==-1;
    const is_super=["SUPER_ADMIN"].indexOf(role)!==-1;
    //const is_lector=["TEACHER"].indexOf(role)!==-1;
    //TODO: view,lector
    if (nextProps.admin) {
      return {visible:is_admin}
    } else if (nextProps.super) {
      return {visible:is_super}
    }
   
  }

  render() {
   


    if (this.state.visible) {
      return ( 
        <React.Fragment>
            {this.props.children}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

AuthOnly.propTypes = {
  children: PropTypes.any,
  auth: PropTypes.object,
  admin: PropTypes.bool,
  super: PropTypes.bool,
};
AuthOnly.defaultProps = {
  admin:false,
  super:false,
};


function mapStateToProps(state) {
  return { 
      auth: state.auth
  }
}


export default compose(
  connect(mapStateToProps,{}),
)(AuthOnly)
