import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import EmailsHeaderTabs from './EmailsHeaderTabs';
import EmailIns from './EmailIns';
import EmailTemplates from './EmailTemplates';
import BulkMail from './BulkMail';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class EmailsBoard extends React.Component {

  
  renderTabIn() {
    const {auth} = this.props;
    return (
      <EmailIns auth={auth} />
    )
  }

  renderTabTemplates() {
    const {auth} = this.props;
    return (
      <EmailTemplates auth={auth} />
    )
  }

  renderTabBulk() {
    const {auth} = this.props;
    return (
      <BulkMail auth={auth} />
    )
  }

  render() {
    const { classes , auth,page} = this.props;
    var tab=null;
    switch(page) {
      case 'in': tab = this.renderTabIn(); break;
      case 'templates': tab = this.renderTabTemplates(); break;
      case 'bulk': tab = this.renderTabBulk(); break;
      default:
      break;
    }
   

    return (
      <div>
        <EmailsHeaderTabs  activeTab={page} auth={auth} />
        <div className={classes.tab_root}>{tab}</div>
      </div>
    );
  }
}

EmailsBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.oneOf(["in","templates","bulk"]).isRequired
};
EmailsBoard.defaultProps = {
  
};

export default withStyles(styles)(EmailsBoard);