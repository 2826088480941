import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 3,
      width: '100%',
    },
    textfield: {
        margin: theme.spacing.unit,
    }

});
  
function null2empty(v) {
    if ((v === null) || (v === undefined)) {return ""}
    return ""+v;
  }
  function empty2null(v) {
    if (v === "") { return null} 
    return parseInt(v,10);
  }
  


class NumberField extends React.Component {

    handleChange(e) {
        this.props.onChange(empty2null(e.target.value));
    }

    render() {
        const { classes } = this.props;
        const val = null2empty(this.props.value);
        const style = this.props.width?{width:this.props.width}:{};
        return (
            <FormControl className={classes.textfield} style={style} required={this.props.required} error={this.props.error}> 
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Input type={"number"} value={val} name={this.props.name} id={this.props.id} onChange={(e)=>this.handleChange(e)}/>
            </FormControl>
        )
    }
}


NumberField.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.number,
    error: PropTypes.bool,
    required: PropTypes.bool
};
  
NumberField.defaultProps = {
    autoFocus: false,
    name: "string",
    error:false,
    required:false,
}


export default compose(
    withStyles(styles),
)(NumberField)