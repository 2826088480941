import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import StringViewField  from './StringViewField';
import StringField  from './StringField';
import ActionButton from './ActionButton';
import { Typography } from '@material-ui/core';

const styles = theme => ({
 
});


const UPLOAD_IMAGE=gql`
mutation($file:Upload!,$name:String!){
  imageCreate(name:$name,file:$file) {
    id
  }
}
`;

class ImageUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      value:"",
      err:null,
      name:"",
      wait:false
    }

    this.file = null;
    this.input = React.createRef();
    this.form = React.createRef();
  }
  onDropFile(e) {
    const {maxFileSize} = this.props;
    const files = e.target.files;
    console.log(files);
    if (files.length) {
      const f = files[0];
      if (f.size>maxFileSize) {
        console.log("too big");
        this.setState({err:"soubor je moc velky"});
        return;
      }
      this.setState({value:e.target.value});
      this.file = f;
    
    }  else {
      console.log("no file");
    }   
  }

  doUpload(e) {
    const {client,onUploaded} = this.props;
    e.preventDefault();
    if (this.file) {
      this.setState({wait:true});
      client.mutate({
        mutation:UPLOAD_IMAGE,
        variables:{
          file:this.file,
          name:this.state.name
        },
      }).then(res=>{
       console.log(res);
       if (onUploaded) {
         onUploaded(res);
       }
       this.doClear();
      }).catch(err=>{
       console.error(err);
       this.setState({err:""+err});
       this.doClear();
      })
    } 
  }

  doAsk(e) {
    e.preventDefault();
    this.setState({err:null});
    this.input.current.click();
  }


  doClear(e) {
    if (e) e.preventDefault();
    this.file=null;
    this.setState({wait:false,value:"",name:"",file:null},()=>{
        this.form.current.reset();
    });
  }
  onSubmit(e) {
    e.preventDefault();
  }

  handleChangeName(v) {
    this.setState({name:v});
  }

  render() {
    const { accept } = this.props;
    const { value,err,wait} = this.state;
    const safe_filename = (value && this.file)?this.file.name:"";
    return (
      <form ref={this.form} onSubmit={(e)=>this.onSubmit(e)} noValidate autoComplete="off">
       
        <input style={{display:"none"}} id="profile_pic" ref={this.input} type="file" name="profile_pic" value={value} accept={accept} onChange={(e)=>this.onDropFile(e)}/>
        {/*
        <button onClick={(e)=>this.doAsk(e)}> open </button>
        <button onClick={(e)=>this.doUpload(e)}> do </button>
        <button onClick={(e)=>this.doClear(e)}> clear </button>
        */}
        <ActionButton label={"Vybrat soubor"} onClick={(e)=>this.doAsk(e)}/>
        <StringViewField  width={600} label="Vybraný soubor" value={safe_filename}  />

        <StringField required width={200} id="name" label="název pro uložení" value={this.state.name}  onChange={(v)=>this.handleChangeName(v)} />

        <ActionButton disabled={ wait || (value==null) || this.state.name.length===0} label={"Nahrát"} onClick={(e)=>this.doUpload(e)}/>

        { err && (<Typography color="error"> chyba nahravani: {err} </Typography>)}
      </form>
    );
  }
}

ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  imgExtension: PropTypes.arrayOf(PropTypes.string).isRequired,
  accept:PropTypes.string,
  maxFileSize: PropTypes.number,
  onUploaded:PropTypes.func,
};
ImageUpload.defaultProps = {
  accept: "image/png, image/jpeg",
  maxFileSize: 100000
};


export default compose(
  withStyles(styles),
  withApollo,
)(ImageUpload)
