import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Typography from '@material-ui/core/Typography';

const styles = theme => ({
 
});


class EnrollPreviewTable extends React.Component {

  constructor(props) {
    super(props);
    this.state={
    }
  }

  renderCourse(c) {
    return (
      <TableRow key={c.key}>
        <TableCell>{c.period}</TableCell>
        <TableCell>{c.code + " - " +c.name}</TableCell>
        <TableCell>{c.evi_group}</TableCell>
        <TableCell>{c.place}</TableCell>
        <TableCell>{c.teacher}</TableCell>
        <TableCell>{c.first_period}</TableCell>
      </TableRow>
    )
  }

  renderSection(section,idx) {
    return (
      <React.Fragment key={idx}>
        <TableRow>
          <TableCell colSpan={1000}><Typography variant={"title"}>{section.name}</Typography></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Termín</TableCell>
          <TableCell>Kurz</TableCell>
          <TableCell>Ev. skupina</TableCell>
          <TableCell>Místo</TableCell>
          <TableCell>Lektor</TableCell>
          <TableCell>Zahájení</TableCell>
        </TableRow>
        {section.courses.map(c=>{return this.renderCourse(c)})}
      </React.Fragment>
    )
  }
  render() {
    const { sections } = this.props;
    return (
      
         <Table>
           <TableBody>
              { sections.map((s,idx)=>{
                return this.renderSection(s,idx);
              })}
            </TableBody>
         </Table>
      
    );
  }
}

EnrollPreviewTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired
};
EnrollPreviewTable.defaultProps = {
  
};

export default withStyles(styles)(EnrollPreviewTable);