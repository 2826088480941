import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EnumField from './../EnumField';

import {SeatType_field_enum } from './../../../graphql/enums/seattype';


const styles = theme => ({
 
});



class SeatTypeField extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { value,onChange,disabled,required} = this.props;
     
    return (
      <EnumField disabled={disabled} required={required} error={this.props.error} width={250} id="seat_type" label="Typ sezení" values={SeatType_field_enum} value={value} onChange={(v)=>onChange(v)} />
    );
  }
}

SeatTypeField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool
};
SeatTypeField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false
};

export default withStyles(styles)(SeatTypeField);