import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
//import DateView from './DateView';
import Badge from '@material-ui/core/Badge';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
});


class SaleEventHeaderTabs extends React.Component {

 

  renderSaleLabel() {
    const { classes,saleCartBadge} = this.props;
    if (saleCartBadge) {
      return (
        <Badge className={classes.padding} color="secondary" badgeContent={saleCartBadge}>
          prodej
        </Badge>
      )
    } else {
      return "prodej"
    }
  }
  
  render() {
    const { classes,activeTab,event_id,event} = this.props;
    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                {event &&(
                  <React.Fragment>
                    Akce - {event.name}
                  </React.Fragment>
                )}
                {!event &&(
                  <span>{event_id} - nenalezeno</span>
                )}
            </Typography>


            {/*scrollable scrollButtons="auto"*/}
            <Tabs className={classes.toolBar_item} value={activeTab} >
              <Tab value="info" label="info" component={Link} to={"/spa/sale_event/"+event_id+"/info"}/>
              <Tab value="sale" label={this.renderSaleLabel()} component={Link} to={"/spa/sale_event/"+event_id+"/sale"}/>
              <Tab value="orders" label="objednávky" component={Link} to={"/spa/sale_event/"+event_id+"/orders"}/>
              <Tab value="reservations" label="rezervace" component={Link} to={"/spa/sale_event/"+event_id+"/reservations"}/>
              <Tab value="payments" label="platby" component={Link} to={"/spa/sale_event/"+event_id+"/payments"}/>
              <Tab value="tickets" label="vstupenky" component={Link} to={"/spa/sale_event/"+event_id+"/tickets"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

SaleEventHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  event: PropTypes.object,
  activeTab: PropTypes.oneOf(["info","tickets","payments","orders","reservations","sale"]).isRequired,
  saleCartBadge: PropTypes.number
};
SaleEventHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(SaleEventHeaderTabs);