import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "react-apollo";

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DebugObjectView from './../Utils/DebugObjectView';
import TableCell from './SimpleDataTableCell';
import SimpleRow from './SimpleDataTableRow';

import SimpleEditDialog from './SimpleEditDialog';
import SimpleDeleteDialog from './SimpleDeleteDialog';
import SimpleViewDialog from './SimpleViewDialog';
//import ActionButton from './../ActionButton';
import Lodash from 'lodash';


const styles = theme => ({
  row: {
    height:24,
  }
});

function safeS(a) {
  if ((a===null) || (a===undefined)) {
    return "";
  }
  return a;
}
const compareLocale="cz";
function safeCmp(a,b) {
  return safeS(a).localeCompare(safeS(b),compareLocale)
}
function safeCmpChain(a,b,keys) {
  for (var k of keys) {
    var c;
    if (k.charAt(0)==='-') {
        k = k.substring(1);
        c = -safeCmp(a[k],b[k]);
    } else {
        c = safeCmp(a[k],b[k]);
    }

    if (c) {
      return c
    }
  }
  return 0
}


class SimpleDataTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.state={
      order:[],
      activeDrag:false,
    }
    this.dnd_ref = React.createRef();
    this.editDialog = React.createRef();
    this.viewDialog = React.createRef();
    this.deleteDialog = React.createRef();
  }
 
  sortBy(sort_keys,autosave) {
    console.log("sdt.sortBy",sort_keys,autosave,this.props.data);
    const data = Lodash.clone(this.props.data);
    const sorted = data.sort((a,b)=>safeCmpChain(a,b,sort_keys));
    const new_order = sorted.map(i=>i.id);
    
    this.setState({order:new_order});
    if (autosave) {
      Promise.resolve(this.props.onItemsOrder(new_order)).then(res=>{
        console.log("onItemsOrder result is",res)
      }).catch(err=>{
        console.error("Nelze ulozit poradi",err);
      })
    }
  }

  _reorder(items) {
    const { order } = this.state;
    if (order.length !== items.length) {
      return items;
    }
    return order.map((id)=>{return Lodash.find(items,{id:id}) });
  }

  findRowIndex(id) {
    const items = this._reorder(this.props.data);
    const item = items.filter(c => c.id === id)[0]
    const index = items.indexOf(item);
    return index;
  }


  dndMoveRow(id, atIndex) {
    //console.log("moveRow",id,atIndex);
    
    const index = this.findRowIndex(id)
    let order = []
    if (this.state.order.length !== this.props.data.length) {
       order = this.props.data.map(i=>{return i.id});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,id);
    //console.log("new order state",order);
    this.setState({order:order});
  }

  onSaveOrder() {
    if (this.props.onItemsOrder  && this.state.order) {
      Promise.resolve(this.props.onItemsOrder(this.state.order)).then(res=>{
        console.log("onItemsOrder result is",res)
      }).catch(err=>{
        console.error("Nelze ulozit poradi",err);
      })
    } else {
      console.error("Neni callback na ulozeni poradi nebo neni poradi")
    }
  }

  onSave(doc) {
    console.log("SimpleDataTable.onSave",doc)
    Promise.resolve(this.props.onItemSave(doc)).then(res=>{
      console.log("onItemSave result is",res)
      if (this.editDialog.current) {
        this.editDialog.current.doClose();
      }
      if (this.props.autoReload && this.props.refetch) {
        this.props.refetch();
      }
    }).catch(err=>{
      console.error("Nelze ulozit",err);
    })
  }


  handleDelete(doc) {
    console.log("SimpleDataTable.onDelete",doc)
    Promise.resolve(this.props.onItemDelete(doc)).then(res=>{
      console.log("onItemDelete result is",res)
      if (this.deleteDialog.current) {
        this.deleteDialog.current.doClose();
      }
      if (this.props.autoReload && this.props.refetch) {
        this.props.refetch();
      }
    }).catch(err=>{
      console.error("Nelze smazat",err);
    })
  }

  onEdit(doc) {
    if (this.editDialog.current) {
      this.editDialog.current.doEdit(doc);
    }
  }

  onView(doc) {
    if (this.viewDialog.current) {
      this.viewDialog.current.doView(doc);
    }
  }

  onDelete(doc) {
    if (this.deleteDialog.current) {
      this.deleteDialog.current.doDelete(doc);
    }
  }


  edit(doc) {
    if (this.editDialog.current) {
      this.editDialog.current.doEdit(doc);
    }
  }


   
  renderEditDialog() {
    return (
      <SimpleEditDialog innerRef={this.editDialog} formFieldValidator={this.props.formFieldValidator}  renderForm={this.props.renderEditForm} onSave={(doc,dirty)=>this.onSave(doc,dirty)} />
    );
  }

  renderViewDialog() {
    return (
      <SimpleViewDialog innerRef={this.viewDialog} renderForm={this.props.renderViewForm} />
    );
  }

  renderDeleteDialog() {
    return (
      <SimpleDeleteDialog innerRef={this.deleteDialog} renderForm={this.props.renderDeleteForm} onDelete={(doc)=>this.handleDelete(doc)}/>
    );
  }

  render() {
    const { classes, renderHeader,renderRow,debug,renderEmptyInfo,editable,deletable,viewable,sortable} = this.props;
    //console.log("Table render");

    const actbuttons = editable || deletable || viewable || this.props.renderRowExtraActions;
    let editDialog = null;
    if (editable) {
      editDialog=this.renderEditDialog();
    }
    let viewDialog = null;
    if (viewable) {
      viewDialog=this.renderViewDialog();
    }
    let deleteDialog = null;
    if (deletable) {
      deleteDialog=this.renderDeleteDialog();
    }

    //  const rows = this.renderRows(this.props.data);
    return (
        <React.Fragment>
           {editDialog}
           {viewDialog}
           {deleteDialog}
           
           {debug && (<DebugObjectView object={{data:this.props.data}} />)}
        <Table ref={this.dnd_ref}>
            <TableHead>
                <TableRow className={classes.row}>
                   {sortable && (<TableCell>řazení</TableCell>)}
                   {renderHeader()}
                   {actbuttons && (<TableCell/>)}
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {this._reorder(this.props.data).map((item,idx)=>{
              return (
                <SimpleRow key={idx}
                  renderRow={renderRow}
                  renderRowExtraActions={this.props.renderRowExtraActions}
                  item_index={idx}
                  item={item}
                  sortable={sortable}
                  viewable={viewable}
                  editable={editable}
                  deletable={deletable}
                  onView={()=>this.onView(item)}
                  onEdit={()=>this.onEdit(item)}
                  onDelete={()=>this.onDelete(item)}
                  onDrag={(active)=>this.setState({activeDrag:active})}
                  dndMoveRow={(id,idx)=>this.dndMoveRow(id,idx)}
                  onSaveOrder={()=>this.onSaveOrder()}
                  activeDrag={this.state.activeDrag}
                  dnd_ref={this.dnd_ref}
                />
              )
            })}
            {this.props.data.length==0 && renderEmptyInfo && (
               <TableRow className={classes.row}>
                <TableCell colSpan={999}>
                {renderEmptyInfo()}
                </TableCell>
               </TableRow>
            )}
            </TableBody>
          </Table>
        </React.Fragment>
    );
  }
}


SimpleDataTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderHeader:PropTypes.func.isRequired,
  renderRow:PropTypes.func.isRequired,
  renderRowExtraActions:PropTypes.func,
  renderEmptyInfo:PropTypes.func,
  refetch:PropTypes.func,
  sortable:PropTypes.bool,
  editable:PropTypes.bool,
  deletable:PropTypes.bool,
  viewable:PropTypes.bool,
  onItemSave:PropTypes.func,
  onItemDelete:PropTypes.func,
  onItemsOrder:PropTypes.func,
  renderEditForm:PropTypes.func,
  renderViewForm:PropTypes.func,
  renderDeleteForm:PropTypes.func,
  formFieldValidator:PropTypes.func,
  autoReload:PropTypes.bool,
  debug: PropTypes.bool
};

SimpleDataTable.defaultProps = {
 debug:false,
 sortable:false,
 editable:false,
 deletable:false,
 sortable:false,
 autoReload:false
};




export default compose(
  withStyles(styles),
)(SimpleDataTable)
