import React from 'react';
import PropTypes from 'prop-types';
import {  Group ,Rect} from 'react-konva';
import Lodash from 'lodash';


const bg_colors = {
  line:'gray',
  fill:'gray',
  text:'black'
};

const zone_colors = {
  line: 'gray',
  text: 'black',
  fill: 'rgba(0,255,0,0.5)'
};

const zone_ua_colors = {
  line: 'gray',
  text: 'black',
  fill: 'rgba(255,0,0,0.5)'
};

const zone_sel_colors = {
  line: 'red',
  text: 'black',
  fill: 'rgba(0,0,255,0.5)'
};


class PlacePlanUni extends React.Component {

    decodeTarget(target) {
      if (!target) {
        return null;
      }
      if (!target.attrs) {
        return null;
      }
      try {
        const info = JSON.parse(target.attrs.id);
        return info;
      } catch(e) {
        console.log("can't decode target info, json parse problem",target,e)
      } 
      return null;
    }

    onMouseOver(e) {
     
      const info = this.decodeTarget(e.target);
      if (!info) {
        return;
      }

      //console.log("PlacePlan.onMouseOver",info)
      switch(info.type) {
        case "SeatWrongType": break;
        case "SeatCircle":
        case "SeatBox":
        case "SeatLineH":
        case "SeatLineV":
        case "SeatChair":
          if (this.props.onSeatOver) { this.props.onSeatOver(info.seat_id,info.seat_idx); }
          break;
        case "Zone":
          if (this.props.onZoneOver) { this.props.onZoneOver(info.zone_id); }
          break;
      default:
          console.log("unexpected info type (mouseOver plan)",info);
      }  

   


     
    }

    onMouseOut(e) {
     // console.log("onMouseOut",e)
      if (this.props.onSeatOver) {
        this.props.onSeatOver(null,null);
      }
      if (this.props.onZoneOver) {
        this.props.onZoneOver(null);
      }

    }
    onClick(e) {
      const info = this.decodeTarget(e.target);
      if (!info) {
        return;
      }
      //console.log("PlacePlan.onClick",info)
      switch(info.type) {
        case "SeatWrongType": break;
        case "SeatCircle":
        case "SeatBox":
        case "SeatLineH":
        case "SeatLineV":
        case "SeatChair":
          if (this.props.onSeatClick) { this.props.onSeatClick(info.seat_id,info.seat_idx); }
          break;
        case "Zone":
          if (this.props.onZoneClick) { this.props.onZoneClick(info.zone_id); }
          break;
      default:
          console.log("unexpected info type (onClick plan)",info);
      }  

  
    }

    renderOneZone(zone) {
      const {id,map_zone_key,fill_color,stroke_color,text_color} = zone;
      const id_string = JSON.stringify({type:"Zone",zone_id:id});
      const z = Lodash.find(this.props.renderZones,{key:map_zone_key});
      if (z) {
        return z.render({id_string:id_string,colors:{fill:fill_color,line:stroke_color,text:text_color}});
      }
      return null;
    }

    render() {

        if (this.props.oneZone) {
          return this.renderOneZone(this.props.oneZone)
        }

        const {renderBG,renderZones,showUnattachedZones,attachedZones,selectedZone,children,onSeatOver,onSeatClick,onZoneClick,onZoneOver}  = this.props;
        const bg = renderBG?renderBG({colors:bg_colors}):null;


        const zones = renderZones.map(z=>{

          const sel = (z.key === selectedZone);
          if (attachedZones.indexOf(z.key)!==-1) {
            return z.render({colors:sel?zone_sel_colors:zone_colors,onClick:()=>onZoneClick(z.key)});
          }  else if (showUnattachedZones) {
            return z.render({colors:sel?zone_sel_colors:zone_ua_colors,onClick:()=>onZoneClick(z.key)});
          }
          return null;
          
        })

        /*
        React.Children.forEach(children, (c)=>{
          console.log("child",c)
        })
        */
        const clisten = (onSeatOver!=null) || (onZoneOver!=null) ||  (onZoneClick!=null) || (onSeatClick!=null);

        return (
          <React.Fragment>
            {/* place background */}
            <Group listening={false}>
            {bg}
            </Group>

            {/* drag & drop background */}
            <Group listening={true}>
            <Rect
              x={0}
              y={0}
              width={5997}
              height={5997}
            />
            </Group>
            <Group listening={false}>
            {zones}
            </Group>
            <Group listening={ clisten } onTap={(e)=>this.onClick(e)} onClick={(e)=>this.onClick(e)} onMouseOver={(e)=>this.onMouseOver(e)} onMouseOut={(e)=>this.onMouseOut(e)}>
            {children}
            </Group>
          </React.Fragment>
        )
    }
}


PlacePlanUni.propTypes = {
 children: PropTypes.any,
 oneZone: PropTypes.object,
 showUnattachedZones: PropTypes.bool,
 attachedZones: PropTypes.arrayOf(PropTypes.string),
 onZoneClick: PropTypes.func,
 onSeatClick: PropTypes.func,
 onSeatOver: PropTypes.func,
 onZoneOver: PropTypes.func,
 selectedZone: PropTypes.string,
 renderBG: PropTypes.func,
 renderZones: PropTypes.arrayOf(PropTypes.shape({key:PropTypes.string.isRequired,render:PropTypes.func.isRequired}))
}
PlacePlanUni.defaultProps = {
  showUnattachedZones:false,
  renderZones:[],
  attachedZones:[],
  selectedZone: null,
  onZoneClick: null,
  onSeatOver:null,
}


export default PlacePlanUni;