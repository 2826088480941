import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanViewField from './BooleanViewField';
import StringViewField  from './StringViewField';
import DateViewField  from './DateViewField';
import NumberViewField  from './NumberViewField';
import EnumViewField from './EnumViewField';
const styles = _theme => ({
  
});

/*
addressing:{ type: GraphQLString },
//ref_gid: {type:GraphQLInt},
//reg_datetime: { type: GraphQLDateTime },
//name: { type: GraphQLString },
//surname: { type: GraphQLString },
//email: {type: GraphQLString},
//phone: {type: GraphQLString},
//year: {type:GraphQLInt},
//school:{type: GraphQLString},
//school_class:{type: GraphQLString},
//street:{type: GraphQLString},
//street_no:{type: GraphQLString},
//city:{type: GraphQLString},
//post_code:{type: GraphQLString},
//comment:{type: GraphQLString},
//course_cost: {type:GraphQLInt},
//paid: {type:GraphQLInt},
//paid_ok:{type:GraphQLBoolean},
//long_period: {type:GraphQLBoolean},
//student_check:{type:GraphQLBoolean},
//student:{type:GraphQLBoolean},
//card_out:{type:GraphQLBoolean},
//var_sym:{type:GraphQLInt},
//pay_info: {type: GraphQLString},
//discount: {type: GraphQLString},
*/



class StudentViewForm extends React.Component {



  render() {
    const { doc } = this.props;
    return (
      <div className="foo">
          <div>
          <EnumViewField  width={100}  label="Oslovení" values={[{key:"s",label:"slečna"},{key:"d",label:"paní"},{key:"p",label:"pan"}]} value={doc.addressing}  />
          <StringViewField  label="Přijmení" value={doc.surname}  />
          <StringViewField  label="Jméno" value={doc.name}  />
          <StringViewField   width={300} label="Email" value={doc.email}  />
          <StringViewField   width={300} label="Email2" value={doc.email2}  />
          <StringViewField  label="Telefon" value={doc.phone}  />
          <NumberViewField  width={100}  label="Ročník" value={doc.year}  />
          </div>
          <div>
          <DateViewField  width={200} label="Datum přihlášení" value={doc.reg_datetime}  />
          <NumberViewField  width={150}  label="Číslo karty" value={doc.ref_gid}  />
          <StringViewField  width={150}  label="Kód karty" value={doc.card_id}  />
          <BooleanViewField  width={100}  label="Student" value={doc.student} />
          <BooleanViewField  width={150}  label="Student ověřen" value={doc.student_check} />
          <BooleanViewField  width={100}  label="Karta vydána" value={doc.card_out} />
          </div>
          <div>
          <NumberViewField  width={100}  label="Kurzovné" value={doc.course_cost}  />
          <BooleanViewField  width={100}  label="Celo-roční" value={doc.long_period} />
          <NumberViewField  width={100}  label="Zaplaceno" value={doc.paid_amount}  />
          <StringViewField  label="Sleva" value={doc.discount}  />
          <NumberViewField  width={150}  label="Var. symbol" value={doc.var_sym}  />
          <BooleanViewField  width={150}  label="Zaplaceno vše" value={doc.paid_ok} />
          </div>
          <div>
          <StringViewField  width={500} label="Škola" value={doc.school}  />
          <StringViewField  label="Třída" value={doc.school_class}  />
          <StringViewField  width={200} label="Ulice" value={doc.street}  />
          <StringViewField  label="Číslo" value={doc.street_no}  />
          <StringViewField  label="Město" value={doc.city}  />
          <StringViewField  label="Psč" value={doc.post_code}  />
          </div>
          <StringViewField  width={500} label="Poznámka" value={doc.comment}  />
       </div>
    );
  }
}

StudentViewForm.propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  detail: PropTypes.bool
};
StudentViewForm.defaultProps = {
  detail:false
};

export default withStyles(styles)(StudentViewForm);