import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from "lodash";
import {EventStatus_field_enum } from './../../../graphql/enums/eventstatus';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class EventStatusView extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,event_status } = this.props;
    const seatt = Lodash.find(EventStatus_field_enum,{key:event_status});
    return ( 
      <span className={classes.root}>
      { seatt?seatt.label:(<span> ? {event_status} </span>) }
      </span>
    );
  
  }
}

EventStatusView.propTypes = {
  classes: PropTypes.object.isRequired,
  event_status: PropTypes.string
};
EventStatusView.defaultProps = {
  
};

export default withStyles(styles)(EventStatusView);