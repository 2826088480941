import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import DateView from './DateView';
import ActionLink from './ActionLink';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  }
});


class SetupEventZoneHeaderTabs extends React.Component {

 


  
  render() {
    const { classes,activeTab,event_id,event_zone_id,event,event_zone} = this.props;
    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                {event && event_zone &&(
                  <React.Fragment>
                    Akce - {event.name} - <DateView date={event.date} /> - Zona: {event_zone.name}
                  </React.Fragment>
                )}
                {! (event  && event_zone) &&(
                  <span>event:{event_id},event_zone:{event_zone_id} - nenalezeno</span>
                )}
            </Typography>


            <ActionLink useRouter tooltip={"zpět na zóny události"} label="Zpět"  href={'/spa/setup_event/'+event.id+'/zones'}/>
             

            <Tabs className={classes.toolBar_item} value={activeTab}>
              <Tab value="info" label="info" component={Link} to={"/spa/setup_event_zone/"+event_id+"/"+event_zone_id+"/info"}/>
              <Tab value="seats" label="sezení" component={Link} to={"/spa/setup_event_zone/"+event_id+"/"+event_zone_id+"/seats"}/>
              <Tab value="prices" label="ceny" component={Link} to={"/spa/setup_event_zone/"+event_id+"/"+event_zone_id+"/prices"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

SetupEventZoneHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  event_zone_id: PropTypes.string.isRequired,
  event: PropTypes.object,
  event_zone: PropTypes.object,
  activeTab: PropTypes.oneOf(["info","seats","prices"]).isRequired,
};
SetupEventZoneHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(SetupEventZoneHeaderTabs);