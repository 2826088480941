import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import CourseForm from './CourseForm';


const GET_COURSE = gql`
  query Course($course_key:String!){
      course(key: $course_key) {
        key
        evi_group
        folder_key
        season_key
        name
        code
        active
        suspend
        cost_sa
        cost_sb
        cost_a
        cost_b
        cost_mode
        cost_sale
        accounting_year
        group_mode
        var_sym_prefix
        capacity
        card_line_1
        card_line_2
        period
        first_period
        teacher
        place
        comment
      }
    
  }
`;

const UPDATE_COURSE = gql`
mutation updateCourse($key:String!,$evi_group: EviGroup, $name:String, $code:String, $active:Boolean, $cost_a:Int,$cost_b:Int,$cost_sa:Int,$cost_sb:Int,$cost_sale:Boolean,$cost_mode:CostMode,$group_mode:GroupMode,$capacity:Int,$card_line_1:String,$card_line_2:String,$period:String,$first_period:String,$teacher:String,$place:String,$comment:String,$accounting_year:Int,$var_sym_prefix:Int!) {
  courseUpdate(key:$key,evi_group:$evi_group,name:$name,code:$code,active:$active,cost_a:$cost_a,cost_b:$cost_b,cost_sa:$cost_sa,cost_sb:$cost_sb,cost_sale:$cost_sale,cost_mode:$cost_mode,group_mode:$group_mode,capacity:$capacity,card_line_1:$card_line_1,card_line_2:$card_line_2,period:$period,first_period:$first_period,teacher:$teacher,place:$place,comment:$comment,accounting_year:$accounting_year,var_sym_prefix:$var_sym_prefix) {
    key
    evi_group
    folder_key
    season_key
    name
    code
    active
    suspend
    cost_sa
    cost_sb
    cost_a
    cost_b
    cost_mode
    cost_sale
    accounting_year
    group_mode
    var_sym_prefix
    capacity
    card_line_1
    card_line_2
    period
    first_period
    teacher
    place
    comment
  }
}
`;

class CourseEdit extends React.Component {


  constructor(props) {
    super(props);

    this.state = {  
      dirty:false,
      valid:true
    }
    this.form = React.createRef();
  }

  doSave() {
    console.log("CourseEdit doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:UPDATE_COURSE,
      variables:this.form.current.state.doc
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
    //console.log("this.form.current",this.form.current);
  }


  onDocChange(d,valid) {
    console.log("CourseEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 
 
  render() {
    const {course_key,evi_group} = this.props;
    return (
      <Query query={GET_COURSE} variables={{ course_key }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
                <CourseForm innerRef={this.form} lock_evi_group={evi_group!==null} onDocChange={(d,v)=>this.onDocChange(d,v)} doc={data.course}/>
          )
        }}
      </Query>
    );
  }
}

CourseEdit.propTypes = {
  client: PropTypes.object.isRequired,
  course_key: PropTypes.string.isRequired,
  evi_group: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
CourseEdit.defaultProps = {
 
};

export default withApollo(CourseEdit,{withRef:true});
