import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query } from "react-apollo";
import Lodash from "lodash";
import {GET_ZONES } from './Queries';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class ZoneIDView extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,zone_id } = this.props;
    return (
      <Query query={GET_ZONES} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (loading) return null; 
        if (error) return `Error!: ${error}`;
        const zone = Lodash.find(data.zones,{id:zone_id});
        return ( 
          <span className={classes.root}>
          { zone?zone.name:(<span> ? {zone_id} </span>) }
          </span>
        );
      }}
      </Query>
    );
  }
}

ZoneIDView.propTypes = {
  classes: PropTypes.object.isRequired,
  zone_id: PropTypes.string
};
ZoneIDView.defaultProps = {
  
};

export default withStyles(styles)(ZoneIDView);