import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect} from 'react-router-dom'
import Courses from './Courses';
import MyCourses from './MyCourses';
import CoursesHeader from './CoursesHeader';
import Typography from '@material-ui/core/Typography';

import { compose } from 'react-apollo'
import { connect } from 'react-redux'

const styles = theme => ({
});


class CoursesBoard extends React.Component {

  
  renderCourses() {
    const { auth,folder_key,season_key, evi_group } = this.props;
    if (folder_key===null || season_key===null) {
      return (
          <Typography variant="title" color="error"> Není zvolena sezóna nebo složka! </Typography>
      )
    }
    return ( 
      <Courses auth={auth} folder_key={folder_key} season_key={season_key} evi_group={evi_group} />
    );
  }
  renderMyCourses() {
    const { auth } = this.props;
    return ( 
      <MyCourses auth={auth} list_key={auth.course_list} />
    );
  }

  render() {
    const { auth } = this.props;
    var courses =null;
    if (auth.is_admin_or_view) {
      courses = this.renderCourses();
    } else if (auth.is_lector) {
      courses = this.renderMyCourses();
    } else if (auth.is_clerk) {
      return (
        <Redirect to={"/spa/tickets/events"} />
      );
    } else {
      return null;
    }
    return (
      <div>
        <CoursesHeader auth={auth} />
        {courses}
      </div>
    );
  }
}

CoursesBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  season_key: PropTypes.string,
  folder_key: PropTypes.string,
  evi_group: PropTypes.string,
};
CoursesBoard.defaultProps = {
  
};

function mapStateToProps(state) {
  return { 
      folder_key: state.select.folder_key,
      season_key: state.select.season_key,
      evi_group: state.select.evi_group,
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,{})
)(CoursesBoard)