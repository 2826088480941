import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  checkbox: {
    height: "20px",
    width: "20px",
  },
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  courses: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func
};

const defaultProps = {
  courses: [],
  selected: []
};

class CourseList extends React.Component {
  

  renderCourse(c) {
    const { classes } = this.props;
    const isSelected = this.props.selected.indexOf(c.id) !== -1;
    return (
      <ListItem
        key={c.id}
      >
        <Checkbox
                checked={isSelected}
                onChange={(event,checked)=>this.props.onSelect(c.id,checked)}
                tabIndex={-1}
                disableRipple
                className={classes.checkbox}
        />
        <ListItemText primary={c.code +" - "+c.name} />
      </ListItem>
    )
  }

  render() {
    const { classes } = this.props;
    const ci = this.props.courses.map((s=>this.renderCourse(s)));
    return (
      <div className={classes.root}>
        <List dense>
          {ci}
        </List>
      </div>
    );
  }
}

CourseList.propTypes = propTypes;
CourseList.defaultProps = defaultProps;

export default withStyles(styles)(CourseList);