
import Lodash from "lodash";

function getTicketPlaceText(event_ticket,event_seats) {
    const seat = Lodash.find(event_seats,{id:event_ticket.event_seat_id});
    let place = "?";
    if (seat) {
        place = "";

        if (seat.type == "LINE_H") {
            place += seat.name+ ", židle "+(event_ticket.event_seat_idx+seat.col_label_base);
        } else if (seat.type == "LINE_V") {
            place += seat.name+ ", židle "+(event_ticket.event_seat_idx+seat.row_label_base);
        } else if (seat.type == "BOX" || seat.type=="CIRCLE") {
            place += "stůl "+seat.name+", židle "+(event_ticket.event_seat_idx+1);
        } else if (seat.type == "FREE") {
            place += "bez určení místa (p.č. "+(event_ticket.event_seat_idx+1)+")";
        } else {
            place += seat.type+"/"+seat.name+"/"+(event_ticket.event_seat_idx+1);
        }
    }
    return place;
} 

function getTicketPrimaryText(event_ticket,event_zones) {
    return getTicketZoneText(event_ticket,event_zones)+", "+event_ticket.name+" - "+event_ticket.cost+"kč"
}

function getTicketZoneText(event_ticket,event_zones) {
    const zone = Lodash.find(event_zones,{id:event_ticket.event_zone_id});
    let zt = "?"
    if (zone) {
      zt = zone.name;
    }
    return zt;
}

function getTicketZonePlaceText(event_ticket,event_zones,event_seats) {
    return getTicketZoneText(event_ticket,event_zones)+"/"+getTicketPlaceText(event_ticket,event_seats);
}

export {
    getTicketPlaceText,
    getTicketPrimaryText,
    getTicketZoneText,
    getTicketZonePlaceText
};