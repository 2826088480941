


const  EventStatus_schema_enum = ['new','ready','open','suspended','closed','error'];
const  EventStatus_gql_values =  { 
    NEW:{value:"new"},
    READY:{value:"ready"},
    OPEN:{value:"open"},
    SUSPENDED:{value:"suspended"},
    CLOSED:{value:"closed"},
    ERROR:{value:"error"},
};

const EventStatus_field_enum = [
    {key:"NEW",label:"nová"},
    {key:"READY",label:"připravená"},
    {key:"OPEN",label:"v prodeji"},
    {key:"SUSPENDED",label:"pozastavená"},
    {key:"CLOSED",label:"ukončená"},
    {key:"ERROR",label:"chyba"},
];
const EventStatus_props_enum = ['NEW','READY','OPEN','SUSPENDED','CLOSED','ERROR'];


module.exports = {
    EventStatus_schema_enum,
    EventStatus_gql_values,
    EventStatus_field_enum,
    EventStatus_props_enum
};