import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';



const styles = theme => ({
  chip: {
    margin: theme.spacing.unit / 2,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  }
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  folder_dict: PropTypes.object,  //?
  season_dict: PropTypes.object,  //?
  courses: PropTypes.array,
  onDelete: PropTypes.func
};

const defaultProps = {
  courses: [],
};

class CourseChips extends React.Component {
 

  longCourseName(course) {
    const f = this.props.folder_dict[course.folder_key];
    const s = this.props.season_dict[course.season_key];
    return s.name+" "+f.name+" - "+course.code;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.row}>
        {this.props.courses.map(c => {
          if (this.props.onDelete) {
          return (
            <Chip
              label={this.longCourseName(c)}
              key={c.id}
              onDelete={()=>this.props.onDelete(c.id)}
              className={classes.chip}
            />
          ); } else {
            return (
              <Chip
                label={this.longCourseName(c)}
                key={c.id}
                className={classes.chip}
              />
            );
          }

        })}
      </div>
    );
  }
}

CourseChips.propTypes = propTypes;
CourseChips.defaultProps = defaultProps;

export default withStyles(styles)(CourseChips);