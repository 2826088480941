import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Paper from '@material-ui/core/Paper';
import { Stage, Layer } from 'react-konva';
//import Chairs from './FloorPlan/Chairs';
import ButtonZoomIn from './FloorPlan/ButtonZoomIn';
import ButtonZoomOut from './FloorPlan/ButtonZoomOut';
import ButtonZoomReset from './FloorPlan/ButtonZoomReset';

import PlanLegendPanel from './FloorPlan/PlanLegendPanel';

//import Button from '@material-ui/core/Button';

import PlacePlan from './FloorPlan/PlacePlan';

import Seat from './FloorPlan/Seat';
import Zone from './FloorPlan/Zone';
import {getZoneBoundingBox,getPlaceBoundingBox} from './FloorPlan/Utils';

import { compose } from 'react-apollo';
//import gql from 'graphql-tag';
import ResizeDetector from 'react-resize-detector';
import Lodash from 'lodash';

const styles = theme => ({
  lim_div: {
    maxWidth:1000,
    minWidth:300,
    minHeight:300
  },
  root: {
    width:"100%",
    paddingTop: "100%",
    position: "relative"
  },
  root_in: {
    position:  "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    border: "1px solid black",
  }
});

/*
const MARKER_COLORS = {
  'XX': 'aaa'
}
*/

function markerColor(m) {
  if (m.type == "SELECTED") {
    return "purple"
  }

  return m.my_booking?'blue':'red';
}

function prepareMarker(m) {
  return {
    seat_id:m.event_seat_id,
    seat_idx: m.event_seat_idx,
    color: markerColor(m),
  }
}



class EventPlanView extends React.Component {




  constructor(props) {
    super(props);
    this.plan_layer = React.createRef();
    this.zoom_steps = 5;
    //this.updateDefaultZoomAndOffset();
  
    this.state= {
      //zoomLevel:this.default_zoom,
      pointer_x:null,
      pointer_y:null,
      seat_over_id:null,
      seat_over_idx:null,
      //auto_width:this.auto_width,
      //auto_height:this.auto_height,

      size_ready:false,      
      detected_width:null,
      detected_height:null,
    }

    //this.handleResize = this.updateDim.bind(this);
  }

  zoomToZone(zone) {
    const { detected_width,detected_height } = this.state;
    if (detected_height && detected_width) {
      let box = getZoneBoundingBox(this.props.place.map,zone.map_zone_key);
      this.updateDefaultZoomAndOffset(detected_width,detected_height,box);
      this.handleClickZoomReset();
    }
  }

  zoomToPlace() {
    const { detected_width,detected_height } = this.state;
    if (detected_height && detected_width) {
      let box = getPlaceBoundingBox(this.props.place.map);
      this.updateDefaultZoomAndOffset(detected_width,detected_height,box);
      this.handleClickZoomReset();
    }
  }

  onMouseMove(e) {
    if (!this.props.show_pointer) {
      return;
    }
    let x = e.evt.layerX;
    let y = e.evt.layerY;
    const plan_layer = this.plan_layer.current;
    if (plan_layer) {
      const tr = plan_layer.getAbsoluteTransform().copy();
      const p = tr.invert().point({x,y});
      this.setState({pointer_x:Math.round(p.x),pointer_y:Math.round(p.y)});
    }
 }

  onAutoResize(w,h) {
    console.log("EventPlanView.onAutoResize",w,h)
    

    this.updateDefaultZoomAndOffset(w,h);

    this.setState({detected_width:w,detected_height:h,size_ready:true,zoomLevel:this.default_zoom},()=>{
      
    })
  }

  getBoudingBox() {
    let box = {x:0,y:0,w:6000,h:6000};
    if (this.props.zoomZone &&  !this.props.zoomZone.free_zone) {
      box = getZoneBoundingBox(this.props.place.map,this.props.zoomZone.map_zone_key);
    } else {
      box = getPlaceBoundingBox(this.props.place.map);
    }
    return box;
  }

  getDefaultZoomAndOffset(w,h,opt_box) {
    
    //this.auto_width = window.innerWidth*(this.props.halfWidth?0.4:0.9);
    //this.auto_height = window.innerHeight*0.9-100;

    let width = w;//this.props.width?this.props.width:this.auto_width;
    let height = h;//this.props.height?this.props.height:this.auto_height;
    
    let box = {x:0,y:0,w:6000,h:6000};
    if (opt_box) {
      box=opt_box;
    } else {
      box = this.getBoudingBox();
    }

   
    let max_box = getPlaceBoundingBox(this.props.place.map);


    //TODO: box.x != 0 !!!!?

    let zoomW = width / box.w;
    let zoomH = height / box.h;
    let zoom = Math.min(zoomW,zoomH,1);  

    let min_zoomW = width / max_box.w;
    let min_zoomH = height / max_box.h;
    let min_zoom = Math.min(min_zoomW,min_zoomH,1);


    let center_x = (width-(box.w*zoom))/2;
    let center_y = (height-(box.h*zoom))/2;

    let offsetX = center_x-box.x*zoom;
    let offsetY = center_y-box.y*zoom;

    return {zoom:zoom,max_zoom:1,min_zoom:min_zoom,x:offsetX,y:offsetY,width:width,height:height};
  }



  updateDefaultZoomAndOffset(w,h,box) {
    const p = this.getDefaultZoomAndOffset(w,h,box);
    this.width=p.width;
    this.height=p.height;
    this.default_zoom=p.zoom;
    this.max_zoom=p.max_zoom;
    this.min_zoom=p.min_zoom;
    this.current_zoom=p.zoom;
    this.current_x=p.x;
    this.current_y=p.y;
    this.default_x=p.x;
    this.default_y=p.y;
  }

/*
  updateDim() {
    console.log("updateDim");
    this.updateDefaultZoomAndOffset();
    this.setState({auto_width:this.auto_width,auto_height:this.auto_height});
  }

  componentDidMount() {
    this.updateDim();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
*/

  handleClickZoomOut() {
    const zoom_step = (this.max_zoom - this.min_zoom)/this.zoom_steps;
    const new_zoom = Math.max(this.current_zoom-zoom_step,this.min_zoom);
    const old_zoom = this.current_zoom;
    this.current_zoom = new_zoom;
    this.current_x = -(((new_zoom*(-this.current_x+this.width/2))/old_zoom)-this.width/2);
    this.current_y = -(((new_zoom*(-this.current_y+this.height/2))/old_zoom)-this.height/2);
  
    this.redrawLayer();
  }

  handleClickZoomIn() {
    const zoom_step = (this.max_zoom - this.min_zoom)/this.zoom_steps;
    const new_zoom = Math.min(this.current_zoom+zoom_step,this.max_zoom);
    const old_zoom = this.current_zoom;
    this.current_zoom = new_zoom;
    this.current_x = -(((new_zoom*(-this.current_x+this.width/2))/old_zoom)-this.width/2);
    this.current_y = -(((new_zoom*(-this.current_y+this.height/2))/old_zoom)-this.height/2);
 
    this.redrawLayer();
  }


  handleClickZoomReset() {
    this.current_x = this.default_x;
    this.current_y = this.default_y;
    this.current_zoom = this.default_zoom;

    this.redrawLayer();
  }

  redrawLayer() {
    const plan_layer = this.plan_layer.current;
    if (plan_layer) {
      plan_layer.x(this.current_x);
      plan_layer.y(this.current_y);
      plan_layer.scaleX(this.current_zoom);
      plan_layer.scaleY(this.current_zoom);
      plan_layer.draw();
    }
  }

  onDragEnd(e) {
    const plan_layer = this.plan_layer.current;
    console.log("onDragEnd");
    if (plan_layer) {
      this.current_x=plan_layer.x();
      this.current_y=plan_layer.y();
    }
  }

  onSeatOver(seat_id,seat_idx) {
    this.setState({seat_over_id:seat_id,seat_over_idx:seat_idx})
    //console.log("onSeatOver",seat_id,seat_idx)
    if (this.props.onSeatHover) {
      if (seat_id) {
        const seat = Lodash.find(this.props.seats,{id:seat_id});
        if (!seat) {
          console.error("EventPlanView, onSeatOver, can't find seat!");
        } else {
          this.props.onSeatHover(seat,seat_idx);
        }
      } else {
        this.props.onSeatHover(null,null);
      }
    }
  }

  onSeatClick(seat_id,seat_idx) {
    console.log("onSeatClick",seat_id,seat_idx)
    if (this.props.onSeatClick) {
        const seat = Lodash.find(this.props.seats,{id:seat_id});
        if (!seat) {
          console.error("EventPlanView, onSeatClick, can't find seat!");
        } else {
          this.props.onSeatClick(seat,seat_idx);
        }
    }
  }

  onZoneOver(zone_id) {
    this.setState({zone_over_id:zone_id})
   // console.log("onZoneOver",zone_id)
  }

  onZoneClick(zone_id) {
    //this.setState({zone_over_id:zone_id})
    console.log("onZoneClick",zone_id)
    if (this.props.onZoneClick) {
      const zone = Lodash.find(this.props.zones,{id:zone_id});
      if (!zone) {
        console.error("EventPlanView, onZoneClick, can't find zone!");
      } else {
        this.props.onZoneClick(zone);
      }
    }
  }


  
  render() {
    const { classes,place,seats,zones,show_pointer,seat_markers,disableTableSelection,showLegend } = this.props;


  
    const seat_markers_dict = Lodash.groupBy(Lodash.map(seat_markers,prepareMarker),'seat_id');
    
    return (
      <React.Fragment>
      {show_pointer &&(
        <div>{this.state.pointer_x}x{this.state.pointer_y}</div>
      )}  
      <div className={classes.lim_div}>
      <div className={classes.root}>
      <div className={classes.root_in}>
         <ResizeDetector handleWidth handleHeight onResize={(w,h)=>this.onAutoResize(w,h)} />
         {this.state.size_ready && (
            <Stage width={this.state.detected_width} height={this.state.detected_height} onMouseMove={(e)=>this.onMouseMove(e)}>
                <Layer x={this.current_x} y={this.current_y} ref={this.plan_layer}  onDragEnd={(e)=>this.onDragEnd(e)}  draggable={true} scaleX={this.current_zoom} scaleY={this.current_zoom} >
                  <PlacePlan place_map={place.map} 
                    onSeatOver = {(seat_id,seat_idx)=>this.onSeatOver(seat_id,seat_idx)}
                    onSeatClick = {(seat_id,seat_idx)=>this.onSeatClick(seat_id,seat_idx)}

                    onZoneOver = {(zone_id)=>this.onZoneOver(zone_id)}
                    onZoneClick = {(zone_id)=>this.onZoneClick(zone_id)}
                  >

                    {zones.map(z=>{

                      const primary_color = z.primary_color?z.primary_color:"red";
                      const secondary_color = z.secondary_color?z.secondary_color:"red";
                      if (z.free_zone) {
                        return null;
                      }
                      return (
                        <Zone key={z.id} id={z.id} place_map={place.map} map_zone_key={z.map_zone_key} primary_color={primary_color} secondary_color={secondary_color}/>
                      )
                    })}

                    {seats.map(s=>{
                        let markers = null;
                        let markers_dict = null;
                        if (seat_markers_dict) {
                          markers = seat_markers_dict[s.id];
                          markers_dict = Lodash.mapValues(Lodash.groupBy(markers,'seat_idx'),(v)=>{return v[0]});
                        }
                        // console.log("SeatMarkers for s.id:",s.type,  markers_dict);
                        return (
                          <Seat key={s.id} zone_id={s.event_zone_id?s.event_zone_id:s.zone_id} id={s.id} name={s.name}  type={s.type} 
                            color="black"
                            markers={null}
                            markers_dict={markers_dict}
                            capacity={s.capacity}
                            map_position_x={s.map_position_x} map_position_y={s.map_position_y} map_position_angle={s.map_position_angle} map_sector_angle={s.map_sector_angle}
                            box_capacity_left={s.box_capacity_left}
                            box_capacity_right={s.box_capacity_right}
                            box_capacity_top={s.box_capacity_top}
                            box_capacity_bottom={s.box_capacity_bottom}
                          

                            hover={s.id == this.state.seat_over_id}
                            hover_idx={(s.id == this.state.seat_over_id)?this.state.seat_over_idx:-1}
                            disableTableSelection={disableTableSelection}
                          />
                        );
                    })}
                  </PlacePlan>
                </Layer>
                <Layer>
                  <ButtonZoomIn x={10} y={10} onClick={(x)=>this.handleClickZoomIn(x)} />
                  <ButtonZoomOut x={40} y={10} onClick={(x)=>this.handleClickZoomOut(x)} />
                  <ButtonZoomReset x={70} y={10} onClick={(x)=>this.handleClickZoomReset(x)} />

                  {(this.state.detected_height!=null && showLegend) && (
                    <PlanLegendPanel x={15} y={this.state.detected_height-10} />
                  )}
                  
                </Layer>
            </Stage>
        )}
      </div>
      </div>
      </div>
      </React.Fragment>
    );
  }
}

EventPlanView.propTypes = {
  classes: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
  show_pointer: PropTypes.bool,
  zones: PropTypes.arrayOf(PropTypes.object),
  seats: PropTypes.arrayOf(PropTypes.object),
  seat_markers: PropTypes.arrayOf(PropTypes.object),
  zoomZone: PropTypes.object,
  onSeatHover: PropTypes.func,
  onSeatClick: PropTypes.func,
  onZoneClick: PropTypes.func,
  disableTableSelection: PropTypes.bool,
  showLegend: PropTypes.bool
};
EventPlanView.defaultProps = {
  show_pointer: false,
  visibleZones: [],
  seats:[],
  zones:[],
  seat_markers:[],
  disableTableSelection:false,
  showLegend: false
};


export default compose(
  withStyles(styles),
)(EventPlanView)
