
const opts = (state={srch_season_keys:[]}, action) => {
    console.log("ACTION",action)
    switch (action.type) {
      case 'SET_SRCH_SEASONS':
        return  Object.assign({}, state, {
          srch_season_keys: action.srch_season_keys
        })
      default:
        return state
    }
  }
  
  export default opts;