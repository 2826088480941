import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { DatePicker } from 'material-ui-pickers';

const styles = theme => ({
  textfield: {
    margin: theme.spacing.unit,
  }
});



class DateField extends React.Component {

  constructor(props) {
    super(props);
  }
  onChange = (date)=>{
    if (date) {
      this.props.onChange(date.format("YYYY-MM-DD"));
    } else {
      this.props.onChange(null);
    }
    
  }

  render() {
    const {classes, label,value,disabled,required,error,width} = this.props;
   
    return (
      <DatePicker 
        className={classes.textfield}
        error={error} 
        clearable={!required} 
        required={required} 
        disabled={disabled} 
        animateYearScrolling={false}  
        label={label} 
        value={value} 
        format="L"
        onChange={this.onChange}
        width={width}
        cancelLabel="Zrušit"
        clearLabel="Vymazat"
        />
    );
  }
}

DateField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  width:PropTypes.number
};
DateField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false,
  width:300
};

export default withStyles(styles)(DateField);