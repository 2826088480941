


const  OrderOwner_schema_enum = ['public','clerk','admin'];
const  OrderOwner_gql_values =  { 
    PUBLIC:{value:"public"},
    CLERK:{value:"clerk"},
    ADMIN:{value:"admin"},
};

const OrderOwner_field_enum = [
    {key:"PUBLIC",label:"online"},
    {key:"CLERK",label:"přímý"},
    {key:"ADMIN",label:"administrator"},
];
const OrderOwner_props_enum = ['PUBLIC','CLERK','ADMIN'];


module.exports = {
    OrderOwner_schema_enum,
    OrderOwner_gql_values,
    OrderOwner_field_enum,
    OrderOwner_props_enum
};