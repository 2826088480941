import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Query, compose } from "react-apollo";
import SimpleDataTable from './SimpleDataTable';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
});



class SimpleGQLTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
 
  refetch() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  }
  
  edit(item) {
    if (this.table.current) {
      this.table.current.edit(item);
    }
  }
  sortBy(keys,autosave) {
    if (this.table.current) {
      this.table.current.sortBy(keys,autosave);
    }
  }

  gqlSave(client,item) {
    const {gqlCreate,gqlUpdate} = this.props;
    if (item.id) {
      if (gqlUpdate) {
        return client.mutate({
          mutation: gqlUpdate,
          variables:item
        });
      } else {
        console.error("gqlUpdate not defined");
        return false;
      }
    } else {
      if (gqlCreate) {
        return client.mutate({
          mutation: gqlCreate,
          variables:item
        });
      } else {
        console.error("gqlCreate not defined");
        return false;
      }
    }
  }

  gqlDelete(client,item) {
    const {gqlDelete} = this.props;
    if (gqlDelete) {
      return client.mutate({
        mutation: gqlDelete,
        variables:item
      });
    } else {
      console.error("gqlDelete not defined");
      return false;
    }
  }

  gqlSaveOrder(client,item_ids) {
    //console.log("gqlSaveOrder",item_ids);

    const {gqlSaveOrder,variables} = this.props;

    let ovar = Object.assign({},variables);
    ovar["ids"] = item_ids;

    console.log("gqlSaveOrder",ovar);
    

    if (gqlSaveOrder) {
      return client.mutate({
        mutation: gqlSaveOrder,
        variables: ovar,
      });
    } else {
      console.error("gqlSaveOrder not defined");
      return false;
    }

  }


  render() {
    const { autoReload,query,variables, formFieldValidator,renderEmptyInfo,renderRow,renderRowExtraActions,renderHeader,debug,getData,onItemSave,onItemDelete,onItemsOrder} = this.props;


    return (
        <Query query={query} variables={variables} fetchPolicy={"network-only"}>
           {({ loading, error, data, refetch, client }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <SimpleDataTable 
                  innerRef = {this.table}
                  renderRow={renderRow}
                  renderRowExtraActions={renderRowExtraActions}
                  renderHeader={renderHeader}
                  renderEmptyInfo={renderEmptyInfo}
                  refetch={refetch}
                  debug={debug}
                  data={getData(data)}
                  sortable={this.props.sortable}
                  editable={this.props.editable}
                  viewable={this.props.viewable}
                  deletable={this.props.deletable}
                  onItemSave={onItemSave?onItemSave:(item)=>this.gqlSave(client,item)}
                  onItemDelete={onItemDelete?onItemDelete:(item)=>this.gqlDelete(client,item)}
                  onItemsOrder={onItemsOrder?onItemsOrder:(item_ids)=>this.gqlSaveOrder(client,item_ids)}

                  renderEditForm={this.props.renderEditForm}
                  renderViewForm={this.props.renderViewForm}
                  renderDeleteForm={this.props.renderDeleteForm}
                  formFieldValidator={formFieldValidator}
                  autoReload={autoReload}
              />
            );
          }}
        </Query>
    );
  }
}

SimpleGQLTable.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  renderHeader:PropTypes.func.isRequired,
  renderRow:PropTypes.func.isRequired,
  renderRowExtraActions:PropTypes.func,
  renderEmptyInfo:PropTypes.func,
  getData:PropTypes.func.isRequired,

  sortable:PropTypes.bool,
  editable:PropTypes.bool,
  viewable:PropTypes.bool,
  deletable:PropTypes.bool,
  
  onItemSave:PropTypes.func,
  onItemDelete:PropTypes.func,
  onItemsOrder:PropTypes.func,
  gqlUpdate:PropTypes.object,
  gqlCreate:PropTypes.object,
  gqlDelete:PropTypes.object,
  gqlSaveOrder:PropTypes.object,

  renderEditForm:PropTypes.func,
  renderViewForm:PropTypes.func,
  renderDeleteForm:PropTypes.func,

  formFieldValidator:PropTypes.func,
  autoReload: PropTypes.bool,
  debug: PropTypes.bool
};

SimpleGQLTable.defaultProps = {
 debug:false,
 editable:false,
 sortable:false,
 viewable:false,
 deletable:false,
 autoReload:true
};




export default compose(
  withStyles(styles),
)(SimpleGQLTable)
