import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import DebugOnly from './../Utils/DebugOnly';

const styles = theme => ({
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
});



class SimpleDataTableCell extends React.PureComponent {
  
  constructor(props) {
    super(props);
  }
 
  
  render() {
    const { classes, colSpan, children,debug_only} = this.props;
    if (debug_only) {
      return (
        <DebugOnly>
          <TableCell colSpan={colSpan} className={classes.cell}>{children}</TableCell>
        </DebugOnly>
      );
    } else {
    return (
      <TableCell colSpan={colSpan} className={classes.cell}>{children}</TableCell>
    );
    }
  }
}

SimpleDataTableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  colSpan: PropTypes.any,
  debug_only: PropTypes.bool,
};

SimpleDataTableCell.defaultProps = {
  debug_only: false,
};



export default withStyles(styles)(SimpleDataTableCell)
