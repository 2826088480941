import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import EnrollHeaderTabs from './EnrollHeaderTabs';
import EnrollPreview from './EnrollPreview';

const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class EnrollBoard extends React.Component {

  

  render() {
    const { classes, auth, enroll_group} = this.props;
    var eg = 0;
    
    if (enroll_group) {
      eg = parseInt(enroll_group,10);
    }

    return (
      <div>
        <EnrollHeaderTabs activeTab={eg} auth={auth} />
        <div className={classes.tab_root}>
          <EnrollPreview enroll_group={eg} auth={auth}/>
        </div>
      </div>
    );
  }
}

EnrollBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  enroll_group: PropTypes.string
};
EnrollBoard.defaultProps = {
  
};

export default withStyles(styles)(EnrollBoard);