import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import DateView from './DateView';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import SexView from './SexView';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

var lodash = require('lodash');

const GET_COURSE_WITH_ACTS = gql`
query CourseWithActs($course_key:String!){ 
  students(course_key:$course_key,filter_status:IN) {
    key
    sex
    name
    surname
    activites {
      id
     date
    }
  }
 }
`;

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  ibutton: {
    margin: 0
  },
  cell: {
    //     paddingLeft: '10px',
    //     paddingRight: '10px',
  }
 
});

function safeS(a) {
  if ((a===null) || (a===undefined)) {
    return "";
  }
  return a;
}


const compareLocale="cz";
function safeCmp(a,b) {
  return safeS(a).localeCompare(safeS(b),compareLocale)
}
function safeCmpChain(a,b,keys) {
  for (var k of keys) {
    var c;
    if (k.charAt(0)==='-') {
        k = k.substring(1);
        c = -safeCmp(a[k],b[k]);
    } else {
        c = safeCmp(a[k],b[k]);
    }

    if (c) {
      return c
    }
  }
  return 0
}


function sort_function(a,b,props) {
  return safeCmpChain(a,b,props);
}

class StudentsActivityTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      studs: [],
      activites: {},
      loading: false,
      openLogs: false,
      logs:[],
      student:{}
    }
  }

  componentDidMount() {
    this.refreshStudents();
  }
 
  onApiError(e) {
    console.error(e);
    this.setState({errorString:"chyba, prosím opakuj akci"})
  }

  refreshStudents() {
    this.setState({loading:true});

    this.props.client.query({
      query:GET_COURSE_WITH_ACTS,
      variables:{course_key:this.props.course_id},
      fetchPolicy:"network-only"
    }).then(studs=>{
      this.setState({students: lodash.cloneDeep(studs.data.students),loading:false});
    })


  }

  handleClickLog(student) {
    this.setState({ student:student, openLogs:true});
  }

  handleRequestCloseLogs() {
    this.setState({ openLogs: false});
  }

  renderLogDialog() {
    //const classes = this.props.classes;
    return (
        <Dialog open={this.state.openLogs} onClose={(e)=>this.handleRequestCloseLogs(e)}>
            <DialogTitle>Docházka žáka - {this.state.student.name} {this.state.student.surname}</DialogTitle>
            <DialogContent>

              
                {lodash.sortBy(this.state.student.activites,['date']).map((i) => {
                    return (
                        <p key={i.id}>
                            <DateView date={i.date}/>
                        </p>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>this.handleRequestCloseLogs(e)} color="primary">
                    Zavřít
                </Button>
            </DialogActions>
        </Dialog>
    );
}


  renderActivity(student,act) {
    const classes = this.props.classes;
    if (act) {
      return [
        (<TableCell key="d" className={classes.cell} padding="dense">
          <DateView key="d" date={lodash.last(lodash.sortBy(act,['date'])).date} />
        </TableCell>),
        (<TableCell key="c" className={classes.cell} padding="dense">
          <span key="c"> {act.length}</span>
        </TableCell>),
        (<TableCell key="b" className={classes.cell} padding="dense">
          <IconButton key="b" className={classes.ibutton} onClick={()=>this.handleClickLog(student)}>
            <ReceiptIcon/>
          </IconButton>
        </TableCell>)
      ];
    } else {
      return (
        <TableCell className={classes.cell} padding="dense" colSpan={3}></TableCell>
      )
    }
  }

  renderTableRow(s) {
    const classes = this.props.classes;
    const act = this.renderActivity(s,s.activites);
    return (
      <TableRow key={s.key}>
          <TableCell className={classes.cell} padding="dense"><SexView sex={s.sex}/></TableCell>
          <TableCell className={classes.cell} padding="dense">{s.name}</TableCell>
          <TableCell className={classes.cell} padding="dense">{s.surname}</TableCell>
          {act}
      </TableRow>
    )
  }

  render() {
    const classes = this.props.classes;
    var sort_opt = ['surname', 'name'];
    const logDialog = this.renderLogDialog();
    const sr =  this.state.students.sort((a,b)=>sort_function(a,b,sort_opt)).map((s=>this.renderTableRow(s)));
    return (
      <Paper>
        { logDialog }
        <Table>
          <TableHead>
              <TableRow>
                  <TableCell className={classes.cell}></TableCell>
                  <TableCell className={classes.cell}>jméno</TableCell>
                  <TableCell className={classes.cell}>přijmení</TableCell>
                  <TableCell className={classes.cell}>poslední vstup</TableCell>
                  <TableCell className={classes.cell}>počet</TableCell>
                  <TableCell className={classes.cell}></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {sr}
          </TableBody>
          { this.state.loading && 
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableFooter>
          }
        </Table>
      </Paper>
    );
  }
}


StudentsActivityTable.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  course_id: PropTypes.string.isRequired,
  group_mode: PropTypes.oneOf(["School","Pair","Single"])
};

StudentsActivityTable.defaultProps = {
  group_mode: "Single" 
};


export default compose(
  withStyles(styles),
  withApollo,
)(StudentsActivityTable)
