import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventStrip from './EventStrip';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
//import Lodash from 'lodash';
//import EventCapacityView from './EventCapacityView';
import SimpleDataTable from './Tables/SimpleDataTable';
import TableCell from './Tables/SimpleDataTableCell';
import EventTicketStatusView from './Fields/EventTicketStatusView';
import EventTicketPlaceView from './Fields/EventTicketPlaceView';
import DateView from './DateView';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});


class SaleEventTabTickets extends React.Component {

 
 
  render() {
    const { classes,event} = this.props;

    return (
      <React.Fragment>
            <Paper className={classes.paper}>
              <EventStrip show_debug_info={false} variant="sale" event={event}/>
            </Paper>
            <Typography variant="subheading"> Přehled vstupenek  </Typography>

            {event.tickets && (

              <SimpleDataTable data={event.tickets} 
                renderHeader={()=>(
                  <React.Fragment>
                    <TableCell debug_only>id</TableCell>
                    <TableCell debug_only>payment_id</TableCell>
                    <TableCell debug_only>event_zone_id</TableCell>
                    <TableCell debug_only>event_seat_id</TableCell>
                    <TableCell debug_only>event_seat_idx</TableCell>
                    <TableCell>typ/stav</TableCell>
                    <TableCell>kod</TableCell>
                    <TableCell>nazev</TableCell>
                    <TableCell>cena</TableCell>
                    <TableCell>misto</TableCell>
                    <TableCell>datum vzniku</TableCell>
                    <TableCell>datum zmeny</TableCell>
                    <TableCell></TableCell>
                  </React.Fragment>
                )}
                renderRow={(item)=>(
                  <React.Fragment>
                    <TableCell debug_only>{item.id}</TableCell>
                    <TableCell debug_only>{item.payment_id}</TableCell>
                    <TableCell debug_only>{item.event_zone_id}</TableCell>
                    <TableCell debug_only>{item.event_seat_id}</TableCell>
                    <TableCell debug_only>{item.event_seat_idx}</TableCell>
                    <TableCell><EventTicketStatusView event_ticket_status={item.status}/></TableCell>
                    <TableCell>{item.ticket_key}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.cost}</TableCell>
                    <TableCell><EventTicketPlaceView event_ticket={item} event_zones={event.zones} event_seats={event.seats}/>  </TableCell>
                    <TableCell> <DateView date={item.created_at}/> </TableCell>
                    <TableCell> <DateView date={item.updated_at}/> </TableCell>
                    <TableCell>
                      {/*
                      {auth.is_admin && (<ActionButton tooltip={"odbrat z události"} icon={'delete'} onClick={()=>this.removeZone(item)}/>)}
                      <ActionLink useRouter tooltip={"nastavit zonu"} icon={'setup'} href={'/spa/setup_event_zone/'+event.id+'/'+item.id+"/info"}/>
                      */}
                    </TableCell>
                
                  </React.Fragment>
                )}
              />


            )}
            
            

      </React.Fragment>
    );
  }
}

SaleEventTabTickets.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
SaleEventTabTickets.defaultProps = {
  
};


export default withStyles(styles)(SaleEventTabTickets);