import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        width: "100%"
    },
    rootError: {
        width: "100%",
        color: theme.palette.error.main
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    textFieldLong: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300 + theme.spacing.unit,
    },
    textFieldShort: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 100 - theme.spacing.unit,
    },
    textFieldInput: {
        color: theme.palette.text.primary
    }

});


const CurrentStudent = gql`
    query Student($student_key: String!) {
        student(key:$student_key) {
            key,status,name,surname, 
            course { key season {name} folder { name } name code} 
            course_cost paid var_sym pay_info paid_amount
        }
    }
`;


function status2text(s) {
    switch(s) {
        case 'IN': return "Zapsán";
        case 'SPARE': return "Náhradník";
        case 'FILED': return "Přihláška";
        case 'KICKED': return "Vyřazen";
        default: 
    }
    return "?";
}


class StudentCard extends React.Component {

  renderStringField(id,label,value) {
      const classes = this.props.classes;
      return ( 
            <TextField id={id} disabled className={classes.textField}
                label={label}
                value={value?value:""}
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    classes: {
                      disabled: classes.textFieldInput,
                    },
                }}
            />
      );
  }
  renderStringFieldShort(id,label,value) {
    const classes = this.props.classes;
    return ( 
          <TextField id={id} disabled className={classes.textFieldShort}
              label={label}
              value={value?value:""}
              margin="dense"
              InputLabelProps={{
                  shrink: true,
              }}
              InputProps={{
                  classes: {
                    disabled: classes.textFieldInput,
                  },
              }}
          />
        );
    }
    renderStringFieldLong(id,label,value) {
        const classes = this.props.classes;
        return ( 
              <TextField id={id} disabled className={classes.textFieldLong}
                  label={label}
                  value={value?value:""}
                  margin="dense"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  InputProps={{
                      classes: {
                        disabled: classes.textFieldInput,
                      },
                  }}
              />
        );
    
    }


  renderNumberField(id,label,value) {
    const classes = this.props.classes;
    return ( 
          <TextField id={id} disabled className={classes.textFieldShort}
              label={label}
              value={value?""+value:""}
              margin="dense"
              InputLabelProps={{
                  shrink: true,
              }}
              InputProps={{
                  classes: {
                    disabled: classes.textFieldInput,
                  },
              }}
          />
        );
    }



    render() {
        const classes = this.props.classes;
        const { loading, error, student} = this.props.student;
        if (loading) {
            return ( 
                <div className={classes.root}> 
                    <CircularProgress />
                </div>
            )
        }
        if (error) {
            console.error(error);
            return ( 
                <div className={classes.rootError}>
                     Interní chyba
                </div> 
            ) 
        }

        if (!student) {
            return ( 
                <div className={classes.rootError}>
                     {'Žák nenalezen, id žáka: "'+this.props.student_key+'"'} 
                </div> 
            ) 
        }
 
        return (
        <div className={classes.root}>
        <div> 
            {this.renderStringField("surname","Přijmení",student.surname)}
            {this.renderStringField("name","Jméno",student.name)}
            {this.renderStringField("status","Stav",status2text(student.status))}
            
        </div>
        <div>
            {this.renderStringFieldShort("code","Č. kurzu",student.course.code)}
            {this.renderStringFieldShort("cname","Název kurzu",student.course.name)}
            {this.renderStringFieldLong("folder","Složka",student.course.folder.name)}
            {this.renderStringFieldShort("season","Sezóna",student.course.season.name)}
        </div>
        <div>
            {this.renderNumberField("course_cost","Kurzovné",student.course_cost)}
            {this.renderNumberField("paid","Zaplaceno",student.paid)}
            {this.renderNumberField("paid_amount","Zaplaceno2",student.paid_amount)}
            {this.renderNumberField("var_sym","Var. sym",student.var_sym)}
            {this.renderStringFieldLong("pay_info","Platební info",student.pay_info)}
        </div>
        </div>
        );
    }
}

StudentCard.propTypes = {
    student: PropTypes.shape({student:PropTypes.object,loading:PropTypes.bool,error:PropTypes.bool}),
    classes: PropTypes.object.isRequired,
    student_key: PropTypes.string.isRequired,
};

export default compose(
    withStyles(styles),
    graphql(CurrentStudent,{
        name: "student",
       // options: ({student_key})=>({variables:{student_key:student_key}})
    }),
)(StudentCard)


