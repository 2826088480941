import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  
});

const A4_ratio = 1.41;

const sizes = {
  'small': {
    width:100,
    height:Math.floor(100*A4_ratio),
  },
  'normal': {
    width:200,
    height:Math.floor(200*A4_ratio)
  },
  'big': {
    width:400,
    height:Math.floor(400*A4_ratio)
  },
}


class EventImage extends React.Component {

  
  render() {
    const { image,variant,show_debug_info} = this.props;

    const size = sizes[variant];
    let url = "//via.placeholder.com/"+size.width+"x"+size.height;
    if (image) {
      url = image.url;
    }
    return (
      <React.Fragment>
        <img width={size.width} height={size.height} src={url} />
        {show_debug_info && image && (
          <React.Fragment>
          <div> original {image.width}x{image.height}</div>
          <div> current {size.width}x{size.height}</div>
          <div> name {image.name} </div>
          <div> <a href={url}>link</a> </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

EventImage.propTypes = {
  classes: PropTypes.object.isRequired,
 // auth: PropTypes.object.isRequired,
  image: PropTypes.object,
  variant: PropTypes.oneOf(["small","normal","big"]),
  show_debug_info: PropTypes.bool
};
EventImage.defaultProps = {
  variant:"normal",
  show_debug_info:false
};


export default withStyles(styles)(EventImage);