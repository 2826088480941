import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EventImage from './EventImage';
import DebugObjectView from './Utils/DebugObjectView';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import DateView from './DateView';
import Button from '@material-ui/core/Button';
import EventCapacityView from './EventCapacityView';
const styles = theme => ({
  root: {
    flexGrow: 1,
    //maxWidth: 600,
    padding: theme.spacing.unit * 2,
  },
  main_grid: {
    maxWidth: 800,
  },
  button: {
    margin: theme.spacing.unit,
  }
});



class EventStrip extends React.Component {

  
  render() {
    const { classes,event,show_debug_info,onBuy,variant} = this.props;

    if (variant == "sale") {
      return (
        <React.Fragment>
           {show_debug_info && (<DebugObjectView name="event strip" object={event} />)}
          <Grid container spacing={16} justify="flex-start" alignItems="center" className={classes.root}>
            <Grid item >
              <Typography variant="headline" gutterBottom>{event.name}</Typography>
              <Typography variant="headline"><DateView date={event.date} long/></Typography>
            </Grid>
            {event.place && (
              <Grid item >
                <Typography variant="title" gutterBottom>{event.place.long_name}</Typography>
                <Typography variant="subheading" color="textSecondary">{event.place.address}</Typography>
              </Grid>
            )}
            {event.capacity_info &&(
              <Grid item>
                <EventCapacityView cap_info={event.capacity_info} />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {show_debug_info && (<DebugObjectView name="event strip" object={event} />)}

        <Grid container justify="center"
  alignItems="center" className={classes.root}>
          <Grid item container spacing={16} className={classes.main_grid}>
            <Grid item>
              <EventImage show_debug_info={show_debug_info} variant="normal" image={event.image} />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item container direction="column" spacing={16} justify="center"
    alignItems="stretch">
                <Grid item >
                  <Typography variant="headline" gutterBottom>{event.name}</Typography>
                  <Typography variant="headline"><DateView date={event.date} long/></Typography>
                </Grid>
                {event.place && (
                  <Grid item >
                    <Typography variant="title" gutterBottom>{event.place.long_name}</Typography>
                    <Typography variant="subheading" color="textSecondary">{event.place.address}</Typography>
                  </Grid>
                )}
                {onBuy && (
                  <Grid item container justify="flex-end" alignItems="center" >
                      <Grid item>
                      <Button variant="contained" className={classes.button} onClick={()=>this.props.onBuy(event)}>
                          Koupit vstupenky
                      </Button>
                      </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EventStrip.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(["sale","normal","big"]),
  event: PropTypes.object.isRequired,
  show_debug_info: PropTypes.bool,
  onBuy: PropTypes.func,
  public: PropTypes.bool,
};
EventStrip.defaultProps = {
  variant:"normal",
  show_debug_info:false,
  public: false
};


export default withStyles(styles)(EventStrip);