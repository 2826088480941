import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EventPlanView from './EventPlanView';

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  label:{ 
    paddingTop: theme.spacing.unit * 2
  }
});






class EventZoneSeatChooseMap extends React.Component {
  selectSeat(seat,seat_idx) {
    this.props.onSelect(seat,seat_idx);
  }
 
  render() {
    const { classes,list,label,event_place,event_zone,markers } = this.props;
    const title = label?(<React.Fragment ><Typography className={classes.label} variant={"body2"}>{label}</Typography><Divider/></React.Fragment>):null;
    return (
      <div className={classes.root}>
        {title}
        <EventPlanView 
          place={event_place} 
          zones={[event_zone]}
          seats={list}
          seat_markers={markers}
          zoomZone={event_zone}
          disableTableSelection={true}
          onSeatClick={(seat,seat_idx)=>this.selectSeat(seat,seat_idx)}
          showLegend={true}
      />
      </div>
    );
  }
}

EventZoneSeatChooseMap.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  label: PropTypes.string,
  event_place: PropTypes.object.isRequired,
  event_zone: PropTypes.object.isRequired,
  markers: PropTypes.array,
  dense: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};
EventZoneSeatChooseMap.defaultProps = {
  dense:false,
  markers:[],
};


export default withStyles(styles)(EventZoneSeatChooseMap);