import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ConfigHeaderTabs from './ConfigHeaderTabs';
import ConfigTabInfo from './ConfigTabInfo';
import ConfigTabSchools from './ConfigTabSchools';

import CGroups from './CGroups';
import Folders from './Folders';
import Seasons from './Seasons';
import Images from './Images';
import Places from './Places';
import Zones from './Zones';
import Seats from './Seats';
import PricePlans from './PricePlans';
import ZonePrices from './ZonePrices';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class ConfigBoard extends React.Component {

  

  renderTabInfo() {
    const {auth} = this.props;
    return (
      <ConfigTabInfo auth={auth}/>
    )
  }

  renderTabSchools() {
    const {auth} = this.props;
    return (
      <ConfigTabSchools auth={auth}/>
    )
  }

  renderTabFolders() {
    const {auth} = this.props;
    return (
      <Folders auth={auth}/>
    )
  }

  renderTabSeasons() {
    const {auth} = this.props;
    return (
      <Seasons auth={auth}/>
    )
  }

  renderTabCGroups() {
    const {auth} = this.props;
    return (
      <CGroups auth={auth}/>
    )
  }

  renderTabImages() {
    const {auth} = this.props;
    return (
      <Images auth={auth} />
    )
  }


  renderTabPlaces() {
    const {auth} = this.props;
    return (
      <Places auth={auth} />
    )
  }

  renderTabZones() {
    const {auth} = this.props;
    return (
      <Zones auth={auth} />
    )
  }

  renderTabZonePrices() {
    const {auth} = this.props;
    return (
      <ZonePrices auth={auth} />
    )
  }


  renderTabPricePlans() {
    const {auth} = this.props;
    return (
      <PricePlans auth={auth} />
    )
  }

  renderTabSeats() {
    const {auth} = this.props;
    return (
      <Seats auth={auth} />
    )
  }



  render() {
    const { classes , auth,page} = this.props;
    let tab = null;
    switch(page) {
      case 'info': tab = this.renderTabInfo(); break;
      case 'schools': tab = this.renderTabSchools(); break;
      case 'folders': tab = this.renderTabFolders(); break;
      case 'seasons': tab = this.renderTabSeasons(); break;
      case 'cgroups': tab = this.renderTabCGroups(); break;
      case 'images': tab = this.renderTabImages(); break;
      case 'places': tab = this.renderTabPlaces(); break;
      case 'zones': tab = this.renderTabZones(); break;
      case 'seats': tab = this.renderTabSeats(); break;
      case 'priceplans': tab = this.renderTabPricePlans(); break;
      case 'zoneprices': tab = this.renderTabZonePrices(); break;


      default:
      break;
    }
    return (
      <React.Fragment>
        <ConfigHeaderTabs activeTab={page} auth={auth} />
        <div className={classes.tab_root}>{tab}</div>
      </React.Fragment>
    );
  }
}

ConfigBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.oneOf(["info","schools","folders","seasons","cgroups","images","places","zones","seats","priceplans","zoneprices"]).isRequired
};
ConfigBoard.defaultProps = {
  
};

export default withStyles(styles)(ConfigBoard);