import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

//import { compose, graphql } from 'react-apollo';
//import gql from 'graphql-tag';
import { Query } from "react-apollo";
import EnumField from './../EnumField';
import {GET_PLACES } from './Queries';

const styles = theme => ({
 
});

/*
const GET_PLACES = gql`
  query Places {
    places {
      id
      name
      long_name
      map
    }
  }
`;
*/

class PlaceIDField extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { value,onChange,disabled,required} = this.props;
     
    return (
     
         <Query query={GET_PLACES} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            const pe = data.places.map(s=>{
              return {key:s.id,label:s.name};
            });

            return ( 
              <EnumField disabled={disabled} required={required} error={this.props.error} width={250} id="place_id" label="Místo" values={pe} value={value} onChange={(v)=>onChange(v)} />
            );
          }}
          </Query>

    
    );
  }
}

PlaceIDField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool
};
PlaceIDField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false
};

export default withStyles(styles)(PlaceIDField);