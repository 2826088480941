import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StringViewField from './StringViewField';

const styles = theme => ({
 
});


class BooleanViewField extends React.Component {

  render() {
    const { width,label,value } = this.props;
    const val = (value===null)?"":(value?"ano":"ne");
    return (
        <StringViewField width={width} label={label} value={val}/>
    );
  }
}

BooleanViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.bool,
};
BooleanViewField.defaultProps = {
  
};

export default withStyles(styles)(BooleanViewField);