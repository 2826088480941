import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { compose } from "react-apollo";

import BanksHeaderTabs from './BanksHeaderTabs';
import BankTrs from './BankTrs';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class BanksBoard extends React.Component {

  
  renderTabTrs() {
    const {auth,evi_group} = this.props;
    return (
      <BankTrs auth={auth} evi_group={evi_group} />
    )
  }


  render() {
    const { classes , auth,page} = this.props;
    var tab=null;
    switch(page) {
      case 'trs': tab = this.renderTabTrs(); break;
      default:
      break;
    }
   

    return (
      <div>
        <BanksHeaderTabs  activeTab={page} auth={auth} />
        <div className={classes.tab_root}>{tab}</div>
      </div>
    );
  }
}

BanksBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.oneOf(["trs"]).isRequired,
  evi_group: PropTypes.string,
};
BanksBoard.defaultProps = {
  
};

function mapStateToProps(state) {
  return { 
      evi_group: state.select.evi_group,
  }
}



export default compose(
  withStyles(styles),
  connect(mapStateToProps,{}),
)(BanksBoard);
