import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CourseAdd from './CourseAdd';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    minWidth:800
  }
});


class CourseAddDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      valid:false
    }
    this.edit = React.createRef();
  }

  onSave() {
    if (this.edit.current) {
      this.edit.current.wrappedInstance.doSave();
    }
  }

  onEnter() {
    this.setState({valid:false});
  }

  onChange(d,v) {
    this.setState({valid:v});
  } 


  

  render() {
    const {folder_key,season_key,open,onClose,caption,evi_group} = this.props;
    return (
      <Dialog disableBackdropClick maxWidth={false} open={open} onClose={onClose} onEnter={()=>this.onEnter()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {folder_key && season_key && (<CourseAdd ref={this.edit} folder_key={folder_key} season_key={season_key} evi_group={evi_group} onClose={onClose} onChange={(d,v)=>this.onChange(d,v)} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Neukládat
        </Button>
        <Button disabled={!this.state.valid} onClick={()=>this.onSave()} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}
 
CourseAddDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  season_key:PropTypes.string,
  folder_key:PropTypes.string,
  evi_group:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
CourseAddDialog.defaultProps = {
  caption:"Založení kurzu"
};

export default withStyles(styles)(CourseAddDialog);