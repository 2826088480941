import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from "react-apollo";
import EnumField from './../EnumField';
import {GET_ZONES } from './Queries';

const styles = theme => ({
 
});


class ZoneIDField extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { value,onChange,disabled,required,place_id} = this.props;
     
    return (
     
         <Query query={GET_ZONES} variables={{place_id}}fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            const pe = data.zones.map(s=>{
              return {key:s.id,label:s.name};
            });

            return ( 
              <EnumField disabled={disabled} required={required} error={this.props.error} width={250} id="zone_id" label="Zóna" values={pe} value={value} onChange={(v)=>onChange(v)} />
            );
          }}
          </Query>

    
    );
  }
}

ZoneIDField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  place_id: PropTypes.string
};
ZoneIDField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false
};

export default withStyles(styles)(ZoneIDField);