import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import DateView from './DateView';

const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  }
});


class SetupEventHeaderTabs extends React.Component {

 


  
  render() {
    const { classes,activeTab,event_id,event} = this.props;
    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                {event &&(
                  <React.Fragment>
                    Akce - {event.name} - <DateView date={event.date} />
                  </React.Fragment>
                )}
                {!event &&(
                  <span>{event_id} - nenalezeno</span>
                )}
            </Typography>



            <Tabs className={classes.toolBar_item} value={activeTab}>
              <Tab value="info" label="info" component={Link} to={"/spa/setup_event/"+event_id+"/info"}/>
              <Tab value="plan" label="plánek" component={Link} to={"/spa/setup_event/"+event_id+"/plan"}/>
              <Tab value="zones" label="zóny" component={Link} to={"/spa/setup_event/"+event_id+"/zones"}/>
              <Tab value="priceplans" label="cenové plány" component={Link} to={"/spa/setup_event/"+event_id+"/priceplans"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

SetupEventHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  event: PropTypes.object,
  activeTab: PropTypes.oneOf(["info","plan","zones","priceplans"]).isRequired,
};
SetupEventHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(SetupEventHeaderTabs);