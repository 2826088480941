import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventsTable from './EventsTable';
import { Query } from "react-apollo";
import EventViewDialog from './EventViewDialog';
import EventDeleteDialog from './EventDeleteDialog';
import EventEditDialog from './EventEditDialog';
import ActionButton from './ActionButton';
import {Redirect} from 'react-router-dom'
import PdfViewDialog from './PdfViewDialog';

import {GET_EVENTS } from './Fields/Queries';


const SAVE_ORDERING = gql`
    mutation events_ordering($ids: [ID]!) {
        events_ordering(ids:$ids) 
    }
`;

const HIDE_EVENT = gql`
mutation hideEvent($id:ID!) {
  eventHide(id:$id) {
    id
  }
}
`;

const UPDATE_EVENT = gql`
mutation updateEvent($id:ID!,$name:String,$status:EventStatus,$comment:String,$image_id:ID,$date:DateTime,$auto_open_date:DateTime,$auto_suspend_date:DateTime) {
  eventUpdate(id:$id,name:$name,status:$status,comment:$comment,image_id:$image_id,date:$date,auto_open_date:$auto_open_date,auto_suspend_date:$auto_suspend_date) {
    id
  }
}
`;

const CREATE_EVENT = gql`
mutation createEvent($name:String!,$place_id:ID!,$comment:String,$image_id:ID,$date:DateTime!,$auto_open_date:DateTime,$auto_suspend_date:DateTime) {
  eventCreate(name:$name,place_id:$place_id,comment:$comment,image_id:$image_id,date:$date,auto_open_date:$auto_open_date,auto_suspend_date:$auto_suspend_date) {
    id
  }
}
`;

const PRINT_EVENT_REPORT=gql`
    mutation printEventReport($event_id:ID!) {
        printEventReport(event_id:$event_id) {
            error
            error_message
            s3key
            url
            file_name
            content_type
        }
    }
`;


const styles = theme => ({
 
});


class Events extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_event:null,
      delete_event:null,
      setup_event:null,
      sale_event:null,
      reportUrl:null,
      reportWait:false,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
  }



  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  }

  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({name:null,place_id:null,comment:null,image_id:null,date:null,auto_open_date:null,auto_suspend_date:null});
    }
  }


  handleEditEvent(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSaveEvent() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("event update",doc)
        this.props.updateEvent({
          variables: doc
        }).then(res=>{
          console.log("update ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("event create",doc)
        this.props.createEvent({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }


  handleSetupEvent(item) {
    this.setState({setup_event:item});
  }

  handleSaleEvent(item) {
    this.setState({sale_event:item});
  }

  handleShowEvent(item) {
    this.setState({show_event:item});
  }
  closeShowEvent() {
    this.setState({show_event:null});
  }

  handleDeleteEvent(item) {
    this.setState({delete_event:item});
  }
  closeDeleteEvent() {
    this.setState({delete_event:null});
  }

  doDeleteEvent() {
    const id = this.state.delete_event.id;
    this.setState({delete_event:null});
    this.props.hideEvent({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        ids:ids
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  handlePrintEvent(item) {
  // alert("handlePrintEvent - todo");
   this.setState({reportWait:true})
   this.props.printEventReport({
        variables: { event_id:item.id }
    }).then(res=>{
        console.log("event report print res=",res);
        this.setState({reportUrl:res.data.printEventReport.url})
    }).catch(err=>{
        alert('chyba tisku',err)
    })
  }


  render() {
    const { auth} = this.props;
    const {setup_event,sale_event} = this.state;

    if (setup_event) {
      return (
        <Redirect push to={"/spa/setup_event/"+setup_event.id+"/info"} />
      )
    }

    if (sale_event) {
      return (
        <Redirect push to={"/spa/sale_event/"+sale_event.id+"/info"} />
      )
    }
    

    return (
      <React.Fragment>
      
        <PdfViewDialog caption={"Report akce"} pdfUrl={this.state.reportUrl} open={this.state.reportUrl!=null || this.state.reportWait} onClose={()=>this.setState({reportUrl:null,reportWait:false})}/>



        {auth.is_super && (
          <ActionButton label={"Nová akce"} onClick={()=>this.handleNew()}/>
        )}
     

       
        <EventViewDialog event={this.state.show_event}  open={this.state.show_event!==null} onClose={()=>this.closeShowEvent()} /> 
        <EventDeleteDialog event={this.state.delete_event}  open={this.state.delete_event!==null} onDelete={()=>this.doDeleteEvent()} onClose={()=>this.closeDeleteEvent()} /> 
        <EventEditDialog innerRef = {this.edit} onSave={()=>this.doSaveEvent()} /> 
       
        <Query query={GET_EVENTS} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <EventsTable 
                  innerRef = {this.table}
                  auth={auth}
                  data={data.events} 
                  refetch={refetch}
                  saveOrdering={(ids)=>this.saveOrder(ids)}
                  onSetup={(item)=>this.handleSetupEvent(item)}
                  onSale={(item)=>this.handleSaleEvent(item)}
                  onEdit={(item)=>this.handleEditEvent(item)}
                  onShow={(item)=>this.handleShowEvent(item)}
                  onPrint={(item)=>this.handlePrintEvent(item)}
                  onDelete={(item)=>this.handleDeleteEvent(item)}
              />
            );
          }}
      </Query>

      </React.Fragment>
    );
  }
}

Events.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hideEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  printEventReport:  PropTypes.func.isRequired,
};
Events.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_EVENT,{
    name: "hideEvent",
  }),
  graphql(UPDATE_EVENT,{
    name: "updateEvent",
  }),
  graphql(CREATE_EVENT,{
    name: "createEvent",
  }),
  graphql(PRINT_EVENT_REPORT,{
    name:"printEventReport",
  }),
)(Events)
