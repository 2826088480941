import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "react-apollo";
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from './SimpleDataTableCell';
import ActionButton from './../ActionButton';
import { DragSource, DropTarget } from 'react-dnd';
import DragHandle from './../DragHandle';
import classNames from 'classnames';

const styles = theme => ({
  row: {
    height:24,
  },
  dragging: {
    opacity:0,
  },
});



class SimpleDataTableRow extends React.PureComponent {
  
  constructor(props) {
    super(props);
  }
 
 

  



  

  render() {
    const { classes,renderRow,editable,deletable,viewable,sortable,item,renderRowExtraActions} = this.props;
    const { activeDrag, isDragging, connectDragSource, connectDragPreview,connectDropTarget } = this.props;


    //console.log("Row render",item.name,isDragging);

    const actbuttons = editable || deletable || viewable || renderRowExtraActions;
   
    const className = classNames([
      { [classes.dragging]: isDragging },
      { [classes.hover]: !activeDrag},
      classes.row
    ]);

    return (
      <TableRow hover={!activeDrag} className={className}>

        {sortable && (
              <TableCell> 
                {connectDropTarget(connectDragSource(<div><DragHandle/></div>))} 
              </TableCell>
        )}

        {renderRow(item,sortable?connectDragPreview:(n)=>n)}
        {actbuttons && (<TableCell>
                          { renderRowExtraActions && renderRowExtraActions(item)}
                          { viewable && (<ActionButton icon={'view'} onClick={()=>this.props.onView(item)}/>)}
                          { editable && (<ActionButton icon={'edit'} onClick={()=>this.props.onEdit(item)}/>)}
                          { deletable && (<ActionButton icon={'delete'} onClick={()=>this.props.onDelete(item)}/>)}
                      </TableCell>)}
      </TableRow>
    );
  }
}

const rowSource = {

  canDrag(props) {
    //console.log("rowSource.canDrag",props)
    return true;
  },

  isDragging(props, monitor) {
    const mitem  = monitor.getItem();
    if (!mitem) {
      return false;
    }
   
    if (props.dnd_ref !== mitem.dnd_ref) {
      return false;
    }

    if (mitem && props.item) {
      return mitem.id==props.item.id;
    }
    return false;
  },

  beginDrag(props) {
    props.onDrag(true);
    const r = {
      item: props.item,
      id: props.item.id,
      originalIndex: props.item_index,
      dnd_ref:props.dnd_ref
    };
    return r;
  },
  endDrag(props, monitor) {
    props.onDrag(false);
    const mitem = monitor.getItem();
		const didDrop = monitor.didDrop()

		if (!didDrop) {
      console.log("enddrag - moverow back")
			props.dndMoveRow(mitem.id, mitem.originalIndex)
    } else {
      props.onSaveOrder();
    }
    
    return;
  }
};

const rowTarget = {
  
  hover(props, monitor, component) {
    const mitem = monitor.getItem();
    if (!mitem) {
      return;
    }
    if (!props.item) {
      return;
    }
    if (props.dnd_ref !== mitem.dnd_ref) {
      return;
    }

		if (mitem.id !== props.item.id) {
 			props.dndMoveRow(mitem.id, props.item_index);
		}
    return;
  },

  canDrop(props, monitor) {

    const mitem = monitor.getItem();
    if (!mitem) {
      return false;
    }
    if (!props.item) {
      return false;
    }
    if (props.dnd_ref !== mitem.dnd_ref) {
      return false;
    }
    return true;  //FIX: test !

  }
}


function dnd_src(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}
function dnd_trg(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}




SimpleDataTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  item_index: PropTypes.number.isRequired,
  dndMoveRow: PropTypes.func.isRequired,
  renderRow:PropTypes.func.isRequired,
  renderRowExtraActions:PropTypes.func,
  onView:PropTypes.func,
  onEdit:PropTypes.func,
  onDelete:PropTypes.func,

  sortable:PropTypes.bool,
  editable:PropTypes.bool,
  deletable:PropTypes.bool,
  viewable:PropTypes.bool,



  dnd_ref: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,

  onDrag: PropTypes.func.isRequired,
  activeDrag: PropTypes.bool.isRequired,
  onSaveOrder: PropTypes.func.isRequired,

};

SimpleDataTableRow.defaultProps = {
 sortable:false,
 editable:false,
 deletable:false,
 sortable:false,
};




export default compose(
  withStyles(styles),
  DropTarget('sdt_row', rowTarget, dnd_trg),
  DragSource('sdt_row', rowSource, dnd_src)
  
)(SimpleDataTableRow)
