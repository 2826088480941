import { doRelogin } from './auth';
import {store} from './store';
import Moment from 'moment';
import { clearInfoMessage, clearErrorMessage, setNewVersion, setOffline, setOnline } from './actions'
import version from './../version.json';


function checkNewVersion() {
    const server = store.getState().server;
    fetch("/spa_status").then((resp) => resp.json()).then(data=>{
        //console.log("checkNewVersion",data,version)
        if (data.version.commit !== version.commit) {
            store.dispatch(setNewVersion());
        }
        if (server.offline) {
            store.dispatch(setOnline());
        }
    }).catch(err=>{
        console.log("checkNewVersion error",err);
        if (!server.offline) {
            store.dispatch(setOffline());
        }
    })
}


function expireNofity() {
    const notify = store.getState().notify;
  //  console.log("expireClipboard",clipboard);
    if (notify.error && notify.error_expire) {
        if (Moment(notify.error_expire).isBefore()) {
            store.dispatch(clearErrorMessage());
        }
    }
    if (notify.info && notify.info_expire) {
        if (Moment(notify.info_expire).isBefore()) {
            store.dispatch(clearInfoMessage());
        }
    }
}


function onTick(){
   //console.log("onTick");
    checkNewVersion();
    doRelogin();
    expireNofity();

}
var timerId;

const startCron = ()=>{ timerId = setInterval(onTick,5000);}
const stopCron = () => { clearInterval(timerId);}

export {
    startCron,
    stopCron,
}