import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Query } from "react-apollo";
import EnumViewField from './../EnumViewField';
import {GET_PRICE_PLANS } from './Queries';

const styles = theme => ({
 
});


class PricePlanIDViewField extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { value,required} = this.props;
     
    return (
     
         <Query query={GET_PRICE_PLANS} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            const pe = data.pricePlans.map(s=>{
              return {key:s.id,label:s.name};
            });

            return ( 
              <EnumViewField required={required} error={this.props.error} width={250} label="Cenový plán" values={pe} value={value}/>
            );
          }}
          </Query>

    
    );
  }
}

PricePlanIDViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool
};
PricePlanIDViewField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false
};

export default withStyles(styles)(PricePlanIDViewField);