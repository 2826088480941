import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import DateField from './Fields/DateField';
var moment = require('moment');
moment.locale('cs');

const styles = theme => ({
 
});

const GET_PAYREPORTM = gql`
  query CoursesPaymentsReport($course_keys:[String]!, $toDate:Date!) {
    coursesPaymentsReport(courseKeys:$course_keys,date:$toDate) {
    
      sum {
        amount
        amountByStatus {
          s
          n
          k
          e
        }
      }
      courses {
        courseKey
        date
        amount
        amountByStatus {
          s
          n
          k
          e
        } 
      }

    }
  } 
`;

class CoursesCash extends React.Component {
  constructor(props) {
    super(props);

    this.state = {  
      toDate: null
    }
    
  }

  render() {
    const {courses} = this.props;
    const course_keys = courses.map(c=>c.key);
    const {toDate} = this.state;
    console.log('toDate',toDate);
    return (
      <div>
        <div>Pro stav vybraného kurzovného k požadovanému dni zvol den:</div>
        <DateField  label="datum" value={this.state.toDate}  onChange={(v)=>this.setState({toDate:v})}/>
        { toDate && (
          <Query query={GET_PAYREPORTM} variables={{ course_keys, toDate }} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;
              return  (
                <React.Fragment>
                  <div>celkem vybráno: {data.coursesPaymentsReport.sum.amount} kč</div>
                  <div>v zapsaných vybráno: {data.coursesPaymentsReport.sum.amountByStatus.e} kč</div>
                  <div>v přihláškách vybráno: {data.coursesPaymentsReport.sum.amountByStatus.n} kč</div>
                  <div>v náhradnících vybráno: {data.coursesPaymentsReport.sum.amountByStatus.s} kč</div>
                  <div>v smazaných vybráno: {data.coursesPaymentsReport.sum.amountByStatus.k} kč</div>
                </React.Fragment>
              )
          }}
          </Query>
        )}
        <div>Přehled peněz ke zvolenému dni (např. zvolený den 1.1.2020 znamená, že se vemou všechny platby připsané do půlnoci z 31.12.2019 na 1.1.2020)</div>

      </div>
    );
  }
}

CoursesCash.propTypes = {  
  classes: PropTypes.object.isRequired,
  courses:PropTypes.array.isRequired,
};
CoursesCash.defaultProps = {
 
};

export default withStyles(styles)(CoursesCash);