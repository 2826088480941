import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DebugObjectView from './Utils/DebugObjectView';
import Lodash from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from './Tables/SimpleDataTableCell';


const styles = theme => ({
  row: {
    height:24,
  }
});

function cost_table(event) {


  const zone_costs = Lodash.groupBy(event.zoneprices,'event_zone_id');
  const zones = Lodash.map(event.zones,(z)=>{
    let zone = Lodash.clone(z);
    const costs = zone_costs[z.id];
    if (costs) {
      zone.costs = Lodash.groupBy(costs,"event_price_plan_id");
      const vals = Lodash.values(zone.costs);
      const lens = Lodash.map(vals,(e)=>{return e.length});
      
      zone.cost_rows = Lodash.max(lens);
    } else {
      zone.costs = {};
      zone.cost_rows = 0;
    }
    return zone;
  });


  return {event_zones:zones, event_price_plans:event.priceplans};
}


class EventCostView extends React.Component {

  constructor(props) {
    super(props);
  }

  

  render() {
    const { classes,event } = this.props;
    const data = cost_table(event)
    return (
      <React.Fragment>
      <DebugObjectView object={data}/>
      <Table>
        <TableHead>
            <TableRow className={classes.row}>
              <TableCell> zona </TableCell>
              {data.event_price_plans.map(pp=>{
                return (
                  <TableCell colSpan={2} key={pp.id}>{pp.name}</TableCell>
                )
              })}
            </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
            {data.event_zones.map(z=>{
              if (z.cost_rows) {
                return (
                <React.Fragment key={z.id}>
                {Lodash.range(z.cost_rows).map(r=>{return (
                  <TableRow key={r} className={classes.row}>
                  <TableCell>{z.name} </TableCell>
                  {data.event_price_plans.map(pp=>{
                    let cr = null;
                    if (z.costs[pp.id]) {
                      if (z.costs[pp.id].length>r) {
                        cr = z.costs[pp.id][r];
                      }
                    }
                    return (
                      <React.Fragment key={pp.id}>
                        <TableCell>{cr?cr.name:""}</TableCell>
                        <TableCell>{cr?cr.cost:""}</TableCell>
                      </React.Fragment>
                    )
                  })}
                  </TableRow>
                )
                })}


                </React.Fragment>
                );
              } else {
                return (
                <TableRow key={z.id} className={classes.row}>
                  <TableCell>{z.name} </TableCell>
                  {data.event_price_plans.map(pp=>{
                  return (
                    <TableCell colSpan={2} key={pp.id}>zadna cena</TableCell>
                  )
                  })}
                </TableRow>
                );
              }
            })}
           
        </TableBody>
      </Table>
      </React.Fragment>
    );
  }
}

EventCostView.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
EventCostView.defaultProps = {
  
};

export default withStyles(styles)(EventCostView);