import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SimpleDataTable from './Tables/SimpleDataTable';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import {GET_ZONES } from './Fields/Queries';
import ActionButton from './ActionButton';
import ActionLink from './ActionLink';
import BooleanView from './BooleanView';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';


const CREATE_EVENT_ZONE = gql`
mutation eventZoneCreate($event_id:ID!,$zone_id:ID!) {
  eventZoneCreate(event_id:$event_id,zone_id:$zone_id) {
    id
  }
}
`;
const DELETE_EVENT_ZONE = gql`
mutation eventZoneDelete($event_zone_id:ID!) {
  eventZoneDelete(id:$event_zone_id) {
    id
  }
}
`;


const styles = theme => ({
  sep: {
    marginTop: theme.spacing.unit*3
  }
});


class SetupEventTabZones extends React.Component {

  constructor(props) {
    super(props);
    this.zone_table = React.createRef();
  }

  filterout_used_zones(zones) {
    const { event } = this.props;
    const used_zone_ids = event.zones.map(z=>z.zone_id);
    const unused = zones.filter(z=>{
        const un = (used_zone_ids.indexOf(z.id)==-1)
        return un;
      });
    return unused;
  }

  reload() {
    this.props.refetch();
    if (this.zone_table.current) {
      this.zone_table.current.refetch();
    }
  }

  addZone(zone) {
    const { event } = this.props;
    this.props.createEventZone({
      variables: {
        event_id:event.id,
        zone_id:zone.id
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }

  removeZone(event_zone) {
    //const { event } = this.props;
    this.props.deleteEventZone({
      variables: {
        event_zone_id:event_zone.id,
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }


  render() {
    const { event,auth,classes} = this.props;
    const editable = (event.status == "NEW" || event.test_mode);


    return (
      <React.Fragment>

         <Typography variant="title">Seznam zón, které jsou nastavené pro akci</Typography>
          {!editable &&(
            <Typography variant="subheading">nastavené zóny již nelze odebrat, protoze akce se již prodává nebo prodávala</Typography>
          )}
 

          <SimpleDataTable data={event.zones} 
            renderHeader={()=>(
              <React.Fragment>
                <TableCell debug_only>event_zone_id</TableCell>
                <TableCell debug_only>zone_id</TableCell>
                <TableCell>název</TableCell>
                <TableCell>veřejný název</TableCell>
                <TableCell>zóna stání</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell debug_only>{item.id}</TableCell>
                <TableCell debug_only>{item.zone_id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.long_name}</TableCell>
                <TableCell><BooleanView value={item.free_zone} /></TableCell>
                <TableCell>
                  {auth.is_admin && editable && (<ActionButton tooltip={"odbrat z události"} icon={'delete'} onClick={()=>this.removeZone(item)}/>)}
                  <ActionLink useRouter tooltip={"nastavit zonu"} icon={'setup'} href={'/spa/setup_event_zone/'+event.id+'/'+item.id+"/info"}/>
                </TableCell>
             
              </React.Fragment>
            )}
          />

          <Typography className={classes.sep} variant="title">Seznam zón, které je možné do akce přidat</Typography>
    
          
          <SimpleGQLTable  innerRef={this.zone_table}
            query={GET_ZONES} variables={{place_id:event.place_id}} 
            getData={(r)=>{return this.filterout_used_zones(r.zones)}}
            renderHeader={()=>(
              <React.Fragment>
                <TableCell debug_only>zone_id</TableCell>
                <TableCell>název</TableCell>
                <TableCell>veřejný název</TableCell>
                <TableCell>zóna stání</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell debug_only>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.long_name}</TableCell>
                <TableCell><BooleanView value={item.free_zone} /></TableCell>
                <TableCell>
                  {auth.is_admin && (<ActionButton tooltip={"přidat do události"} icon={'add'} onClick={()=>this.addZone(item)}/>)}
                </TableCell>
              </React.Fragment>
            )}
            renderEmptyInfo={()=>(<div>žádná zóna k přidání (jsou přidané všechny, nebo není žádná pro lokalitu nastavená) </div>)}
          />


      </React.Fragment>
    );
  }
}

SetupEventTabZones.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  createEventZone: PropTypes.func.isRequired,
  deleteEventZone: PropTypes.func.isRequired,
};
SetupEventTabZones.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CREATE_EVENT_ZONE,{
    name: "createEventZone",
  }),
  graphql(DELETE_EVENT_ZONE,{
    name: "deleteEventZone",
  }),
)(SetupEventTabZones)
