import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ViewIcon from '@material-ui/icons/Pageview';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SetupIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/ModeEdit';
import PayIcon from '@material-ui/icons/AttachMoney';
import Pay2Icon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CardIcon from '@material-ui/icons/CreditCard';
import DebugIcon from '@material-ui/icons/BugReport';

import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    minHeight: 24
  },
});


class ActionLink extends React.PureComponent {
 
  renderIcon() {
    switch(this.props.icon) {
      case 'debug': return (<DebugIcon/>);
      case 'view': return (<ViewIcon/>);
      case 'delete': return (<DeleteIcon/>);
      case 'setup': return (<SetupIcon/>);
      case 'edit':return (<EditIcon/>);
      case 'add':return (<AddIcon/>);
      case 'pay':return (<PayIcon/>);
      case 'pay2':return (<Pay2Icon/>);
      case 'receipt':return (<ReceiptIcon/>);
      case 'card':return (<CardIcon/>);
      case 'students': return (<PeopleIcon/>);
      default: return (<div>?</div>);
    }
  }


  renderIconButton() {
    const icon = this.renderIcon();
    const {useRouter} = this.props;
    if (useRouter) {
      return (
        <IconButton style={{height:24}}color={'primary'} component={Link} to={this.props.href}>
          {icon}
        </IconButton>
      )
    }
    return (
      <IconButton style={{height:24}}color={'primary'} href={this.props.href}>
        {icon}
      </IconButton>
    );
  }

  renderLabelButton() {
    const {label,classes} = this.props;
    const {useRouter} = this.props;
    if (useRouter) {
      <Button variant="raised" className={classes.button} style={{height:24}} component={Link} to={this.props.href}>
        {label}
      </Button>
    }
    return (
      <Button variant="raised" className={classes.button} style={{height:24}} href={this.props.href}>
        {label}
      </Button>
    );
  }

 render() {
    const ib =this.props.icon?this.renderIconButton():this.renderLabelButton();
    if (this.props.tooltip) {
       return (
        <Tooltip title={this.props.tooltip}>
        {ib}
        </Tooltip>
       )
    } else {
      return ib;
    }
  }
};

ActionLink.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.oneOf(['debug','view', 'setup','delete','edit','add','pay','pay2','students','receipt','card']),
  tooltip: PropTypes.string,
  label: PropTypes.string,
  href:PropTypes.string.isRequired,
  useRouter:PropTypes.bool
};
ActionLink.defaultProps = {
  useRouter:false
};

export default withStyles(styles)(ActionLink);