import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from "lodash";
import {OrderStatus_field_enum } from './../../../graphql/enums/orderstatus';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class OrderStatusView extends React.PureComponent {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,value } = this.props;
    const item = Lodash.find(OrderStatus_field_enum,{key:value});
    return ( 
      <span className={classes.root}>
      { item?item.label:(<span> ? {value} </span>) }
      </span>
    );
  
  }
}

OrderStatusView.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string
};
OrderStatusView.defaultProps = {
  
};

export default withStyles(styles)(OrderStatusView);