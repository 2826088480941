import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import EventStrip from './EventStrip';
//import DateView from './DateView';
//import Paper from '@material-ui/core/Paper';
//import Lodash from 'lodash';
//import EventCapacityView from './EventCapacityView';
import DateView from './DateView';
import BooleanView from './BooleanView';
import PaymentTypeView from './Fields/PaymentTypeView';
import PaymentStatusView from './Fields/PaymentStatusView';
//import DebugObjectView from './Utils/DebugObjectView';
//import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import { Query} from "react-apollo";
import SimpleDataTable from './Tables/SimpleDataTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaleEventPaymentsReport from './SaleEventPaymentsReport';
import gql from 'graphql-tag';
import ActionLink from './ActionLink';


const GET_EVENT_PAYMENTS = gql`
query EventPayments($event_id:ID!) {
  eventPayments(event_id:$event_id) {
    id
    public_key
    type
    status
    user_id
    user_login
    order_id
    order_public_key
    pays_order_id
    pays_test_mode
    amount
    comment
    created_at
    updated_at
  }
}
`;



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});





class SaleEventTabPayments extends React.Component {

  constructor(props) {
    super(props);
    this.orders_table = React.createRef();
  }
 
  render() {
    const { event,auth} = this.props;

    return (
      <React.Fragment>
            <Typography variant="subheading"> Přehled plateb za objednávky  </Typography>

             <Query query={GET_EVENT_PAYMENTS} variables={{event_id:event.id}} fetchPolicy={"network-only"}>
              {({ loading, error, data, refetch, client }) => {
                if (loading) return (<CircularProgress/>);
                if (error) return `Error!: ${error}`;
                return (
                  <React.Fragment>
                    <SaleEventPaymentsReport auth={auth} payments={data.eventPayments} />
                    <SimpleDataTable  innerRef={this.orders_table}
                      query={GET_EVENT_PAYMENTS} variables={{event_id:event.id}} 
                      data={data.eventPayments}
                      renderHeader={()=>(
                        <React.Fragment>
                          <TableCell debug_only>id</TableCell>
                          <TableCell debug_only>pays_test_mode</TableCell>
                          <TableCell debug_only>order_id</TableCell>
                          <TableCell debug_only>user_id</TableCell>
                          <TableCell>ID platby</TableCell>
                          <TableCell>ID platby pays</TableCell>
                          <TableCell>ID pokladního</TableCell>
                          <TableCell>typ</TableCell>
                          <TableCell>stav</TableCell>
                          <TableCell>částka</TableCell>
                          <TableCell>ID objednávky</TableCell>
                          <TableCell>poznámka</TableCell>
                          <TableCell>datum vzniku</TableCell>
                  
                        </React.Fragment>
                      )}
                      renderRow={(item)=>(
                        <React.Fragment>
                          <TableCell debug_only>{item.id}</TableCell>
                          <TableCell debug_only><BooleanView value={item.pays_test_mode}/></TableCell>
                          <TableCell debug_only>{item.order_id}</TableCell>
                          <TableCell debug_only>{item.user_id}</TableCell>
                    
                          <TableCell>{item.public_key}</TableCell>
                          <TableCell>{item.pays_order_id}</TableCell>
                          <TableCell>{item.user_login}</TableCell>
                          <TableCell><PaymentTypeView value={item.type}/></TableCell>
                          <TableCell><PaymentStatusView value={item.status}/></TableCell>
                          <TableCell>{item.amount}</TableCell>
                          <TableCell>{item.order_public_key}</TableCell>
                          <TableCell>{item.comment}</TableCell>
                          <TableCell> <DateView date={item.created_at}/> </TableCell>
                        </React.Fragment>
                      )}
                      renderRowExtraActions={(item)=>{
                        if (item.order_public_key) {
                          return (
                            ///spa/sale_event/5bbbab9d21ddf5fa0d91ba85/sale/order/OE2X-XCXR-YL8P-73HF
                            <ActionLink useRouter href={"/spa/sale_event/"+event.id+"/sale/order/"+item.order_public_key} icon='view'/>
                          )
                        }
                        return (
                        <span>  </span>
                        )
                      }}
                      renderEmptyInfo={()=>(<div> nejsou žádné platby pro tuto akci </div>)}
                    />


                  </React.Fragment>
                )
              }}
            </Query>
            
            
            

      </React.Fragment>
    );
  }
}

SaleEventTabPayments.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
SaleEventTabPayments.defaultProps = {
  
};


export default withStyles(styles)(SaleEventTabPayments);