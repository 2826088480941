import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import SeasonFolderNav from './SeasonFolderNav';
import CourseList from './CourseList';
import CourseChips from './CourseChips';
import CGroupNav from './CGroupNav';
import BulkEmailsView from './BulkEmailsView';
import BulkEmailsFetch from './BulkEmailsFetch';
import EmailTemplateNav from './EmailTemplateNav';
import EmailTemplatePreview from './EmailTemplatePreview';
import { compose, withApollo } from "react-apollo";


//const delay = require('delay');

var Api = require("../libs/Api");

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    flexGrow: 1,
   // marginTop: 30,
  },
  paper: {
    padding: 16,
 //   color: theme.palette.text.secondary,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit ,
  },
});

/*
return new Promise(function(resolve,reject){
  pAll([getFolders,getSeasons]).then(d=>{
      var fd = d[0].reduce((d,val)=>{
          d[val.id]=val;
          return d;
      },{})
      var sd = d[1].reduce((d,val)=>{
          d[val.id]=val;
          return d;
      },{})
      resolve({folders:d[0],folder_dict:fd,seasons:d[1],season_dict:sd});
  }).catch(reject);
});
*/


class BulkMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeStep: 0,
        noadvert: false,
        sending: false,
        catalog: {folders:[],seasons:[],folder_dict:{},season_dict:{}},
        folders: [],
        season_id: "",
        folder_id: "",
        cgroup_id: "",
        email_template_id: "",
        courses: [],
        selected_courses: [],
        target_courses:[],
        emails:[],
        cgroups:[],
        email_templates:[]
    }
  }

  getSteps() {
    return ['Výběr kurzů', 'Načtení adres', 'Volba emailu', 'Odeslání'];
  }
  
  getStepContent(step) {
    switch (step) {
      case 0:
        return 'Vyber kurzy do výběru nebo načti uložený seznam kurzů';
      case 1:
        return 'Nastav paramatery načtení emailu a načti je';
      case 2:
        return 'Vyber email, který má být odeslán';
      case 3:
        return 'Nastav parametry odeslání a odešli hromadný email';
      case 4: 
        return '';  
      default:
        return 'Unknown step';
    }
  }


  componentDidMount() {
    Api.gqlCatalog(this.props.client).then(cat=>{
      this.setState({catalog:cat})
    }).catch(this.onApiError);

 
    Api.gqlCGroups(this.props.client).then(cgroups=>{
      //console.log("GQL_CGROUPS:",cgroups);
      this.setState({cgroups:cgroups})
    })
    /*
    Api.emailTemplates().then(et=>{
      console.log("emailTemplates",et);
      this.setState({email_templates:et})
    })
*/
    Api.gqlEmailTemplates(this.props.client).then(et=>{
     // console.log("gql_emailTemplates",et);
      this.setState({email_templates:et})
    })

  }

  onSelectSeasonFolder(season_id,folder_id) {
    //console.log("onSelectSeasonFolder",season_id,folder_id)
    this.setState({season_id:season_id,folder_id:folder_id});
    this.updateCourseList(season_id,folder_id)
  }

  onSelectCGroup(cgroup_id) {
    this.setState({cgroup_id:cgroup_id});
  }
  onSelectEmailTemplate(et_id) {

    const et = this.state.email_templates.find((c)=>{return c.id === et_id});

    this.setState({email_template_id:et_id, email_template:et})
  }

  updateCourseList(season_id,folder_id) {
    if ((season_id!=="" && folder_id!=="")) {

     // Api.coursesBySF(season_id,folder_id).then(data=>{
     //   console.log("coursesBySF",data);
     //   this.setState({courses:data,selected_courses:[]})
     // }).catch(this.onApiError);

      Api.gqlCoursesBySF(this.props.client,season_id,folder_id).then(data=>{
       // console.log("gqlCoursesBySF",data);
        this.setState({courses:data,selected_courses:[]})
      }).catch(this.onApiError);

    } else {
        this.setState({courses:[],selected_courses:[]});
    }
  }

  onSelectCourse(id,checked) {
    const {selected_courses} = this.state
    const i = selected_courses.indexOf(id);
    const newSelected = [...selected_courses];
    if (i === -1) {
        newSelected.push(id);
    } else {
        newSelected.splice(i, 1);
    }
    this.setState({selected_courses:newSelected});
  }

  onDeleteCourse(course_id) {
    //console.log("onDeleteCourse",course_id)
    const {target_courses} = this.state;
    const newTarget = target_courses.filter((c)=>{return c.id !== course_id});
    this.setState({target_courses:newTarget});
  }

  onClickAdd() {
    //console.log("onClickAdd")
    const {target_courses} = this.state
    const tc_ids = target_courses.map((c)=>{return c.id});
    const clist = this.state.courses.filter((c)=>{return (this.state.selected_courses.indexOf(c.id)!==-1) && (tc_ids.indexOf(c.id)===-1)});
    this.setState({target_courses:[...target_courses,...clist]});
  }

  onClickLoadGroup() {
    const cg = this.state.cgroups.find((c)=>{return c.id === this.state.cgroup_id});
    if (cg) {
      //console.log("loading courses ",cg.course_ids);
      //Api.coursesByIDs(cg.course_ids).then(clist=>{
      //  console.log("coursesByIDs",clist);
      //  this.setState({target_courses:clist});
      //}).catch(this.onApiError)

      Api.gqlCoursesByCG(this.props.client,cg.id).then(clist=>{
        console.log("gql_coursesByCGID",clist);
        this.setState({target_courses:clist});
      }).catch(this.onApiError)

    } else {
      console.error("can't find cgroup",this.state.cgroup_id);
    }
  }

  onEmails(el) {
    this.setState({emails:el});
  }

  handleChangeAdvert(value) {
    this.setState({noadvert:value})
  }

  onDoSend() {
    this.setState({sending:true});
    Api.gqlSendBulkEmail(this.props.client,this.state.emails,this.state.email_template_id,this.state.noadvert).then((ok)=>{
      this.setState({activeStep:4})
    }).catch(this.onApiError)
  }

  renderSelectCourses() {
    const { classes } = this.props;
    return (
      <Grid key="selectc" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Výběr kurzů</Typography>
        <Paper className={classes.paper}>
          <CGroupNav native={true} selected_cgroup_id={this.state.cgroup_id} cgroups={this.state.cgroups} onSelect={(c_id)=>{this.onSelectCGroup(c_id)}} />
          <Button variant="raised" disabled={this.state.cgroup_id===""}  className={classes.button} onClick={(e)=>this.onClickLoadGroup(e)}> Načíst skupinu do výběru</Button>
          <SeasonFolderNav native={true} selected_folder_id={this.state.folder_id} selected_season_id={this.state.season_id} folders={this.state.catalog.folders} seasons={this.state.catalog.seasons} onSelect={(s,f)=>{this.onSelectSeasonFolder(s,f)}}/>
          <CourseList courses={this.state.courses} selected={this.state.selected_courses} onSelect={(id,checked)=>{this.onSelectCourse(id,checked)}}/>
          <Button variant="raised" disabled={this.state.selected_courses.length===0}  className={classes.button} onClick={(e)=>this.onClickAdd(e)}> Přidat označené do výběru </Button>
        </Paper>
      </Grid>
    )
  }

  renderSelectedCourses(deletable=false) {
    const { classes } = this.props;
    return (
      <Grid key="selectedc" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Vybrané kurzy</Typography>
        <Paper className={classes.paper}>
          <CourseChips folder_dict={this.state.catalog.folder_dict} season_dict={this.state.catalog.season_dict} courses={this.state.target_courses} onDelete={deletable?(course_id)=>{this.onDeleteCourse(course_id)}:null}/>
        </Paper>
      </Grid>
    )
  }
  renderFetchEmails() {
    const { classes } = this.props;
    return (
      <Grid key="fetchem" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Načtení adres</Typography>
        <Paper className={classes.paper}>
          <BulkEmailsFetch onEmails={(el)=>{this.onEmails(el)}} course_ids={this.state.target_courses.map((c)=>{return c.id})}/>
          <BulkEmailsView emails={this.state.emails}/>
        </Paper>
      </Grid>
    )
  }

  renderFetchedEmails() {
    const { classes } = this.props;
    return (
      <Grid key="fetchedem" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Načtené adresy</Typography>
        <Paper className={classes.paper}>
          <BulkEmailsView emails={this.state.emails}/>
        </Paper>
      </Grid>
    )
  }


  renderSelectTemplate() {
    const { classes } = this.props;
    return (
      <Grid key="selecttemp" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Volba šablony emailu</Typography>
        <Paper className={classes.paper}>
          <EmailTemplateNav native selected_email_template_id={this.state.email_template_id} email_templates={this.state.email_templates} onSelect={(c_id)=>{this.onSelectEmailTemplate(c_id)}} />
          { this.state.email_template && <EmailTemplatePreview showDate={true} email_template={this.state.email_template}/> }
        </Paper>
      </Grid>
    )
  }

  renderSelectedTemplateAndRecipients() {
    const { classes } = this.props;
    return (
      <Grid key="selectedtar" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Hromadný email</Typography>
        <Paper className={classes.paper}>
          <BulkEmailsView emails={this.state.emails}/>
          <EmailTemplatePreview showSubject={true} email_template={this.state.email_template}/>
        </Paper>
      </Grid>
    )
  }

  renderSendForm() {
    const { classes , auth} = this.props;
    return (
      <Grid key="sendform" item xs={12} sm={6}>
        <Typography type="title" gutterBottom>Odeslání</Typography>
        <Paper className={classes.paper}>

          <div>
            <FormControl disabled={this.state.sending} component="fieldset" className={classes.formControl}>
              <FormLabel component="label">Nastavení odeslání</FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.noadvert}
                        onChange={(e,v)=>{this.handleChangeAdvert(v)}}
                        value="noadvert"
                      />
                    }
                    label="Nejde o obchodní sdělení"
                  />
                </FormGroup>
              <FormHelperText>{'Pokud je "zašktnuto" pak Email bude odeslán i žákům, kteří nechtějí dostávat "obchodní sdělení"'}</FormHelperText>
            </FormControl>
          </div>
          {auth.is_admin && (<Button variant="raised" disabled={this.state.sending}  className={classes.button} onClick={(e)=>this.onDoSend(e)}> Odeslat hromadný email </Button>)}
          {!auth.is_admin && (<Typography color={"error"}>Nemáte právo odesílat hromadnou poštu</Typography>)}
        </Paper>
      </Grid>
    )
  }

  onSetStep(step) {
    this.setState({activeStep:step});
  }
  onRestart() {
    this.setState({sending:false,activeStep:0,target_courses:[],emails:[],email_template_id: "",email_template:null})
  }

  renderStepButtons(step) {
    const { classes } = this.props;
    switch (step) {
      case 0:
        return [
          <Button key="b1" variant="raised" disabled={this.state.target_courses.length===0}  className={classes.button} onClick={(e)=>this.onSetStep(1)}> Mám vybrané kurzy, přejít na načtení emailů </Button>,
        ];
      case 1:
        return [
          <Button key="b1" variant="raised" className={classes.button} onClick={(e)=>this.onSetStep(0)}> Zpět na výber kurzů </Button>,
          <Button key="b2" variant="raised" disabled={this.state.emails.length===0}  className={classes.button} onClick={(e)=>this.onSetStep(2)}> Mám načtené emaily, přejít na výběr šablony </Button>,
        ];
      case 2: 
        return [
          <Button key="b1" variant="raised" className={classes.button} onClick={(e)=>this.onSetStep(1)}> Zpět na načtení emailů </Button>,
          <Button key="b2" variant="raised" disabled={!this.state.email_template}  className={classes.button} onClick={(e)=>this.onSetStep(3)}> Mám vybranou šablonu emailu, přejít na odeslání </Button>,
        ];
      case 3:
        return [
          <Button key="b1" variant="raised" disabled={this.state.sending} className={classes.button} onClick={(e)=>this.onSetStep(2)}> Zpět na výběr šablony emailu </Button>,
        ];
      case 4:
        return [
          <Button key="b2" variant="raised" className={classes.button} onClick={(e)=>this.onRestart()}> Další hromadné odeslání </Button>,
        ]
      default:
        return 'Unknown step';
    }
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const selectCourses = this.renderSelectCourses();
    //const loadCourses = this.renderLoadCourses();
    const selectedCourses = this.renderSelectedCourses(true);
    const selectedCoursesRO = this.renderSelectedCourses(false);
    const fetchEmails = this.renderFetchEmails();
    const fetchedEmails = this.renderFetchedEmails();
    const selectTemplate = this.renderSelectTemplate();
    const selectedTemplateAndRecipients = this.renderSelectedTemplateAndRecipients();
    const sendForm = this.renderSendForm();
    const stepButtons = this.renderStepButtons(activeStep);
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const props = {};
                //if (this.isStepOptional(index)) {
                //  props.optional = true;
                //}
                //if (this.isStepSkipped(index)) {
                //  props.completed = false;
                //}
                return (
                  <Step key={label} {...props}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Typography className={classes.instructions}>{this.getStepContent(activeStep)}  {stepButtons}</Typography>
            
          </Grid>
          { activeStep === 0 && [selectCourses,selectedCourses] }
          { activeStep === 1 && [selectedCoursesRO,fetchEmails] }
          { activeStep === 2 && [fetchedEmails,selectTemplate] }
          { activeStep === 3 && [selectedTemplateAndRecipients,sendForm] }
          { activeStep === 4 && <Typography type="title"> hromadný email odeslán </Typography> }
          
        </Grid>
      </div>
    );
  }
}


BulkMail.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

BulkMail.defaultProps = {

};


export default compose(
  withStyles(styles),
  withApollo,
)(BulkMail)

