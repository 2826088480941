import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import StringField from './StringField';
//import EnumField from './EnumField';
import PlaceIDField from './Fields/PlaceIDField';
import ImageIDField from './Fields/ImageIDField';
//import { DatePicker } from 'material-ui-pickers';
//import DateField from './Fields/DateField';
import DateTimeField from './Fields/DateTimeField';
import Lodash from 'lodash';

const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class EventEditDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      doc:null,
      open:false,
      dirty:false,
      valid:false,
      selectedDate: '2018-01-01T00:00:00.000Z',
    }
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  }


  doEdit(doc) {
    const dc = Object.assign({},doc);
    this.setState({
        doc:dc,
        doc_err: this.getDocErr(dc),
        open:true,
        dirty:false,
        valid:this.checkDoc(dc)
    })
  }
  getDoc() {
    return this.state.doc;
  }

  doClose() {
    this.setState({doc:null,doc_err:null,open:false})
  }


  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err,
      dirty:true,
      valid:this.checkDoc(doc)
    });
  }

  checkDocField(name,value) {
    if (["name","place_id","date"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  

  render() {
    const {onSave,caption_edit,caption_new} = this.props;
    const {open,doc,doc_err,valid,dirty} = this.state;
    const new_mode = !(doc && doc.id);

    const caption = (doc && doc.id)?caption_edit:caption_new;
    return (
      <Dialog maxWidth={false} open={open} onClose={()=>this.doClose()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {doc && (
            <React.Fragment>
                <StringField required width={300} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
                {new_mode && (<PlaceIDField required width={250} value={doc.place_id} error={doc_err.place_id} onChange={(v)=>this.handleDocChange("place_id",v)} />)}
                <StringField width={500} id="comment" label="Poznámka" value={doc.comment} error={doc_err.comment} onChange={(v)=>this.handleDocChange("comment",v)} />
                <ImageIDField  width={250} value={doc.image_id} error={doc_err.image_id} onChange={(v)=>this.handleDocChange("image_id",v)} />
                <DateTimeField required label="Datum akce" value={doc.date} error={doc_err.date} onChange={(v)=>this.handleDocChange("date",v)}/>
                <DateTimeField   label="Otevření prodeje" value={doc.auto_open_date} error={doc_err.auto_open_date} onChange={(v)=>this.handleDocChange("auto_open_date",v)}/>
                <DateTimeField   label="Ukončení prodeje" value={doc.auto_suspend_date} error={doc_err.auto_suspend_date} onChange={(v)=>this.handleDocChange("auto_suspend_date",v)}/>
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>

        <Button onClick={()=>this.doClose()} color="primary">
          Zavřít
        </Button>
        <Button disabled={!valid || !dirty} onClick={onSave} color="primary">
          Uložit
        </Button>

      </DialogActions>
    </Dialog>
    );
  }
}

EventEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  caption_edit: PropTypes.string,
  caption_new: PropTypes.string
};
EventEditDialog.defaultProps = {
  caption_edit:"Editace akce",
  caption_new:"Nová akce"
};

export default withStyles(styles)(EventEditDialog);