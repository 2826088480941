


const  OrderStatus_schema_enum = ['incomplete','reservation','pending','paid','declined','cancelled','refunded','declined'];
const  OrderStatus_gql_values =  { 
    INCOMPLETE:{value:"incomplete"},
    RESERVATION:{value:"reservation"},
    PENDING:{value:"pending"},
    PAID:{value:"paid"},
    DECLINED:{value:"declined"},
    CANCELLED:{value:"cancelled"},
    REFUNDED:{value:"refunded"},
};

const OrderStatus_field_enum = [
    {key:"INCOMPLETE",label:"nedokončená"},
    {key:"RESERVATION",label:"rezervace"},
    {key:"PENDING",label:"čeká na platbu"},
    {key:"PAID",label:"zaplacená"},
    {key:"DECLINED",label:"zamítnutá"},
    {key:"CANCELLED",label:"zrušená"},
    {key:"REFUNDED",label:"vrácená"},
];
const OrderStatus_props_enum = ['INCOMPLETE','RESERVATION','PENDING','PAID','DECLINED','CANCELLED','REFUNDED'];


module.exports = {
    OrderStatus_schema_enum,
    OrderStatus_gql_values,
    OrderStatus_field_enum,
    OrderStatus_props_enum
};