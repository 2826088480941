import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Lodash from 'lodash';

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 370,
  },
  label:{ 
    paddingTop: theme.spacing.unit * 2
  }
});



function getZonePrices(zoneprices,zone) {
  return Lodash.filter(zoneprices,(zp)=>{
    return zp.event_zone_id == zone.id;
  })
}

function getZoneFreeCapacity(zone,zone_capacity_infos) {
  const zci = Lodash.find(zone_capacity_infos,{event_zone_id:zone.id});
  if (zci && zci.free_capacity>0) {
    return zci.free_capacity;
  } else {
    return 0;
  }
}


class EventTicketVariantList extends React.Component {
 
  handleOnSelect(v) {
    if (v.freeCap>0)
      this.props.onSelect(v.zone,v.price)
  }
  render() {
    const { classes,prices,zones,dense,label,zone_capacity_infos } = this.props;
 

    const variants = zones.map(fz=>{
      const zprices = getZonePrices(prices,fz);
      const freeCap = getZoneFreeCapacity(fz,zone_capacity_infos);
      return zprices.map(zp=>{
        return {zone:fz,price:zp,freeCap};
      })
    }) 
    const list = Lodash.flatten(variants);

    const title = label?(<React.Fragment ><Typography className={classes.label} variant={"body2"}>{label}</Typography><Divider/></React.Fragment>):null;
    return (
      <div className={classes.root}>
        {title}
        <div className={classes.list}>
          <List dense={dense}>
            {list.map((v,idx)=>{
              const free_cap = (v.freeCap>0);
              const color = v.zone.primary_color?v.zone.primary_color:"gray";
              return (
                <ListItem key={idx} button={free_cap} onClick={()=>this.handleOnSelect(v)}>
                  <Avatar style={{backgroundColor: color}}>
                      <PlaceIcon />
                  </Avatar>
                  <ListItemText
                    primary={v.zone.name+", "+v.price.cost+"kč"}
                    secondary={v.price.name}
                  />
                  <ListItemSecondaryAction>
                      {free_cap?(
                        <Button variant="contained" disabled={!free_cap} onClick={()=>this.handleOnSelect(v)}>
                          vybrat
                        </Button>
                      ):(
                        <Typography color="error" variant="button" >vyprodáno</Typography> 
                      )}
                   
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
    );
  }
}

EventTicketVariantList.propTypes = {
  classes: PropTypes.object.isRequired,
  prices: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  zone_capacity_infos: PropTypes.array.isRequired,
  label: PropTypes.string,
  dense: PropTypes.bool,
  onSelect: PropTypes.func,
};
EventTicketVariantList.defaultProps = {
  dense:false
};


export default withStyles(styles)(EventTicketVariantList);