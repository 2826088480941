import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import ActionButton from './ActionButton';
import DragHandle from './DragHandle';
import PlaceIDView from './Fields/PlaceIDView';
//import BooleanView from './BooleanView';
//import PlaceMapView from './PlaceMapView';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
   // maxWidth: 1000,
   // background: theme.palette.background.paper,
  },
  dragging: {
    opacity:0,
    //color: "rgba(0, 0, 0, 0)",
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  row: {
    height:24,
  }
});


class PricePlansTableRow extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
    
    }
  }
 


 
  
  

  render() {
    const { auth,activeDrag, isDragging, connectDragSource, connectDragPreview,connectDropTarget, data, classes } = this.props;
    const className = classNames([
      { [classes.dragging]: isDragging },
      { [classes.hover]: !activeDrag},
      classes.row
    ]);
    return (
      
      <TableRow  hover={!activeDrag} className={className}>
          <TableCell className={classes.cell}> {connectDropTarget(connectDragSource(
            <div><DragHandle/></div>
            ))} </TableCell>
          <TableCell className={classes.cell}> {connectDragPreview(<div>{data.name}</div>)} </TableCell>
          <TableCell className={classes.cell}> <PlaceIDView place_id={data.place_id} /> </TableCell>
          <TableCell className={classes.cell}>  
             
          {auth.is_admin && this.props.onEdit && (<ActionButton tooltip={"editovat"} icon={'edit'} onClick={()=>this.props.onEdit(data)}/>)}
          {this.props.onShow && (<ActionButton tooltip={"zobrazit"} icon={'view'} onClick={()=>this.props.onShow(data)}/>)}
          {auth.is_admin && this.props.onDelete && (<ActionButton tooltip={"smazat"} icon={'delete'} onClick={()=>this.props.onDelete(data)}/>)}

          </TableCell>
      </TableRow>
      );
  }
}

PricePlansTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  activeDrag: PropTypes.bool.isRequired,
  findRow: PropTypes.func.isRequired,
  moveRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  onDelete: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

PricePlansTableRow.defaultProps = {
 
};


const rowSource = {

  canDrag(props) {
    return props.auth.is_admin;
  },

  beginDrag(props) {
    props.onDrag(true);
    const r = {
      item: props.item,
      id: props.id,
      originalIndex: props.findRow(props.id).index,
    };
  
    return r;
  },
  endDrag(props, monitor) {
    props.onDrag(false);

    const { id: droppedId, originalIndex } = monitor.getItem()
		const didDrop = monitor.didDrop()

		if (!didDrop) {
			props.moveRow(droppedId, originalIndex)
    } else {
      props.save();
    }
    
    return;
  }
};

const rowTarget = {
  
  hover(props, monitor, component) {

    const draggedId  = monitor.getItem().id;
		const overId  = props.id;

		if (draggedId !== overId) {
			const overIndex  = props.findRow(overId).index;
			props.moveRow(draggedId, overIndex);
		}
    return;
  }
}

function dnd_src(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}
function dnd_trg(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default compose(
  withStyles(styles),
  DropTarget('row', rowTarget, dnd_trg),
  DragSource('row', rowSource, dnd_src)
  
)(PricePlansTableRow)
