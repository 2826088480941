import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DateView from './DateView';


class IFrame extends React.Component {
  

  /**
   * Called after mounting the component. Triggers initial update of
   * the iframe
   */
  componentDidMount() {
      this._updateIframe();
  }

  /**
   * Called each time the props changes. Triggers an update of the iframe to
   * pass the new content
   */
  componentDidUpdate() {
      this._updateIframe();
  }

  /**
   * Updates the iframes content and inserts stylesheets.
   * TODO: Currently stylesheets are just added for proof of concept. Implement
   * and algorithm which updates the stylesheets properly.
   */
  _updateIframe() {
      const iframe = this.iframeRef;
      const document = iframe.contentDocument;
      const head = document.getElementsByTagName('head')[0];
      document.body.innerHTML = this.props.content;
      
      this.props.stylesheets.forEach(url => {
          const ref = document.createElement('link');
          ref.rel = 'stylesheet';
          ref.type = 'text/css';
          ref.href = url;
          head.appendChild(ref);
      });
  }

  /**
   * This component renders just and iframe
   */
  render() {
      const { classes } = this.props;
      return <iframe className={classes.iframe} ref={(el) => this.iframeRef = el} title={'preview_iframe'}/>
  }
}

IFrame.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  stylesheets: PropTypes.arrayOf(PropTypes.string),
};


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  iframe: {
    width: '100%',
    height: 300
  }
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  showSubject: PropTypes.bool,
  showDate: PropTypes.bool,
  email_template: PropTypes.object
};

const defaultProps = {
  showSubject: false,
  showDate: false
};

class EmailTemplatePreview extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.props.showDate &&  <p> datum vložení: <DateView date={ this.props.email_template.date}/> </p> }
        {this.props.showSubject &&  <p> předmět zprávy: <b>{ this.props.email_template.subject} </b></p> }
        <IFrame classes={classes} content={this.props.email_template.text_as_html} stylesheets={[]} />
      </div>
    );
  }
}

EmailTemplatePreview.propTypes = propTypes;
EmailTemplatePreview.defaultProps = defaultProps;

export default withStyles(styles)(EmailTemplatePreview);