import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanField from './BooleanField';
import StringField  from './StringField';
import NumberField  from './NumberField';
import EnumField from './EnumField';
import Lodash from 'lodash';
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});




class FolderForm extends React.Component {


  constructor(props) {
    super(props);
    console.log("constructor",props.doc)
    this.state = {  
      doc: Object.assign({},props.doc),
      doc_err: this.getDocErr(props.doc),
    }
  }

  setDoc(doc) {
    this.setState({
      doc: Object.assign({},doc),
      doc_err: this.getDocErr(doc),
    })
  }
 

  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err
    });
    this.props.onDocChange(doc,this.checkDoc(doc));
  }

  checkDocField(name,value) {
    if (["name","evi_group"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  renderForm() {
    const { classes } = this.props;
    const { doc, doc_err } = this.state;
    //const doc_ok=this.checkDoc(doc);
    return (
        <form className={classes.form}  noValidate autoComplete="off">  
        <div>
          <StringField required width={300} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
          <BooleanField required width={200} id="no_stat" label="nezahrnovat do statistik" value={doc.no_stat} error={doc_err.no_stat} onChange={(v)=>this.handleDocChange("no_stat",v)} />
          <BooleanField required width={200} id="access_check" label="kontrola vstupu" value={doc.access_check} error={doc_err.access_check} onChange={(v)=>this.handleDocChange("access_check",v)} />
          <EnumField required width={200} id="evi_group" label="Evidence" values={[{key:"VB",label:"VB"},{key:"LB",label:"LB"},{key:"SRO",label:"sro"},{key:"ST", value:"Stadion"},{key:"AB",label:"AB"},{key:"ZS",label:"Spolek"},{key:"NO",label:"nezařazeno"}]} value={doc.evi_group} error={doc_err.evi_group} onChange={(v)=>this.handleDocChange("evi_group",v)} />
          <NumberField required width={150} id="enroll_group" label="zápisová skupina" value={doc.enroll_group} error={doc_err.enroll_group} onChange={(v)=>this.handleDocChange("enroll_group",v)} />
          <NumberField required width={150} id="order_value" label="řazení" value={doc.order_value} error={doc_err.order_value} onChange={(v)=>this.handleDocChange("order_value",v)} />

        </div>
        </form>
    )
  }

  render() {
    const form = this.renderForm();
    return (
      <div className="foo">
        {form}
      </div>
    );
  }
}

FolderForm.propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  //onSave: PropTypes.func.isRequired,
  //onCancel: PropTypes.func.isRequired,
  onDocChange: PropTypes.func.isRequired,
};
FolderForm.defaultProps = {
  
};

export default withStyles(styles)(FolderForm);