import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';


import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';



import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import StudentViewDialog from './StudentViewDialog';
import StudentPayEditDialog from './StudentPayEditDialog';
import ActionLink from './ActionLink';
import ActionButton from './ActionButton';
import { compose, withApollo } from "react-apollo";
import { connect } from 'react-redux'
import { setSrchSeasons } from './../actions'


var Api = require("../libs/Api");



const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    typo: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400,
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    paper: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    cell: {
        paddingLeft: '10px',
        paddingRight: '10px',
    }
});
  





class Srch extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            counter:0,
            folders:[],        
            seasons:[],
            //activeSeasons:[],
            srchString: "",
            searching: false,
            errorString: "",
            results: [],
            showStudent_key:null,
            payStudent_key:null,
            payStudent_status:"IN"
        };
    }

    componentDidMount() {
        Api.gqlCatalog(this.props.client).then(cat=>{
            if ((cat.seasons.length>0) && (this.props.srch_season_keys.length==0)) {
                const as = [cat.seasons[0].id];
                this.props.onChangeSrchSeasons(as);
                //this.setState({activeSeasons:as})
            }
            this.setState({seasons:cat.seasons,folders:cat.folders})
        }).catch(this.onApiError);
    }

    
    onApiError(e) {
        console.error(e);
        this.setState({errorString:"chyba, prosím opakuj hledání"})
    }

    onClickSearch(e) {
        this.setState({searching:true, results:[], errorString:""});
        Api.gqlSearch(this.props.client,this.state.srchString,this.props.srch_season_keys).then(res=>{
            console.log(res);
            this.setState({searching:false, results:res.students});
        }).catch((err)=>{
            this.onApiError(err);
            this.setState({searching:false});
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onClickSearch(e);
        }
    }

    handleCheckSeason(id, checked){

        const { srch_season_keys } = this.props;
        const currentIndex = srch_season_keys.indexOf(id);
        const newActiveSeasons = [...srch_season_keys];
    
        if (currentIndex === -1) {
          newActiveSeasons.push(id);
        } else {
          newActiveSeasons.splice(currentIndex, 1);
        }
       // console.log("new onChangeSrchSeasons",newActiveSeasons)
        this.props.onChangeSrchSeasons(newActiveSeasons);

       // this.setState({
       //   activeSeasons: newActiveSeasons,
       // });
        
    };

    isSeasonChecked(id) {
        return this.props.srch_season_keys.indexOf(id)!==-1;
    }

    renderSeasonCheckBox(s) {
        return (
            <FormControlLabel key={s.id}
            control={
              <Checkbox
                value={s.id}
                checked={this.isSeasonChecked(s.id)}
                onChange={(event,checked)=>this.handleCheckSeason(s.id,checked)}
              />
            }
            label={s.name}
          />
        );
    }

    lookupRef(c) {
        if (c) {
                const f = this.state.folders.find(f=>{return f.id === c.folder_key});
                const s = this.state.seasons.find(f=>{return f.id === c.season_key});
                if (f && s) {
                    return {folder:f,season:s};
                }
        } 
        return {folder:{name:"?"},season:{name:"?"}};
    }


    handleCloseStudentViewDialog() {
        this.setState({showStudent_key:null});
    }
    handleActionShowStudent(student_key) {
        this.setState({showStudent_key:student_key});
    }
    
    handleActionPayStudent(student_key,student_status) {
        this.setState({payStudent_key:student_key,payStudent_status:student_status});
    }
    

    handleCloseStudentPayDialog() {
        this.setState({payStudent_key:null});
    }

    renderResultRowCmds(s) {
        const {auth} = this.props;
       // var shref = "/courses/detail/";
        var smode = "in"
        switch(s.status) {
            case "e": /*shref = "/courses/detail/";*/  smode="in"; break;
            case "IN": /*shref = "/courses/detail/";*/  smode="in"; break;
            case "n": /*shref = "/courses/enroll/";*/  smode="filed"; break;
            case "FILED": /*shref = "/courses/enroll/";*/  smode="filed"; break;
            case "s": /*shref = "/courses/spare/";*/ smode="spare"; break;
            case "SPARE": /*shref = "/courses/spare/";*/ smode="spare"; break;
            case "k": /*shref = "/courses/spare/";*/ smode="kicked"; break;
            case "KICKED": /*shref = "/courses/spare/";*/ smode="kicked"; break;
            default:
        }
        return (
            <div>
                <ActionButton tooltip={"zobrazit"} icon={'view'} onClick={()=>this.handleActionShowStudent(s.id)}/>
                {auth.is_admin && (<ActionLink useRouter tooltip={"platba"} icon={'pay'} href={"/spa/student/payment/"+s.id}/>) }
                <ActionLink useRouter tooltip={"do kurzu"} icon={'students'} href={"/spa/course/"+s.course_key+"/"+smode}/>
                <ActionLink useRouter tooltip={"Doklad"} icon={'receipt'} href={"/spa/student/invoice/"+s.id}/>
                {auth.is_admin && (<ActionLink useRouter tooltip={"Nová karta"} icon={'card'} href={"/spa/student/newcard/"+s.id}/>)}
                {auth.is_super && (<ActionLink useRouter tooltip={"Debug"} icon={'debug'} href={"/spa/student/debug/"+s.id}/>)}
            </div>
        );

    }

    renderResultRow(s) {
        const classes = this.props.classes;
        const ref = this.lookupRef(s.course);
        const cmds = this.renderResultRowCmds(s);
        return (
            <TableRow key={s.id}>
                <TableCell className={classes.cell}>{s.course.evi_group}</TableCell>
                <TableCell className={classes.cell}>{Api.nameOfStatus(s.status)}</TableCell>
                <TableCell className={classes.cell}>{s.name}</TableCell>
                <TableCell className={classes.cell}>{s.surname}</TableCell>
                <TableCell className={classes.cell}>{s.email}</TableCell>
                <TableCell className={classes.cell}>{s.var_sym}</TableCell>

                <TableCell className={classes.cell}>{s.course_cost}</TableCell>
                <TableCell className={classes.cell}>{s.paid_amount}</TableCell>
                <TableCell className={classes.cell}>{s.discount}</TableCell>
                <TableCell className={classes.cell}>{s.ref_gid}</TableCell>


                <TableCell className={classes.cell}>{s.course.code}</TableCell>
                <TableCell className={classes.cell}>{ref.folder.name}</TableCell>
                <TableCell className={classes.cell}>{ref.season.name}</TableCell>
                <TableCell className={classes.cell}>{cmds}</TableCell>
            </TableRow>
        )
    }


    renderStudentViewDialog() {
        const {showStudent_key} = this.state;
        var title = "Detail žáka"
        return (
          <StudentViewDialog open={showStudent_key!=null} student_key={showStudent_key} caption={title} onClose={()=>this.handleCloseStudentViewDialog()}/>
        )
      }
    

    renderStudentPayDialog() {
        const {payStudent_key,payStudent_status} = this.state;
        var title="";
        switch (payStudent_status) {
          case "IN": title = "Editace platby žáka"; break;
          case "FILED": title = "Editace platby přihlášky"; break;
          case "SPARE": title = "Editace  platby náhradníka"; break;
          default:
            title = "Editace platby";
        }
        return (
          <StudentPayEditDialog mode={payStudent_status} open={payStudent_key!=null} student_key={payStudent_key} caption={title} onClose={()=>this.handleCloseStudentPayDialog()}/>
        )
    }
      
    render () {
        const classes = this.props.classes;
        const sc = this.state.seasons.map((s=>this.renderSeasonCheckBox(s)));
        const sr = this.state.results.map((s=>this.renderResultRow(s)));
        const svd = this.renderStudentViewDialog();
        const spd = this.renderStudentPayDialog();
        return (
            <div>
                {svd}
                {spd}
                <Paper>   
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormHelperText>Označ sezóny pro hledání</FormHelperText>
                        <FormGroup row> {sc} </FormGroup>
                    </FormControl>
                    <br/>
                    <TextField
                        id="full-width"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder=""
                        helperText="zadej jméno, email, variabilní symbol nebo číslo karty"
                        margin="normal"
                        disabled={this.state.searching}
                        value={this.state.srchString}
                        onChange={(event)=>this.setState({srchString:event.target.value})}
                        onKeyPress={(event=> this.handleKeyPress(event))}
                    />
                    <Button variant="raised" disabled={this.state.searching}  className={classes.button} onClick={(e)=>this.onClickSearch(e)}> Hledat </Button>
                    {this.state.searching && <LinearProgress />}
                    {this.state.errorString && <SnackbarContent message={this.state.errorString}/>}
                </Paper>
                <Paper className={classes.paper}>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                <TableCell className={classes.cell}>Ev.</TableCell>
                                <TableCell className={classes.cell}>typ</TableCell>
                                <TableCell className={classes.cell}>jméno</TableCell>
                                <TableCell className={classes.cell}>přijmení</TableCell>
                                <TableCell className={classes.cell}>email</TableCell>
                                <TableCell className={classes.cell}>v.s.</TableCell>
                                <TableCell className={classes.cell}>kurzovné</TableCell>
                                <TableCell className={classes.cell}>zaplaceno</TableCell>
                                <TableCell className={classes.cell}>sleva</TableCell>
                                <TableCell className={classes.cell}>č.k.</TableCell>
                                <TableCell className={classes.cell}>kurz</TableCell>
                                <TableCell className={classes.cell}>kategorie</TableCell>
                                <TableCell className={classes.cell}>sezóna</TableCell>
                                <TableCell className={classes.cell}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sr}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );

    }
}




Srch.propTypes = {
    classes: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    srch_season_keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChangeSrchSeasons: PropTypes.func.isRequired
};
  
Srch.defaultProps = {};

function mapStateToProps(state) {
    return { 
        evi_group: state.select.evi_group,
        srch_season_keys: state.opts.srch_season_keys
    }
  }
    
  const mapDispatchToProps = dispatch => {
    return {
      onChangeSrchSeasons: keys => {
        dispatch(setSrchSeasons(keys))
      },
    }
  }
  


export default compose(
    withStyles(styles),
    withApollo,
    connect(mapStateToProps,mapDispatchToProps)
  )(Srch)
  
  