import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place';
import SeatIcon from '@material-ui/icons/EventSeat';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
//import Lodash from 'lodash';
import {getTicketPlaceText} from "../libs/Tickets";

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    //overflow: 'auto',
    //height: 300,
  },
  label:{ 
    paddingTop: theme.spacing.unit * 2
  }
});






class EventTicketVariantOne extends React.Component {
 
  getTicketPlaceText(seat,seat_idx) {

    return getTicketPlaceText({event_seat_id:seat.id,event_seat_idx:seat_idx},[seat]);

  } 
 
  render() {
    const { classes,price,zone,dense,label,seat,seat_idx } = this.props;
    const color = zone.primary_color?zone.primary_color:"gray";
    const title = label?(<React.Fragment ><Typography className={classes.label} variant={"body2"}>{label}</Typography><Divider/></React.Fragment>):null;
    return (
      <div className={classes.root}>
        {title}
        <div className={classes.list}>
          <List dense={dense}>
            <ListItem>
              <Avatar style={{backgroundColor: color}}>
                  <PlaceIcon />
              </Avatar>
              <ListItemText
                primary={zone.name+", "+price.cost+"kč"}
                secondary={price.name}
              />
              <ListItemSecondaryAction>
                  <Button variant="contained" onClick={this.props.onReset}>
                    změnit
                  </Button>
              </ListItemSecondaryAction>
            </ListItem>

            { seat && (
              <ListItem>
              <Avatar>
                  <SeatIcon />
              </Avatar>
              <ListItemText
                primary={"Místo - "+this.getTicketPlaceText(seat,seat_idx)}
              />
              </ListItem>
            )}
          </List>
        </div>
      </div>
    );
  }
}

EventTicketVariantOne.propTypes = {
  classes: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  zone: PropTypes.object.isRequired,
  seat: PropTypes.object,
  seat_idx: PropTypes.number,
  label: PropTypes.string,
  dense: PropTypes.bool,
  onReset: PropTypes.func.isRequired,
};
EventTicketVariantOne.defaultProps = {
  dense:false
};


export default withStyles(styles)(EventTicketVariantOne);