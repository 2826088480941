import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from "lodash";
import {EventTicketStatus_field_enum } from './../../../graphql/enums/eventticketstatus';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class EventTicketStatusView extends React.PureComponent {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,event_ticket_status } = this.props;
    const ss = Lodash.find(EventTicketStatus_field_enum,{key:event_ticket_status});
    return ( 
      <span className={classes.root}>
      { ss?ss.label:(<span> ? {event_ticket_status} </span>) }
      </span>
    );
  
  }
}

EventTicketStatusView.propTypes = {
  classes: PropTypes.object.isRequired,
  event_ticket_status: PropTypes.string
};
EventTicketStatusView.defaultProps = {
  
};

export default withStyles(styles)(EventTicketStatusView);