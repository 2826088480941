import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import Checkbox from '@material-ui/core/Checkbox';
import ModeEditIcon from '@material-ui/icons/ModeEdit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { withStyles } from '@material-ui/core/styles';
import DateView from './DateView';
import BooleanView from './BooleanView';
import gql from "graphql-tag";
import { compose, withApollo } from "react-apollo";


var lodash = require('lodash');
var moment = require('moment');
moment.locale('cs');

//var Api = require("../libs/Api");

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },

    button: {
      margin: theme.spacing.unit,
    },
    typo: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    formControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    paper: {
        maxWidth: '800px',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    cell: {
   //     paddingLeft: '10px',
   //     paddingRight: '10px',
    },
    green: {
        color: "green"
    }
});

const GET_ASSISTANTS = gql`
query Assistants {
    assistants {
        id
        surname
        name
        active
        ref_gid
    }
}
`;

const GET_ASSISTANT_LOGS = gql`
query AssistantLogs($assistant_id:String!) {
    assistant(id:$assistant_id) {
        id
        surname
        name
        active
        ref_gid
        activites {
            id
            date
            mode
        }
    }
}
`;


const GET_ASSISTANTS_LAST = gql`
query AssistantsLast {
    assistants {
        id
        surname
        name
        active
        ref_gid
        last_activity {
            id
            date
            mode
        }
    }
}
`;

const GET_ASSISTANT = gql`
query Assistant($assistant_id:String!) {
    assistant(id:$assistant_id) {
        id
        surname
        name
        active
        ref_gid
    }
}`;


const CREATE_ASSISTANT = gql`
mutation CreateAssistant($surname:String!,$name:String,$active:Boolean!) {
    assistantCreate(surname:$surname,name:$name,active:$active) {
        id
    }
}
`;


const UPDATE_ASSISTANT = gql`
mutation UpdateAssistant($id:String!,$surname:String,$name:String,$active:Boolean) {
    assistantUpdate(id:$id,surname:$surname,name:$name,active:$active) {
        id
    }
}
`;


const HIDE_ASSISTANT = gql`
mutation HideAssistant($id:String!) {
    assistantHide(id:$id) {
        id
    }
}
`;

const PRINT_CARD = gql`
mutation PrintACards($assistant_ids:[String]!) {
    printAssistantCards(assistant_ids:$assistant_ids) {
      error
      error_message
      s3key
      url
      file_name
      content_type
    }
  }
`;

const compareLocale="cz";
function safeS(a) {
    if ((a === undefined) || (a === null)) {
      return ""
    } else {
      return a
    }
}
  
function safeCmp(a,b) {
  return safeS(a).localeCompare(safeS(b),compareLocale)
}

function localeSortBy(a,b,keys) {
    for (var k of keys) {
        var c = safeCmp(a[k],b[k]);
        if (c) {
          return c
        }
      }
    return 0
}


class Assistant extends Component {
    
    constructor(props) {
        super(props);
        this.state = {  
            open:false,
            askOpen:false,
            askMsg:"",
            toDel: "",
            wait:false,
            openEdit:false,
            openLogs:false,
            errorString: "",
            assistant: { id:"",name: "", surname: "", active:true},
            logs:[],
            assistants: [],
            selected:[],
            lastActivites: [],
           // showActivity:false
        }
    }

    onApiError(e) {
        console.error(e);
        this.setState({errorString:"chyba"})
    }
    handleChange(event,name) {
        let { assistant } = this.state;
        assistant[name] = event.target.value;
        this.setState({ assistant: assistant });
    };
    handleChangeCB(value,name) {
        let { assistant } = this.state;
        assistant[name] = value;
        this.setState({ assistant: assistant });
    };
    
    refreshAssistants() {

        const { client } = this.props;
        client.query({
            query:GET_ASSISTANTS,
            fetchPolicy:"no-cache",
        }).then(d=>{
            const ass = lodash.cloneDeep(d.data.assistants).sort((a,b)=>localeSortBy(a,b,['surname','name']));
            this.setState({assistants:ass});
        }).catch(this.onApiError);
    }

    refreshLastActivities() {
        this.props.client.query({
            query:GET_ASSISTANTS_LAST,
            fetchPolicy:"no-cache"
        }).then(d=>{
            console.log("refreshLastActivities",d.data);
            const ass = lodash.cloneDeep(d.data.assistants).sort((a,b)=>localeSortBy(a,b,['surname','name']));
           

            this.setState({lastActivites:ass,lastUpdate:new Date()});
        })
       // Api.assistantsLastLog().then(lal=>{
       //     const acti = lodash.keyBy(lal.logs,'_id');
       //     this.setState({lastActivites:acti,lastUpdate:new Date()});
       // }).catch(this.onApiError);
    }
    
    updateTimer() {
        if (this.props.page ==="online" ) {
            this.refreshLastActivities();
        }
    }
 
    componentDidMount() {
        this.refreshAssistants();
        this.refreshLastActivities();
        this.tid = setInterval(()=>{this.updateTimer()}, 60000);

    }
    componentWillUnmount() {
        clearInterval(this.tid);
    }
    onSelect(id,checked) {
        const {selected} = this.state
        const i = selected.indexOf(id);
        const newSelected = [...selected];
        if (i === -1) {
            newSelected.push(id);
        } else {
            newSelected.splice(i, 1);
        }
        this.setState({selected:newSelected});
    }

    handleSelectAll(e,checked) {
        if (checked) {
            const newSelected = this.state.assistants.map(s=>s.id);
            this.setState({selected:newSelected})
        } else {
            this.setState({selected:[]})
        }
    }
 
    

    handleClickDelete(id) {
        this.setState({toDel:id, askOpen:true,askMsg:"opravu smazat?"});
    };

    handleClickNew() {
        this.setState({ open: true, openEdit:false, assistant: {name:"",surname:"",id:"",active:true} });
    };

    handleClickEdit(id) {
        this.props.client.query({
            query:GET_ASSISTANT,
            variables:{assistant_id:id},
            fetchPolicy:"no-cache"
        }).then(d=>{
           const ass = Object.assign({},d.data.assistant);
            this.setState({ open: true, openEdit:true, assistant:ass });
        }).catch((err)=>{
            this.onApiError(err);
        })
    };
    
    handleClickLog(id) {
        this.props.client.query({
            query:GET_ASSISTANT_LOGS,
            variables:{assistant_id:id},
            fetchPolicy:"no-cache",
        }).then(d=>{
            console.log("handleClickLog",d);
            const logs = d.data.assistant.activites?d.data.assistant.activites:[];
            this.setState({openLogs:true,assistant:d.data.assistant,logs:logs})
        }).catch((err)=>{
            this.onApiError(err);
        })
    }

    handleRequestSave() {
        if (this.state.openEdit) {
            this.props.client.mutate({
                mutation:UPDATE_ASSISTANT,
                variables:this.state.assistant
            }).then(res=>{
                this.refreshAssistants();
            }).catch((err)=>{
                this.onApiError(err);
            })
        } else {
            this.props.client.mutate({
                mutation:CREATE_ASSISTANT,
                variables:this.state.assistant
            }).then(res=>{
           // Api.putAssistant(this.state.assistant).then(res=>{
                this.refreshAssistants();
            }).catch((err)=>{
                this.onApiError(err);
            })
        }
        this.setState({ open: false });
    };

    handleRequestCancelAsk() {
        this.setState({ askOpen: false });
    }
    handleRequestConfirmAsk() {
        this.setState({ askOpen: false });
        this.props.client.mutate({
            mutation:HIDE_ASSISTANT,
            variables:{id:this.state.toDel},
        }).then(ass=>{
            this.refreshAssistants();
        }).catch((err)=>{
            this.onApiError(err);
        })
    }
    
    handleRequestCancel() {
        this.setState({ open: false });
    };

    handleRequestCloseLogs() {
        this.setState({ openLogs: false });
    }

    handleClickCards() {
        const torender = this.state.assistants.filter(a=>{return this.state.selected.indexOf(a.id)!==-1});
        this.setState({wait:true});
        console.log("handleClickCards",torender)
        this.props.client.mutate({
            mutation:PRINT_CARD,
            variables:{assistant_ids:torender.map(a=>{return a.id})}
        }).then(res=>{
            //console.log("PRINT_CARD res",res);
            
            //console.log(file_url);
            this.setState({wait:false});
            window.location.href = res.data.printAssistantCards.url;
            
        }).catch((err)=>{
            this.setState({wait:false});
            this.onApiError(err);
        })

    }

    renderAskDialog() {
       // const classes = this.props.classes;
        return (
            <Dialog open={this.state.askOpen} onClose={(e)=>this.handleRequestCancelAsk(e)}>
                <DialogTitle>{this.state.askMsg}</DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>this.handleRequestCancelAsk(e)} color="primary">
                        Storno
                    </Button>
                    <Button onClick={(e)=>this.handleRequestConfirmAsk(e)} color="primary">
                        Smazat
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderDialog() {
        const classes = this.props.classes;
        return (
            <Dialog open={this.state.open} onClose={(e)=>this.handleRequestCancel(e)}>
                <DialogTitle>{this.state.openEdit?'editovat asistenta':'vytvořit nového asistenta'}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        label="Jméno"
                        className={classes.textField}
                        value={this.state.assistant.name}
                        onChange={(e)=>this.handleChange(e,'name')}
                        margin="normal"
                        autoComplete="off"
                    />
            
                    <TextField
                        id="surname"
                        label="Přijmení"
                        className={classes.textField}
                        value={this.state.assistant.surname}
                        onChange={(e)=>this.handleChange(e,'surname')}
                        margin="normal"
                        autoComplete="off"
                    />
                
                    <FormControlLabel className={classes.textField}
                        control={
                            <Checkbox
                                checked={this.state.assistant.active}
                                onChange={(e,checked)=>this.handleChangeCB(checked,'active')}
                                value="checked"
                            />
                        }
                        label="aktivní"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>this.handleRequestCancel(e)} color="primary">
                        Storno
                    </Button>
                    <Button onClick={(e)=>this.handleRequestSave(e)} color="primary">
                        Uložit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    renderLogDialog() {
        //const classes = this.props.classes;
        return (
            <Dialog open={this.state.openLogs} onClose={(e)=>this.handleRequestCloseLogs(e)}>
                <DialogTitle>Docházka assistenta - {this.state.assistant.name} {this.state.assistant.surname}</DialogTitle>
                <DialogContent>
                    {this.state.logs.map((i) => {
                        return (
                            <p key={i.id}>
                                <DateView date={i.date}/> {i.mode === 'OUT' && 'odchod'} {i.mode === 'IN' && 'příchod'} 
                            </p>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e)=>this.handleRequestCloseLogs(e)} color="primary">
                        Zavřít
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    renderTableRowCmds(a) {
        const {auth} = this.props;
        return (
            <div>
                {auth.is_admin && (
                    <IconButton color='primary' onClick={(e)=>this.handleClickEdit(a.id)}>
                        <ModeEditIcon/>
                    </IconButton>
                )}
                <IconButton onClick={(e)=>this.handleClickLog(a.id)}>
                    <ReceiptIcon/>
                </IconButton>
                {auth.is_admin && (
                    <IconButton color='secondary' onClick={(e)=>this.handleClickDelete(a.id)}>
                        <DeleteIcon/>
                    </IconButton>
                )}
            </div>
        );
    }

    renderTableRow(a) {
        const classes = this.props.classes;
        const cmds = this.renderTableRowCmds(a);
        const isSelected = this.state.selected.indexOf(a.id) !== -1;
        return (
            <TableRow key={a.id}>
                <TableCell className={classes.cell} padding="checkbox">
                      <Checkbox checked={isSelected} onChange={(event,checked)=>this.onSelect(a.id,checked)}/>
                </TableCell>
                <TableCell className={classes.cell} padding="dense"><BooleanView value={a.active} /></TableCell>
                <TableCell className={classes.cell} padding="dense">{a.name}</TableCell>
                <TableCell className={classes.cell} padding="dense">{a.surname}</TableCell>
                <TableCell className={classes.cell} padding="dense">{a.ref_gid}</TableCell>
                <TableCell className={classes.cell} padding="dense">{cmds}</TableCell>
            </TableRow>
        )
    }


    renderTable() {
        const {classes,auth} = this.props;
        const sr = this.state.assistants.map((s=>this.renderTableRow(s)));
        const numSelected = this.state.selected.length;
        const rowCount = this.state.assistants.length;
        return (
            <Paper className={classes.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell} padding="checkbox">
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={numSelected === rowCount}
                                    onChange={(e,checked)=>this.handleSelectAll(e,checked)}
                                />
                            </TableCell>
                            <TableCell className={classes.cell}>aktivní</TableCell>
                            <TableCell className={classes.cell}>jméno</TableCell>
                            <TableCell className={classes.cell}>přijmení</TableCell>
                            <TableCell className={classes.cell}>číslo karty</TableCell>
                            <TableCell className={classes.cell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sr}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Button variant="raised" disabled={this.state.wait}  className={classes.button} onClick={(e)=>this.handleClickCards()}>Tisk karet</Button>
                                {auth.is_admin && (<Button variant="raised" disabled={this.state.wait}  className={classes.button} onClick={(e)=>this.handleClickNew(e)}>nový assistent</Button>)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
                {this.state.wait && <LinearProgress />}
            </Paper>
        ) 
    }
    renderActivityInfo(act,pastNow) {
        const classes = this.props.classes;
        if (act) {
            if (act.mode ==="OUT") {
                return (
                 <Typography> odchod <DateView date={act.date}/> </Typography>
                )
            } else if (act.mode ==="IN") {
                if (pastNow.isBefore(act.date)) {
                    return (
                        <Typography className={classes.green}> příchod <DateView date={act.date}/> </Typography>
                    )
                } else {
                    return (
                        <Typography > příchod <DateView date={act.date}/> </Typography>
                    )
                }
            } else {
                return "?";
            }
        } else {
            return (
                <Typography > žádný záznam </Typography >
            )
        }
    }

    renderActivityTableRow(a,pastNow) {
        const classes = this.props.classes;
        const act = this.renderActivityInfo(a.last_activity,pastNow);
        
        return (
            <TableRow key={a.id}>
                <TableCell className={classes.cell} padding="dense">{a.name}</TableCell>
                <TableCell className={classes.cell} padding="dense">{a.surname}</TableCell>
                <TableCell className={classes.cell} padding="dense">{act}</TableCell>
            </TableRow>
        )
    }

    renderActivityTable() {
        const classes = this.props.classes;
        const pastNow = moment().subtract(12,"h");
        const sr = this.state.lastActivites.map((s=>this.renderActivityTableRow(s,pastNow)));
        return (
            <Paper className={classes.paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}>jméno</TableCell>
                            <TableCell className={classes.cell}>přijmení</TableCell>
                            <TableCell className={classes.cell}>poslední záznam</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sr}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3}>
                                poslední aktualizace:  {this.state.lastUpdate && (<DateView date={this.state.lastUpdate}/>)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        ) 
    }


    render() {
        const {page} = this.props;

        var table = null;
        switch(page) {
            case "list": table = this.renderTable(); break;
            case "online": table = this.renderActivityTable(); break;
            default:
        }
      //  const table = this.props.page.showActivity? this.renderActivityTable(): this.renderTable();
        const dialog =  this.renderDialog();
        const logDialog = this.renderLogDialog();
        const askDialog = this.renderAskDialog();
        return (
            <div>
                {dialog}
                {askDialog}
                {logDialog}
                {table}
            </div>
        ) 
    }
}



Assistant.propTypes = {
    classes: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    page: PropTypes.oneOf(["list","online"]).isRequired,
};

Assistant.defaultProps = {

};


export default compose(
    withStyles(styles),
    withApollo,
)(Assistant)
  