
import gql from "graphql-tag";

var axios = require('axios');
//const pAll = require('p-all');
var lodash = require('lodash');

var remote_api = axios.create({
  baseURL: "/rapi",
  timeout: 30000,
});



const GET_CATALOG = gql`
  query xCatalog{    
    folders {
      id:key
      name
    }
    seasons {
      id:key
      name
    }
  }
`;
const GET_CGROUPS = gql`
query xCGroups{    
    cgroups {
      id:key
      name
      course_ids:course_keys
      type
    }
  }
`;

const GET_COURSES = gql`
query xCourses($folder_key: String!, $season_key:String!) {
    courses(folder_key:$folder_key, season_key:$season_key) {
        id:key
        code
        name
        folder_key
        season_key
    }
}
`;

const GET_EMAIL_TEMPLATES = gql`
query xEmailTemplates{
emailTemplates {
    id:key
    date
    email_id
    s3key
    text_as_html
    text
    subject
    order_value
    hidden
  }
}
`;


const SEARCH_STUDENTS =gql`
query xSearchStudents($text:String!,$season_keys:[String]!){
    searchStudents(text:$text,season_keys:$season_keys) {
      id:key
      course_key
      course {
          code
          season_key
          folder_key
          evi_group
      }
      status
      addressing
      sex
      ref_gid
      card_id
      reg_datetime
      ref_key
      partner_ref_key
      name
      surname
      email
      phone
      year
      school
      school_class
      street
      street_no
      city
      post_code
      comment
      course_cost
      paid
      paid_ok
      paid_amount
      long_period
      student_check
      student
      card_out
      var_sym
      pay_info
      discount
    }
  }`;


  const GET_CGROUP_COURSES = gql`
  query xCGroupCourses($cgroup_key:String!){    
      cgroup(key:$cgroup_key) {
        courses {
            id:key
            name
            code
            folder_key
            season_key
        }
      }
    }
  `;
  

const SEND_BULK_EMAIL = gql`
mutation xEmailBulkSend($email_template_key:String!,$recipients:[String]!,$noadvert:Boolean!){
    emailBulkSend(email_template_key:$email_template_key,recipients:$recipients,noadvert:$noadvert) {
      ok
    }
}`;


const GET_STUDENT_EMAILS = gql`
query xStudentEmails($course_key:String!,$filter_sex:StudentSex,$filter_statuses:[StudentStatus]) {
    students(course_key:$course_key,filter_sex:$filter_sex,filter_statuses:$filter_statuses,filter_valid_email:true) {
        email
    }
}`;


const GET_STUDENT = gql`
query xStudent($student_key:String!) {
    student(key:$student_key) {
        key,
        course_key
        course {
            key
            folder_key
            season_key
            name
            code
            evi_group
        }
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        ref_key
        
        name
        surname
        email
        phone
        year
        school
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
    }
} 

`;

const STUDENT_NEW_CARD = gql`
mutation xStudentNewCard($student_key:String!,$reason:String) {
    student:studentInvalidateCard(key:$student_key,reason:$reason) {
        key,
        course_key
        course {
            key
            folder_key
            season_key
            name
            code
            evi_group
        }
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        ref_key
        
        name
        surname
        email
        phone
        year
        school
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
    }
} 

`;

const PRINT_CARD = gql`
mutation printStudentCards($course_key:String!,$student_keys:[String]!) {
    printStudentCards(course_key:$course_key,student_keys:$student_keys) {
      url
      file_name
      content_type
      error
      error_message
    }
  }
`;

function doSearch(client,srch,seasons){
    return new Promise(function(resolve,reject){
        client.query({
            query: SEARCH_STUDENTS,
            variables: { text:srch, season_keys:seasons},
            fetchPolicy:"network-only",
          }).then(d=>{
              console.log("search result data2:",d.data.searchStudents)
              resolve({students:d.data.searchStudents});
          }).catch(err=>{
            console.error(err);
            reject(err);
          });


/*
        remote_api.post("/search",{srchString:srch,seasons:seasons}).then(res=>{
            console.log(res.data);
            var students = res.data.students;
            const ckeys = students.map((s)=>{return s.course_key});
            getCourses(ckeys).then(clist=>{
                resolve({students:students,courses:clist});
            }).catch(reject);
        }).catch(err=>{
            console.error(err);
            reject(err);
        })
*/
    });
}
/*
function getCoursesSF(season_id, folder_id) {
    return new Promise(function(resolve,reject){
        remote_api.get("/courses/"+season_id+"/"+folder_id).then(res=>{
            resolve(res.data.courses);
        }).catch(reject);
    }); 
}
*/
/*
function getCourses(course_keys) {
    return new Promise(function(resolve,reject){
        remote_api.post("/courses",{course_keys:course_keys}).then(res=>{
            resolve(res.data.courses);
        }).catch(reject);
    }); 
}
*/
/*
function getSeasons(){
    return new Promise(function(resolve,reject){
        remote_api.get("/seasons").then(res=>{
            resolve(res.data.seasons);
        }).catch(reject)
    });
}

function getFolders(){
    return new Promise(function(resolve,reject){
        remote_api.get("/folders").then(res=>{
            resolve(res.data.folders);
        }).catch(reject)
    });
}
*/
/*
function getCGroups(){
    return new Promise(function(resolve,reject){
        remote_api.get("/cgroups").then(res=>{
            resolve(res.data.cgroups);
        }).catch(reject)
    });
}
*/

function gqlCGroups(client) {
    return new Promise(function(resolve,reject){
      client.query({
        query: GET_CGROUPS,
        fetchPolicy:"network-only",
      }).then(d=>{
          resolve(d.data.cgroups);
      }).catch(reject);
    });
}

function gqlCoursesByCG(client,cgroup_key) {
    return new Promise(function(resolve,reject){
        client.query({
          query: GET_CGROUP_COURSES,
          variables: {cgroup_key},
          fetchPolicy:"network-only",
        }).then(d=>{
            resolve(d.data.cgroup.courses);
        }).catch(reject);
      });
}



function gqlCoursesBySF(client,season_key,folder_key) {
    return new Promise(function(resolve,reject){
        client.query({
          query: GET_COURSES,
          variables: {folder_key,season_key},
          fetchPolicy:"network-only",
        }).then(d=>{
            resolve(d.data.courses);
        }).catch(reject);
      });
}


function gqlEmailTemplates(client) {
    return new Promise(function(resolve,reject){
      client.query({
        query: GET_EMAIL_TEMPLATES,
        fetchPolicy:"network-only",
      }).then(d=>{
          resolve(lodash.reverse(lodash.sortBy(d.data.emailTemplates,['date'])));
          //resolve(d.data.emailTemplates);
      }).catch(reject);
    });
}


/*
function getEmailTemplates(){
    return new Promise(function(resolve,reject){
        remote_api.get("/emails/templates").then(res=>{
            resolve(lodash.reverse(lodash.sortBy(res.data.email_templates,['date'])));
        }).catch(reject)
    });
}
*/
function gqlCatalog(client) {
    return new Promise(function(resolve,reject){
      client.query({
        query: GET_CATALOG,
        fetchPolicy:"network-only",
      }).then(d=>{
          var fd = d.data.folders.reduce((d,val)=>{
              d[val.id]=val;
              return d;
          },{})
          var sd = d.data.seasons.reduce((d,val)=>{
              d[val.id]=val;
              return d;
          },{})
          resolve({folders:d.data.folders,folder_dict:fd,seasons:d.data.seasons,season_dict:sd});
      }).catch(reject);
    });
}
/*
function getCatalog() {
    return new Promise(function(resolve,reject){
        pAll([getFolders,getSeasons]).then(d=>{
            var fd = d[0].reduce((d,val)=>{
                d[val.id]=val;
                return d;
            },{})
            var sd = d[1].reduce((d,val)=>{
                d[val.id]=val;
                return d;
            },{})
            resolve({folders:d[0],folder_dict:fd,seasons:d[1],season_dict:sd});
        }).catch(reject);
    });
}
*/

function getStudent(id) {
    console.log("getStudent", id);
    return new Promise(function(resolve,reject){
        remote_api.get("/student/"+id).then(res=>{
            resolve({student:res.data.student,course:res.data.course});
        }).catch(reject)
    });
}


function gqlStudent(client,id) {
    console.log("gqlStudent", id);
    return new Promise(function(resolve,reject){
        client.query({
            query: GET_STUDENT,
            variables:{student_key:id},
            fetchPolicy:"network-only",
        }).then(d=>{
            console.log("gqlStudent d:",d)
            //resolve({});
            resolve({student:d.data.student,course:d.data.student.course});
        }).catch(reject);

      //  remote_api.get("/student/"+id).then(res=>{
      //      resolve({student:res.data.student,course:res.data.course});
      //  }).catch(reject)
    });
}


/*
function getStudents(course_id) {
    console.log("getStudents", course_id);
    return new Promise(function(resolve,reject){
        remote_api.get("/students/"+course_id).then(res=>{
            resolve({students:res.data.students});
        }).catch(reject)
    });
}

function getStudentsLog(course_id) {
    console.log("getStudentsLog", course_id);
    return new Promise(function(resolve,reject){
        remote_api.get("/course/"+course_id+"/activites").then(res=>{
            resolve({course_id:res.data.course_id,logs:res.data.activites});
        }).catch(reject)
    });
}
*/

function getStudentsLogRange(start_date,end_date) {
    console.log("getStudentsLogRange", start_date,end_date);
    return new Promise(function(resolve,reject){
        remote_api.get("/students/activities/"+start_date+"/"+end_date).then(res=>{
            resolve({courses:res.data.courses,totalNumberOfActivities:res.data.totalNumberOfActivities});
        }).catch(reject)
    });
}


function getNewInvoiceNumber() {
    console.log("getNewInvoiceNumber");
    return new Promise(function(resolve,reject){
        remote_api.get("/new_invoice_number").then(res=>{
            resolve({number:res.data.number});
        }).catch(reject)
    });
}

function renderInvoice(student,invoice) {
    console.log("render invoice");
    return new Promise(function(resolve,reject){
        remote_api.post("/render_invoice",{student:student,invoice:invoice}).then(res=>{
            resolve(res.data.file_url);
        }).catch(reject)
    });   
}
/*
function newCard(student,reason) {
    console.log("new card");
    return new Promise(function(resolve,reject){
        remote_api.post("/new_card",{student:student,reason:reason}).then(res=>{
            resolve({student:res.data.student,course:res.data.course});
        }).catch(reject)
    });   
}
*/

function gqlNewCard(client,student,reason) {
    console.log("gql new card");
    return new Promise(function(resolve,reject){
        client.mutate({
            mutation: STUDENT_NEW_CARD,
            variables:{student_key:student.key,reason:reason},
        }).then(res=>{
            resolve({student:res.data.student,course:res.data.student.course});
        }).catch(reject);
    });   
}

function gql_renderCard(client,student) {
    console.log("render new card",student);
    return new Promise(function(resolve,reject){

        client.mutate({
            mutation: PRINT_CARD,
            variables:{course_key:student.course_key,student_keys:[student.key]},
        }).then(d=>{
            console.log("PRINT_CARD d:",d.data.printStudentCards)
            resolve(d.data.printStudentCards.url);
        }).catch(reject);

        
    })

}

/*
function renderCards(students) {
    console.log("render cards");
    return new Promise(function(resolve,reject){
        remote_api.post("/render_cards/students",{students:students}).then(res=>{
            resolve(res.data.file_url);
        }).catch(reject)
    });   
}
*/
/*
function getEmailFromCourse(course_id,gender,statuses) {
    console.log("getEmailFromCourse",course_id,gender,statuses);
    return new Promise(function(resolve,reject){
        remote_api.get("/emails/fromcourse/"+course_id,{params:{gender:gender,status:statuses}}).then(res=>{
            resolve(res.data.emails);
        }).catch(reject)
    });   
}
*/
function gqlEmailFromCourse(client,course_key,gender,statuses) {
    console.log("gqlEmailFromCourse",course_key,gender,statuses);
    return new Promise(function(resolve,reject){
        client.query({
            query: GET_STUDENT_EMAILS,
            variables: { course_key:course_key, filter_sex:gender, filter_statuses:statuses},
            fetchPolicy:"network-only",
          }).then(d=>{
              console.log("gqlEmailFromCourse res",d)
              const emails = lodash.map(d.data.students,(s)=>{
                  return s.email;
              })
            resolve(emails);
          }).catch(reject);
    });   
}


/*
function sendBulkEmail(recipients,email_template_id, noadvert) {
    console.log("sendBulkEmail",recipients,email_template_id,noadvert)
    return new Promise(function(resolve,reject){
        remote_api.post("/emails/send_bulk",{params:{recipients:recipients,email_template_id:email_template_id,noadvert:noadvert}}).then(res=>{
            resolve(res.data.ok);
        }).catch(reject)
    });   
}
*/
function gqlSendBulkEmail(client,recipients,email_template_id, noadvert) {
    console.log("gqlSendBulkEmail",recipients,email_template_id,noadvert)
    return new Promise(function(resolve,reject){
        client.mutate({
            mutation: SEND_BULK_EMAIL,
            variables: {
                email_template_key:email_template_id,
                recipients: recipients,
                noadvert:noadvert
            }
          }).then(d=>{
            console.log("gqlSendBulkEmail",d)
            resolve(d.data.emailBulkSend.ok);
          }).catch(reject);
       // remote_api.post("/emails/send_bulk",{params:{recipients:recipients,email_template_id:email_template_id,noadvert:noadvert}}).then(res=>{
       //     resolve(res.data.ok);
       // }).catch(reject)
    });   
}

/*
function getAssistant(id) {
    console.log("getAssistant", id);
    return new Promise(function(resolve,reject){
        remote_api.get("/assistant/"+id).then(res=>{
            resolve(res.data.assistant);
        }).catch(reject)
    });
}

function getAssistantLog(id) {
    console.log("getAssistant", id);
    return new Promise(function(resolve,reject){
        remote_api.get("/assistant/"+id+"/activites").then(res=>{
            resolve({assistant:res.data.assistant,logs:res.data.activites});
        }).catch(reject)
    });
}

function getAssistantsLastLog(id) {
    console.log("getAssistantsLastLog", id);
    return new Promise(function(resolve,reject){
        remote_api.get("/assistants/last_activity").then(res=>{
            resolve({logs:res.data.activites});
        }).catch(reject)
    });
}

function getAssistants() {
    console.log("getAssistants");
    return new Promise(function(resolve,reject){
        remote_api.get("/assistants").then(res=>{
            const as = res.data.assistants.sort((a,b)=>localeSortBy(a,b,['surname','name']));
            resolve(as);
        }).catch(reject)
    });
}

function putAssistant(assistant) {
    console.log("putAssistant");
    return new Promise(function(resolve,reject){
        remote_api.post("/assistant",{assistant:assistant}).then(res=>{
            resolve(res.data.assistant);
        }).catch(reject)
    });
}

function updateAssistant(assistant) {
    console.log("updateAssistant");
    return new Promise(function(resolve,reject){
        remote_api.post("/assistant/"+assistant.id,{assistant:assistant}).then(res=>{
            resolve(res.data.assistant);
        }).catch(reject)
    });
}

function deleteAssistant(assistant_id) {
    console.log("deleteAssistant");
    return new Promise(function(resolve,reject){
        remote_api.delete("/assistant/"+assistant_id).then(res=>{
            resolve(res.data.assistant);
        }).catch(reject)
    });
}

function renderAssistantCards(assistants) {
    console.log("render assistant cards");
    return new Promise(function(resolve,reject){
        remote_api.post("/render_cards/assistants",{assistants:assistants}).then(res=>{
            resolve(res.data.file_url);
        }).catch(reject)
    });   
}
*/


function nameOfStatus(s) {
    switch (s) {
        case "e": return "zapsaný žák";
        case "IN": return "zapsaný žák";
        case "n": return "přihláška";
        case "FILED": return "přihláška";
        case "k": return "vyřazený žák";
        case "KICKED": return "vyřazený žák";
        case "s": return "náhradník";
        case "SPARE": return "náhradník";
        default: 
            return "?"
    }
}
function nameOfBool(b) {
    switch (b) {
        case true: return "ano";
        case false: return "ne";
        default: return "?";
    }
}

function nameOfSex(s) {
    switch (s) {
        case "m": return "p";
        case "f": return "d";
        default: return "?";
    }
}


const compareLocale="cz";
function safeS(a) {
    if ((a === undefined) || (a === null)) {
      return ""
    } else {
      return a
    }
}
  
function safeCmp(a,b) {
  return safeS(a).localeCompare(safeS(b),compareLocale)
}

function localeSortBy(a,b,keys) {
    for (var k of keys) {
        var c = safeCmp(a[k],b[k]);
        if (c) {
          return c
        }
      }
    return 0
}

module.exports = {
    gqlSearch: doSearch,
    gqlCatalog: gqlCatalog,
    gqlCGroups: gqlCGroups,
    gqlEmailTemplates: gqlEmailTemplates,
    gqlCoursesByCG: gqlCoursesByCG,
    gqlCoursesBySF: gqlCoursesBySF,
    gqlSendBulkEmail: gqlSendBulkEmail,
    gqlEmailsFromCourse: gqlEmailFromCourse,
    gqlStudent: gqlStudent,
    gqlNewCard: gqlNewCard,
    student: getStudent,  //stale pouzito v Invoice.js a NewCard.js !!
   // cgroups: getCGroups,
   // students: getStudents,
   // studentsLog: getStudentsLog,
    studentsLogRange: getStudentsLogRange,
    //emailTemplates: getEmailTemplates,
    //sendBulkEmail: sendBulkEmail,
    //coursesBySF: getCoursesSF, 
    //coursesByIDs: getCourses,
    //emailsFromCourse: getEmailFromCourse,
    newInvoiceNumber: getNewInvoiceNumber,
    renderInvoice: renderInvoice,
    //assistants: getAssistants,
    //assistant: getAssistant,
    //assistantLog: getAssistantLog,
    //assistantsLastLog: getAssistantsLastLog,
    //putAssistant: putAssistant,
    //updateAssistant:updateAssistant,
    //deleteAssistant:deleteAssistant,
    //renderAssistantCards: renderAssistantCards,
   // newCard: newCard,
   // renderCards: renderCards,
    gql_renderCard: gql_renderCard,
    nameOfStatus: nameOfStatus,
    nameOfBool: nameOfBool,
    nameOfSex: nameOfSex,
    localeSortBy: localeSortBy
}
