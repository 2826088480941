import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DebugObjectView from './Utils/DebugObjectView';
import Typography from '@material-ui/core/Typography';
import SimpleDataTable from './Tables/SimpleDataTable';
import TableCell from './Tables/SimpleDataTableCell';
import ActionButton from './ActionButton';
import EventTicketStatusView from './Fields/EventTicketStatusView';
import { compose /*, graphql */} from 'react-apollo';
//import gql from 'graphql-tag';
import DateView from './DateView'
import Lodash from 'lodash';

const styles = theme => ({
 
});

class SetupEventSeat extends React.Component {

  constructor(props) {
    super(props);
  }

  reload() {
    if (this.props.onChange) {
      this.props.onChange();
    }
  }

  reserveChair(chair) {

    alert("fixme - obsolete!");

  }

  unreserveChair(chair) {
    alert("fixme - obsolete!");
  }

  getTicket(idx) {
    const {tickets,seat}  = this.props;
    if (tickets) {
      return Lodash.find(tickets,{event_seat_idx:idx,event_seat_id:seat.id})
    }
    return null;
  }
  renderChairState(ticket) {
    if (!ticket) {
      return "volno";
    }

    return (
      <EventTicketStatusView event_ticket_status={ticket.status} />
    );
  }

  render() {
    const { auth, onClose,seat,seat_idx,tickets} = this.props;


    let chairs = [];
    if (seat_idx!=null) {
      const ticket = this.getTicket(seat_idx);
      chairs.push({seat:seat,seat_idx:seat_idx,ticket})
    } else {
      chairs = Lodash.map(Lodash.range(seat.capacity),(idx)=>{
        const ticket = this.getTicket(idx);
        return {seat:seat,seat_idx:idx,ticket};
      });
    }

    return (
      <div>
        <DebugObjectView name="event_seat" object={{seat:seat,seat_idx:seat_idx,tickets,chairs}} />

        <Typography variant="title"> Stůl {seat.name} </Typography>
        <SimpleDataTable data={chairs} 
          renderHeader={()=>(
            <React.Fragment>
              <TableCell debug_only>idx</TableCell>
              <TableCell>židle #</TableCell>
              <TableCell>stav</TableCell>
              <TableCell>datum</TableCell>
              <TableCell></TableCell>
            </React.Fragment>
          )}
          renderRow={(item)=>(
            <React.Fragment>
              <TableCell debug_only>{item.seat_idx}</TableCell>
              <TableCell>{item.seat_idx+1}</TableCell>
              <TableCell>{this.renderChairState(item.ticket)}</TableCell>
              <TableCell>{item.ticket?(<DateView date={item.ticket.updated_at}/>):null} </TableCell>
              <TableCell>
                {auth.is_admin && item.ticket==null && (<ActionButton tooltip={"rezervovat"} icon={'noshop'} onClick={()=>this.reserveChair(item)}/>)}
                {auth.is_admin && item.ticket!=null && item.ticket.status=="RESERVATION" && (<ActionButton tooltip={"zrušit rezervaci"} icon={'shop'} onClick={()=>this.unreserveChair(item)}/>)}
              </TableCell>
          
            </React.Fragment>
          )}
          renderEmptyInfo={()=>(<div>žádné židle</div>)}
        />
             

        {onClose && (
          <Button onClick={onClose} color="primary">
              Zavřít
          </Button>
        )}
      </div>
    );
  }
}

SetupEventSeat.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  seat: PropTypes.object.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.object),
  seat_idx: PropTypes.number,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  //createReservation: PropTypes.func.isRequired,
  //deleteReservation: PropTypes.func.isRequired,
};
SetupEventSeat.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  /*
  graphql(CREATE_EVENT_TICKET_RESERVATION,{
    name: "createReservation",
  }),
  graphql(DELETE_EVENT_TICKET_RESERVATION,{
    name: "deleteReservation",
  }),
  */
)(SetupEventSeat)
