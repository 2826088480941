import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';

import SaleEventHeaderTabs from './SaleEventHeaderTabs';
import SaleEventTabInfo from './SaleEventTabInfo';
import SaleEventTabTickets from './SaleEventTabTickets';
import SaleEventTabPayments from './SaleEventTabPayments';
import SaleEventTabOrders from './SaleEventTabOrders';
import SaleEventTabSale from './SaleEventTabSale';
import SaleEventTabReservations from './SaleEventTabReservations';

const GET_EVENT = gql`
  query Event($event_id:ID!,$with_order:Boolean!,$public_order_key:String!) {
    event(id:$event_id) {
      id
      name
      status
      
      image_id
      image {
        url
        s3key
        name
        width
        height
      }
      place_id
      place {
        name
        long_name
        map
        address
      }

      zones {
        id
        zone_id
        map_zone_key
        free_zone
        name
        primary_color
        secondary_color
      }

      priceplans {
        id
        name
        price_plan_id
      }  

      zoneprices {
        id
        name
        cost
        sale_student
        event_price_plan_id
        event_zone_id
        zone_price_id
      }

      activezoneprices {
        id
        name
        cost
        sale_student
        event_price_plan_id
        event_zone_id
        zone_price_id
      }

      seats {
        id
        name
        type
        capacity
        event_zone_id
        seat_id
        zone_id
        name
        type
        capacity
        row_label_base
        col_label_base
        box_capacity_left
        box_capacity_right
        box_capacity_top
        box_capacity_bottom
        map_position_x
        map_position_y
        map_position_angle
        map_sector_angle
      }

      capacity_info { 
        ticket_counts {
          total
          reservation
          selling
          sold
          booked
          error
        }
        total_capacity
        free_capacity
        zone_infos {
          event_zone_id
          total_capacity
          free_capacity
          ticket_counts {
            total
            reservation
            selling
            sold
            booked
            error
          }
          markers {
            type
            my_booking
            event_seat_id
            event_zone_id
            event_seat_idx
          }
        }
      }

      tickets {
        id
        status
        ticket_key
        event_id
        event_zone_id
        event_seat_id
        event_seat_idx
        name
        comment
        cost
        sale_student
        booking_ref
        created_at
        updated_at
      }
 
      comment
      date
      auto_open_date
      auto_suspend_date

    }
    cartTickets:eventTicketBookings(event_id:$event_id) {
      id
      status
      ticket_key
      event_id
      event_zone_id
      event_seat_id
      event_seat_idx
      name
      comment
      cost
      sale_student
      booking_ref
      created_at
      updated_at
    }

    order:eventOrder(public_order_key:$public_order_key) @include(if: $with_order) {
      public_key  
      status
      owner_mode
      last_payment_status
      last_payment_type
      price
      only_reservation
      reservation_due_date
      customer_name
      customer_surname
      customer_phone
      customer_email
      comment
      tickets:event_tickets {
        id
        status
        ticket_key
        event_id
        event_zone_id
        event_seat_id
        event_seat_idx
        name
        comment
        cost
        sale_student
        booking_ref
        created_at
        updated_at
      }
    }
     
  }
`;



const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});




class SaleEventBoard extends React.Component {

  

  renderTabInfo(event) {
    const {auth} = this.props;
    return (
      <SaleEventTabInfo auth={auth} event={event}/>
    )
  }

  renderTabTickets(event) {
    const {auth} = this.props;
    return (
      <SaleEventTabTickets auth={auth} event={event}/>
    )
  }

  renderTabOrders(event,refetch) {
    const {auth} = this.props;
    return (
      <SaleEventTabOrders auth={auth} event={event} refetch={refetch}/>
    )
  }

  renderTabReservations(event,refetch) {
    const {auth} = this.props;
    return (
      <SaleEventTabReservations auth={auth} event={event} refetch={refetch}/>
    )
  }

  renderTabPayments(event,refetch) {
    const {auth} = this.props;
    return (
      <SaleEventTabPayments auth={auth} event={event} refetch={refetch}/>
    )
  }

  renderTabSale(event,cartTickets,order,action,action_param,refetch) {
    const {auth} = this.props;
    return (
      <SaleEventTabSale auth={auth} event={event} order={order} cartTickets={cartTickets} refetch={refetch} action={action} action_param={action_param}/>
    )
  }

  refresh() {
    //do headertabs nacpat refresh callback a tady to pred ref zavolat
    //taby pak dostavaji tuhle funkci na zavolani
  }




  render() {
    const { classes , auth,page,event_id,action,action_param} = this.props;
    const with_order = (page == 'sale') && ((action == 'order') || (action=='order_done')) && (action_param!=undefined)
    const public_order_key = with_order?action_param:"missing";

    return (
        <Query query={GET_EVENT} variables={{event_id,with_order,public_order_key}} fetchPolicy={"network-only"}>
            {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;

              let tab=null;
              let saleCartBadge=0;
              const order = with_order?data.order:null;
              if (data.event) {
                switch(page) {
                  case 'info': tab = this.renderTabInfo(data.event); break;
                  case 'tickets': tab = this.renderTabTickets(data.event,refetch); break;
                  case 'payments': tab = this.renderTabPayments(data.event,refetch); break;
                  case 'orders': tab = this.renderTabOrders(data.event,refetch); break;
                  case 'reservations': tab = this.renderTabReservations(data.event,refetch); break;
                  case 'sale': tab = this.renderTabSale(data.event,data.cartTickets,order,action,action_param,refetch); break;
                  default:
                  break;
                }
              }
              if (data.cartTickets) {
                saleCartBadge=data.cartTickets.length;
              }
              return (
                <React.Fragment>
                  <SaleEventHeaderTabs event_id={event_id} event={data.event} saleCartBadge={saleCartBadge} activeTab={page} auth={auth} />
                  <div className={classes.tab_root}>{tab}</div>
                </React.Fragment>
              );
            }}
        </Query>
    );
  }
}

SaleEventBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  page: PropTypes.oneOf(["info","tickets","payments","reservations","orders","sale"]).isRequired,
  action: PropTypes.oneOf(["new_order","new_reservation","order","order_done","new_reservation"]),
  action_param: PropTypes.string,
};
SaleEventBoard.defaultProps = {
  
};

export default withStyles(styles)(SaleEventBoard);