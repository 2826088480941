
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';
import DateView from './DateView';
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag';
import StudentCard from './StudentCard';
import ActionButton from './ActionButton';
import PdfViewDialog from './PdfViewDialog';
var moment = require('moment');

var Api = require("../libs/Api");


const propTypes = {
    classes: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    student_id: PropTypes.string.isRequired,
    invoices: PropTypes.object,
    addInvoice: PropTypes.func,
    printInvoice: PropTypes.func
};
  
const defaultProps = {};
  

const CurrentInvoices = gql`
  query Invoices($student_key: String!) {
    invoices(student_key:$student_key) {
        id, no, amount, duplicate, sale_date, issue_date, description
    }
  }
`;


const AddInvoice = gql`
    mutation AddInvoice($student_key: String!, $no: String!, $duplicate:Boolean, $amount:Int , $description: String, $sale_date:Date, $issue_date:Date) {
        invoiceAdd(student_key:$student_key,no:$no,duplicate:$duplicate,amount:$amount,description:$description,sale_date:$sale_date,issue_date:$issue_date) {
            id
        }
    }
`;

const PrintInvoice=gql`
    mutation PrintInvoice($invoice_id:ID!) {
        printInvoice(invoice_id:$invoice_id) {
            error
            error_message
            s3key
            url
            file_name
            content_type
        }
    }
`;


const CurrentStudent = gql`
    query Student($student_key: String!) {
        student(key:$student_key) {
            key,name,surname
        }
    }
`;

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    paper: {
        //maxWidth: '800px',
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit*3,
        padding: theme.spacing.unit,
        //overflowX: 'auto',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    checkField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    textFieldLong: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 400+2*theme.spacing.unit,
    },

    button: {
      margin: theme.spacing.unit,
    },
    table: {
        minWidth: 700,
    },
});


class Invoice extends Component {
    
    constructor(props) {
        super(props);
        this.now_date = moment().format("YYYY-MM-DD");
        this.no_prefix = moment().format("YYYYMM");
        this.state = {  
            invoiceUrl:null,
            wait:false,
            student: {},
            course: {},
            invoice: {
                duplicate:false,
                number: "",
                desc: "",
                amount: 0,
                date_i: this.now_date,
                date_p: "",
            }
        };
    }

    onApiError(e) {
        console.error(e);
        this.setState({errorString:"chyba"})
    }
    onClick(e) {
        this.setState({wait:true});

        if (this.state.invoice.duplicate) {


            this.props.addInvoice({
                variables: {
                    student_key:this.props.student_id,
                    no: this.state.invoice.number,
                    duplicate:true,
                    amount: this.state.invoice.amount,
                    sale_date: this.state.invoice.date_p!==""?moment(this.state.invoice.date_p).format("YYYY-MM-DD"):null,
                    issue_date: this.state.invoice.date_i!==""?moment(this.state.invoice.date_i).format("YYYY-MM-DD"):null, 
                    description: this.state.invoice.desc
                }
            }).then(res=>{
                console.log("addInvoice res",res);
                this.showInvoice({id:res.data.invoiceAdd.id});
            }).catch((err)=>{
                this.setState({wait:false});
                this.onApiError(err);
            })

            /*
            Api.renderInvoice(this.state.student,this.state.invoice).then(file_url=>{
                console.log(file_url);
                this.setState({wait:false});
                window.location.href = file_url;
            }).catch((err)=>{
                this.setState({wait:false});
                this.onApiError(err);
            })
            */


        } else {
            Api.newInvoiceNumber().then(data=>{
                let { invoice } = this.state;
                invoice.number = this.no_prefix+data.number;
                this.props.addInvoice({
                    variables: {
                        student_key:this.props.student_id,
                        no: invoice.number,
                        duplicate:false,
                        amount: invoice.amount,
                        sale_date: invoice.date_p!==""?moment(invoice.date_p).format("YYYY-MM-DD"):null,
                        issue_date: invoice.date_i!==""?moment(invoice.date_i).format("YYYY-MM-DD"):null, 
                        description: invoice.desc
                    }
                }).then(res=>{
                    console.log("addInvoice res",res);
                    this.showInvoice({id:res.data.invoiceAdd.id});
                }).catch((err)=>{
                    this.setState({wait:false});
                    this.onApiError(err);
                })


          
            }).catch((err)=>{
                this.setState({wait:false});
                this.onApiError(err);
            })         
        }

     
    }
    handleCheckDupl(checked) {
        let { invoice } = this.state;
        invoice.duplicate = checked;
        this.setState({ invoice: invoice });
    }

    handleChange(event,name) {
        let { invoice } = this.state;
        invoice[name] = event.target.value;
        this.setState({ invoice: invoice });
    };
  
    handleChangeI(event,name) {
        let { invoice } = this.state;
        const iv = parseInt(event.target.value,10);
        if (!isNaN(iv)) {
            invoice[name] = iv;
        } else {
            invoice[name] = null;
        }
        this.setState({ invoice: invoice });
    };


    componentDidMount() {
        Api.gqlStudent(this.props.client,this.props.student_id).then(data=>{
            console.log("API.gqlstudent",data) 
            let { invoice } = this.state;
            invoice.desc = "kurzovné za taneční kurz "+data.course.code;
            invoice.amount = data.student.paid;
            this.setState({invoice:invoice,student:data.student,course:data.course})
        }).catch(err=>{
             console.error(err);
             this.onApiError(err);
            })
        /*    
        Api.student(this.props.student_id).then(data=>{

            console.log("API.student",data)
            let { invoice } = this.state;
            invoice.desc = "kurzovné za taneční kurz "+data.course.code;
            invoice.amount = data.student.paid;
            this.setState({invoice:invoice,student:data.student,course:data.course})
        }).catch(err=>{
            console.error(err);
            this.onApiError(err);
        });
        */
    }
    showInvoice(invoice) {
        this.props.printInvoice({
            variables: { invoice_id:invoice.id }
        }).then(res=>{
            console.log("invoice print res=",res);
            this.setState({invoiceUrl:res.data.printInvoice.url})
        }).catch(err=>{
            alert('chyba tisku',err)
        })
    }

    render() {
        const {classes,auth} = this.props;
        return (
            <div>
                <PdfViewDialog caption={"Doklad"} pdfUrl={this.state.invoiceUrl} open={this.state.invoiceUrl!=null} onClose={()=>this.setState({invoiceUrl:null,wait:false})}/>
 
                <Typography variant="title"> Žák </Typography>
                <Paper className={classes.paper}>
                    <StudentCard student_key={this.props.student_id}/>
                </Paper>

                <Typography variant="title">Přehled vystavených dokladů</Typography>
                <Paper className={classes.paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Číslo dokladu</TableCell>
                                <TableCell>Duplikát</TableCell>
                                <TableCell>Datum vystavení</TableCell>
                                <TableCell>Datum plnění</TableCell>
                                <TableCell numeric>Částka</TableCell>
                                <TableCell>Popis</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>  
                        {this.props.invoices.invoices && this.props.invoices.invoices.length>0 && (
                            <TableBody>
                                {this.props.invoices.invoices.map((i,idx)=>{
                                    return (
                                        <TableRow key={i.id}>
                                             <TableCell>{i.no}</TableCell>
                                             <TableCell>{i.duplicate?"ano":"ne"} </TableCell>
                                             <TableCell><DateView date={i.issue_date} only_date/></TableCell>
                                             <TableCell><DateView date={i.sale_date} only_date/></TableCell>
                                             <TableCell numeric>{i.amount}</TableCell>
                                             <TableCell>{i.description}</TableCell>

                                             <TableCell>
                                                <ActionButton tooltip={"zobrazit"} icon={'view'} onClick={()=>this.showInvoice(i)}/>
                                             </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody> 
                        )}
                   
                    </Table>

                </Paper>

                {auth.is_admin &&(
                    <React.Fragment>
                        <Typography variant="title">Vystavení nového dokladu</Typography>
                        <Paper className={classes.paper}>  

                                <FormControlLabel
                                    className={classes.checkField}
                                    control={
                                        <Checkbox
                                            value="duplicate"
                                            checked={(this.state.invoice.duplicate)}
                                            onChange={(event,checked)=>this.handleCheckDupl(checked)}
                                        />
                                    }
                                    label="Vystavit duplikát"
                                />
                                <br/>
                                { this.state.invoice.duplicate && 
                                    <TextField
                                        id="number"
                                        label="Číslo dokladu"
                                        value={this.state.invoice.number}
                                        onChange={(e)=>this.handleChange(e,'number')}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin="normal"
                                    />
                                }

                                <TextField
                                    id="date_i"
                                    label="Datum vystavení"
                                    type="date"
                                    value={this.state.invoice.date_i}
                                    onChange={(e)=>this.handleChange(e,'date_i')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />

                                <TextField
                                    id="date_p"
                                    label="Datum plnění"
                                    type="date"
                                    value={this.state.invoice.date_p}
                                    onChange={(e)=>this.handleChange(e,'date_p')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />

                                <TextField
                                    id="amount"
                                    label="Částka"
                                    value={this.state.invoice.amount}
                                    onChange={(e)=>this.handleChangeI(e,'amount')}
                                    type="number"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                />

                                <TextField
                                    id="desc"
                                    label="Rozsah a předmět plnění"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e)=>this.handleChange(e,'desc')}
                                    value={this.state.invoice.desc}
                                    placeholder="kurzovné za taneční kurz např. S210"
                                    className={classes.textFieldLong}
                                    margin="normal"
                                />

                                <Button variant="raised" disabled={this.state.wait}  className={classes.button} onClick={(e)=>this.onClick(e)}> Vystavit doklad </Button>
                        </Paper>
                

                    </React.Fragment>
                )}

            </div>
        )
    }

}


Invoice.propTypes = propTypes;
Invoice.defaultProps = defaultProps;

export default compose(
    withStyles(styles),
     withApollo,
    graphql(CurrentInvoices,{
        name: "invoices",
        options: ({student_id})=>({variables:{student_key:student_id}})
    }),
    graphql(CurrentStudent,{
        name: "student",
        options: ({student_id})=>({variables:{student_key:student_id}})
    }),
    graphql(AddInvoice,{
        name:"addInvoice",
        options: {
            refetchQueries: [
                'Invoices',
              ],
        }
    }),
    graphql(PrintInvoice,{
        name:"printInvoice",
    }),

)(Invoice)


