import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import StudentForm from './StudentForm';



const GET_STUDENT = gql`
  query Student($student_key:String!){
      student(key: $student_key) {
        key
        course_key
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        ref_key
        partner_ref_key
        name
        surname
        email
        email2
        phone
        year
        school
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
      }
  }
`;


//addressing, surname, name, email, phone, year, student, student_check, card_out, comment, course_cost
//long_period, paid, pay_info, discount, paid_ok, school, school_class, street, city, post_code

const UPDATE_STUDENT = gql`
mutation updateStudent($key:String!,$addressing:String,$surname:String,$name:String, $email:String, $email2:String, $phone:String, $year:Int, $student:Boolean, $student_check:Boolean, $card_out:Boolean, $comment:String, $course_cost:Int, $long_period:Boolean, $paid:Int, $pay_info:String, $discount:String, $paid_ok:Boolean, $school:String, $school_class:String, $street:String, $city:String, $post_code:String) {
  studentUpdate(key:$key,addressing:$addressing,surname:$surname,name:$name, email:$email, email2:$email2, phone:$phone, year:$year, student:$student, student_check:$student_check, card_out:$card_out, comment:$comment, course_cost:$course_cost,long_period:$long_period, paid:$paid, pay_info:$pay_info, discount:$discount, paid_ok:$paid_ok, school:$school, school_class:$school_class, street:$street, city:$city, post_code:$post_code) {
    key
    course_key
    status
    addressing
    sex
    ref_gid
    card_id
    reg_datetime
    ref_key
    partner_ref_key
    name
    surname
    email
    email2
    phone
    year
    school
    school_class
    street
    street_no
    city
    post_code
    comment
    course_cost
    paid
    paid_ok
    long_period
    student_check
    student
    card_out
    var_sym
    pay_info
    discount
  }
}
`;

class StudentEdit extends React.Component {


  constructor(props) {
    super(props);
    
    this.state = {  
      dirty:false,
      valid:true
    }
    this.form = React.createRef();
  }


  doSave() {
    console.log("StudentEdit doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:UPDATE_STUDENT,
      variables:this.form.current.state.doc,
      refetchQueries:["CourseWithStats","Students","Student"]
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
   
  }
 
  onDocChange(d,valid) {
    console.log("CourseEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 
 
  render() {
    const {student_key,mode,withPayment} = this.props;
    return (
      <div className="foo">
      <Query query={GET_STUDENT} variables={{ student_key }} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
                <StudentForm innerRef={this.form} withPayment={withPayment} onDocChange={(d,v)=>this.onDocChange(d,v)} doc={data.student} mode={mode} />
          )
        }}
      </Query>
      </div>
    );
  }
}

StudentEdit.propTypes = {
  client: PropTypes.object.isRequired,
  student_key: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  withPayment: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
StudentEdit.defaultProps = {
  withPayment:false
};

export default withApollo(StudentEdit,{withRef:true});
