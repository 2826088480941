import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  }
});


class BanksHeaderTabs extends React.Component {

 


  
  render() {
    const { classes,activeTab} = this.props;

    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                Banka
            </Typography>
          
            <Tabs className={classes.toolBar_item} value={activeTab}>
              <Tab value="trs" label="transakce" component={Link} to={"/spa/banks/trs"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

BanksHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  activeTab: PropTypes.oneOf(["trs"]).isRequired,
};
BanksHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(BanksHeaderTabs);