import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import EventStrip from './EventStrip';
//import DateView from './DateView';
//import Paper from '@material-ui/core/Paper';
//import Lodash from 'lodash';
//import EventCapacityView from './EventCapacityView';
import DateView from './DateView';
import BooleanView from './BooleanView';
import PaymentTypeView from './Fields/PaymentTypeView';
import PaymentStatusView from './Fields/PaymentStatusView';
//import DebugObjectView from './Utils/DebugObjectView';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';

import gql from 'graphql-tag';


const GET_ORDER_PAYMENTS = gql`
query EventOrderPayments($event_id:ID!,$public_order_key:String!) {
  eventOrderPayments(event_id:$event_id, public_order_key:$public_order_key) {
    id
    public_key
    type
    status
    user_id
    user_login
    order_id
    order_public_key
    pays_order_id
    pays_test_mode
    amount
    comment
    created_at
    updated_at
  }
}
`;



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});





class SaleEventOrderPaymentsView extends React.Component {

  constructor(props) {
    super(props);
    this.pays_table = React.createRef();
  }
  reload() {
    if (this.pays_table.current) {
    //  alert("SaleEventOrderPaymentsView reload")
      this.pays_table.current.refetch();
    }
  }

  render() {
    const { event,public_order_key} = this.props;

    return (
      <React.Fragment>
            <Typography variant="subheading"> Přehled plateb objednávky  </Typography>
            <SimpleGQLTable  innerRef={this.pays_table}
              query={GET_ORDER_PAYMENTS} variables={{event_id:event.id,public_order_key}} 
              getData={(r)=>{return r.eventOrderPayments}}
              renderHeader={()=>(
                <React.Fragment>
                  <TableCell debug_only>id</TableCell>
                  <TableCell debug_only>pays_test_mode</TableCell>
                  <TableCell debug_only>order_id</TableCell>
                  <TableCell debug_only>user_id</TableCell>
                  <TableCell>ID platby</TableCell>
                  <TableCell>ID platby pays</TableCell>
                  <TableCell>ID pokladního</TableCell>
                  <TableCell>typ</TableCell>
                  <TableCell>stav</TableCell>
                  <TableCell>částka</TableCell>
                  <TableCell>poznámka</TableCell>
                  <TableCell>datum vzniku</TableCell>
                </React.Fragment>
              )}
              renderRow={(item)=>(
                <React.Fragment>
                  <TableCell debug_only>{item.id}</TableCell>
                  <TableCell debug_only><BooleanView value={item.pays_test_mode}/></TableCell>
                  <TableCell debug_only>{item.order_id}</TableCell>
                  <TableCell debug_only>{item.user_id}</TableCell>
            
                  <TableCell>{item.public_key}</TableCell>
                  <TableCell>{item.pays_order_id}</TableCell>
                  <TableCell>{item.user_login}</TableCell>
                  <TableCell><PaymentTypeView value={item.type}/></TableCell>
                  <TableCell><PaymentStatusView value={item.status}/></TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.comment}</TableCell>
                  <TableCell> <DateView date={item.created_at}/> </TableCell>
                </React.Fragment>
              )}
              renderEmptyInfo={()=>(<div> nejsou žádné platby pro tuto objednávku </div>)}
            />

            
            

      </React.Fragment>
    );
  }
}

SaleEventOrderPaymentsView.propTypes = {
  classes: PropTypes.object.isRequired,
  //auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  public_order_key: PropTypes.string.isRequired,
};
SaleEventOrderPaymentsView.defaultProps = {
  
};


export default withStyles(styles)(SaleEventOrderPaymentsView);