import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';


const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class ImageDeleteDialog extends React.Component {

  render() {
    const {image,open,onClose,onDelete,caption} = this.props;
    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {image && (
            <React.Fragment>
              <div> {image.name} </div>
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} color="secondary">
          Smazat
        </Button>

        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

ImageDeleteDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  image:PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  caption: PropTypes.string
};
ImageDeleteDialog.defaultProps = {
  caption:"Smazat obrázek?"
};

export default withStyles(styles)(ImageDeleteDialog);