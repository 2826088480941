import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class SimpleViewDialog extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      doc:null,
      open:false,
    }
  }

  doView(doc) {
    this.setState({open:true,doc:doc})
  }
  doClose() {
    this.setState({open:false,doc:null})
  }

  render() {
    const {caption,renderForm} = this.props;
    const {doc,open} = this.state;
    return (
      <Dialog maxWidth={false} open={open} onClose={()=>this.doClose()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {doc && (
            <React.Fragment>
              {renderForm(doc)}
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>this.doClose()} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

SimpleViewDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  renderForm: PropTypes.func.isRequired,
  caption: PropTypes.string
};
SimpleViewDialog.defaultProps = {
  caption:"Náhled položky"
};

export default withStyles(styles)(SimpleViewDialog);