import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import SexView from './SexView';
import BooleanView from './BooleanView';
import Checkbox from '@material-ui/core/Checkbox';
//import ActionLink from './ActionLink';
import ActionButton from './ActionButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button';
import PdfViewDialog from './PdfViewDialog';
import gql from "graphql-tag";
import { compose, graphql} from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import CourseSelectField from './CourseSelectField';
import BooleanField from './BooleanField';
import EnumField from './EnumField';
import StudentChips from './StudentChips';
import StudentViewDialog from './StudentViewDialog';
import StudentEditDialog from './StudentEditDialog';
import StudentAddDialog from './StudentAddDialog';
import StudentPayEditDialog from './StudentPayEditDialog';

import StudentsTableRowCmds from './StudentsTableRowCmds';
import Lodash from 'lodash';
const pEachSeries = require('p-each-series');

const styles = () => ({
  cell: {
    padding:0
  },
  cellH: {
    padding:0,
    fontWeight: "bold"
  }
});

function safeS(a) {
  if ((a===null) || (a===undefined)) {
    return "";
  }
  return a;
}
const compareLocale="cz";
function safeCmp(a,b) {
  return safeS(a).localeCompare(safeS(b),compareLocale)
}
function safeCmpChain(a,b,keys) {
  for (var k of keys) {
    var c;
    if (k.charAt(0)==='-') {
        k = k.substring(1);
        c = -safeCmp(a[k],b[k]);
    } else {
        c = safeCmp(a[k],b[k]);
    }

    if (c) {
      return c
    }
  }
  return 0
}

function topay(s) {
  if (s.course_cost===null || isNaN(s.course_cost)) {
    return null;
  }
  if (s.paid_amount===null || isNaN(s.paid_amount)) {
    return s.course_cost;
  } else {
    return s.course_cost - s.paid_amount;
  }
}


const PRINT_STUDENT_CARDS = gql`
mutation printStudentCards($course_key:String!,$student_keys:[String]!) {
  printStudentCards(course_key:$course_key,student_keys:$student_keys) {
    url
    file_name
    content_type
    error
    error_message
  }
}`;

const STUDENT_MOVE = gql`
mutation studentMove($key:String!,$new_course_key:String!,$send_email_notification:Boolean) {
  studentMove(key:$key,new_course_key:$new_course_key,send_email_notification:$send_email_notification) {
    key
    course_key
  }
}`;

const STUDENT_COPY = gql`
mutation studentCopy($key:String!,$new_course_key:String!,$new_status:StudentStatus!,$send_email_notification:Boolean) {
  studentCopy(key:$key,new_course_key:$new_course_key,new_status:$new_status,send_email_notification:$send_email_notification) {
    key
    course_key
    status
  }
}`;

const STUDENT_UPDATE_CARD = gql`
mutation studentUpdateCard($key:String!,$card_out:Boolean) {
  studentUpdate(key:$key,card_out:$card_out) {
    key
    card_out
  }
}
`;

const STUDENT_CHANGE_STATUS = gql`
mutation studentChangeStatus($key:String!,$new_status:StudentStatus!,$send_email_notification:Boolean) {
  studentChangeStatus(key:$key,new_status:$new_status,send_email_notification:$send_email_notification) {
    key
    status
  }
}`;


const EMAIL_SEND_NOTIFICATION = gql`
mutation emailSend($type: EmailNotificationType!, $student_key:String!){
  emailSend(type:$type,student_key:$student_key){
    ok
  }
}
`;

const STUDENT_MAKE_PAIR = gql`
mutation studentMakePair($student_a_key:String!,$student_b_key:String!){
  studentMakePair(student_a_key:$student_a_key,student_b_key:$student_b_key) {
    a {
      key
      ref_key
      partner_ref_key
    } b {
      key
      ref_key
      partner_ref_key
    }
  }
}
`;


/*
const TEST_MUTATION = gql`
mutation testMutationDelay($delay:Int) {
  testMutationDelay(delay:$delay) {
   ok
  }
}
`;
*/


class StudentsTable extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      selected:[],
      emailDlgOpen:false,
      emailDlgEmails:[],
      printStudentCardsDlgOpen:false,
      printStudentCardsDlgUrl:null,
      actionDlgOpen:false,
      actionDlgCmd:null,
      actionDlgWait:false,
      actionDlgSelected:[],
      actionDlgProgress:0,
      send_email: false,
      card_out:true,
      copy_status:"FILED",
      target_season_key:null,
      target_folder_key:null,
      target_course_key:null,
    //  school_students:[],
      school_groups:[],
      pair_pairs:[],
      pair_singles:[],
      single_students:[],

      showStudent_key:null,
      editStudent_key:null,
      payStudent_key:null,
      addStudent_open:false,
      last_students:[],
      last_group_mode:null
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("getDerivedStateFromProps",prevState,nextProps);
  
    const { students,group_mode } = nextProps;

    if (prevState.last_group_mode === group_mode) {
      if (prevState.last_students.length === students.length) {
        const diff = Lodash.differenceWith(prevState.last_students, students, Lodash.isEqual);
        if (diff.length===0) {
          return null;
        }
      }
    }
    // 




//    var school_students = [];
    var school_groups = [];
    var pair_pairs = [];
    var pair_singles = [];
    var single_students = [];

    
    switch (group_mode) {
      case 'SCHOOL':
        const school_students = students.sort((a,b)=>safeCmpChain(a,b,["school","school_class","-sex","surname","name"]));
        school_groups = Lodash.groupBy(school_students,function(s){
          return safeS(s.school)+"#"+safeS(s.school_class);
        });
        break;
      case 'PAIR': 
        const gdict = Lodash.reduce(students,(acc,s)=>{
          acc[s.ref_key] = s;
          return acc;
        },{});

        const gr = Lodash.reduce(students,(acc,s)=>{
          if (s.key in acc.done_dict) {
            return acc;
          } 
          if ((s.partner_ref_key) && (s.partner_ref_key in gdict)) {
            const p = gdict[s.partner_ref_key];

            if (p.partner_ref_key === s.ref_key) {
              if (s.sex === "MALE") {
                acc.pairs.push([s,p]);
              } else {
                acc.pairs.push([p,s]);
              }
              acc.done_dict[s.key]=true;
              acc.done_dict[p.key]=true;
            } else {
              acc.singles.push(s);
              acc.done_dict[s.key]=true;
            }
          } else {
            acc.singles.push(s);
            acc.done_dict[s.key]=true;
        } 
          return acc;
        },{done_dict:{},pairs:[],singles:[]})

        pair_pairs = gr.pairs.sort((a,b)=>{
          return safeCmpChain(a[0],b[0],["surname","name"]);
        });
        pair_singles = gr.singles;
        break;
      case 'SINGLE':
      default:
        single_students = students.sort((a,b)=>safeCmpChain(a,b,["surname","name"]));
    }


    return {
      selected:[],
     // school_students,
      school_groups,
      pair_pairs,
      pair_singles,
      single_students,
      last_group_mode:group_mode,
      last_students:students,
    }
  }

  handleCloseStudentViewDialog() {
    this.setState({showStudent_key:null});
  }
  handleActionShowStudent(student_key) {
    this.setState({showStudent_key:student_key});
  }

  handleCloseStudentEditDialog() {
    this.setState({editStudent_key:null});
    this.props.refetch();
  }
  handleActionEditStudent(student_key) {
    this.setState({editStudent_key:student_key});
  }

  handleCloseStudentAddDialog() {
    this.setState({addStudent_open:false});
    this.props.refetch();
  }
  handleActionAddStudent() {
    this.setState({addStudent_open:true});
  }

  handleCloseStudentPayDialog() {
    this.setState({payStudent_key:null});
    this.props.refetch();
  }
  handleActionPayStudent(student_key) {
    this.setState({payStudent_key:student_key});
  }

  handleCmdAction(action,student_key) {
    switch(action) {
      case "VIEW": this.handleActionShowStudent(student_key); break;
      case "EDIT": this.handleActionEditStudent(student_key); break;
      case "PAY": this.handleActionPayStudent(student_key); break;
      default:
        console.log("unexpected cmd action",action);
    }
  }

  renderCmds(s) {
    const { auth } = this.props;
    return (
      <StudentsTableRowCmds key={s.key} student_key={s.key} is_admin={auth.is_admin} is_super={auth.is_super} onAction={(a,k)=>this.handleCmdAction(a,k)}/>
    )
  }

  renderCmds_old(s) {
    const { auth } = this.props;
    return (
        <React.Fragment>
            <ActionButton tooltip={"zobrazit"} icon={'view'} onClick={()=>this.handleActionShowStudent(s.key)}/>
            {auth.is_admin &&(
              <React.Fragment>
                <ActionButton tooltip={"editovat"} icon={'edit'} onClick={()=>this.handleActionEditStudent(s.key)}/>
                <ActionButton tooltip={"platba"} icon={'pay'} onClick={()=>this.handleActionPayStudent(s.key)}/>
              </React.Fragment>
            )}
        </React.Fragment>
    )
  }

  isSelected(key) {
    return (this.state.selected.indexOf(key) !== -1);
  } 

  getSelected() {
    return Lodash.filter(this.props.students,(s)=>this.isSelected(s.key));
  }

  handleCheckClick(key) {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  }
  
  handleCheckAllClick(checked) {
    console.log("handleCheckAllClick");
    if (checked) {
      const all = Lodash.map(this.props.students,(s)=>{return s.key;});
      this.setState({ selected: all});
    }   else {
      this.setState({selected:[]});
    }
  }
  renderCheckBox(s) {
    const sel = this.isSelected(s.key);
    return (
      <Checkbox checked={sel} onChange={()=>this.handleCheckClick(s.key)}/>
    )
  }

  renderHeadAsSchool() {
    const { classes, filter_status } = this.props;
    return (
      <TableHead>
          <TableRow>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell} colSpan={3}>Žák</TableCell>
            <TableCell className={classes.cell}>Kurzovné</TableCell>
            <TableCell className={classes.cell}>Platba</TableCell>
            <TableCell className={classes.cell}>Dopl.</TableCell>
            <TableCell className={classes.cell}>Sleva</TableCell>
            <TableCell className={classes.cell}>Pl. info</TableCell>
            {filter_status==="IN" && (<TableCell className={classes.cell}>Karta</TableCell>)}
            {filter_status!=="IN" && (<TableCell className={classes.cell}>VS</TableCell>)}
            <TableCell className={classes.cell}>Poznámka</TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
    );
  }

  renderHeadAsSingle() {
    const { classes, filter_status } = this.props;
    return (
      <TableHead>
          <TableRow>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell} colSpan={3}>Žák</TableCell>
            <TableCell className={classes.cell}>Student</TableCell>
            <TableCell className={classes.cell}>Kurzovné</TableCell>
            <TableCell className={classes.cell}>Platba</TableCell>
            <TableCell className={classes.cell}>Dopl.</TableCell>
            <TableCell className={classes.cell}>Sleva</TableCell>
            <TableCell className={classes.cell}>Pl. info</TableCell>
            {filter_status==="IN" && (<TableCell className={classes.cell}>Karta</TableCell>)}
            {filter_status!=="IN" && (<TableCell className={classes.cell}>VS</TableCell>)}
            <TableCell className={classes.cell}>Poznámka</TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
    );
  }
  
  renderHeadAsPair() {
    const { classes, filter_status } = this.props;
    return (
      <TableHead>
          <TableRow>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell}>#</TableCell>
            <TableCell className={classes.cell} colSpan={3}>Žák</TableCell>
            <TableCell className={classes.cell}>Student</TableCell>
            <TableCell className={classes.cell}>Kurzovné</TableCell>
            <TableCell className={classes.cell}>Platba</TableCell>
            <TableCell className={classes.cell}>Dopl.</TableCell>
            <TableCell className={classes.cell}>Sleva</TableCell>
            <TableCell className={classes.cell}>Pl. info</TableCell>
            {filter_status==="IN" && (<TableCell className={classes.cell}>Karta</TableCell>)}
            {filter_status!=="IN" && (<TableCell className={classes.cell}>VS</TableCell>)}
            <TableCell className={classes.cell}>Poznámka</TableCell>
            <TableCell className={classes.cell}></TableCell>
          </TableRow>
        </TableHead>
    );
  }

  renderBodyAsSchool(groups) {
    const { classes,filter_status } = this.props;
    const me = this;
    return (
      <TableBody>
       {Lodash.transform(groups,function (res,studs,key){
         const idx_off = res.ccount;
         const mf_count = Lodash.reduce(studs,(acc,s)=>{
            if (s.sex==="MALE") {
              acc.m++;
            } else if (s.sex==="FEMALE") {
              acc.f++;
            }
            return acc;
         },{m:0,f:0});
         const el = (
          <React.Fragment key={"fr##"+key}>
            <TableRow key={"ffrr##"+key}><TableCell className={classes.cellH} colSpan={1000}> Škola: {studs[0].school} Třída: {studs[0].school_class}, Počet žáků - P:{mf_count.m} D:{mf_count.f} Celkem:{studs.length}</TableCell></TableRow>
            {studs.map((s,idx)=>{
              const cmd = me.renderCmds(s);
              const check = me.renderCheckBox(s);
              return (
                <TableRow hover  key={s.key}>
                  <TableCell className={classes.cell} style={{width:30}}>
                   {check}
                  </TableCell>
                  <TableCell numeric className={classes.cell}>{idx_off+idx+1}</TableCell>
                  <TableCell className={classes.cell}><SexView sex={s.sex}/></TableCell>
                  <TableCell className={classes.cell}>{s.surname}</TableCell>
                  <TableCell className={classes.cell}>{s.name}</TableCell>
                  <TableCell className={classes.cell}>{s.course_cost}</TableCell>
                  <TableCell className={classes.cell}>{s.paid_amount}</TableCell>
                  <TableCell className={classes.cell}>{topay(s)}</TableCell>
                  <TableCell className={classes.cell}>{s.discount}</TableCell>
                  <TableCell className={classes.cell}>{s.pay_info}</TableCell>
                  {filter_status === "IN" && (<TableCell className={classes.cell}><BooleanView value={s.card_out}/></TableCell>)}
                  {filter_status !== "IN" && (<TableCell className={classes.cell}>{s.var_sym}</TableCell>)}
                  <TableCell className={classes.cell}>{s.comment}</TableCell>
                  <TableCell className={classes.cell}>{cmd}</TableCell>
                </TableRow>
              )
            })}
          </React.Fragment>
         );
         res.list.push(el);
         res.ccount = res.ccount+studs.length;
         return res;
       },{list:[],ccount:0}).list}
      </TableBody>
    )
  }
  renderBodyAsSingle(students) {
    const { classes , filter_status} = this.props;
    const me = this;
    return (
      <TableBody>
          {students.map((s,idx)=>{
            const cmd = me.renderCmds(s);
            const check = me.renderCheckBox(s);
            return (
              <TableRow hover key={s.key}>
                  <TableCell className={classes.cell} style={{width:30}}>
                  {check}
                  </TableCell>
                  <TableCell numeric className={classes.cell}>{idx+1}</TableCell>
                  <TableCell className={classes.cell}><SexView sex={s.sex}/></TableCell>
                  <TableCell className={classes.cell}>{s.surname}</TableCell>
                  <TableCell className={classes.cell}>{s.name}</TableCell>
                  <TableCell className={classes.cell}><BooleanView value={s.student}/></TableCell>
                  <TableCell className={classes.cell}>{s.course_cost}</TableCell>
                  <TableCell className={classes.cell}>{s.paid_amount}</TableCell>
                  <TableCell className={classes.cell}>{topay(s)}</TableCell>
                  <TableCell className={classes.cell}>{s.discount}</TableCell>
                  <TableCell className={classes.cell}>{s.pay_info}</TableCell>
                  {filter_status === "IN" && (<TableCell className={classes.cell}><BooleanView value={s.card_out}/></TableCell>)}
                  {filter_status !== "IN" && (<TableCell className={classes.cell}>{s.var_sym}</TableCell>)}
                  <TableCell className={classes.cell}>{s.comment}</TableCell>
                  <TableCell className={classes.cell}>{cmd}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
    )
  }

  renderPairMemberRow(s,idx,pidx,span2,first) {
    const { classes ,filter_status} = this.props;
    const cmd = this.renderCmds(s);
    const check = this.renderCheckBox(s);
    return (
        <TableRow hover key={s.key}>
            <TableCell className={classes.cell} style={{width:30}}>
            {check}
            </TableCell>
            <TableCell numeric className={classes.cell}>{idx}</TableCell>
            {first?(<TableCell numeric className={classes.cell} rowSpan={span2?2:1}>{pidx}</TableCell>):null}
            <TableCell className={classes.cell}><SexView sex={s.sex}/></TableCell>
            <TableCell className={classes.cell}>{s.surname}</TableCell>
            <TableCell className={classes.cell}>{s.name}</TableCell>
            <TableCell className={classes.cell}><BooleanView value={s.student}/></TableCell>
            <TableCell className={classes.cell}>{s.course_cost}</TableCell>
            <TableCell className={classes.cell}>{s.paid_amount}</TableCell>
            <TableCell className={classes.cell}>{topay(s)}</TableCell>
            <TableCell className={classes.cell}>{s.discount}</TableCell>
            <TableCell className={classes.cell}>{s.pay_info}</TableCell>
            {filter_status === "IN" && (<TableCell className={classes.cell}><BooleanView value={s.card_out}/></TableCell>)}
            {filter_status !== "IN" && (<TableCell className={classes.cell}>{s.var_sym}</TableCell>)}
            <TableCell className={classes.cell}>{s.comment}</TableCell>
            <TableCell className={classes.cell}>{cmd}</TableCell>
        </TableRow>
    )
  }

  renderBodyAsPair(pairs,singles) {
    const { classes } = this.props;
    const me = this;
    return (
      <TableBody>
        {pairs.map((p,idx)=>{
            const p1 = me.renderPairMemberRow(p[0],idx*2+1,idx+1,true,true);
            const p2 = me.renderPairMemberRow(p[1],idx*2+2,null,false,false);
            return (
              <React.Fragment key={"ffr#"+p[0].key}>{p1}{p2}</React.Fragment>
            )
        })}
        <TableRow key={"ffspe"}><TableCell className={classes.cellH} colSpan={1000}> Bez partnera </TableCell></TableRow>
        {singles.map((s,idx)=>{
            return me.renderPairMemberRow(s,pairs.length*2+idx+1,null,false,true);
        })}
      </TableBody> 
    );
  }

  handleActionEmails() {
    const emails = Lodash.compact(this.getSelected().map((s)=>{return s.email}));
    this.setState({emailDlgOpen:true,emailDlgEmails:emails}) 
  }

  handleActionStudentCards() {
    this.setState({printStudentCardsDlgOpen:true,printStudentCardsDlgUrl:null},()=>{
        this.props.printStudentCards({variables:{
          course_key:this.props.course_key,
          student_keys:this.state.selected
        }}).then(res=>{
          if (res.data.printStudentCards.error) {
            this.setState({printStudentCardsDlgOpen:false});
            console.log("PRINT",res.data);
            alert("chyba:"+res.data.printStudentCards.error_message);
          } else {
            this.setState({printStudentCardsDlgOpen:true,printStudentCardsDlgUrl:res.data.printStudentCards.url});
          }
         
        })
    }); 
  }

  handleClosePrintStudentCardsDlg() {
    this.setState({printStudentCardsDlgOpen:false})
  }


  renderFooter() {
    const { classes, filter_status,auth} = this.props;
    const indeterminate = ((this.state.selected.length>0) && ( this.props.students.length !== this.state.selected.length));
    const checked = ((this.state.selected.length>0) && ( this.props.students.length === this.state.selected.length));
    const any = (this.state.selected.length>0);
    const pair = (this.state.selected.length===2);
    var entity = "?";
    switch(filter_status) {
      case 'IN': entity = "žáků"; break;
      case 'FILED': entity = "přihlášek"; break;
      case 'SPARE': entity = "náhradníků"; break;
      case 'KICKED': entity = "smazaných"; break;
      default:
        
    }
    return (
      <TableFooter> 
        <TableRow>
                  <TableCell className={classes.cell} style={{width:30}}>
                    <Checkbox 
                    indeterminate = {indeterminate}
                    checked = {checked}
                    onChange={(e,c)=>this.handleCheckAllClick(c)}
                     />
                  </TableCell>
                  <TableCell className={classes.cell} colSpan={1000}>
                  {auth.is_admin &&(
                      <React.Fragment>

                        <ActionButton disabled={!any} tooltip={"Přesun vybraných "+entity+" do jineho kurzu"} label={"Přesunout"} onClick={()=>this.handleActionCmd("MOVE")}/>
                        <ActionButton disabled={!any} tooltip={"Kopie vybraných "+entity+" do jineho kurzu"} label={"Kopírovat"} onClick={()=>this.handleActionCmd("COPY")}/>

                      </React.Fragment>
                    )}
 
                    {(filter_status === "IN") && (<ActionButton disabled={!any} label={"Tisk průkazek"} onClick={()=>this.handleActionStudentCards()}/>)}
                    <ActionButton disabled={!any} tooltip={"Zobrazení emailů vybraných "+entity} label={"EMaily"} onClick={()=>this.handleActionEmails()}/>
                    {auth.is_admin &&(
                      <React.Fragment>
                        {(filter_status === "IN" || filter_status === "FILED" || filter_status === "SPARE") && (<ActionButton disabled={!any} tooltip={"Odeslání výzvy k platbě na adresy vybraných "+entity} label={"Výzva k platbě"} onClick={()=>this.handleActionCmd("NOTIF_PAY")}/>)}
                  
                        {(filter_status === "IN") &&  (<ActionButton disabled={!any} label={"Vydání průkazek"} onClick={()=>this.handleActionCmd("CARD_OUT")}/>)}
                        <ActionButton disabled={!pair} tooltip={"Spárování dvojice "+entity}label={"Spárovat"} onClick={()=>this.handleActionCmd("MAKE_PAIR")}/>
                        {(filter_status === "IN" || filter_status === "SPARE" || filter_status === "KICKED") && (<ActionButton disabled={!any} tooltip={"Přeřazení vyrabných "+entity+" mezi přihlášky"} label={"Do přihlášek"} onClick={()=>this.handleActionCmd("TO_FILED")}/>)}
                        {(filter_status === "IN" || filter_status === "FILED" || filter_status === "KICKED") && (<ActionButton disabled={!any} tooltip={"Přeřazení vyrabných "+entity+" mezi náhradníky"} label={"Do náhradníků"} onClick={()=>this.handleActionCmd("TO_SPARE")}/>)}
                        {(filter_status === "SPARE" || filter_status === "FILED") && (<ActionButton disabled={!any} tooltip={"Zařazení vyrabných "+entity+" do zapsaných"} label={"Zapsat do kurzu"} onClick={()=>this.handleActionCmd("TO_IN")}/>)}
                        {(filter_status !== "KICKED") && (<ActionButton disabled={!any} tooltip={"Smazání vybraných "+entity}label={"Smazat"} onClick={()=>this.handleActionCmd("TO_KICKED")}/>)}

                      </React.Fragment>
                    )}
                  </TableCell>
        </TableRow>
      </TableFooter>
    )
  }
  handleCloseEmailDlg() {
    this.setState({emailDlgOpen:false})
  }
  renderEmailsDlg() {
    //const {classes} = this.props;
    return (
      <Dialog
          open={this.state.emailDlgOpen}
          onClose={()=>this.handleCloseEmailDlg()}
        >
          <DialogTitle>Emaily vybraných žáků</DialogTitle>
          <DialogContent>
            <DialogContentText>
             {this.state.emailDlgEmails.join(", ")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.handleCloseEmailDlg()} color="primary">
              Zavřít
            </Button>
          </DialogActions>
        </Dialog>
    )
  }

  renderPrintCardsDialog()  {
    return (
      <PdfViewDialog caption={"Karty žáků"} pdfUrl={this.state.printStudentCardsDlgUrl} open={this.state.printStudentCardsDlgOpen} onClose={()=>this.handleClosePrintStudentCardsDlg()}/>
    )
  }

  handleActionCmd(cmd) {
    if (!this.state.folder_key && this.props.folder_key) {
      this.setState({target_folder_key:this.props.folder_key})
    }
    if (!this.state.season_key && this.props.season_key) {
      this.setState({target_season_key:this.props.season_key})
    }
    const sel = this.getSelected();
    this.setState({actionDlgOpen:true,actionDlgWait:false,actionDlgCmd:cmd,actionDlgSelected:sel});
  }

  handleCloseActionDlg() {
    this.setState({actionDlgOpen:false})
  }

  doActionOne(s) {
    const me = this;
    const action  = this.state.actionDlgCmd;
    return new Promise(function(resolve,reject){
      switch(action) {
        case "MOVE":
          me.props.studentMove({variables:{
            key:s.key,
            new_course_key: me.state.target_course_key,
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;

        case "COPY":
          me.props.studentCopy({variables:{
            key:s.key,
            new_course_key: me.state.target_course_key,
            new_status: me.state.copy_status,
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;

        case "TO_IN":
          me.props.studentChangeStatus({variables:{
            key:s.key,
            new_status: "IN",
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;  
        case "TO_SPARE":
          me.props.studentChangeStatus({variables:{
            key:s.key,
            new_status: "SPARE",
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;  
        case "TO_FILED":
          me.props.studentChangeStatus({variables:{
            key:s.key,
            new_status: "FILED",
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;  
        case "TO_KICKED":
          me.props.studentChangeStatus({variables:{
            key:s.key,
            new_status: "KICKED",
            send_email_notification: me.state.send_email
          }}).then(_=>{
            resolve("ok");
          })
        break;  
        case "CARD_OUT":
          me.props.studentUpdateCard({variables:{
            key:s.key,
            card_out: me.state.card_out,
          }}).then(_=>{
            resolve("ok");
          })
        break;  
        case "NOTIF_PAY":
          me.props.sendNotification({variables:{
            type: "PAYMENT_REQ",
            student_key:s.key,
          }}).then(_=>{
            resolve("ok");
          })
        break;
        default:
          reject("neznama akce");
      }
    });
  }

  handleActionDoPair() {
    this.setState({actionDlgWait:true,actionDlgProgress:50}); 
    //const me = this;
    this.props.studentMakePair({variables:{
      student_a_key:this.state.actionDlgSelected[0].key,
      student_b_key:this.state.actionDlgSelected[1].key
    }}).then(_=>{
      this.setState({actionDlgWait:false,actionDlgOpen:false,selected:[]});
      this.props.refetch();
    }).catch(err=>{
      alert("chyba:"+err)
      this.setState({actionDlgWait:false,actionDlgOpen:false});
      this.props.refetch();
    })
  }

  handleActionDo() {
    if (this.state.actionDlgCmd === "MAKE_PAIR") {
      this.handleActionDoPair();
      return;
    }
    this.setState({actionDlgWait:true}); 
    const me = this;
    const total_count = this.state.actionDlgSelected.length;
    pEachSeries(this.state.actionDlgSelected,(s,idx)=>{
        me.setState({actionDlgProgress:(idx*100/total_count)});
        return me.doActionOne(s,idx);
    }).then(_=>{
      this.setState({actionDlgWait:false,actionDlgOpen:false,selected:[]});
      console.log("REFETCH",this.props.refetch)
      this.props.refetch();
    }).catch(err=>{
      alert("chyba:"+err)
      this.setState({actionDlgWait:false,actionDlgOpen:false});
      this.props.refetch();
    })
  }
  
  handleChangeTargetCourse(s,f,c) {
    this.setState({
      target_season_key:s,
      target_folder_key:f,
      target_course_key:c
    })
  }

  handleChangeSendEmail(v) {
    this.setState({send_email:v});
  }
  handleChangeCopyStatus(v) {
    this.setState({copy_status:v});
  }
  handleChangeCardOut(v) {
    this.setState({card_out:v});
  }

  renderActionDialog() {
    const {filter_status,evi_group} = this.props;
    var title = "?";
    var email_info = "?";
    var ready=false;
    var show_target = false;
    var show_email_notif = false;
    var show_card_out  = false;
    var show_copy_status = false;
    var entity="?";
    switch(filter_status) {
      case 'IN': entity = "žáků"; break;
      case 'FILED': entity = "přihlášek"; break;
      case 'SPARE': entity = "náhradníků"; break;
      default:
        
    }

    switch (this.state.actionDlgCmd) {
      case 'MOVE': 
        title="Přesun "+entity+" do jiného kurzu";
        email_info="Poslat email o opřeřazení";
        ready = (this.state.target_course_key !==null) && (this.state.send_email !==null);
        show_target = true;
        show_email_notif = true;
      break;
      case 'COPY': 
        title="Kopie "+entity+" do jiného kurzu";
        email_info="Poslat email o zápisu do kurzu";
        ready = (this.state.target_course_key !==null) && (this.state.send_email !==null) && (this.state.copy_status !==null);
        show_target = true;
        show_email_notif = true;
        show_copy_status = true;
      break;
      case 'CARD_OUT':
        title="Změna informace o vydané kartě";
        ready = (this.state.card_out !==null);
        show_card_out = true;
      break;
      case 'MAKE_PAIR':
        title="Spárování dvou "+entity;
        ready = true;
      break;

      case 'TO_IN':
        title="Zařazení "+entity+" mezi zapsané žáky";
        ready = (this.state.send_email !==null);
        show_email_notif = true;
        email_info="Poslat email o zařazení mezi zapsané žáky kurzu";
      break;

      case 'TO_SPARE':
        title="Zařazení "+entity+" mezi náhradníky";
        ready = (this.state.send_email !==null);
        show_email_notif = true;
        email_info="Poslat email o zařazení mezi náhradníky";
      break;
      
      case 'TO_FILED':
        title="Zařazení "+entity+" mezi přihlášky";
        ready = (this.state.send_email !==null);
        show_email_notif = true;
        email_info="Poslat email o zařazení mezi přihlášky";
      break;

      case 'TO_KICKED':
        title="Vyřazení "+entity+" z kurzu";
        ready = (this.state.send_email !==null);
        show_email_notif = true;
        email_info="Poslat email o vyřazení z kurzu";
      break;

      case 'NOTIF_PAY':
        title="Odeslat výzvu k platbě";
        ready = true;
      break;
      default:
    } 
    return (
      <Dialog maxWidth={false}
          open={this.state.actionDlgOpen}
          onClose={()=>this.handleCloseActionDlg()}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            Vybraní:
              <StudentChips students={this.state.actionDlgSelected}/>
            <div>
              {show_target && (
                <React.Fragment>
                  <div> Cílový kurz: </div>
                  <CourseSelectField 
                    disabled = {this.state.actionDlgWait}
                    season_key={this.state.target_season_key}
                    folder_key={this.state.target_folder_key}
                    course_key={this.state.target_course_key}
                    evi_group={evi_group}
                    onChange={(s,f,c)=>this.handleChangeTargetCourse(s,f,c)}
                  />
                </React.Fragment>
              )}
              {show_copy_status &&(
                <React.Fragment>
                  <div> {"Kopie "+entity+" (vytvoření nových) mezi:"}  </div>
                  <EnumField required disabled={this.state.actionDlgWait}  width={200} id="copy_status" label="Stav" values={[{key:"IN",label:"zapsane"},{key:"FILED",label:"prihlasky"},{key:"SPARE",label:"nahradniky"}]} value={this.state.copy_status} onChange={(v)=>this.handleChangeCopyStatus(v)} />
                </React.Fragment>
              )}

              {show_email_notif &&(
                <React.Fragment>
                  <div> {email_info} </div>
                  <BooleanField required  disabled={this.state.actionDlgWait} width={100} id="emailnotif" label="odeslat info" value={this.state.send_email} onChange={(v)=>this.handleChangeSendEmail(v)} />
                </React.Fragment>
              )}

              {show_card_out &&(
                <React.Fragment>
                  <div> Nový stav karty </div>
                  <BooleanField required  disabled={this.state.actionDlgWait} width={100} id="cardoutn" label="byla vydána" value={this.state.card_out} onChange={(v)=>this.handleChangeCardOut(v)} />
                </React.Fragment>
              )}
              
            </div>
       
            {this.state.actionDlgWait &&  (
              <React.Fragment>
                <CircularProgress />
                <LinearProgress variant="determinate" value={this.state.actionDlgProgress}/>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>


            <Button disabled={this.state.actionDlgWait} onClick={()=>this.handleCloseActionDlg()} color="primary">
              Zavřít
            </Button>

            <Button disabled={!ready || this.state.actionDlgWait} onClick={()=>this.handleActionDo()} color="primary">
              Provést
            </Button>

          </DialogActions>
        </Dialog>
    )
 
  }
    
  renderStudentViewDialog() {
    const {showStudent_key} = this.state;
    var title=""
    switch (this.props.filter_status) {
      case "IN": title = "Detail žáka"; break;
      case "FILED": title = "Detail přihlášky"; break;
      case "SPARE": title = "Detail náhradníka"; break;
      default:
        title = "Detail";
    }
    return (
      <StudentViewDialog open={showStudent_key!=null} student_key={showStudent_key} caption={title} onClose={()=>this.handleCloseStudentViewDialog()}/>
    )
  }


  renderStudentEditDialog() {
    const {editStudent_key} = this.state;
    var title=""
    switch (this.props.filter_status) {
      case "IN": title = "Editace žáka"; break;
      case "FILED": title = "Editace přihlášky"; break;
      case "SPARE": title = "Editace náhradníka"; break;
      default:
        title = "Editace";
    }
    return (
      <StudentEditDialog mode={this.props.filter_status} open={editStudent_key!=null} student_key={editStudent_key} caption={title} onClose={()=>this.handleCloseStudentEditDialog()}/>
    )
  }

  renderStudentPayDialog() {
    const {payStudent_key} = this.state;
    var title=""
    switch (this.props.filter_status) {
      case "IN": title = "Editace platby žáka"; break;
      case "FILED": title = "Editace platby přihlášky"; break;
      case "SPARE": title = "Editace  platby náhradníka"; break;
      default:
        title = "Editace platby";
    }
    return (
      <StudentPayEditDialog mode={this.props.filter_status} open={payStudent_key!=null} student_key={payStudent_key} caption={title} onClose={()=>this.handleCloseStudentPayDialog()}/>
    )
  }

  renderStudentAddDialog() {
    const {addStudent_open} = this.state;
    const {course_key} = this.props;
    var title=""
    switch (this.props.filter_status) {
      case "IN": title = "Nový zapsaný žák"; break;
      case "FILED": title = "Nová přihláška"; break;
      case "SPARE": title = "Nový náhradník"; break;
      default:
        title = "Nový žák";
    }
    return (
      <StudentAddDialog mode={this.props.filter_status} open={addStudent_open} course_key={course_key} caption={title} onClose={()=>this.handleCloseStudentAddDialog()}/>
    )
  }

  renderAddButton() {

    switch (this.props.filter_status) {
      case "IN": return (<ActionButton tooltip={"Zapsat nového žáka"} label={"Nový žák"} onClick={()=>this.handleActionAddStudent()}/>);
      case "FILED": return (<ActionButton tooltip={"Vytvoření nové přihlášky"} label={"Nová přihláška"} onClick={()=>this.handleActionAddStudent()}/>);
      case "SPARE": return (<ActionButton tooltip={"Vytvoření nového náhradníka"} label={"Nový náhradník"} onClick={()=>this.handleActionAddStudent()}/>);
      default:
        return null;
    }

  }

  render() {
    const { students,group_mode,auth } = this.props;
    
    var notitle = "nic";
    switch (this.props.filter_status) {
      case "IN": notitle = "Žádný žák"; break;
      case "FILED": notitle = "Žádná přihláška"; break;
      case "SPARE": notitle = "Žádný náhradník"; break;
      default:
        notitle = "nic";
    }

    const studentAddDialog = this.renderStudentAddDialog();
    const studentAddButton = this.renderAddButton();

    if (students.length ===0) {
      return (
        <div> 
          {studentAddDialog}
          {auth.is_admin && studentAddButton}
          {notitle} 
        </div>
      )
    }


    var tableBody = null;
    var tableHead = null;
    switch (group_mode) {
      case 'SCHOOL':
        tableBody = this.renderBodyAsSchool(this.state.school_groups);
        tableHead = this.renderHeadAsSchool();
        break;
      case 'PAIR': 
        tableHead = this.renderHeadAsPair();
        tableBody = this.renderBodyAsPair(this.state.pair_pairs,this.state.pair_singles);
        break;
      case 'SINGLE':
      default:
        tableBody = this.renderBodyAsSingle(this.state.single_students);
        tableHead = this.renderHeadAsSingle();
    }

    const tableFooter = this.renderFooter();
    const emailsDlg = this.renderEmailsDlg();
    const printCardsDialog = this.renderPrintCardsDialog();
    const actionDialog = this.renderActionDialog();
    const studentViewDialog = this.renderStudentViewDialog();
    const studentEditDialog = this.renderStudentEditDialog();
    const studentPayDialog = this.renderStudentPayDialog();
    return (
      <React.Fragment>
        {emailsDlg}
        {printCardsDialog}
        {actionDialog}
        {studentViewDialog}
        {studentEditDialog}
        {studentPayDialog}
        {studentAddDialog}
        {auth.is_admin && studentAddButton}
        <Table>
          {tableHead}
          {tableBody}
          {tableFooter}
        </Table>
      </React.Fragment>
    );
  }
}

StudentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  course_key: PropTypes.string.isRequired,
  season_key: PropTypes.string,
  folder_key: PropTypes.string,
  evi_group: PropTypes.string,
  students: PropTypes.arrayOf(PropTypes.object),
  group_mode: PropTypes.oneOf(['SINGLE','SCHOOL','PAIR']).isRequired,
  refetch: PropTypes.func.isRequired,
  printStudentCards: PropTypes.func.isRequired,
  studentMove: PropTypes.func.isRequired,
  studentCopy: PropTypes.func.isRequired,
  studentChangeStatus: PropTypes.func.isRequired,
  studentUpdateCard: PropTypes.func.isRequired,
  sendNotification: PropTypes.func.isRequired,
  studentMakePair: PropTypes.func.isRequired,
  filter_status: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  auth: PropTypes.object.isRequired
};
StudentsTable.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  graphql(PRINT_STUDENT_CARDS,{
    name:"printStudentCards",
  }),
  graphql(STUDENT_MOVE,{
    name:"studentMove",
  }),
  graphql(STUDENT_COPY,{
    name:"studentCopy",
  }),
  graphql(STUDENT_CHANGE_STATUS,{
    name:"studentChangeStatus"
  }),
  graphql(STUDENT_UPDATE_CARD,{
    name:"studentUpdateCard"
  }),
  graphql(STUDENT_MAKE_PAIR,{
    name:"studentMakePair"
  }),
  graphql(EMAIL_SEND_NOTIFICATION,{
    name:"sendNotification"
  }),
)(StudentsTable);
