import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StringViewField from './StringViewField';
import Lodash from 'lodash';
const styles = theme => ({
 
});


class EnumViewField extends React.Component {

  render() {
    const { width,label,value,values } = this.props;
    const e = Lodash.find(values,{'key':value});
    const val =e?e.label:null;

    return (
        <StringViewField width={width} label={label} value={val}/>
    );
  }
}

EnumViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
  })).isRequired,
};
EnumViewField.defaultProps = {
  
};

export default withStyles(styles)(EnumViewField);