import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import ActionLink from './ActionLink';
import ActionButton from './ActionButton';
import DragHandle from './DragHandle';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
   // maxWidth: 1000,
   // background: theme.palette.background.paper,
  },
  dragging: {
    opacity:0,
    //color: "rgba(0, 0, 0, 0)",
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  cellb: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    borderLeft: `1px solid
    ${
      theme.palette.type === 'light'
        ? lighten(fade(theme.palette.divider, 1), 0.88)
        : darken(fade(theme.palette.divider, 1), 0.8)
    }`
  },
  row: {
    height:24,
  }
});


class MyCoursesRow extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
    
    }
  }
 


 
  
  

  render() {
    const { activeDrag, isDragging, connectDragSource, connectDragPreview,connectDropTarget, course, classes } = this.props;
    const className = classNames([
      { [classes.dragging]: isDragging },
      { [classes.hover]: !activeDrag},
      classes.row
    ]);
    return (
      
      <TableRow  hover={!activeDrag} className={className}>
          <TableCell className={classes.cell}> {connectDropTarget(connectDragSource(
            <div><DragHandle/></div>
            ))} </TableCell>
          <TableCell className={classes.cell}> {connectDragPreview(<div>{course.code}</div>)} </TableCell>
          <TableCell className={classes.cell}> {course.name} </TableCell>

          <TableCell className={classes.cell}>{course.first_period}</TableCell>
          <TableCell className={classes.cell}>{course.period}</TableCell>
          <TableCell className={classes.cell}>{course.place}</TableCell>
          <TableCell className={classes.cell}>{course.teacher}</TableCell>

          <TableCell className={classes.cellb}>{course.stats.in.male.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.in.female.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.in.total.count}</TableCell>


          <TableCell className={classes.cellb}>{course.stats.filed.male.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.filed.female.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.filed.total.count}</TableCell>

          <TableCell className={classes.cellb}>{course.stats.spare.male.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.spare.female.count}</TableCell>
          <TableCell className={classes.cell}>{course.stats.spare.total.count}</TableCell>

   
          <TableCell className={classes.cell}>  
              <ActionButton tooltip={"zobrazit"} icon={'view'} onClick={()=>this.props.onShowCourse(course.key)}/>
              <ActionLink useRouter tooltip={"zapsaní"} icon={'students'} href={"/spa/course/"+course.key+"/in"}/>
          </TableCell>
      </TableRow>
      );
  }
}

MyCoursesRow.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
//  onSwap: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  activeDrag: PropTypes.bool.isRequired,
  findRow: PropTypes.func.isRequired,
  moveRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  onShowCourse: PropTypes.func.isRequired,
};

MyCoursesRow.defaultProps = {
 
};


const rowSource = {
  beginDrag(props) {
    props.onDrag(true);
    const r = {
      course: props.course,
      id: props.id,
      originalIndex: props.findRow(props.id).index,
    };
  
    return r;
  },
  endDrag(props, monitor, component) {
    props.onDrag(false);

    const { id: droppedId, originalIndex } = monitor.getItem()
		const didDrop = monitor.didDrop()

		if (!didDrop) {
			props.moveRow(droppedId, originalIndex)
    } else {
      props.save();
    }
    
    return;
  }
};

const rowTarget = {
  
  hover(props, monitor, component) {

    const draggedId  = monitor.getItem().id;
		const overId  = props.id;

		if (draggedId !== overId) {
			const overIndex  = props.findRow(overId).index;
			props.moveRow(draggedId, overIndex);
		}
    return;
  }
}

function dnd_src(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}
function dnd_trg(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default compose(
  withStyles(styles),
  DropTarget('row', rowTarget, dnd_trg),
  DragSource('row', rowSource, dnd_src)
  
)(MyCoursesRow)
