import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import PricePlansTable from './PricePlansTable';
import { Query } from "react-apollo";
import PricePlanViewDialog from './PricePlanViewDialog';
import PricePlanDeleteDialog from './PricePlanDeleteDialog';
import PricePlanEditDialog from './PricePlanEditDialog';
import ActionButton from './ActionButton';
import PlaceIDField from './Fields/PlaceIDField';
import { connect } from 'react-redux'
import { setPlaceIDForPricePlan } from './../actions';
import {GET_PRICE_PLANS } from './Fields/Queries';

const SAVE_ORDERING = gql`
    mutation pricePlans_ordering($ids: [ID]!,$place_id:ID) {
      pricePlans_ordering(ids:$ids,place_id:$place_id) 
    }
`;

const HIDE_PRICE_PLAN = gql`
mutation hidePricePlan($id:ID!) {
  pricePlanHide(id:$id) {
    id
  }
}
`;

const UPDATE_PRICE_PLAN= gql`
mutation updatePricePlan($id:ID!,$name:String,$place_id:ID) {
  pricePlanUpdate(id:$id,name:$name,place_id:$place_id) {
    id
  }
}
`;

const CREATE_PRICE_PLAN = gql`
mutation createPricePlan($name:String!,$place_id:ID!) {
  pricePlanCreate(name:$name,place_id:$place_id) {
    id
  }
}
`;

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 3,
  }
});


class PricePlans extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_price_plan:null,
      delete_price_plan:null,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
  }


  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  

  }

  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({name:null,place_id:this.props.place_id});
    }
  }


  handleEdit(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSave() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("price_plan update",doc)
        this.props.updatePricePlan({
          variables: doc
        }).then(res=>{
          console.log("price_plan ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("price_plan create",doc)
        this.props.createPricePlan({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }

  handleShow(item) {
    this.setState({show_price_plan:item});
  }
  closeShow() {
    this.setState({show_price_plan:null});
  }

  handleDelete(item) {
    this.setState({delete_price_plan:item});
  }
  closeDelete() {
    this.setState({delete_price_plan:null});
  }

  doDelete() {
    const id = this.state.delete_price_plan.id;
    this.setState({delete_price_plan:null});
    this.props.hidePricePlan({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    const { place_id } = this.props; 
    this.props.saveOrdering({
      variables: {
        ids:ids,
        place_id
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  render() {
    const { auth, place_id} = this.props;
  
    return (
      <React.Fragment>
        <PlaceIDField required error={place_id===null} value={place_id} onChange={(v)=>this.props.onChangePlaceID(v)}/>
        {place_id && (<ActionButton label={"Nový cenový plán"} onClick={()=>this.handleNew()}/>)}

        
        <PricePlanViewDialog price_plan={this.state.show_price_plan}  open={this.state.show_price_plan!==null} onClose={()=>this.closeShow()} /> 
        <PricePlanDeleteDialog price_plan={this.state.delete_price_plan}  open={this.state.delete_price_plan!==null} onDelete={()=>this.doDelete()} onClose={()=>this.closeDelete()} /> 
        <PricePlanEditDialog innerRef = {this.edit} onSave={()=>this.doSave()} /> 
        
        <Query query={GET_PRICE_PLANS} variables={{place_id}} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <PricePlansTable 
                  innerRef = {this.table}
                  auth={auth}
                  data={data.pricePlans} 
                  refetch={refetch}
                  saveOrdering={(ids)=>this.saveOrder(ids)}
                  onEdit={(item)=>this.handleEdit(item)}
                  onShow={(item)=>this.handleShow(item)}
                  onDelete={(item)=>this.handleDelete(item)}
              />
            );
          }}
      </Query>

      </React.Fragment>
    );
  }
}

PricePlans.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hidePricePlan: PropTypes.func.isRequired,
  updatePricePlan: PropTypes.func.isRequired,
  createPricePlan: PropTypes.func.isRequired,
  place_id: PropTypes.string,
  onChangePlaceID: PropTypes.func.isRequired
};
PricePlans.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      place_id: state.select.place_id_for_price_plans,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePlaceID: id => {
      dispatch(setPlaceIDForPricePlan(id))
    },
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_PRICE_PLAN,{
    name: "hidePricePlan",
  }),
  graphql(UPDATE_PRICE_PLAN,{
    name: "updatePricePlan",
  }),
  graphql(CREATE_PRICE_PLAN,{
    name: "createPricePlan",
  }),
)(PricePlans)
