import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query } from "react-apollo";
import Lodash from "lodash";
import {GET_PRICE_PLANS } from './Queries';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class PricePlanIDView extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,price_plan_id } = this.props;
    return (
      <Query query={GET_PRICE_PLANS} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (loading) return null; 
        if (error) return `Error!: ${error}`;
        const price_plan = Lodash.find(data.pricePlans,{id:price_plan_id});
        return ( 
          <span className={classes.root}>
          { price_plan?price_plan.name:(<span> ? {price_plan_id} </span>) }
          </span>
        );
      }}
      </Query>
    );
  }
}

PricePlanIDView.propTypes = {
  classes: PropTypes.object.isRequired,
  price_plan_id: PropTypes.string
};
PricePlanIDView.defaultProps = {
  
};

export default withStyles(styles)(PricePlanIDView);