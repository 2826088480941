import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import DateField from './Fields/DateField';
import DateView from './DateView';
var moment = require('moment');
moment.locale('cs');

const styles = theme => ({
 
});

const GET_PAYREPORT = gql`
  query CoursePaymentsReport($course_key:String!, $toDate:Date!) {
    coursePaymentsReport(courseKey:$course_key,date:$toDate) {
      amount
      date
      count
      amountByStatus {
        s
        n
        k
        e
      }
      countByStatus {
        s
        n
        k
        e
      }
    }
  } 
`;

class CourseCash extends React.Component {
  constructor(props) {
    super(props);

    this.state = {  
      toDate: moment().format('YYYY-MM-DD')
    }
    
  }

  render() {
    const {course_key} = this.props;
    const {toDate} = this.state;
    console.log('toDate',toDate);
    return (
      <div>
        <div>Přehled peněz ke zvolenému dni (např. zvolený den 1.1.2020 znamená, že se vemou všechny platby připsané do půlnoci z 31.12.2019 na 1.1.2020)</div>
        <DateField  required  label="datum" value={this.state.toDate}  onChange={(v)=>this.setState({toDate:v})}/>

       <Query query={GET_PAYREPORT} variables={{ course_key, toDate }} fetchPolicy={"network-only"}>
       {({ loading, error, data, refetch }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          return  (
            <React.Fragment>
              <div>zvolený den: <DateView date={data.coursePaymentsReport.date}/></div>
              <div>celkem vybráno: {data.coursePaymentsReport.amount} kč</div>
              <div>v zapsaných vybráno: {data.coursePaymentsReport.amountByStatus.e} kč</div>
              <div>v přihláškách vybráno: {data.coursePaymentsReport.amountByStatus.n} kč</div>
              <div>v náhradnících vybráno: {data.coursePaymentsReport.amountByStatus.s} kč</div>
              <div>v smazaných vybráno: {data.coursePaymentsReport.amountByStatus.k} kč</div>
            </React.Fragment>
          )
       }}
       </Query>
      </div>
    );
  }
}

CourseCash.propTypes = {  
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string.isRequired,
};
CourseCash.defaultProps = {
 
};

export default withStyles(styles)(CourseCash);