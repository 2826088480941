import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
  root: {
    flexGrow: 1,
    //maxWidth: 600,
    padding: theme.spacing.unit * 2,
  },
  main_grid: {
    maxWidth: 800,
  },
  button: {
    margin: theme.spacing.unit,
  }
});



class EventCapacityView extends React.Component {

  
  render() {
    const { cap_info} = this.props;

    

    return (
      <React.Fragment>
        <div> celková kapacita: {cap_info.total_capacity}</div>
        <div> volná kapacita {cap_info.free_capacity}</div>
        <div> počet rezervovaných vstupenek {cap_info.ticket_counts.reservation}  </div>
        <div> počet prodaných vstupenek {cap_info.ticket_counts.sold} </div>
        <div> počet vstupenek čekajících na platbu {cap_info.ticket_counts.selling} </div>
        <div> počet vstupenek v košíku {cap_info.ticket_counts.booked} </div>   
         
      </React.Fragment>
    );
  }
}

EventCapacityView.propTypes = {
  classes: PropTypes.object.isRequired,
  cap_info: PropTypes.shape({
    ticket_counts: PropTypes.shape({
      total: PropTypes.number.isRequired,
      reservation: PropTypes.number.isRequired,
      sold: PropTypes.number.isRequired,
      selling: PropTypes.number.isRequired,
      booked: PropTypes.number.isRequired,
      error: PropTypes.number.isRequired,
    }).isRequired,
    total_capacity:PropTypes.number.isRequired,
    free_capacity:PropTypes.number.isRequired
  }).isRequired,
  
};
EventCapacityView.defaultProps = {
  
};


export default withStyles(styles)(EventCapacityView);