import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import CourseForm from './CourseForm';
import moment from 'moment';



const CREATE_COURSE = gql`
mutation createCourse($season_key:String!, $folder_key:String!, $evi_group: EviGroup!, $name:String, $code:String!, $active:Boolean!, $cost_a:Int!,$cost_b:Int!,$cost_sa:Int!,$cost_sb:Int!,$cost_sale:Boolean!,$cost_mode:CostMode!,$group_mode:GroupMode!,$capacity:Int!,$card_line_1:String,$card_line_2:String,$period:String,$first_period:String,$teacher:String,$place:String,$comment:String,$accounting_year:Int!,$var_sym_prefix:Int!) {
  courseCreate(season_key:$season_key,folder_key:$folder_key,evi_group:$evi_group,name:$name,code:$code,active:$active,cost_a:$cost_a,cost_b:$cost_b,cost_sa:$cost_sa,cost_sb:$cost_sb,cost_sale:$cost_sale,cost_mode:$cost_mode,group_mode:$group_mode,capacity:$capacity,card_line_1:$card_line_1,card_line_2:$card_line_2,period:$period,first_period:$first_period,teacher:$teacher,place:$place,comment:$comment,accounting_year:$accounting_year,var_sym_prefix:$var_sym_prefix) {
    key
    evi_group
    folder_key
    season_key
    name
    code
    active
    suspend
    cost_sa
    cost_sb
    cost_a
    cost_b
    cost_mode
    cost_sale
    accounting_year
    group_mode
    var_sym_prefix
    capacity
    card_line_1
    card_line_2
    period
    first_period
    teacher
    place
    comment
  }
}
`;

class CourseAdd extends React.Component {


  constructor(props) {
    super(props);
    this.state = {  
      course: {
        folder_key:props.folder_key,
        season_key:props.season_key,
        evi_group:props.evi_group,
        name:null,
        code:null,
        active:false,
        suspend:false,
        cost_sa:0,
        cost_sb:0,
        cost_a:0,
        cost_b:0,
        cost_mode:"NORMAL",
        accounting_year: moment().year(),
        cost_sale:false,
        group_mode:"SINGLE",
        var_sym_prefix:null,
        capacity:null,
        card_line_1:null,
        card_line_2:null,
        period:null,
        first_period:null,
        teacher:null,
        place:null,
        comment:null
      },
      valid:false
    }

    this.form = React.createRef();

  }

  doSave() {
    console.log("CourseEdit doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:CREATE_COURSE,
      variables:this.form.current.state.doc,
      refetchQueries: ["Courses"]
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
    //console.log("this.form.current",this.form.current);
  }


  onDocChange(d,valid) {
    console.log("CourseEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 

 
  render() {
    const {evi_group} = this.props;
    return (
        <CourseForm innerRef={this.form} lock_evi_group={evi_group!==null} onDocChange={(d,v)=>this.onDocChange(d,v)} doc={this.state.course} />       
    );
  }
}

CourseAdd.propTypes = {
  client: PropTypes.object.isRequired,
  folder_key: PropTypes.string.isRequired,
  season_key: PropTypes.string.isRequired,
  evi_group: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func.isRequired

};
CourseAdd.defaultProps = {
  
};

export default withApollo(CourseAdd,{withRef:true});
