import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EnumField from './EnumField';
import gql from "graphql-tag";
import { Query} from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
 
});


const GET_CATALOG = gql`
  query Catalog{    
    folders {
      key
      name
    }
    seasons {
      key
      name
    }
  }
`;

const GET_COURSES = gql`
  query Courses($folder_key: String!, $season_key:String!, $evi_group:EviGroup) {
    courses(folder_key:$folder_key, season_key:$season_key, evi_group:$evi_group) {
        key
        name
        code
    }
  }
`;  

class CourseSelectField extends React.Component {

  handleChangeS(s) {
    this.props.onChange(s,this.props.folder_key,null);
  }

  handleChangeF(f) {
    this.props.onChange(this.props.season_key,f,null);
  }

  handleChangeC(c) {
    this.props.onChange(this.props.season_key,this.props.folder_key,c);
  }

  render() {
    const {  evi_group, season_key,folder_key,course_key,disabled} = this.props;
    return (
      <div>
         <Query query={GET_CATALOG}>
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            const se = data.seasons.map(s=>{
              return {key:s.key,label:s.name};
            });
            const fe = data.folders.map(s=>{
              return {key:s.key,label:s.name};
            });
            return (
                <React.Fragment>
                  <EnumField disabled={disabled} required width={150} id="season" label="Sezóna" values={se} value={season_key} onChange={(v)=>this.handleChangeS(v)} />
                  <EnumField disabled={disabled} required width={300} id="folder" label="Složka" values={fe} value={folder_key} onChange={(v)=>this.handleChangeF(v)} />
                  { folder_key && season_key && (
                    <Query query={GET_COURSES} variables={{ season_key,folder_key, evi_group }}>
                      {({ loading, error, data }) => {
                        if (loading) return (<CircularProgress/>);
                        if (error) return `Error!: ${error}`;
                        const ce = data.courses.map(c=>{
                          return {key:c.key,label:c.code+ " "+c.name}
                        })
                        return (
                          <EnumField disabled={disabled} required width={250} id="course" label="Kurz" values={ce} value={course_key} onChange={(v)=>this.handleChangeC(v)} />
                        )                        
                      }}
                    </Query>
                  )}
                </React.Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}

CourseSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  course_key: PropTypes.string,
  season_key: PropTypes.string,
  folder_key: PropTypes.string,
  evi_group: PropTypes.string,
  onChange: PropTypes.func,
};
CourseSelectField.defaultProps = {
  disabled:false
};

export default withStyles(styles)(CourseSelectField);