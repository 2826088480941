import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanField from './BooleanField';
import StringField  from './StringField';
import NumberField  from './NumberField';
import EnumField from './EnumField';
import Lodash from 'lodash';
import SchoolISelect from './Fields/SchoolISelect';
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});




class StudentForm extends React.Component {


  constructor(props) {
    super(props);
    console.log("constructor",props.doc)
    this.state = {  
      doc: Object.assign({},props.doc),
      doc_err: this.getDocErr(props.doc),
    }
  }

  setDoc(doc) {
    this.setState({
      doc: Object.assign({},doc),
      doc_err: this.getDocErr(doc),
    })
  }
 

  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err
    });
    this.props.onDocChange(doc,this.checkDoc(doc));
  }

  checkDocField(name,value) {
     
    if (["addressing","surname"].includes(name)  && value===null) {
      return false;
    }
    if (this.props.withPayment) {
      if (["course_cost"].includes(name)  && value===null) {
        return false;
      }
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }
  
  
  renderForm() {
    const { classes, withPayment} = this.props;
    const { doc, doc_err } = this.state;
    //const doc_ok=this.checkDoc(doc);
    return (
        <form className={classes.form}  noValidate autoComplete="off">  
          <div>
          <EnumField required width={200} id="addressing" label="Oslovení" values={[{key:"s",label:"slečna"},{key:"d",label:"paní"},{key:"p",label:"pan"}]} value={doc.addressing} error={doc_err.addressing} onChange={(v)=>this.handleDocChange("addressing",v)} />
          <StringField required width={200} id="surname" label="Přijmení" value={doc.surname} error={doc_err.surname} onChange={(v)=>this.handleDocChange("surname",v)} />
          <StringField width={200} id="name" label="Jméno" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
          <StringField width={300} id="email" label="Email" value={doc.email} error={doc_err.email} onChange={(v)=>this.handleDocChange("email",v)} />
          <StringField width={300} id="email2" label="Email2" value={doc.email2} error={doc_err.email2} onChange={(v)=>this.handleDocChange("email2",v)} />
          <StringField id="phone" label="Telefon" value={doc.phone} error={doc_err.phone} onChange={(v)=>this.handleDocChange("phone",v)} />
          <NumberField width={100} id="year" label="Ročník" value={doc.year} error={doc_err.year} onChange={(v)=>this.handleDocChange("year",v)} />
          </div>
          <div>
          <BooleanField required width={100} id="student" label="Student" value={doc.student} error={doc_err.student} onChange={(v)=>this.handleDocChange("student",v)} />
          <BooleanField width={150} id="student_check" label="Student ověřen" value={doc.student_check} error={doc_err.student_check} onChange={(v)=>this.handleDocChange("student_check",v)} />
          <BooleanField width={100} id="card_out" label="Karta vydána" value={doc.card_out} error={doc_err.card_out} onChange={(v)=>this.handleDocChange("card_out",v)} />
          <StringField  width={500} id="comment" label="Poznámka" value={doc.comment} error={doc_err.comment} onChange={(v)=>this.handleDocChange("comment",v)} />
          </div>    
          { withPayment && 
              (<div>
            <NumberField required width={100} id="course_cost" label="Kurzovné" value={doc.course_cost} error={doc_err.course_cost} onChange={(v)=>this.handleDocChange("course_cost",v)} />
            <BooleanField id="long_period" label="Celo-roční" value={doc.long_period} error={doc_err.long_period} onChange={(v)=>this.handleDocChange("long_period",v)} />
            <NumberField width={100} id="paid" label="Zaplaceno" value={doc.paid} error={doc_err.paid} onChange={(v)=>this.handleDocChange("paid",v)} />
            <StringField width={400} id="pay_info" label="Pl. info" value={doc.pay_info} error={doc_err.pay_info} onChange={(v)=>this.handleDocChange("pay_info",v)} />
            <StringField  id="discount" label="Sleva" value={doc.discount} error={doc_err.discount} onChange={(v)=>this.handleDocChange("discount",v)} />
            <BooleanField width={150} id="paid_ok" label="Zaplaceno vše" value={doc.paid_ok} error={doc_err.paid_ok} onChange={(v)=>this.handleDocChange("paid_ok",v)} />
            </div>)
          }

          <div>

          <SchoolISelect onChange={(s)=>this.handleDocChange("school",s)}/>
          <StringField  width={500} id="school" label="Škola" value={doc.school} error={doc_err.school} onChange={(v)=>this.handleDocChange("school",v)} />
          <StringField  id="school_class" label="Třída" value={doc.school_class} error={doc_err.school_class} onChange={(v)=>this.handleDocChange("school_class",v)} />
          <StringField  width={200} id="street" label="Ulice" value={doc.street} error={doc_err.street} onChange={(v)=>this.handleDocChange("street",v)} />
          <StringField  id="street_no" label="Číslo" value={doc.street_no} error={doc_err.street_no} onChange={(v)=>this.handleDocChange("street_no",v)} />
          <StringField  id="city" label="Město" value={doc.city} error={doc_err.city} onChange={(v)=>this.handleDocChange("city",v)} />
          <StringField  id="post_code" label="Psč" value={doc.post_code} error={doc_err.post_code} onChange={(v)=>this.handleDocChange("post_code",v)} />

          </div>    
        </form>
    )
  }

  render() {
    const form = this.renderForm();
    return (
      <div className="foo">
        {form}
      </div>
    );
  }
}

StudentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  //onSave: PropTypes.func.isRequired,
  //onCancel: PropTypes.func.isRequired,
  onDocChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  withPayment: PropTypes.bool,
};
StudentForm.defaultProps = {
  withPayment:false
};

export default withStyles(styles)(StudentForm);