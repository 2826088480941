import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";

import { compose, graphql, Query } from 'react-apollo'

import StudentCardView from './StudentCardView';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import DateField from './Fields/DateField';
import CoursePaymentTypeView from './Fields/CoursePaymentTypeView';
import ActionButton from './ActionButton';

import BooleanField from './BooleanField';
import StringField  from './StringField';
import NumberField  from './NumberField';
var moment = require('moment');
moment.locale('cs');


import DebugObjectView from './Utils/DebugObjectView';
const styles = theme => ({
  dialog: {
    width:600
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

const GET_STUDENT_WITH_PAYMENTS = gql`
  query Student($student_key:String!){    
      student(key: $student_key) {
        key
        course_key
        course { key season {name} folder { name } name code} 
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        name
        surname
        email
        email2
        phone
        year
        school
        school_id
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        paid_amount
        payments {
          id
          type
          date
          amount
          firmKey
          studentKey
          fioTrRef
          manualRef
          manualComment
        }
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
      }
  }
`;


/*  store new Payment 

mutation {
  studentNewPaymentByHand(date: "2019-01-01", amount: 1, studentKey:"3503a2df-f523-4e16-ab83-d6de3e4efc41", manualComment:"xxx" ) {
    id
    type
    date
    amount
    firmKey
    studentKey
    fioTrRef
    manualRef
    manualComment
  }
}

*/

const StudentNewPaymentByHand=gql`
    mutation StudentNewPaymentByHand($date:Date!, $amount: Int!, $studentKey:String!, $manualComment: String ) {
      studentNewPaymentByHand(date: $date, amount: $amount, studentKey:$studentKey, manualComment:$manualComment ) {
        id
        type
        date
        amount
        firmKey
        studentKey
        fioTrRef
        manualRef
        manualComment
      }
    }
`;

const EMAIL_SEND_NOTIFICATION = gql`
mutation emailSend($type: EmailNotificationType!, $student_key:String!){
  emailSend(type:$type,student_key:$student_key){
    ok
  }
}
`;

const STUDENT_CHANGE_STATUS = gql`
mutation studentChangeStatus($key:String!,$new_status:StudentStatus!,$send_email_notification:Boolean) {
  studentChangeStatus(key:$key,new_status:$new_status,send_email_notification:$send_email_notification) {
    key
    status
  }
}`;

const STUDENT_CHANGE_COURSE_COST = gql`
mutation studentChangeCourseCost($key:String!,$new_course_cost:Int!,$new_discount: String) {
  studentChangeCourseCost(key:$key,new_course_cost:$new_course_cost,new_discount:$new_discount) {
    key
    course_cost
    discount
    paid_ok
  }
}`;


class StudentPayment extends React.Component {

  constructor(props) {
    super(props);
    this.refetch_student = null;
    this.state = {
      new:false,
      enroll:false,
      change:false,
      pay_comment:'',
      pay_date:null,
      pay_amount: null,
      send_email: false,
      discount_comment: null,
      topay_amount: null,
    }
  }

  handleNew() {
    const now = moment().format("YYYY-MM-DD"); //new Date();
    this.setState({new:true, pay_comment:'', pay_amount:null, pay_date: now});
  }
  isValidPay() {
    const {pay_amount, pay_day, pay_comment} = this.state;
    if ((pay_amount !== null) && (pay_day !== null) && (pay_comment !==null) && (pay_comment.length>0)) {
      return true;
    }
    return false;
  }

  isValidChange() {
    return this.state.topay_amount != null;
  }

  handleCancelNew() {
      this.setState({new:false});
  }
  handleDoNew() {
    // console.log('handleDoNew');
    this.props.studentNewPaymentByHand({
      variables: {
          date: this.state.pay_date,
          amount: this.state.pay_amount,
          studentKey:this.props.student_key,
          manualComment: this.state.pay_comment,
      }
    }).then(res=>{
      // console.log("studentNewPaymentByHand res",res);
      this.setState({new:false});

      if (this.state.send_email) {
        this.props.emailSendNotification({
          variables: {
            type: "PAYMENT_INFO",
            student_key:this.props.student_key,
          }
        }).then(ok=>{
        }).catch(err=>{
          alert('chyba odesilani emailu')
        })
      }
     

      if (this.refetch_student) {
        this.refetch_student();
      }
     // alert('ok');
    }).catch((err)=>{
      alert("chyba pridani platby");
      if (this.refetch_student) {
        this.refetch_student();
      }
    })
  }

  handleEnroll() {
    this.setState({enroll:true});
  }
  handleCancelEnroll() {
    this.setState({enroll:false});
  }
  handleDoEnroll() {
    this.props.studentChangeStatus({
      variables: {
          key: this.props.student_key,
          new_status: "IN",
          send_email_notification: this.state.send_email
      }
    }).then(res=>{
      // console.log("studentNewPaymentByHand res",res);
      this.setState({enroll:false});
      if (this.refetch_student) {
        this.refetch_student();
      }
    }).catch(err=>{
      this.setState({enroll:false});
      alert("chyba");
      if (this.refetch_student) {
        this.refetch_student();
      }
    })
  }

  handleChange(st) {
    this.setState({change:true, topay_amount:st.course_cost, discount_comment:st.discount })
  }
  handleCancelChange() {
    this.setState({change:false});
  }
  handleDoChange() {
    this.props.studentChangeCourseCost({
      variables: {
          key: this.props.student_key,
          new_course_cost: this.state.topay_amount,
          new_discount: this.state.discount_comment
      }
    }).then(res=>{
      // console.log("studentChangeCourseCost res",res);
      this.setState({change:false});
      if (this.refetch_student) {
        this.refetch_student();
      }
    }).catch(err=>{
      this.setState({change:false});
      alert("chyba");
      if (this.refetch_student) {
        this.refetch_student();
      }
    })

  }

  renderEnrollDialog() {
    const classes = this.props.classes;
    return (
        <Dialog open={this.state.enroll} onClose={(e)=>this.handleCancelEnroll()}>
            <DialogTitle>Přesun do zapsaných</DialogTitle>
            <DialogContent>

            <form className={classes.form}  noValidate autoComplete="off">  
              <div>

                <BooleanField required width={170} id="emailnotif" label="odeslat info na email" value={this.state.send_email} onChange={(v)=>this.setState({send_email:v})} />

              </div>
            </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>this.handleCancelEnroll()} color="primary">
                    Storno
                </Button>
                <Button onClick={(e)=>this.handleDoEnroll()} color="primary">
                    Zapsat
                </Button>
            </DialogActions>
        </Dialog>
    )
 }


  renderNewDialog() {
    const classes = this.props.classes;
    return (
        <Dialog open={this.state.new} onClose={(e)=>this.handleCancelNew()}>
            <DialogTitle>Nová platba</DialogTitle>
            <DialogContent>

            <form className={classes.form}  noValidate autoComplete="off">  
              <div>

                <DateField required label="Datum" value={this.state.pay_date} onChange={(v)=>this.setState({pay_date:v})}/>
                <NumberField  required width={150} id="pay_amount" label="Částka" value={this.state.pay_amount} onChange={(v)=>this.setState({pay_amount:v})} />
                <StringField required width={300} id="pay_comment" label="Poznámka" value={this.state.pay_comment}  onChange={(v)=>this.setState({pay_comment:v})} />
                <BooleanField required width={170} id="emailnotif" label="odeslat info na email" value={this.state.send_email} onChange={(v)=>this.setState({send_email:v})} />

              </div>
            </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>this.handleCancelNew()} color="primary">
                    Storno
                </Button>
                <Button disabled={!this.isValidPay()} onClick={(e)=>this.handleDoNew()} color="primary">
                    Zaevidovat
                </Button>
            </DialogActions>
        </Dialog>
    )
 }
 
 renderChangeDialog() {
  const classes = this.props.classes;
  return (
      <Dialog open={this.state.change} onClose={(e)=>this.handleCancelChange()}>
          <DialogTitle>Úprava kurzovného</DialogTitle>
          <DialogContent>

          <form className={classes.form}  noValidate autoComplete="off">  
            <div>

              <NumberField  required width={150} id="topay_amount" label="Částka" value={this.state.topay_amount} onChange={(v)=>this.setState({topay_amount:v})} />
              <StringField  width={300} id="discount_comment" label="Sleva" value={this.state.discount_comment}  onChange={(v)=>this.setState({discount_comment:v})} />

            </div>
          </form>

          </DialogContent>
          <DialogActions>
              <Button onClick={(e)=>this.handleCancelChange()} color="primary">
                  Storno
              </Button>
              <Button disabled={!this.isValidChange()} onClick={(e)=>this.handleDoChange()} color="primary">
                  Uložit
              </Button>
          </DialogActions>
      </Dialog>
  )
}


  renderPaymentsTable(payments) {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell> {"Typ"} </TableCell>
            <TableCell> {"Datum"} </TableCell>
            <TableCell> {"Částka"} </TableCell>
            <TableCell> {"Poznámka"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map(e=>{
                    return (
                      <TableRow key={e.id}>
                          <TableCell> <CoursePaymentTypeView value={e.type}/></TableCell>
                          <TableCell> <DateView only_date={true} date={ e.date}/> </TableCell>
                          <TableCell> {e.amount} </TableCell>
                          <TableCell> 
                              {e.manualComment ? e.manualComment: ""}
                          </TableCell>
                      </TableRow>
                    )
                  })}
        </TableBody>
      </Table>
    )
  }

  render() {
    const {student_key} = this.props;
    const dlg = this.renderNewDialog();
    const dlg2 = this.renderEnrollDialog();
    const dlg3 = this.renderChangeDialog();
    return (
      <div>
        {dlg} {dlg2} {dlg3}
        <Query query={GET_STUDENT_WITH_PAYMENTS} variables={{ student_key }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          this.refetch_student = refetch;
          const table = this.renderPaymentsTable(data.student.payments);
          return (
            <React.Fragment>
              <DebugObjectView object={{student:data.student}} />
              <Paper>
                <StudentCardView student={data.student}/>
              </Paper>
            

              <ActionButton tooltip={"Zaevidovat novou platbu"} label={"Nová platba"} onClick={()=>this.handleNew()}/>
              <ActionButton tooltip={"Zařadit mezi zapsané"} label={"Zapsat do kurzu"} onClick={()=>this.handleEnroll()}/>
              <ActionButton tooltip={"Úprava kurzovného"} label={"Upravit kurzovné"} onClick={()=>this.handleChange(data.student)}/>
        
              <Typography variant="title">Přehled evidovaných plateb</Typography>
              <Paper>
               {table}
              </Paper>
              <div>&nbsp;</div>
              <Typography variant="title">staré informace o platbě</Typography>
              <Typography variant="body2">Je-li evidována platba (a poznámka k ní) ještě ve starém režimu, pak je v přehledu evidovaných plateb uvedena jako první platba typu &quot;počáteční&quot; se stejnou částkou a poznámkou. Datum takové &quot;počáteční&quot; platby odpovídá okamžiku přechodu na nový systém evidence plateb a neodpovídá tedy údaji v původní poznámce k platbě. </Typography>
              <Paper>
                <StudentCardView old_pay student={data.student}/>
              </Paper>
              


            </React.Fragment>
          )
        }}
      </Query>
      </div>
    );
  }
}

StudentPayment.propTypes = {  
  classes: PropTypes.object.isRequired,
  student_key:PropTypes.string.isRequired,
  studentNewPaymentByHand: PropTypes.func,
  emailSendNotification: PropTypes.func,
  studentChangeStatus: PropTypes.func,
  studentChangeCourseCost: PropTypes.func,
  debug: PropTypes.bool
};
StudentPayment.defaultProps = {
  debug:false
};



export default compose(
  withStyles(styles),
  graphql(StudentNewPaymentByHand,{
      name:"studentNewPaymentByHand",
  }),

  graphql(EMAIL_SEND_NOTIFICATION,{
    name:"emailSendNotification",
  }),

  graphql(STUDENT_CHANGE_STATUS,{
    name:"studentChangeStatus",
  }),

  graphql(STUDENT_CHANGE_COURSE_COST,{
    name:"studentChangeCourseCost",
  }),

)(StudentPayment)
