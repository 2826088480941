import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CourseView from './CourseView';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import gql from "graphql-tag";
import {  Mutation } from "react-apollo";


const styles = theme => ({
  root: {
    minWidth:800
  }
});


const HIDE_COURSE = gql`
mutation hideCourse($course_key:String!) {
  courseHide(key:$course_key) {
    key
  }
}
`;


class CourseDelDialog extends React.Component {

  render() {
    const {course_key,open,onClose,caption} = this.props;
    return (
      <Dialog disableBackdropClick maxWidth={"md"} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {course_key && (<CourseView course_key={course_key} onClose={onClose} />)}
      </DialogContent>
      <DialogActions>

         <Mutation mutation={HIDE_COURSE} refetchQueries={["Courses"]}> 
         { hideCourse=>( 
            <Button onClick={()=>{
              hideCourse({variables:{course_key}}).then(d=>{
                onClose(true);
              })
            }} color="secondary">
              Smazat
            </Button>
         )}
         </Mutation>

        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>

      </DialogActions>
    </Dialog>
    );
  }
}

CourseDelDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
CourseDelDialog.defaultProps = {
  caption:"Smazání kurzu"
};

export default withStyles(styles)(CourseDelDialog);