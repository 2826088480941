import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import StudentForm from './StudentForm';
//import moment from 'moment';
/*
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

*/



const CREATE_STUDENT = gql`
mutation createStudent($course_key:String!, $status: StudentStatus!, $addressing: String!, $name: String,$surname: String, 
  $student_check: Boolean, $student: Boolean, $email: String, $email2: String, $phone: String, $year: Int, $school: String,  $school_class: String,
  $street: String, $street_no: String, $city: String,$post_code: String, $comment: String, $paid_ok: Boolean, $long_period: Boolean, $course_cost: Int, $paid: Int,  $pay_info: String, $discount: String, $card_out: Boolean
) {
  studentCreate(course_key:$course_key,status:$status,addressing: $addressing, name: $name,surname: $surname,
    student_check: $student_check, student: $student, email: $email,  email2: $email2, phone: $phone, year: $year, school: $school,  school_class: $school_class,
    street: $street, street_no: $street_no, city: $city,post_code: $post_code, comment: $comment, paid_ok: $paid_ok, long_period: $long_period, course_cost: $course_cost, paid: $paid,  pay_info: $pay_info, discount: $discount, card_out: $card_out
  ) {
    key
    course_key
    status
    addressing
    sex
    ref_gid
    card_id
    reg_datetime
    name
    surname
    email
    email2
    phone
    year
    school
    school_class
    street
    street_no
    city
    post_code
    comment
    course_cost
    paid
    paid_ok
    long_period
    student_check
    student
    card_out
    var_sym
    pay_info
    discount
  }
}
`;

class StudentAdd extends React.Component {


  constructor(props) {
    super(props);
    this.state = {  
      student: {
        course_key:props.course_key,
        status:props.status,
        addressing: null,
        name: null,
        surname: null,
        student_check: null,
        student: null,
        email: null,
        email2: null,
        phone: null,
        year: null,
        school: null,
        school_class: null,
        street: null,
        street_no: null,
        city: null,
        post_code: null,
        comment: null,
        paid_ok: null,
        long_period: null,
        course_cost: null,
        paid: null,
        pay_info: null,
        discount: null,
        card_out: null,
      },
      valid:false
    }
    this.form = React.createRef();
  }

 

  doSave() {
    console.log("StudentAdd doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:CREATE_STUDENT,
      variables:this.form.current.state.doc,
      refetchQueries: ["CourseWithStats","Students"]
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
    //console.log("this.form.current",this.form.current);
  }


  onDocChange(d,valid) {
    console.log("StudentAdd onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 


  render() {
    const {status} = this.props;
    return (
          <StudentForm innerRef={this.form} mode={status} withPayment={true} onDocChange={(d,v)=>this.onDocChange(d,v)} doc={this.state.student} />
    );
  }
}

StudentAdd.propTypes = {
  client: PropTypes.object.isRequired,
  course_key: PropTypes.string.isRequired,
  status: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};
StudentAdd.defaultProps = {
  
};

export default withApollo(StudentAdd,{withRef:true});
