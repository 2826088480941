



const  CoursePaymentType_schema_enum = ['INITIAL','BY_HAND','AUTO'];
const  CoursePaymentType_gql_values =  { 
    INITIAL:{value:"INITIAL"},
    BY_HAND:{value:"BY_HAND"},
    AUTO:{value:"AUTO"},
};

const CoursePaymentType_field_enum = [
    {key:"INITIAL",label:"počáteční"},
    {key:"BY_HAND",label:"ručně"},
    {key:"AUTO",label:"automaticky"},
];
const CoursePaymentType_props_enum = ['INITIAL','BY_HAND','AUTO'];


module.exports = {
    CoursePaymentType_schema_enum,
    CoursePaymentType_gql_values,
    CoursePaymentType_field_enum,
    CoursePaymentType_props_enum
};