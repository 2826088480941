import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanField from './BooleanField';
import StringField  from './StringField';
import NumberField  from './NumberField';
import EnumField from './EnumField';
import Lodash from 'lodash';
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});




class CourseForm extends React.Component {


  constructor(props) {
    super(props);
    console.log("constructor",props.doc)
    this.state = {  
      doc: Object.assign({},props.doc),
      doc_err: this.getDocErr(props.doc),
    }
  }

  setDoc(doc) {
    this.setState({
      doc: Object.assign({},doc),
      doc_err: this.getDocErr(doc),
    })
  }
  

  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err
    });
    this.props.onDocChange(doc,this.checkDoc(doc));
  }

  checkDocField(name,value) {
    if (["code","name","evi_group","active","capacity","group_mode","cost_mode","cost_a","cost_b","cost_sa","cost_sb","cost_sale","accounting_year","var_sym_prefix"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  renderForm() {
    const { classes, lock_evi_group } = this.props;
    const { doc, doc_err } = this.state;
    //const doc_ok=this.checkDoc(doc);
    return (
        <form className={classes.form}  noValidate autoComplete="off">  
          <div>
         
         
          <EnumField disabled={lock_evi_group} required width={200} id="evi_group" label="Evidence" values={[{key:"VB",label:"VB"},{key:"LB",label:"LB"},{key:"SRO",label:"sro"},{key:"ST", label:"Stadion"},{key:"AB",label:"AB"},{key:"ZS",label:"Spolek"},{key:"NO",label:"nezařazeno"}]} value={doc.evi_group} error={doc_err.evi_group} onChange={(v)=>this.handleDocChange("evi_group",v)} />
          
         
          <NumberField required width={150} id="accounting_year" label="Účetní rok" value={doc.accounting_year} error={doc_err.accounting_year} onChange={(v)=>this.handleDocChange("accounting_year",v)} />
          <NumberField required width={150} id="var_sym_prefix" label="Třetí číslo v.s." value={doc.var_sym_prefix} error={doc_err.var_sym_prefix} onChange={(v)=>this.handleDocChange("var_sym_prefix",v)} />
          <BooleanField required width={100} id="active" label="online zápis" value={doc.active} error={doc_err.active} onChange={(v)=>this.handleDocChange("active",v)} />
          <StringField required width={50} id="code" label="Kód" value={doc.code} error={doc_err.code} onChange={(v)=>this.handleDocChange("code",v)} />
          <StringField required width={300} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
          <NumberField required id="capacity" label="Kapacita" value={doc.capacity} error={doc_err.capacity} onChange={(v)=>this.handleDocChange("capacity",v)} />
          </div>
          <div>
          <EnumField required width={200} id="group_mode" label="Řazení" values={[{key:"SCHOOL",label:"po školách"},{key:"PAIR",label:"po párech"},{key:"SINGLE",label:"po jménech"}]} value={doc.group_mode} error={doc_err.group_mode} onChange={(v)=>this.handleDocChange("group_mode",v)} />
          <StringField id="period" label="Termín" value={doc.period} error={doc_err.period} onChange={(v)=>this.handleDocChange("period",v)} />
          <StringField id="first_period" label="Termín zahájení" value={doc.first_period} error={doc_err.first_period} onChange={(v)=>this.handleDocChange("first_period",v)} />
          <StringField id="teacher" label="Lektor" value={doc.teacher} error={doc_err.teacher} onChange={(v)=>this.handleDocChange("teacher",v)} />
          <StringField id="place" label="Místo" value={doc.place} error={doc_err.place} onChange={(v)=>this.handleDocChange("place",v)} />
          </div>
          <div>
          <EnumField required width={200} id="cost_mode" label="Typ ceny" values={[{key:"NORMAL",label:"student/dospělý"},{key:"PERIOD",label:"polo/celo-roční"},{key:"FIX",label:"pevná"}]} value={doc.cost_mode} error={doc_err.cost_mode} onChange={(v)=>this.handleDocChange("cost_mode",v)} />
          <BooleanField required width={100} id="cost_sale" label="Aktivní sleva" value={doc.cost_sale} error={doc_err.cost_sale} onChange={(v)=>this.handleDocChange("cost_sale",v)} />
          <NumberField required id="cost_a" label="Cena A" value={doc.cost_a} error={doc_err.cost_a} onChange={(v)=>this.handleDocChange("cost_a",v)} />
          <NumberField required id="cost_b" label="Cena B" value={doc.cost_b} error={doc_err.cost_b} onChange={(v)=>this.handleDocChange("cost_b",v)} />
          <NumberField required id="cost_sa" label="Cena A sleva" value={doc.cost_sa} error={doc_err.cost_sa} onChange={(v)=>this.handleDocChange("cost_sa",v)} />
          <NumberField required id="cost_sb" label="Cena B sleva" value={doc.cost_sb} error={doc_err.cost_sb} onChange={(v)=>this.handleDocChange("cost_sb",v)} />
          </div>
          <div>
          <StringField width={300} id="card_line_1" label="text karty 1" value={doc.card_line_1} error={doc_err.card_line_1} onChange={(v)=>this.handleDocChange("card_line_1",v)} />
          <StringField width={300} id="card_line_2" label="text karty 2" value={doc.card_line_2} error={doc_err.card_line_2} onChange={(v)=>this.handleDocChange("card_line_2",v)} />
          <StringField width={300} id="comment" label="poznámka" value={doc.comment} error={doc_err.comment} onChange={(v)=>this.handleDocChange("comment",v)} />
          </div>

          {/*
          <Button disabled={!doc_ok} variant="raised" color="primary" className={classes.button} onClick={(e)=>this.props.onSave(this.state.doc)}> Uložit </Button>
          <Button variant="raised" className={classes.button} onClick={(e)=>this.props.onCancel()}> Neukládat </Button>
          */}
        </form>
    )
  }

  render() {
    const form = this.renderForm();
    return (
      <div className="foo">
        {form}
      </div>
    );
  }
}

CourseForm.propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  //onSave: PropTypes.func.isRequired,
  //onCancel: PropTypes.func.isRequired,
  onDocChange: PropTypes.func.isRequired,
  lock_evi_group:PropTypes.bool
};
CourseForm.defaultProps = {
  lock_evi_group:false
};

export default withStyles(styles)(CourseForm);