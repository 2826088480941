import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanField from './BooleanField';
import StringField  from './StringField';
import NumberField  from './NumberField';
//import EnumField from './EnumField';
import BooleanViewField from './BooleanViewField';
import StringViewField  from './StringViewField';
import DateViewField  from './DateViewField';
import NumberViewField  from './NumberViewField';
import EnumViewField from './EnumViewField';

import CircularProgress from '@material-ui/core/CircularProgress';

import Lodash from 'lodash';
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});




class StudentPayForm extends React.Component {


  constructor(props) {
    super(props);
    console.log("constructor",props.doc)
    this.state = {  
      doc: Object.assign({},props.doc),
      doc_err: this.getDocErr(props.doc),
      send_email:false,
      do_enroll:false,
    }
  }

  

  handleChangeSendEmail(v) {
    this.setState({send_email:v})
  }

  handleChangeDoEnroll(v) {
    this.setState({do_enroll:v})
  }

  setDoc(doc) {
    this.setState({
      doc: Object.assign({},doc),
      doc_err: this.getDocErr(doc),
    })
  }

  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err
    });
    this.props.onDocChange(doc,this.checkDoc(doc));
  }

  checkDocField(name,value) {
    if (["course_cost"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }
  
  
  renderForm() {
    const { classes ,wait} = this.props;
    const { doc, doc_err } = this.state;
    //const doc_ok=this.checkDoc(doc);
    return (
        <form className={classes.form}  noValidate autoComplete="off">  
          <div>
          <EnumViewField  width={100}  label="Oslovení" values={[{key:"s",label:"slečna"},{key:"d",label:"paní"},{key:"p",label:"pan"}]} value={doc.addressing}  />
          <StringViewField  label="Přijmení" value={doc.surname}  />
          <StringViewField  label="Jméno" value={doc.name}  />
          <StringViewField   width={300} label="Email" value={doc.email}  />
          <StringViewField   width={300} label="Email2" value={doc.email2}  />
          <StringViewField  label="Telefon" value={doc.phone}  />
          <NumberViewField  width={100}  label="Ročník" value={doc.year}  />
          </div>
          <div>
          <DateViewField  width={200} label="Datum přihlášení" value={doc.reg_datetime}  />
          <NumberViewField  width={150}  label="Číslo karty" value={doc.ref_gid}  />
          <StringViewField  width={150}  label="Kód karty" value={doc.card_id}  />
          <BooleanViewField  width={100}  label="Student" value={doc.student} />
          <BooleanViewField  width={150}  label="Student ověřen" value={doc.student_check} />
          <BooleanViewField  width={100}  label="Karta vydána" value={doc.card_out} />
          </div>
         
          <div>
            <NumberField required width={100} id="course_cost" label="Kurzovné" value={doc.course_cost} error={doc_err.course_cost} onChange={(v)=>this.handleDocChange("course_cost",v)} />
            <BooleanField id="long_period" label="Celo-roční" value={doc.long_period} error={doc_err.long_period} onChange={(v)=>this.handleDocChange("long_period",v)} />
            <NumberField width={100} id="paid" label="Zaplaceno" value={doc.paid} error={doc_err.paid} onChange={(v)=>this.handleDocChange("paid",v)} />
            <StringField width={400} id="pay_info" label="Pl. info" value={doc.pay_info} error={doc_err.pay_info} onChange={(v)=>this.handleDocChange("pay_info",v)} />
            <StringField  id="discount" label="Sleva" value={doc.discount} error={doc_err.discount} onChange={(v)=>this.handleDocChange("discount",v)} />
            <BooleanField width={150} id="paid_ok" label="Zaplaceno vše" value={doc.paid_ok} error={doc_err.paid_ok} onChange={(v)=>this.handleDocChange("paid_ok",v)} />
          </div>
          <div>
          <BooleanField required width={170} id="emailnotif" label="odeslat info na email" value={this.state.send_email} onChange={(v)=>this.handleChangeSendEmail(v)} />
          <BooleanField required width={170} id="doenroll" label="zapsat do kurzu" value={this.state.do_enroll} onChange={(v)=>this.handleChangeDoEnroll(v)} />
          {wait && (<CircularProgress/>)}
          </div>
        </form>
    )
  }

  render() {
    const form = this.renderForm();
    return (
      <div className="foo">
        {form}
      </div>
    );
  }
}

StudentPayForm.propTypes = {
  classes: PropTypes.object.isRequired,
  wait: PropTypes.bool,
  doc: PropTypes.object.isRequired,
 // onSave: PropTypes.func.isRequired,
 // onCancel: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  onDocChange: PropTypes.func.isRequired,
};
StudentPayForm.defaultProps = {
  
};

export default withStyles(styles)(StudentPayForm);