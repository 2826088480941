
const p_opts = (state={debug_view:false}, action) => {
    console.log("ACTION",action)
    switch (action.type) {
      case 'SET_DEBUG_VIEW':
        return  Object.assign({}, state, {
          debug_view: action.visible
        })
      default:
        return state
    }
  }
  
  export default p_opts;