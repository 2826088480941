import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EventImage from './EventImage';
import DebugObjectView from './Utils/DebugObjectView';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Paper from '@material-ui/core/Paper';
import DateView from './DateView';
import Button from '@material-ui/core/Button';
//import EventCapacityView from './EventCapacityView';
import EventStatusView from './Fields/EventStatusView';

const styles = theme => ({
  root: {
    flexGrow: 1,
    //maxWidth: 600,
    padding: theme.spacing.unit * 2,
  },
  main_grid: {
    maxWidth: 800,
  },
  button: {
    margin: theme.spacing.unit,
  }
});



class EventStripAdmin extends React.Component {

  
  render() {
    const { classes,event,show_debug_info,auth} = this.props;

    return (
      <React.Fragment>
        {show_debug_info && (<DebugObjectView name="event strip" object={event} />)}

        <Grid container justify="center"
  alignItems="center" className={classes.root}>
          <Grid item container spacing={16} className={classes.main_grid}>
            <Grid item>
              <EventImage show_debug_info={show_debug_info} variant="normal" image={event.image} />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item container direction="column" spacing={16} justify="center"
    alignItems="stretch">
                <Grid item >
                  <Typography variant="headline" gutterBottom>{event.name}</Typography>
                  <Typography variant="headline"><DateView date={event.date} long/></Typography>
                </Grid>
                {event.place && (
                  <Grid item >
                    <Typography variant="title" gutterBottom>{event.place.long_name}</Typography>
                    <Typography variant="subheading" color="textSecondary">{event.place.address}</Typography>
                  </Grid>
                )}
                
                  <Grid item container justify="flex-start" alignItems="center" >
                      <Grid item>
                        <Typography variant="headline">Stav akce: <EventStatusView event_status={event.status} /></Typography>
                      </Grid>
                      {auth.is_admin && (
                        <Grid>
                        {event.status == "NEW" && (
                          <React.Fragment>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("READY")}>
                            akce je připravena prodej
                          </Button>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("CLOSED")}>
                            uzavřít akci
                          </Button>
                          </React.Fragment>
                        )}  
                        {event.status == "READY" && (
                          <React.Fragment>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("OPEN")}>
                            povolit online prodej
                          </Button>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("CLOSED")}>
                            uzavřít akci
                          </Button>
                          </React.Fragment>
                        )}  

                        {event.status == "OPEN" && (
                          <React.Fragment>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("SUSPENDED")}>
                            ukončit online prodej
                          </Button>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("CLOSED")}>
                            uzavřít akci
                          </Button>
                          </React.Fragment>
                        )}  

                        {event.status == "SUSPENDED" && (
                          <React.Fragment>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("OPEN")}>
                            povolit online prodej
                          </Button>
                          <Button variant="contained" className={classes.button} onClick={()=>this.props.onChangeStatus("CLOSED")}>
                            uzavřít akci
                          </Button>
                          </React.Fragment>
                        )}  
                        
                        </Grid>
                      )}
                     
                  </Grid>
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

EventStripAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  show_debug_info: PropTypes.bool,
  onChangeStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
EventStripAdmin.defaultProps = {
  show_debug_info:false,
};


export default withStyles(styles)(EventStripAdmin);