import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
//import DateView from './DateView';

const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  tab_item: {
    minWidth: 40
  }
});


class ConfigHeaderTabs extends React.Component {

 


  
  render() {
    const { classes,activeTab} = this.props;
    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                Nastavení
            </Typography>
        
            <Tabs scrollable className={classes.toolBar_item} value={activeTab}>
              <Tab className={classes.tab_item} value="info" label="info" component={Link} to={"/spa/config/info"}/>
              <Tab className={classes.tab_item} value="schools" label="školy" component={Link} to={"/spa/config/schools"}/>
              <Tab className={classes.tab_item} value="places" label="místa" component={Link} to={"/spa/config/places"}/>
              <Tab className={classes.tab_item} value="zones" label="zóny" component={Link} to={"/spa/config/zones"}/>
              <Tab className={classes.tab_item} value="seats" label="sezení" component={Link} to={"/spa/config/seats"}/>
              <Tab className={classes.tab_item}value="priceplans" label="cenové plány" component={Link} to={"/spa/config/priceplans"}/>
              <Tab className={classes.tab_item} value="zoneprices" label="ceny zón" component={Link} to={"/spa/config/zoneprices"}/>
              <Tab className={classes.tab_item} value="images" label="grafika" component={Link} to={"/spa/config/images"}/>
              <Tab className={classes.tab_item} value="folders" label="složky" component={Link} to={"/spa/config/folders"}/>
              <Tab className={classes.tab_item} value="seasons" label="sezóny" component={Link} to={"/spa/config/seasons"}/>
              <Tab className={classes.tab_item} value="cgroups" label="skupiny" component={Link} to={"/spa/config/cgroups"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

ConfigHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  activeTab: PropTypes.oneOf(["info","schools","folders","seasons","cgroups","images","places","zones","seats","priceplans","zoneprices"]).isRequired,
};
ConfigHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(ConfigHeaderTabs);