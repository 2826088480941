import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import DebugObjectView from './Utils/DebugObjectView';

import SimpleDataTable from './Tables/SimpleDataTable';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import {GET_SEATS } from './Fields/Queries';
import ActionButton from './ActionButton';
//import ActionLink from './ActionLink';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import SeatTypeView from './Fields/SeatTypeView';
import Typography from '@material-ui/core/Typography';



const CREATE_EVENT_SEAT = gql`
mutation eventSeatCreate($event_id:ID!,$seat_id:ID!,$event_zone_id:ID!) {
  eventSeatCreate(seat_id:$seat_id,event_id:$event_id,event_zone_id:$event_zone_id) {
    id
  }
}
`;
const DELETE_EVENT_SEAT = gql`
mutation eventSeatDelete($event_seat_id:ID!) {
  eventSeatDelete(id:$event_seat_id) {
    id
  }
}
`;



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  sep: {
    marginTop: theme.spacing.unit*3
  }
});


class SetupEventZoneTabSeats extends React.Component {

  constructor(props) {
    super(props);
    this.seat_table = React.createRef();
  }

  filterout_used_seats(seats) {
    const { event_zone } = this.props;
    const used_seat_ids = event_zone.seats.map(z=>z.seat_id);
    const unused = seats.filter(z=>{
        const un = (used_seat_ids.indexOf(z.id)==-1)
        return un;
      });
    return unused;
  }

  reload() {
    this.props.refetch();
    if (this.seat_table.current) {
      this.seat_table.current.refetch();
    }
  }

  addSeat(seat) {
    const { event,event_zone } = this.props;
    this.props.createEventSeat({
      variables: {
        event_id:event.id,
        event_zone_id:event_zone.id,
        seat_id:seat.id
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }

  removeSeat(event_seat) {
    //const { event } = this.props;
    this.props.deleteEventSeat({
      variables: {
        event_seat_id:event_seat.id,
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }


  

  render() {
    const { event,event_zone,auth,classes} = this.props;
    const editable = (event.status == "NEW" || event.test_mode);


    return (
      <React.Fragment>
         
          <Typography variant="title">Seznam sezení, které jsou nastavené pro zónu</Typography>
          {!editable &&(
            <Typography variant="subheading">nastavené sezení již nelze odebrat, protoze akce se již prodává nebo prodávala</Typography>
          )}
          <DebugObjectView name="event" object={event} />
          <DebugObjectView name="event_zone" object={event_zone} />

         
          <SimpleDataTable data={event_zone.seats} 
            renderHeader={()=>(
              <React.Fragment>
                <TableCell debug_only>id</TableCell>
                <TableCell debug_only>seat_id</TableCell>
                <TableCell>název</TableCell>
                <TableCell>typ</TableCell>
                <TableCell>počet míst</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell debug_only>{item.id}</TableCell>
                <TableCell debug_only>{item.seat_id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell><SeatTypeView seat_type={item.type} /></TableCell>
                <TableCell>{item.capacity}</TableCell>
                <TableCell>
                  {auth.is_admin && editable && (<ActionButton tooltip={"odbrat ze zóny"} icon={'delete'} onClick={()=>this.removeSeat(item)}/>)}
                  {/*
                  <ActionLink useRouter tooltip={"nastavit sezení"} icon={'edit'} href={'/spa/setup_event_seat/'+event.id+'/'+item.id+"/info"}/>
                  */}
                </TableCell>
             
              </React.Fragment>
            )}
          />

      
          <Typography className={classes.sep} variant="title">Seznam sezení, které je možné do zóny přidat</Typography>
   
          <SimpleGQLTable  innerRef={this.seat_table}
            query={GET_SEATS} variables={{zone_id:event_zone.zone_id}} 
            getData={(r)=>{return this.filterout_used_seats(r.seats)}}
            renderHeader={()=>(
              <React.Fragment>
                <TableCell debug_only>id</TableCell>
                <TableCell>název</TableCell>
                <TableCell>typ</TableCell>
                <TableCell>počet míst</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell debug_only>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell><SeatTypeView seat_type={item.type} /></TableCell>
                <TableCell>{item.capacity}</TableCell>
                 <TableCell>
                  {auth.is_admin && (<ActionButton tooltip={"přidat do zony"} icon={'add'} onClick={()=>this.addSeat(item)}/>)}
                </TableCell>
              </React.Fragment>
            )}
            renderEmptyInfo={()=>(<div> žádne sezení k přidání (jsou přidané všechny, nebo není žádná pro lokalitu nastavená) </div>)}
          />


      </React.Fragment>
    );
  }
}

SetupEventZoneTabSeats.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  event_zone: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  createEventSeat: PropTypes.func.isRequired,
  deleteEventSeat: PropTypes.func.isRequired,

};
SetupEventZoneTabSeats.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CREATE_EVENT_SEAT,{
    name: "createEventSeat",
  }),
  graphql(DELETE_EVENT_SEAT,{
    name: "deleteEventSeat",
  }),
)(SetupEventZoneTabSeats)
