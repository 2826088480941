import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  }
});


class EmailsHeaderTabs extends React.Component {

 


  
  render() {
    const { classes,activeTab} = this.props;

    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                Pošta
            </Typography>
          
            <Tabs className={classes.toolBar_item} value={activeTab}>
              <Tab value="in" label="Přijaté emaily" component={Link} to={"/spa/emails/in"}/>
              <Tab value="templates" label="Šablony emailů" component={Link} to={"/spa/emails/templates"}/>
              <Tab value="bulk" label="Hromadná pošta" component={Link} to={"/spa/emails/bulk"}/>
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

EmailsHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  activeTab: PropTypes.oneOf(["in","templates","bulk"]).isRequired,
};
EmailsHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(EmailsHeaderTabs);