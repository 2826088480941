import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CourseHeaderTabs from './CourseHeaderTabs';

import CourseViewForm from './CourseViewForm';
import CourseStatsTable from './CourseStatsTable';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import StudentsTable from './StudentsTable';
import StudentsActivityTable from './StudentsActivityTable'; 
import Lodash from 'lodash';
import { connect } from 'react-redux'
import { compose } from 'react-apollo'
import CourseCash from './CourseCash';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});


const GET_COURSE = gql`
  query CourseWithStats($course_key:String!){   
    students(course_key:$course_key) {
      key
      status
      addressing
      sex
      ref_gid
      card_id
      reg_datetime
      ref_key
      partner_ref_key
      name
      surname
      email
      phone
      year
      school
      school_class
      street
      street_no
      city
      post_code
      comment
      course_cost
      paid
      paid_amount
      paid_ok
      long_period
      student_check
      student
      card_out
      var_sym
      pay_info
      discount
    }
   
      course(key: $course_key) {
        key
        evi_group
        folder_key
        season_key
        name
        code
        active
        suspend
        cost_sa
        cost_sb
        cost_a
        cost_b
        cost_mode
        cost_sale
        accounting_year
        group_mode
        capacity
        var_sym_prefix
        card_line_1
        card_line_2
        period
        first_period
        teacher
        place
        comment  
        stats {
          in {
            ...fs
          }
          filed {
            ...fs
          }

          spare {
            ...fs
          }
          kicked {
            ...fs
          }
          total {
            ...fs
          }

        }  
      }
  }
  fragment fs on CourseStatsGenRecord {
    male {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    female {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    total {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
  }
`;


class CourseBoard extends React.Component {

 
  renderInfoTab(data) {
    return (
      <React.Fragment>
              <CourseStatsTable stats={data.course.stats} />
              <CourseViewForm doc={data.course} />
      </React.Fragment>
    )
  }
  renderINTab(data,refetch) {
    const {auth,course_key,evi_group} = this.props;
    const filter_status="IN";
    const sts = Lodash.filter(data.students,{status:filter_status});
    return (
      <React.Fragment>
        <StudentsTable evi_group={evi_group} refetch={refetch} auth={auth} filter_status={filter_status} students={sts} group_mode={data.course.group_mode} course_key={course_key} folder_key={data.course.folder_key} season_key={data.course.season_key}/>
      </React.Fragment>
    )
  }

  renderFILEDTab(data,refetch) {
    const {auth,course_key,evi_group} = this.props;
    const filter_status="FILED";
    const sts = Lodash.filter(data.students,{status:filter_status});
    return (
      <React.Fragment>
        <StudentsTable evi_group={evi_group} refetch={refetch} auth={auth} filter_status={filter_status} students={sts} group_mode={data.course.group_mode} course_key={course_key} folder_key={data.course.folder_key} season_key={data.course.season_key}/>
      </React.Fragment>
    )
  }

  renderSPARETab(data,refetch) {
    const {auth,course_key,evi_group} = this.props;
    const filter_status="SPARE";
    const sts = Lodash.filter(data.students,{status:filter_status});
    return (
      <React.Fragment>
        <StudentsTable evi_group={evi_group} refetch={refetch} auth={auth} filter_status={filter_status} students={sts} group_mode={data.course.group_mode} course_key={course_key} folder_key={data.course.folder_key} season_key={data.course.season_key}/>
      </React.Fragment>
    )
  }

  renderKICKEDTab(data,refetch) {
    const {auth,course_key,evi_group} = this.props;
    const filter_status="KICKED";
    const sts = Lodash.filter(data.students,{status:filter_status});
    return (
      <React.Fragment>
        <StudentsTable evi_group={evi_group} refetch={refetch} auth={auth} filter_status={filter_status} students={sts} group_mode={data.course.group_mode} course_key={course_key} folder_key={data.course.folder_key} season_key={data.course.season_key}/>
      </React.Fragment>
    )
  }

  renderATTENDTab(data,refetch) {
    const {course_key} = this.props;
    return (
      <React.Fragment>
        <StudentsActivityTable course_id={course_key} />
      </React.Fragment>
    )
  }

  renderCashTab() {
    const {course_key} = this.props;
    return (
      <React.Fragment>
         <CourseCash course_key={course_key}/>
      </React.Fragment>
    )
  }


  render() {
    const { classes , evi_group, course_key,auth,mode} = this.props;
    var activeTab=null;
    switch(mode) {
      case 'info': activeTab="INFO"; break;
      case 'in': activeTab="IN"; break;
      case 'filed': activeTab="FILED"; break;
      case 'spare': activeTab="SPARE"; break;
      case 'kicked': activeTab="KICKED"; break;
      case 'attendance': activeTab="ATTENDANCE"; break;
      case 'cash': activeTab="CASH"; break;
      default:
        activeTab="INFO";
    }
    console.log("course board render");
    return (
      <div>
         <Query query={GET_COURSE} variables={{ course_key }} fetchPolicy={"network-only"}>
        {({ loading, error, data, refetch }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          var tab = null;
          switch(activeTab) {
            case 'INFO': tab = this.renderInfoTab(data,refetch); break;  //TODO add refetch
            case 'CASH': tab = this.renderCashTab(); break;
            case 'IN': tab  = this.renderINTab(data,refetch); break;
            case 'FILED': tab  = this.renderFILEDTab(data,refetch); break;
            case 'SPARE': tab  = this.renderSPARETab(data,refetch); break;
            case 'KICKED': tab  = this.renderKICKEDTab(data,refetch); break;
            case 'ATTENDANCE': tab  = this.renderATTENDTab(data,refetch); break;
            default: break;
          }
          return (
            <React.Fragment>
              <CourseHeaderTabs  evi_group={evi_group} activeTab={activeTab} auth={auth} course={data.course} />
              <div className={classes.tab_root}>{tab}</div>
            </React.Fragment>
          )
        }}
        </Query>
        </div>
    );
  }
}

CourseBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string.isRequired,
  evi_group: PropTypes.string,
  auth: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(["info","in","spare","filed","kicked","attendance","cash"]).isRequired
};
CourseBoard.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      evi_group: state.select.evi_group,
  }
}



export default compose(
  withStyles(styles),
  connect(mapStateToProps,{})
)(CourseBoard)