import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'react-apollo'
import StudentPayment from './StudentPayment';

//import { connect } from 'react-redux'
//import StudentDebug from './StudentDebug';

const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  cont: {
    padding: theme.spacing.unit*3,
  }
});


class StudentPaymentBoard extends React.Component {

  
  render() {
    const { classes } = this.props;
    return (
      <div>
         <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
          <Typography className={classes.toolBar_item} variant="title" color="inherit">
            Platby žáka
          </Typography>
        </Toolbar>
        <div className={classes.cont}>
          <StudentPayment student_key={this.props.student_key}/>
        </div>
      </div>
    );
  }
}

StudentPaymentBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  student_key: PropTypes.string.isRequired,
};
StudentPaymentBoard.defaultProps = {
  
};


export default compose(
  withStyles(styles),
 
)(StudentPaymentBoard)