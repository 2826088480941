


const  SeatType_schema_enum = ['chair','line_h','line_v','box','circle','free'];
const  SeatType_gql_values =  { 
    CHAIR:{value:"chair"},
    LINE_H:{value:"line_h"},
    LINE_V:{value:"line_v"},
    BOX:{value:"box"},
    CIRCLE:{value:"circle"},
    FREE:{value:"free"},
};

const SeatType_field_enum = [
    {key:"CHAIR",label:"židle"},
    {key:"LINE_H",label:"řada h"},
    {key:"LINE_V",label:"řada v"},
    {key:"BOX",label:"hranatý stůl"},
    {key:"CIRCLE",label:"kulatý stůl"},
    {key:"FREE",label:"bez sezení"},
];
const SeatType_props_enum = ['CHAIR','LINE_H','LINE_V','BOX','CIRCLE','FREE'];


//export  {
//    SeatType_schema_enum,
//    SeatType_gql_values
//};

module.exports = {
    SeatType_schema_enum,
    SeatType_gql_values,
    SeatType_field_enum,
    SeatType_props_enum
};