import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TicketIcon from '@material-ui/icons/Receipt';
//import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
//import IconButton from '@material-ui/core/IconButton';
import Lodash from "lodash";
//import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'
import ShopIcon from '@material-ui/icons/ShoppingCart';
//import HelpIcon from '@material-ui/icons/HelpOutline';
import Divider from '@material-ui/core/Divider'
//import Moment from 'moment';
//import DateView from './DateView';
//import Tooltip from '@material-ui/core/Tooltip';

import EventTicketStatusView from './Fields/EventTicketStatusView';

import {getTicketPlaceText,getTicketPrimaryText} from "../libs/Tickets";

const styles = theme => ({
  toolbar: {
    //border: "1px solid green",
  },
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
  },
  grow: {
    flexGrow: 1,
  },
  button: {

  },
  res_info: {
    marginLeft: theme.spacing.unit*3,
    //border: "1px solid green",
  },
  shopicon: {
    marginRight: 20
  },
  helpicon: {
    marginLeft: theme.spacing.unit,
  },
  leftm: {
    marginLeft: theme.spacing.unit,
  }
});






class SaleEventShoppingCartAdminViewList extends React.Component {


  render() {
    const { classes,list,dense, event_seats,event_zones,} = this.props;
    const total_cost = Lodash.reduce(list,(s,t)=>{return s+t.cost},0);
    /*
    const last_ticket_m = Lodash.reduce(list,(m,t)=>{
      const um = Moment(t.updated_at).add(1,"h");
      if (m==null) {
        return um;
      } else {
        return um.isAfter(m)?um:m; 
      }
    },null);
    */
    return (
      <div className={classes.root}>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <ShopIcon  className={classes.shopicon} />
          <Typography variant="title" color="inherit"> Vstupenky v objednávce </Typography>
        </Toolbar>
        <Divider/>
        <div className={classes.list}>
        <List dense={dense}>
          {list.map((t,idx)=>{
            return (
              <ListItem key={idx}>
                <ListItemAvatar>
                  <Avatar>
                    <TicketIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={getTicketPrimaryText(t,event_zones)}
                  secondary={getTicketPlaceText(t,event_seats)}
                />
                <EventTicketStatusView event_ticket_status={t.status}/>
              </ListItem>
            )
          })}
        </List>
        </div>
        <Divider/>
        
          <Typography variant={"body2"} className={classes.res_info}> &nbsp; </Typography>
       
        <Divider/>
        <Toolbar variant={"dense"}  className={classes.toolbar}> 
            <Typography className={classes.grow} variant="title" color="inherit"> Cena vstupenek: {total_cost}kč </Typography>
        </Toolbar>
      </div>
    );
  }
}

SaleEventShoppingCartAdminViewList.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  event_seats: PropTypes.array.isRequired,
  event_zones: PropTypes.array.isRequired,
  dense: PropTypes.bool,
};
SaleEventShoppingCartAdminViewList.defaultProps = {
  dense:false,
};


export default withStyles(styles)(SaleEventShoppingCartAdminViewList);