
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import EventPlanView from './EventPlanView';

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
  },
  label:{ 
    paddingTop: theme.spacing.unit * 2
  }
});






class EventZoneMap extends React.Component {
  
  render() {
    const { classes,list,label,event_place } = this.props;
    const title = label?(<React.Fragment ><Typography className={classes.label} variant={"body2"}>{label}</Typography><Divider/></React.Fragment>):null;
    return (
      <div className={classes.root}>
        {title}
        <EventPlanView 
          place={event_place} 
          zones={list}
          //seats={this.state.zone?event.seats:[]}
          //tickets={event.tickets}
          //zoomZone={this.state.zone}
          //onZoneClick={(zone)=>this.selectZone(zone)}
      />
      </div>
    );
  }
}

EventZoneMap.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  label: PropTypes.string,
  event_place: PropTypes.object.isRequired,
  dense: PropTypes.bool,
};
EventZoneMap.defaultProps = {
  dense:false
};


export default withStyles(styles)(EventZoneMap);