import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Lodash from "lodash";
import {getTicketZonePlaceText} from "../../libs/Tickets";

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class EventTicketPlaceView extends React.PureComponent {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,event_ticket,event_seats,event_zones } = this.props;
    const place = getTicketZonePlaceText(event_ticket,event_zones,event_seats);
    /*
    const zone = Lodash.find(event_zones,{id:event_ticket.event_zone_id});
    const seat = Lodash.find(event_seats,{id:event_ticket.event_seat_id});

    let place = "?";
    if (zone && seat) {
      place = zone.name+", ";

      if (seat.type == "LINE_H") {
        place += seat.name+ " místo "+(event_ticket.event_seat_idx+seat.col_label_base);
      } else if (seat.type == "LINE_V") {
        place += seat.name+ " místo "+(event_ticket.event_seat_idx+seat.row_label_base);
      } else {
        place += seat.name+"/"+(event_ticket.event_seat_idx+1);
      }
      
    }
    */
    return ( 
      <span className={classes.root}>
      { place }
      </span>
    );
  
  }
}

EventTicketPlaceView.propTypes = {
  classes: PropTypes.object.isRequired,
  event_ticket: PropTypes.object.isRequired,
  event_seats: PropTypes.array.isRequired,
  event_zones: PropTypes.array.isRequired,
};
EventTicketPlaceView.defaultProps = {
  
};

export default withStyles(styles)(EventTicketPlaceView);