import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { compose, withApollo } from "react-apollo";
var Api = require("../libs/Api");


const styles = theme => ({
  container: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  snackbar: {
    margin: theme.spacing.unit,
  },
  paper: {
      maxWidth: '800px',
      marginTop: theme.spacing.unit * 3,
      padding: theme.spacing.unit,
      overflowX: 'auto',
  },
  textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
  },
  checkField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
  },
  textFieldLong: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 400+2*theme.spacing.unit,
  },

  button: {
    margin: theme.spacing.unit,
  },
});



class NewCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  
      wait:false,
      waitR:false,
      comment:"",
      done:false,
      student: {},
      course: {},
    }
  }

  componentDidMount() {
    Api.gqlStudent(this.props.client,this.props.student_id).then(data=>{
        this.setState({student:data.student,course:data.course})
    }).catch(this.onApiError);
  }

  onApiError(e) {
    console.error(e);
    this.setState({errorString:"chyba"})
  }
  onClick(e) {
    this.setState({wait:true,done:false});
    Api.gqlNewCard(this.props.client,this.state.student,this.state.comment).then(data=>{
      this.setState({comment:"",wait:false,done:true,student:data.student,course:data.course});
    }).catch((err)=>{
      this.setState({wait:false});
      this.onApiError(err);
    })
  }

  onClickRender(e) {
    this.setState({waitR:true});
    Api.gql_renderCard(this.props.client,this.state.student).then(file_url=>{
      this.setState({waitR:false});
      window.location.href = file_url;
    }).catch((err)=>{
      this.setState({waitR:false});
      this.onApiError(err);
    })
  }


  handleChangeComment(event) {
    this.setState({ comment: event.target.value });
  };

  render() {
    const classes = this.props.classes;
    const action = (
      <Button color="secondary" variant="raised" disabled={this.state.waitR} onClick={(e)=>this.onClickRender(e)}>
        vytisknout novou kartu
      </Button>
    );
    return (
        <div>
            <Paper className={classes.paper}>
                <Typography type="headline" component="h3">
                    Žák:
                </Typography>
                <Typography type="body1" component="p">
                    {this.state.student.name} {this.state.student.surname} 
                </Typography>
                <Typography type="body1" component="p">
                    {this.state.student.street} {this.state.student.street_no} {this.state.student.city} {this.state.student.post_code}
                </Typography>
                <Typography type="headline" component="h3">
                    Kurz:
                </Typography>
                <Typography type="body1" component="p">
                    {this.state.course.code} - {this.state.course.name}
                </Typography>
                <Typography type="headline" component="h3">
                    Aktuální karta:
                </Typography>
                <Typography type="body1" component="p">
                    číslo karty: {this.state.student.ref_gid} 
                </Typography>
                <Typography type="body1" component="p">
                    kód karty: {this.state.student.card_id} 
                </Typography>
            </Paper>
            { this.state.done && <SnackbarContent className={classes.snackbar} message={"Nová karta přidělena, číslo:"+this.state.student.ref_gid+", kód:"+this.state.student.card_id} action={action} />}
            <Paper className={classes.paper}> 
              <TextField
                  id="desc"
                  label="Důvod zneplatnění a přidělení nové karty"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  onChange={(e)=>this.handleChangeComment(e)}
                  value={this.state.comment}
                  className={classes.textFieldLong}
                  margin="normal"
              />

              <Button variant="raised" disabled={this.state.wait || this.state.comment.length===0}  className={classes.button} onClick={(e)=>this.onClick(e)}> Zneplatnit a přidelit novou </Button>
              {/*<Typography color="error" variant="title">MOMENTALNE NEFUNGUJE!!!</Typography>*/}
            </Paper>


        
      </div>
    );
  }
}



NewCard.propTypes = {
  classes: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  student_id: PropTypes.string.isRequired,
  auth: PropTypes.shape({is_admin:PropTypes.bool,is_view:PropTypes.bool,is_lector:PropTypes.bool}).isRequired
};

NewCard.defaultProps = {};


export default compose(
  withStyles(styles),
  withApollo,
)(NewCard)

