import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ColorPicker from 'material-ui-color-picker';

const styles = theme => ({
  textfield: {
    margin: theme.spacing.unit,
  }
});



class ColorField extends React.Component {

  constructor(props) {
    super(props);
  }

  onChange(color){
    if ((color) && (color!="")) {
      this.props.onChange(color);
    } else {
      this.props.onChange(null);
    }
  }

  render() {
    const {classes, label,value,disabled,required,error,width} = this.props;
   
    return (
      <ColorPicker 
        value={value?value:""} 
        defaultValue={value?value:""}
        onChange={(c)=>this.onChange(c)}
        TextFieldProps={{className:classes.textfield,error:error,required:required,disabled:disabled,width:width,label:label}}
        />
    );
  }
}

ColorField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  width:PropTypes.number
};
ColorField.defaultProps = {
  value:null,
  disabled:false,
  error:false,
  required:false,
  width:300
};

export default withStyles(styles)(ColorField);