
import React from 'react';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import blue from '@material-ui/core/colors/blue'
import { Line } from 'react-konva';

import {points2bbox,rect2points,getZoneData,getPlaceData} from './PlaceData';

function getSeatStrokeColor(color,hover) {
  switch (color) {
    case "red": return hover?red.A700:red.A100;
    case "green": return hover?green.A700:green.A100;
    case "blue": return hover?blue.A700:blue.A100;
    default: return hover?"purple":"black";
  }
}
function getSeatFillColor(color,hover,marker_color) {
  if (marker_color) {
    return marker_color;
  }
  return "white";
}
function getSeatTextColor(color,hover) {
  return "black";
}


function getZoneBoundingBox(place_map,map_zone_key) {
  const z = getZoneData(place_map,map_zone_key);
  return z.box;
}
function getPlaceBoundingBox(place_map) {
  const z = getPlaceData(place_map);
  return z.box;
}



const planStyles = {
    tableColor: 'white',
    tableBorderColor: 'gray',
    tableBorderColorHover: 'blue',

    chairDistance: 5,
    chairRadius:10,
    labelSize:15,
    tableRadius:20,
   
  };
  
const planColorEnum = ["black","red","green","blue"];
const SeatContext = React.createContext({ color: 'white' });

function withSeats(Component) {
  return function SeatedComponent(props) {
    return (
      <SeatContext.Consumer>
        {seats => <Component {...props} seats={seats} />}
      </SeatContext.Consumer>
    );
  };
}



function renderZoneArea(key,colors,id_string,...points) {
  return (
    <React.Fragment key={key}>
      {points.map((p,idx)=>(
        <Line key={idx}
         id={id_string}
         closed={true}
         points={p}
         fill={colors.fill}
         stroke={colors.line}
        />
      ))} 
    </React.Fragment>
  )
}

function renderZoneArea2(map_key,zone_key,colors,id_string) {
  const z = getZoneData(map_key,zone_key);
  return renderZoneArea(zone_key,colors,id_string,...(z.points));
}
 
function PlanZoneRenderer(plan,zone) {
  return ({colors,id_string}) => renderZoneArea2(plan,zone,colors,id_string);
}


export {PlanZoneRenderer,planStyles,rect2points,points2bbox,getPlaceBoundingBox,getZoneBoundingBox,getSeatStrokeColor,getSeatFillColor,getSeatTextColor,planColorEnum,SeatContext,withSeats};