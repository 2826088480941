import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo'
import Assistant from './Assistant';
import AssistantsHeaderTabs from './AssistantsHeaderTabs';

const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  cont: {
    padding: theme.spacing.unit*3,
  }
});


class AssistantsBoard extends React.Component {

  
  

  render() {
    const { classes,auth,page } = this.props;
    return (
      <div>
        <AssistantsHeaderTabs  activeTab={page} auth={auth} />
        <div className={classes.cont}>
        <Assistant page={page} auth={auth} />
        </div>
      </div>
    );
  }
}

AssistantsBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  page: PropTypes.oneOf(["list","online"]).isRequired

};
AssistantsBoard.defaultProps = {
  
};


export default compose(
  withStyles(styles),
 
)(AssistantsBoard)