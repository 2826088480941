import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MUSwitch from '@material-ui/core/Switch';


const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string)
};

const defaultProps = {
  emails:[]
};

class BulkEmailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ecomail: false
    }
  }

  render() {
    const { classes } = this.props;
    const { ecomail} = this.state;
    const emailstr = this.props.emails.join(ecomail?'\n':', ');
    return (
      <div>
        <div> 
        Řádkový formát
        <MUSwitch checked={ecomail}  onChange={(e,checked)=>this.setState({ecomail:checked}) }  value="ecomail_view"/>
        </div>
       
        <TextField
          label="Email adresy"
          multiline
          rows="10"
          value={emailstr}
          //onChange={this.handleChange('multiline')}
          className={classes.textField}
          margin="normal"
          helperText={"celkový počet adres: "+this.props.emails.length}
        />
      </div>
    );
  }
}

BulkEmailsView.propTypes = propTypes;
BulkEmailsView.defaultProps = defaultProps;

export default withStyles(styles)(BulkEmailsView);