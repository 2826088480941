import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import StudentAdd from './StudentAdd';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    minWidth:800
  }
});


class StudentAddDialog extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      valid:false
    }
    this.edit = React.createRef();
  }

  onSave() {
    if (this.edit.current) {
      this.edit.current.wrappedInstance.doSave();
    }
  }

  onEnter() {
    this.setState({valid:false});
  }

  onChange(d,v) {
    this.setState({valid:v});
  } 


  render() {
    const {course_key,mode,open,onClose,caption} = this.props;
    return (
      <Dialog disableBackdropClick maxWidth={false} open={open} onClose={onClose} onEnter={()=>this.onEnter()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {course_key && (<StudentAdd ref={this.edit} course_key={course_key} status={mode} onClose={onClose} onChange={(d,v)=>this.onChange(d,v)} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Neukládat
        </Button>
        <Button disabled={!this.state.valid} onClick={()=>this.onSave()} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

StudentAddDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
};
StudentAddDialog.defaultProps = {
  caption:"Zapsání žáka"
};

export default withStyles(styles)(StudentAddDialog);