import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
 
});


class PlaceMapView extends React.PureComponent {

  render() {
   // const { classes } = this.props;
    var s;
    switch(this.props.map) {
      case "BOBY": s="Bobycentrum"; break;
      case "BVV": s="Bvv"; break;
      case "BABYLON": s="KC Babylon"; break;
      case "TEST": s="testovaci"; break;
      case "NO": s="bez mapky"; break;
      default: s="?"; 
    }
    return (
      <span style={{marginRight:8,marginLeft:4}}>
        {s}
      </span>
    );
  }
}

PlaceMapView.propTypes = {
  classes: PropTypes.object.isRequired,
  map: PropTypes.oneOf(["BOBY","BVV","BABYLON","TEST","NO"]).isRequired
};
PlaceMapView.defaultProps = {
  
};

export default withStyles(styles)(PlaceMapView);