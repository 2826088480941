import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Lodash from 'lodash';
import FoldersTableRow from './FoldersTableRow';



const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
  },
  tablebody: {
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  row: {
    //height:24,
   
  }
});


const SaveOrdering = gql`
    mutation folders_ordering($keys: [String]!) {
        folders_ordering(keys:$keys) 
    }
`;


class FoldersTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      order:[],
      activeDrag:false,
    }
  }
 
  onDrag(active) {
    this.setState({activeDrag:active})
  }  

 reorder(items) {
    const { order } = this.state;
    if (order.length !== items.length) {
      console.log("missing order")
      return items;
    }
    console.log("reodering");
    return order.map((k)=>{return Lodash.find(items,{key:k}) });
 }

  findRow(key) {
      const items = this.reorder(this.props.folders);
      const item = items.filter(c => c.key === key)[0]
      const index = items.indexOf(item);
      const r =  {
        folder:item,
        index: index,
      };
      console.log("findRow",key,r)
      return r;
  }

  moveRow(key, atIndex) {
    console.log("moveRow",key,atIndex);
    const { index } = this.findRow(key)
    let order = []
    if (this.state.order.length !== this.props.folders.length) {
       order = this.props.folders.map(i=>{return i.key});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,key);
    this.setState({order:order});
  }
  
  saveOrder() {
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        keys:this.state.order
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }

  
  
  renderRows(items) {
    const {auth} = this.props;
    return  this.reorder(items).map((i,idx)=>{
                return  (<FoldersTableRow 
                      save={()=>this.saveOrder()} 
                      moveRow={(k,at)=>this.moveRow(k,at)} 
                      findRow={(k)=>this.findRow(k)}
                      activeDrag={this.state.activeDrag} 
                      id={i.key} 
                      key={i.key} 
                      folder={i} 
                      onDrag={(active)=>this.onDrag(active)} 
                      onEditFolder={this.props.onEditFolder} 
                      auth={auth}
                    />)
          });
  }



  render() {
    const { classes } = this.props;
   
    const rows = this.renderRows(this.props.folders);
    return (
      <Paper className={classes.root}>
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>řazení</TableCell>
                    <TableCell className={classes.cell}>název</TableCell>
                    <TableCell className={classes.cell}>zápisová skupina</TableCell>
                    <TableCell className={classes.cell}>evidenční skupina</TableCell>
                    <TableCell className={classes.cell}>kontrola vstupu</TableCell>
                    <TableCell className={classes.cell}>nezahrnovat do statistik</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {rows}
            </TableBody>
          </Table>
      </Paper>
    );
  }
}

FoldersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  onEditFolder: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

FoldersTable.defaultProps = {
 
};



export default compose(
  withStyles(styles),
  graphql(SaveOrdering,{
    name: "saveOrdering",
  }),
)(FoldersTable)
