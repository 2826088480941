import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'react-apollo'
//import { connect } from 'react-redux'
import StudentDebug from './StudentDebug';

const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  cont: {
    padding: theme.spacing.unit*3,
  }
});


class StudentDebugBoard extends React.Component {

  
  render() {
    const { classes,auth,student_key } = this.props;
    return (
      <div>
         <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
          <Typography className={classes.toolBar_item} variant="title" color="inherit">
            Student Debug - {student_key}
          </Typography>
        </Toolbar>
        <div className={classes.cont}>
        <StudentDebug student_key={student_key} auth={auth} />
        </div>
      </div>
    );
  }
}

StudentDebugBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  student_key: PropTypes.string.isRequired,
};
StudentDebugBoard.defaultProps = {
  
};


export default compose(
  withStyles(styles),
 
)(StudentDebugBoard)