import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import EventStatusViewField from './Fields/EventStatusViewField';
import PlaceIDViewField from './Fields/PlaceIDViewField';
import ImageIDViewField from './Fields/ImageIDViewField';
import StringViewField from './StringViewField';


const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class EventViewDialog extends React.Component {

  render() {
    const {event,open,onClose,caption} = this.props;
    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {event && (
            <React.Fragment>
              <StringViewField width={300} label="Název" value={event.name} />
              <PlaceIDViewField value={event.place_id} />
              <EventStatusViewField value={event.status} />
              <StringViewField width={500} label="Poznámka" value={event.comment} />
              <ImageIDViewField value={event.image_id} />
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

EventViewDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  event:PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
EventViewDialog.defaultProps = {
  caption:"Náhled akce"
};

export default withStyles(styles)(EventViewDialog);