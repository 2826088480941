import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import DateView from './DateView';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import { compose } from 'react-apollo';
//import gql from 'graphql-tag';
import StringField from './StringField';
import StringViewField from './StringViewField';
import Lodash from 'lodash';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import AccountIcon from '@material-ui/icons/AccountBox';
//import DebugObjectView from './Utils/DebugObjectView';
import { /*parseNumber as parsePhoneNumber,*/ isValidNumber as isValidPhoneNumber/*, formatNumber, AsYouType */} from 'libphonenumber-js'

const evalidator = require("email-validator");

const styles = theme => ({
  grid_root: {
      flexGrow: 1,
  },
  toolbar: {
    //border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  accounticon: {
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
  },
  desc: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }
});


class SaleEventBuyTicketClerk extends React.Component {

  constructor(props) {
      super(props);
      this.state={
     
        doc: {
          customer_name:null,
          customer_surname:null,
          customer_email:null,
          customer_phone:null,
          comment:null,
          reservation_due_date:null,
        },
     
        doc_err: {
          customer_name:false,
          customer_surname:false,
          customer_email:false,
          customer_phone:false,
          comment:false,
          reservation_due_date:false,
        },

        dirty:false,
        valid:true,

      }
  } 

  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err,
      dirty:true,
      valid:this.checkDoc(doc)
    });
  }

 // checkDocField(name,value) {
 //   if (["name","surname","email","phone"].includes(name)  && value===null) {
 //     return false;
 //   }
 //   return true;
 // }


  checkDocField(name,value) {
    // const {cost_mode} = this.props;
     switch(name) {  
     case 'customer_email':
         return (value==null) || evalidator.validate(value);
     case 'customer_phone':
         return (value==null) || isValidPhoneNumber(value,'CZ');
     default:
         return (true);
     }
 }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

 
  render() {
    const { classes,public_order_id,order,as_reservation} = this.props;
    const { doc, doc_err, valid} = this.state;

    const has_order = (public_order_id!=null) && (order!=null);
    const is_reservation = as_reservation;//has_order?order.only_reservation:as_reservation;
    let title = "?";
    if (has_order) {
      if (is_reservation) {
        title = "Potvrzení nové rezervace "+public_order_id;
      } else {
        title = "Potvrzení platby nové objednávky "+public_order_id;
      }
    } else {
      if (is_reservation) {
        title = "Nová rezervace"
      } else {
        title = "Údaje pro prodej vstupenek"
      }
    }
    return (
      <React.Fragment>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <AccountIcon  className={classes.accounticon} />
          <Typography className={classes.grow} variant="title" color="inherit"> 
             {title}
          </Typography>
        </Toolbar>
        <Divider/>
          {has_order?(
            <React.Fragment>

              <Typography className={classes.desc} variant="subheading" color="inherit">
                 Vstupenky po zaplacení pošleme na uvedenou email adresu a kódy vstupenek pro jistotu i jako SMS. Jméno a přijmení potřebujeme znát pro případnou kontrolu platby či řešení problemů.
              </Typography>

              <StringViewField width={300} id="name" label="Jméno" value={order.customer_name} />
              <StringViewField width={300} id="surname" label="Přijmení" value={order.customer_surname}/>
              <StringViewField width={300} id="phone" label="Telefon" value={order.customer_phone}  />
              <StringViewField width={300} id="email" label="Email" value={order.customer_email}  />
              <StringViewField width={300} id="comment" label="Poznámka" value={order.comment}  />
            </React.Fragment>
          ):(
            <React.Fragment>

              <Typography className={classes.desc} variant="subheading" color="inherit">
                 Následující údaje nejsou povinné. Nicméně, když se vyplní, bude možné v přehledu vidět, komu se vstupenky prodaly/rezervovaly a taktéž půjde na email nebo telefon poslat kód vstupenek.
              </Typography>

              <StringField width={300} id="name" label="Jméno" value={doc.customer_name} error={doc_err.customer_name} onChange={(v)=>this.handleDocChange("customer_name",v)} />
              <StringField width={300} id="surname" label="Přijmení" value={doc.customer_surname} error={doc_err.customer_surname} onChange={(v)=>this.handleDocChange("customer_surname",v)} />
              <StringField width={300} id="phone" label="Telefon" value={doc.customer_phone} error={doc_err.customer_phone} onChange={(v)=>this.handleDocChange("customer_phone",v)} />
              <StringField width={300} id="email" label="Email" value={doc.customer_email} error={doc_err.customer_email} onChange={(v)=>this.handleDocChange("customer_email",v)} />
              <StringField width={300} id="comment" label="Poznámka" value={doc.comment} error={doc_err.comment} onChange={(v)=>this.handleDocChange("comment",v)} />
            </React.Fragment>
          )}  

      
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          {has_order?(
            <React.Fragment>
              <Typography className={classes.grow} variant="body2" color="inherit"> Pokud je vše v pořádku, stačí objedávku zaplatit kartou a vstupenky Vám pošleme. </Typography>
              <Button className={classes.button} variant="contained" onClick={this.props.onBack}> změnit údaje </Button>
              <Button className={classes.button} variant="contained" onClick={this.props.onConfirm}> {is_reservation?"uložit rezervaci":"uložit objednávku"} </Button>
            </React.Fragment>
          ):(
            <React.Fragment>
              <Typography className={classes.grow} variant="title" color="inherit"> &nbsp; </Typography>
              <Button className={classes.button} variant="contained" disabled={!valid} onClick={()=>this.props.onCreate(this.state.doc)}>  {is_reservation?"pokračovat v rezervaci":"pokračovat k objednávce"}  </Button>
            </React.Fragment>
          )}
        </Toolbar>

      </React.Fragment>
    );
  }
}

SaleEventBuyTicketClerk.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  public_order_id: PropTypes.string,
  order: PropTypes.object,
  onCreate: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  as_reservation: PropTypes.bool.isRequired,
};
SaleEventBuyTicketClerk.defaultProps = {
  public_order_id:null,
  order:null
};



export default compose(
  withStyles(styles),
  /*
  graphql(CREATE_EVENT_TICKET_BOOKING,{
    name: "createEventTicketBooking",
  }),
  graphql(DELETE_EVENT_TICKET_BOOKING,{
    name: "deleteEventTicketBooking",
  }),*/
)(SaleEventBuyTicketClerk)
