import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DebugObjectView from './Utils/DebugObjectView';
import { compose, graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import Typography from '@material-ui/core/Typography';
import StringViewField from './StringViewField';
import NumberViewField from './NumberViewField';
//import Lodash from 'lodash';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import AccountIcon from '@material-ui/icons/AccountBox';
import OrderOwnerViewField from './Fields/OrderOwnerViewField';
import OrderStatusViewField from './Fields/OrderStatusViewField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import StringField from './StringField';
import SaleEventOrderPaymentsView from './SaleEventOrderPaymentsView';

const styles = theme => ({
  root: {
  //   border: "1px solid red",
     flexGrow: 1,
  },
  toolbar: {
    //border: "1px solid green",
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  accounticon: {
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
  },
  desc: {
    padding: theme.spacing.unit
  },
  button: {
    margin: theme.spacing.unit
  }

});


const GET_ORDER_TICKETS_PDF = gql`
query eventOrderTicketsPdf($public_order_key: String!) {
  order:eventOrder(public_order_key:$public_order_key) {
    public_key  
    status
    ticket_pdf_url
    last_payment_status
  }
}
`;

const PRINT_ORDER_TICKETS_ZPL = gql`
mutation eventOrderTicketsZPL($order_public_key: String!) {
  eventOrderTicketsZPL(order_public_key:$order_public_key) {
    ok
    error_msg
  }
}
`;


const CANCEL_ORDER= gql`
mutation eventTicketCancelOrder($event_id: ID!,$public_order_key:String!,$comment:String) {
  eventTicketCancelOrder(event_id:$event_id,public_order_key:$public_order_key,comment:$comment) {
    ok
    error_code
    error_msg
  }
}
`;

const PAY_ORDER= gql`
mutation eventTicketPayOrder($event_id: ID!,$public_order_key:String!,$comment:String) {
  eventTicketPayOrder(event_id:$event_id,public_order_key:$public_order_key,comment:$comment) {
    ok
    error_code
    error_msg
  }
}
`;

const UNPAY_ORDER= gql`
mutation eventTicketUnPayOrder($event_id: ID!,$public_order_key:String!,$comment:String) {
  eventTicketUnPayOrder(event_id:$event_id,public_order_key:$public_order_key,comment:$comment) {
    ok
    error_code
    error_msg
  }
}
`;


class SaleEventOrderAdminView extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      ask:null,
      comment:null,
    }
    this.pays = React.createRef();
  }
  handleChangeComment(v) {

    this.setState({comment:v});
  }

  renderAskDialog() {
    const {ask,comment} = this.state;
    const {order} = this.props;
    if (!ask) return null;
    let title="";
    let act = null;
    switch (ask) {
      case 'cancel': title = "Opravdu stornovat?"; act = ()=>{this.handleCancel()};  break;
      case 'pay': title="Potvrzuji příjem částky "+order.price+"kč do pokladny"; act = ()=>this.handlePay(); break;
      case 'cashback': title="Potvrzuji výdej částky "+order.price+"kč z pokladny"; act = ()=>this.handleCashBack(); break;
      default: return null;
    }
    return (
      <Dialog
        open={true}
        onClose={()=>this.handleCloseAsk()}
      >
      <DialogTitle >{title}</DialogTitle>
      <DialogContent>
          {ask=="pay" &&(<StringField width={400} id="comment" label="poznámka k platbě" value={comment} onChange={(v)=>this.handleChangeComment(v)} />)}
          {ask=="cashback" &&(<StringField width={400} id="comment" label="poznámka k vratce" value={comment} onChange={(v)=>this.handleChangeComment(v)} />)}
          {ask=="cancel" &&(<StringField width={400} id="comment" label="poznámka ke stornu" value={comment} onChange={(v)=>this.handleChangeComment(v)} />)}
      </DialogContent>
      <DialogActions>
            <Button onClick={()=>this.handleCloseAsk()} color="primary">
              Ne
            </Button>
            <Button onClick={act} color="primary" >
              Ano
            </Button>
          </DialogActions>
      </Dialog>
    )
  }
  handleCloseAsk() {
    this.setState({ask:null,comment:null});
  }

  handleAskAction(act) {
    this.setState({ask:act});
  }
 
  handleCancel() {
    const cancel_comment = this.state.comment;
    this.handleCloseAsk(); //it clears comment!


   
    this.props.cancelEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        public_order_key:this.props.order.public_key,
        comment:cancel_comment,
      }
    }).then(res=>{
      if (!res.data.eventTicketCancelOrder.ok) {
        alert(res.data.eventTicketCancelOrder.error_msg);
        //this.handleReload();
      } else {
        alert("objednávka a vstupenky stornovány");
      }
      this.handleReload();
    }).catch(err=>{
      alert(err);
      this.handleReload();
    })

  

  }

  handlePay() {
    const pay_comment = this.state.comment;
    this.handleCloseAsk();//it clears comment!
   

    this.props.payEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        public_order_key:this.props.order.public_key,
        comment:pay_comment,
      }
    }).then(res=>{
      if (!res.data.eventTicketPayOrder.ok) {
        alert(res.data.eventTicketPayOrder.error_msg);
        //this.handleReload();
      } else {
        alert("platba zaevidována");
      }
      this.handleReload();
    }).catch(err=>{
      alert(err);
      this.handleReload();
    })
  
  }


  handleCashBack() {
    const cashback_comment = this.state.comment;
    this.handleCloseAsk();//it clears comment!
   

    this.props.unPayEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        public_order_key:this.props.order.public_key,
        comment:cashback_comment,
      }
    }).then(res=>{
      if (!res.data.eventTicketUnPayOrder.ok) {
        alert(res.data.eventTicketUnPayOrder.error_msg);
        //this.handleReload();
      } else {
        alert("vratka zaevidována");
      }
      this.handleReload();
    }).catch(err=>{
      alert(err);
      this.handleReload();
    })
  
  }


  handleReload() {
    this.props.refetch();
    if (this.pays.current) {
      this.pays.current.reload();
    }
  }

  handlePrintPdf() {
   

    this.props.client.query({
      query: GET_ORDER_TICKETS_PDF,
      variables: { public_order_key:this.props.order.public_key},
      fetchPolicy:"network-only",
    }).then(d=>{
      console.log("GET_ORDER_TICKETS_PDF result",d.data)
      if (d.data && d.data.order && d.data.order.ticket_pdf_url) {
        window.location.href=d.data.order.ticket_pdf_url+"?download"; 
      } else {
        alert("Vstupenky se jeste pripravuji, zkuste to znova");
        this.handleReload();
      }
    }).catch(err=>{
      alert(err);
      this.handleReload();
    });
  }

  handlePrintZPL() {
   

    this.props.client.mutate({
      mutation: PRINT_ORDER_TICKETS_ZPL,
      variables: { order_public_key:this.props.order.public_key},
      fetchPolicy:"no-cache",
    }).then(d=>{
      console.log("PRINT_ORDER_TICKETS_ZPL result",d.data)
      if (d.data && d.data.eventOrderTicketsZPL) {
        if (d.data.eventOrderTicketsZPL.ok) {
          alert("Odesláno na tiskárnu");
        } else {
          alert("Chyba odeslání na tiskárnu: "+d.data.eventOrderTicketsZPL.error_msg)
        }
       
      } else {
        alert("Jejda, neco se pokazilo");
        this.handleReload();
      }
    }).catch(err=>{
      alert(err);
      this.handleReload();
    });
  }

  render() {
    const { classes,event, order} = this.props;
    const ask_dialog = this.renderAskDialog();
    return (
      <Paper className={classes.root}>  
        {ask_dialog}
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <AccountIcon  className={classes.accounticon} />
          <Typography className={classes.grow} variant="title" color={"inherit"}> 
              {"Objednávka "+order.public_key}
          </Typography>
        </Toolbar>
        <Divider/>
        <DebugObjectView object={order} />
        <OrderOwnerViewField value={order.owner_mode} />
        <OrderStatusViewField value={order.status} />
        <NumberViewField  width={300} id="price" label="Cena" value={order.price} />
        <Divider/>
        <StringViewField  width={300} id="name" label="Jméno" value={order.customer_name} />
        <StringViewField  width={300} id="surname" label="Přijmení" value={order.customer_surname}/>
        <StringViewField  width={300} id="phone" label="Telefon" value={order.customer_phone}  />
        <StringViewField  width={300} id="email" label="Email" value={order.customer_email}  />
        <StringViewField  width={300} id="comment" label="Poznámka" value={order.comment} />
        <Divider/>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          {order.status == 'PAID' && (
            <React.Fragment>
              <Button className={classes.button} variant="contained" onClick={()=>this.handlePrintPdf()} > vstupenky pro tisk</Button>
              <Button className={classes.button} variant="contained" onClick={()=>this.handlePrintZPL()}> odeslat na tiskárnu vstupenek </Button>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("cancel")} > stornovat </Button>
            </React.Fragment>
          )}
          {order.status == 'DECLINED' && (
            <React.Fragment>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("pay")} > potvrdit platbu v hotovosti</Button>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("cancel")} > stornovat </Button>
            </React.Fragment>
          )}
          {order.status == 'PENDING' && (
            <React.Fragment>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("pay")} > potvrdit platbu v hotovosti</Button>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("cancel")} > stornovat </Button>
            </React.Fragment>
          )}
          {order.status == 'RESERVATION' && (
            <React.Fragment>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("pay")} > potvrdit platbu v hotovosti</Button>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("cancel")} > stornovat </Button>
            </React.Fragment>
          )}

          {order.status == 'CANCELLED' && (
            <React.Fragment>
              <Button className={classes.button} variant="contained" onClick={()=>this.handleAskAction("cashback")} > potvrdit vrácení hotovosti </Button>
            </React.Fragment>
          )}

          <Button className={classes.button} variant="contained" onClick={()=>this.handleReload()} > přenačíst </Button>

        </Toolbar>
        <SaleEventOrderPaymentsView innerRef={this.pays} event={event} public_order_key={order.public_key}/>
      </Paper>
    );
  }
}

SaleEventOrderAdminView.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  order: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  cancelEventTicketOrder: PropTypes.func.isRequired,
  payEventTicketOrder: PropTypes.func.isRequired,
  unPayEventTicketOrder: PropTypes.func.isRequired,
};
SaleEventOrderAdminView.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CANCEL_ORDER,{
    name: "cancelEventTicketOrder",
  }), 
  graphql(PAY_ORDER,{
    name: "payEventTicketOrder",
  }), 
  graphql(UNPAY_ORDER,{
    name: "unPayEventTicketOrder",
  }), 
  withApollo,
)(SaleEventOrderAdminView);