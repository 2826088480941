import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EnumField from './EnumField';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from "graphql-tag";
import { Query} from "react-apollo";

import { compose } from 'react-apollo'
import { connect } from 'react-redux'

import { setSeason, setFolder } from './../actions'
import CourseAddDialog from './CourseAddDialog';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  }
});


const GET_CATALOG = gql`
  query Catalog{    
    folders {
      key
      name
      evi_group
    }
    seasons {
      key
      name
    }
  }
`;

class CoursesHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      addCourse:false
    }
  }

  renderLectorToolbar() {
      const { classes } = this.props;
      return (
        <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
          <Typography className={classes.toolBar_item} variant="title" color="inherit">
            Přehled kurzů lektora
          </Typography>
        </Toolbar>
      )
  }

  handleCloseCourseAddDialog() {
    this.setState({addCourse:false});
  }

  handleCourseAdd() {
    this.setState({addCourse:true});
  }
  renderCourseAddDialog() {
    const {addCourse} = this.state;
    const {folder_key,season_key, evi_group } = this.props;
    return (
      <CourseAddDialog open={addCourse} season_key={season_key} folder_key={folder_key} evi_group={evi_group} caption={"Založení kurzu"} onClose={()=>this.handleCloseCourseAddDialog()}/>
    )
  }


  handleChangeS(s) {
    this.setState({season_key:s});
  }
  handleChangeF(f) {
    this.setState({folder_key:f});
  }

  renderAdminToolbar() {
    const { classes,folder_key,season_key,auth, evi_group } = this.props;
    const cad = this.renderCourseAddDialog();
    return (
      <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
        <Typography className={classes.toolBar_item} variant="title" color="inherit">
          Přehled kurzů
        </Typography>
        {cad}
        <Query query={GET_CATALOG}>
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            const se = data.seasons.map(s=>{
              return {key:s.key,label:s.name};
            });
            const fe = data.folders.filter(f=>{
              if (evi_group == null) {
                return true;
              }
              return f.evi_group == evi_group;
            }).map(s=>{
              return {key:s.key,label:s.name};
            });
            const ff = fe.find(f=>{return f.key == folder_key});
            const missing_f = !ff;
         
            return (
                <React.Fragment>
                  <EnumField  required width={150} id="season"  values={se} value={season_key} onChange={(v)=>this.props.onChangeSeason(v)} />
                  <EnumField  required width={300} id="folder"  values={fe} value={folder_key} onChange={(v)=>this.props.onChangeFolder(v)} />
                  { (missing_f || folder_key===null || season_key===null) && (
                    <Typography variant="title" color="error"> Není zvolena sezóna nebo složka! </Typography>
                  )}
                  { auth.is_admin && !missing_f && folder_key && season_key &&  (<Button color="inherit" onClick={()=>this.handleCourseAdd()}>Založit kurz</Button>)}

                </React.Fragment>
            );
          }}
        </Query>
      </Toolbar>
    )
}

 
  render() {
    const { auth } = this.props;
    var toolbar = null;
    if (auth.is_admin_or_view) {
      toolbar = this.renderAdminToolbar();
    } else if (auth.is_lector) {
      toolbar = this.renderLectorToolbar();
    }
    return (
      <AppBar position="static" color="default">
          {toolbar}
      </AppBar>
    );
  }
}


function mapStateToProps(state) {
  return { 
      folder_key: state.select.folder_key,
      season_key: state.select.season_key,
      evi_group: state.select.evi_group
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeSeason: key => {
      dispatch(setSeason(key))
    },
    onChangeFolder: key => {
      dispatch(setFolder(key))
    },

  }
}



CoursesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  season_key: PropTypes.string,
  folder_key: PropTypes.string,
  evi_group: PropTypes.string,
  onChangeFolder: PropTypes.func.isRequired,
  onChangeSeason: PropTypes.func.isRequired
};
CoursesHeader.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps)
)(CoursesHeader)