import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DebugObjectView from './Utils/DebugObjectView';
import EventPlanView from './EventPlanView';
import DebugOnly from './Utils/DebugOnly';
import Grid from '@material-ui/core/Grid';
import SetupEventSeat from './SetupEventSeat';
import Lodash from 'lodash';
const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});



class SetupEventZoneTabInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      hover_seat:null,
      hover_seat_idx:null,
      click_seat:null,
      click_seat_idx:null,
    }
  }
  reload() {
    if (this.props.refetch) {
      this.props.refetch();
    }
  }
  
  onSeatHover(hover_seat,hover_seat_idx) {
    this.setState({hover_seat,hover_seat_idx})
  }

  onSeatClick(click_seat,click_seat_idx) {
    this.setState({click_seat,click_seat_idx})
  }

  render() {
    const { auth,classes,event,event_zone} = this.props;
  /*
    let markers = [];
    if (this.state.click_seat) {
      markers=[
        {seat_id:this.state.click_seat.id,color:null,idxs:{1:"green"}}
      ]
    }
*/

    return (
      <React.Fragment>
            <DebugOnly>
              <div> event zone info tab </div>
              <DebugObjectView name="event" object={event} />
              <DebugObjectView name="event_zone" object={event_zone} />
            </DebugOnly>


           <Grid container justify="center" alignContent="center" >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <EventPlanView width={800} height={500}
                  place={event.place} 
                  zones={[event_zone]}
                  zoomZone={event_zone}
                  seats={event_zone.seats}
                  tickets={event_zone.tickets}
                  onSeatHover={(seat,idx)=>this.onSeatHover(seat,idx)}
                  onSeatClick={(seat,idx)=>this.onSeatClick(seat,idx)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <DebugOnly>
                  <div>  hover seat: {this.state.hover_seat?this.state.hover_seat.id:"null"}, idx: {this.state.hover_seat_idx} </div>
                  <div>  click seat: {this.state.click_seat?this.state.click_seat.id:"null"}, idx: {this.state.click_seat_idx} </div>
                </DebugOnly>
                {this.state.click_seat &&(
                  <SetupEventSeat 
                    auth={auth}
                    seat={this.state.click_seat} 
                    seat_idx ={this.state.click_seat_idx} 
                    tickets={Lodash.filter(event_zone.tickets,{event_seat_id:this.state.click_seat.id})}
                    onClose={()=>this.setState({click_seat:null,click_seat_idx:null})}
                    onChange={()=>this.reload()}
                  />
                )}

                <Typography variant="subheading"> Přehled sezení v zóně </Typography>
                  {event_zone.seats.map(ev=>{
                    let color = "default"; 
                    if (this.state.hover_seat) {
                      if (this.state.hover_seat.id == ev.id) {
                        color="primary";
                      }
                    }
                    return (<Chip color={color} key={ev.id} label={ev.name} className={classes.chip} />);
                  })}
                 
              </Grid>
            </Grid>


   
      </React.Fragment>
    );
  }
}

SetupEventZoneTabInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  event_zone: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};
SetupEventZoneTabInfo.defaultProps = {
  
};


export default withStyles(styles)(SetupEventZoneTabInfo);