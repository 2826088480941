import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
import Lodash from 'lodash';
//import DateView from './DateView';
//import BooleanView from './BooleanView';
import DebugObjectView from './Utils/DebugObjectView';
import Grid from '@material-ui/core/Grid';
import Moment from 'moment';



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

function sum_amount(payments) {
    return Lodash.reduce(payments,(a,p)=>{
      return {c:a.c+1,s:a.s+p.amount};
    },{c:0,s:0})
}




class SaleEventPaymentsReport extends React.Component {

  renderClerk(label,login,payments) {
    const a = sum_amount(payments);
    return (
      <div key={login}> {label} - {login}, počet: {a.c}, suma: {a.s} </div>
    )
  }

  renderReport(label,payments) {
    const by_type = Lodash.groupBy(payments,'type');

    let all_cash = [];
    if ('CASH' in by_type) {
      all_cash = by_type['CASH'];
    }

    let all_card = [];
    if ('CARD' in by_type) {
      all_card = by_type['CARD'];
    }
    const card_by_status = Lodash.groupBy(all_card,'status');
    let card_paid = [];
    if ('PAID' in card_by_status) {
      card_paid = card_by_status['PAID'];
    }

    let card_refunded = [];
    if ('REFUNDED' in card_by_status) {
      card_refunded = card_by_status['REFUNDED'];
    }

    let card_declined = [];
    if ('DECLINED' in card_by_status) {
      card_declined = card_by_status['DECLINED'];
    }
    const card_paid_r = sum_amount(card_paid);
    const card_refunded_r = sum_amount(card_refunded);
    const card_declined_r = sum_amount(card_declined);


    const cash_by_status = Lodash.groupBy(all_cash,'status');
    let cash_paid = [];
    if ('PAID' in cash_by_status) {
      cash_paid = cash_by_status['PAID'];
    }
  
    let cash_refunded = [];
    if ('REFUNDED' in cash_by_status) {
      cash_refunded = cash_by_status['REFUNDED'];
    }
    const cash_paid_r = sum_amount(cash_paid);
    const cash_refunded_r = sum_amount(cash_refunded);
    const cash_paid_by_clerk = Lodash.groupBy(cash_paid,'user_login');
    const cash_refunded_by_clerk = Lodash.groupBy(cash_refunded,'user_login');

    return (
      <React.Fragment>
              <Typography variant="subheading">{label}</Typography>
              <div> úspěšné platby kartou -  počet: {card_paid_r.c}, suma: {card_paid_r.s} </div>            
              <div> vrácené platby kartou -  počet: {card_refunded_r.c}, suma: {card_refunded_r.s} </div>            
              <div> neúspěšné platby kartou -  počet: {card_declined_r.c} </div>            
              <div> celkem přijaté platby v hotovosti -  počet: {cash_paid_r.c}, suma: {cash_paid_r.s} </div>            
              <div> celkem vrácené platby v hotovosti -  počet: {cash_refunded_r.c}, suma: {cash_refunded_r.s} </div>   
              {Lodash.map(cash_paid_by_clerk,(v,k)=>{
                return this.renderClerk("hotovost přijatá",k,v);
              })}
              {Lodash.map(cash_refunded_by_clerk,(v,k)=>{
                return this.renderClerk("hotovost vrácená",k,v);
              })}
      </React.Fragment>
    )
  
  }


  render() {
    const { classes,payments} = this.props;
    const now = Moment();
    const nowy = Moment().subtract(1,'day');

    const all = this.renderReport("celé obdobi",payments)
    const today_payments = Lodash.filter(payments,(p)=>{
      return Moment(p.created_at).isSame(now,'day');
    })
    const today = this.renderReport("dnes - "+now.format("YYYY-MM-DD"),today_payments)
 
    const yestreday_payments = Lodash.filter(payments,(p)=>{
      return Moment(p.created_at).isSame(nowy,'day');
    })
    const yestreday = this.renderReport("včera - "+nowy.format("YYYY-MM-DD"),yestreday_payments)
  
    return (
      <React.Fragment>
            <Paper className={classes.paper}>

                <Grid container justify="flex-start" alignContent="center" spacing={16}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                      {all}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                      {today}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                      {yestreday}
                    </Grid>
               </Grid>
 
              <DebugObjectView object={{x:null}}/>
            
            </Paper>
            
      </React.Fragment>
    );
  }
}

SaleEventPaymentsReport.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
};
SaleEventPaymentsReport.defaultProps = {
  
};


export default withStyles(styles)(SaleEventPaymentsReport);