import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query,compose, graphql} from "react-apollo";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ViewIcon from '@material-ui/icons/Pageview';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import DateView from './DateView';

import EmailInPreview from './EmailInPreview';

const styles = theme => ({
  dialog: {
    width:600
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

const GET_EMAILINS = gql`
  query EmailIns{    
    emailIns {
      key
      date
      email_id
      s3key
      text_as_html
      text
      subject
      from
      to
    }
  }
`;

const HIDE_EMAILIN = gql`
mutation hideEmailIn($key:String!) {
  emailInHide(key:$key) {
    key
  }
}`;

const FWD_EMAILIN = gql`
mutation fwdEmailIn($key:String!, $target_email:String!) {
  emailInForward(key:$key target_email:$target_email) {
    ok
  }
}`;


class EmailIns extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email:null,
      show:false,
      del:false,
      fwd:false,
      target_email:'starlet@starlet-brno.cz'
    }
  }

  handleShow(e){
    this.setState({email:e,show:true});
  }

  handleClose() {
    this.setState({show:false});
  }

  handleReqDel() {
    this.setState({show:false,del:true});
  }

  handleReqFwd() {
    this.setState({show:false,fwd:true});
  }


  handleCancelDel() {
    this.setState({del:false});
  }

  handleCancelFwd() {
    this.setState({fwd:false});
  }
 
  handleDoDel() {
    this.props.hideEmailIn({variables:{key:this.state.email.key}}).then(r=>{
      this.setState({del:false});
    });
  }

  handleDoFwd() {
    this.props.fwdEmailIn({variables:{key:this.state.email.key, target_email:this.state.target_email}}).then(r=>{
      this.setState({fwd:false});
     // console.log("R",r);
      if (r.data && r.data.emailInForward && r.data.emailInForward.ok) {
      
      } else {
        alert('chyba');
      }
     
    }).catch(err=>{
      alert('chyba');
    });
  }

  renderDelDialog() {
    // const classes = this.props.classes;
     return (
         <Dialog open={this.state.del} onClose={(e)=>this.handleCancelDel()}>
             <DialogTitle>Opravdu zmazat email</DialogTitle>
             <DialogContent>
             </DialogContent>
             <DialogActions>
                 <Button onClick={(e)=>this.handleCancelDel()} color="primary">
                     Storno
                 </Button>
                 <Button onClick={(e)=>this.handleDoDel()} color="primary">
                     Smazat
                 </Button>
             </DialogActions>
         </Dialog>
     )
 }

 renderFwdDialog() {
   const classes = this.props.classes;
   return (
       <Dialog open={this.state.fwd} onClose={(e)=>this.handleCancelDel()}>
           <DialogTitle>Přeposlání emailu</DialogTitle>
           <DialogContent>
                <TextField
                    id={"target_email"}
                    label="kam přeposlat"
                    className={classes.textField}
                    value={this.state.target_email}
                    onChange={(event)=>this.setState({target_email:event.target.value})}
                    margin="normal"
                />
           </DialogContent>
           <DialogActions>
               <Button onClick={(e)=>this.handleCancelFwd()} color="primary">
                   Storno
               </Button>
               <Button onClick={(e)=>this.handleDoFwd()} color="primary">
                   Přeposlat
               </Button>
           </DialogActions>
       </Dialog>
   )
}

  renderDialog() {
    const { classes, auth} = this.props;
    return (
        <Dialog  open={this.state.show} onClose={(e)=>this.handleClose()}>
            <DialogTitle>Email</DialogTitle>
            <DialogContent className={classes.dialog} >
             
               {this.state.email!==null &&(
                 <EmailInPreview emailin={this.state.email} />
               )}
             
            </DialogContent>
            <DialogActions>
                {auth.is_admin &&(
                  <Button onClick={(e)=>this.handleReqFwd()} color="secondary">
                   Přeposlat
                  </Button>
                )}

                {auth.is_admin &&(
                 <Button onClick={(e)=>this.handleReqDel()} color="secondary">
                   Smazat
                  </Button>
                )}

                <Button onClick={(e)=>this.handleClose()} color="primary">
                    Zavřít
                </Button>
               </DialogActions>
        </Dialog>
    );
  }

  render() {
    //const { classes } = this.props;
    const dlg = this.renderDialog()
    const deldlg = this.renderDelDialog();
    const fwddlg = this.renderFwdDialog();

    return (
      <div className="foo">
      {dlg} {deldlg} {fwddlg}
       <Query query={GET_EMAILINS}>
        {({ loading, error, data }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          return (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> {"Odesílatel"} </TableCell>
                    <TableCell> {"Datum"} </TableCell>
                    <TableCell> {"Předmět"} </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.emailIns.map(e=>{
                    return (
                      <TableRow key={e.key}>
                          <TableCell> {e.from} </TableCell>
                          <TableCell> <DateView date={ e.date}/> </TableCell>
                          <TableCell> {e.subject} </TableCell>
                          <TableCell> 
                              <IconButton style={{height:24}} color={'primary'} onClick={()=>this.handleShow(e)}>
                                  <ViewIcon/>
                              </IconButton>

                          </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
          )
        }}
      </Query>
      </div>
    );
  }
}

EmailIns.propTypes = {
  classes: PropTypes.object.isRequired,
  hideEmailIn: PropTypes.func.isRequired,
  fwdEmailIn: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
EmailIns.defaultProps = {
  
};

export default compose(
  withStyles(styles),
  graphql(HIDE_EMAILIN,{
    name:"hideEmailIn",
    options: {
        refetchQueries: [
            'EmailIns',
          ],
    }
  }),
  graphql(FWD_EMAILIN,{
    name:"fwdEmailIn",
    options: {
        refetchQueries: [
            'EmailIns',
          ],
    }
  }),

)(EmailIns);

