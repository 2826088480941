import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateView from './DateView';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewIcon from '@material-ui/icons/Pageview';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Lodash from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import EmailInPreview from './EmailInPreview';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
   // maxWidth: 1000,
   // background: theme.palette.background.paper,
  },
  dialog: {
    width:600
  }
});

const CurrentEmailTemplates = gql`
  query EmailTemplates {
    emailTemplates {
        key, 
        date,    
        email_id,
        s3key,
        text_as_html,
        text,
        subject
    }
  }
`;

const HideEmailTemplate = gql`
    mutation HideEmailTemplate($key: String!) {
        emailTemplateHide(key:$key) {
            key
        }
    }
`;



class EmailTemplates extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
     askOpen: false,
     toDel: null,
     toShow: null,
    }
  }
 

  handleCloseShow(){
    this.setState({toShow:null});
  }
  
  handleClickShow(i){
    console.log("handleClickShow");
    this.setState({toShow:i});
  }

  handleClickDelete(i){
    this.setState({askOpen:true,toDel:i});
  }
  handleRequestCancelAsk(e) {
    this.setState({askOpen:false,toDel:null});  
  }
  handleRequestConfirmAsk(e) {

    this.props.hideEmailTemplate({
      variables: {
          key:this.state.toDel.key,
      }
    }).then(res=>{
      this.setState({askOpen:false,toDel:null});  
    }).catch(err=>{
      //TODO Dispatch error;
      this.setState({askOpen:false,toDel:null});  
    })

  }

  renderAskDialog() {
    // const classes = this.props.classes;
     return (
         <Dialog open={this.state.askOpen} onClose={(e)=>this.handleRequestCancelAsk(e)}>
             <DialogTitle>{"Opravdu smazat šablonu?"}</DialogTitle>
             <DialogContent>
               {this.state.toDel && (
                 "Šablona s předmětem '" + this.state.toDel.subject +"' bude smazána"
               )}
             </DialogContent>
             <DialogActions>
                 <Button onClick={(e)=>this.handleRequestCancelAsk(e)} color="primary">
                     Storno
                 </Button>
                 <Button onClick={(e)=>this.handleRequestConfirmAsk(e)} color="primary">
                     Smazat
                 </Button>
             </DialogActions>
         </Dialog>
     )
 }

  renderLoading() {
    return (
      <TableRow>
        <TableCell colSpan={3}>
        <CircularProgress />
        </TableCell>
      </TableRow>
    )
  }
  renderRows(items) {
    const { auth } = this.props;
    return Lodash.orderBy(items,["date"],["desc"]).map((i,idx)=>{
      return (
        <TableRow hover key={idx}>
          <TableCell>{i.subject}</TableCell>
          <TableCell> <DateView date={i.date}/> </TableCell>
          <TableCell>  
              <Tooltip title="Zobrazit">
                <IconButton color='primary' onClick={(e)=>this.handleClickShow(i)}>
                    <ViewIcon/>
                </IconButton>
              </Tooltip>
              {auth.is_admin && (
                <Tooltip title="Smazat">
                <IconButton color='secondary' onClick={(e)=>this.handleClickDelete(i)}>
                    <DeleteIcon/>
                </IconButton>
              </Tooltip>
              )}
          
          </TableCell>
        </TableRow>
      )
    })
  }


  renderShowDialog() {
    const classes = this.props.classes;
    return (
        <Dialog  maxWidth={"md"} open={this.state.toShow!==null} onClose={(e)=>this.handleCloseShow()}>
            <DialogTitle>Šablona emailu</DialogTitle>
            <DialogContent className={classes.dialog} >
             
               {this.state.toShow!==null &&(
                 <EmailInPreview showFrom={false} emailin={this.state.toShow} />
               )}
             
            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>this.handleCloseShow()} color="primary">
                    Zavřít
                </Button>
               </DialogActions>
        </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const rows = this.props.emailtemplates.emailTemplates? this.renderRows(this.props.emailtemplates.emailTemplates):this.renderLoading();
    const askDlg = this.renderAskDialog();
    const showDlg = this.renderShowDialog();
    return (
      <Paper className={classes.root}>
        {askDlg}{showDlg}
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.cell}>předmět</TableCell>
                    <TableCell className={classes.cell}>datum</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
          </Table>
      </Paper>
    );
  }
}

EmailTemplates.propTypes = {
  classes: PropTypes.object.isRequired,
  emailtemplates: PropTypes.object.isRequired,
  hideEmailTemplate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

EmailTemplates.defaultProps = {
 
};



export default compose(
  withStyles(styles),
  graphql(CurrentEmailTemplates,{
      name: "emailtemplates",
     // options: ({student_id})=>({variables:{student_key:student_id}})

  }),
  graphql(HideEmailTemplate,{
    name:"hideEmailTemplate",
    options: {
        refetchQueries: [
            'EmailTemplates',
          ],
    }
}),

)(EmailTemplates)
