import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Stage, Layer} from 'react-konva';
import Chairs from './FloorPlan/Chairs';
import ButtonZoomIn from './FloorPlan/ButtonZoomIn';
import ButtonZoomOut from './FloorPlan/ButtonZoomOut';
import ButtonZoomReset from './FloorPlan/ButtonZoomReset';
import Button from '@material-ui/core/Button';




import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

//const pMap = require('p-map');

const styles = theme => ({
   root: {
   //  width:1000,
   //  height:500,
   }
});


const CREATE_SEAT = gql`
mutation createSeat($zone_id:ID!,$name:String!,$type:SeatType!, $capacity:Int!, $box_capacity_left:Int, $box_capacity_right:Int, $box_capacity_top:Int, $box_capacity_bottom:Int, $map_position_x:Int!, $map_position_y:Int!, $map_position_angle:Int,$map_sector_angle:Int) {
  seatCreate(zone_id:$zone_id,name:$name,type:$type,capacity:$capacity,box_capacity_left:$box_capacity_left,box_capacity_right:$box_capacity_right,box_capacity_top:$box_capacity_top,box_capacity_bottom:$box_capacity_bottom,map_position_x:$map_position_x,map_position_y:$map_position_y,map_position_angle:$map_position_angle,map_sector_angle:$map_sector_angle) {
    id
  }
}
`;


class FloorPlanView extends React.Component {

  constructor(props) {
    super(props);
    this.chairs = React.createRef();
    this.plan_layer = React.createRef();
    this.default_zoom=0;
    this.state= {
      zoomLevel:this.default_zoom,
      seat_recs:null,
      pointer_x:0,
      pointer_y:0,
    }

    this.handleResize = this.updateDim.bind(this);
  }


  updateDim() {
    console.log("updateDim");
    let width = window.innerWidth*0.9;
    let height = window.innerHeight*0.9-100;
    let zoomW = width / 6000;
    let zoomH = height / 6000;
    let zoom = Math.min(zoomW,zoomH) * 4;
    this.default_zoom = zoom;
    this.setState({zoomLevel:zoom});
  }

  componentDidMount() {
    this.updateDim();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  onChairsChange(data) {
    console.log(data);
  }

  handleClickZoomIn() {
    const { zoomLevel} = this.state;
    this.setState({zoomLevel:zoomLevel*1.2})
  }

  handleClickZoomOut() {
    const { zoomLevel} = this.state;
    this.setState({zoomLevel:zoomLevel/1.2})
  }

  handleClickZoomReset() {
    this.setState({zoomLevel:this.default_zoom})
    const plan_layer = this.plan_layer.current;
    if (plan_layer) {
      plan_layer.x(0);
      plan_layer.y(0);
      plan_layer.draw();
    }
  }

  onMouseMove(e) {
    //console.log("mouse move",e)
    //console.log(e.target);

    let x = e.evt.layerX;
    let y = e.evt.layerY;


    const plan_layer = this.plan_layer.current;
    if (plan_layer) {
      const tr = plan_layer.getAbsoluteTransform().copy();
      const p = tr.invert().point({x,y});
      this.setState({pointer_x:Math.round(p.x),pointer_y:Math.round(p.y)});
    }


    //this.setState({pointer_x:x,pointer_y:y});
  }

  showSeats() {
    if (this.state.seat_recs) {
      this.setState({seat_recs:null});
      return;
    } 
    if (this.chairs.current) {
      console.log(this.chairs.current.recs);
      this.setState({seat_recs:this.chairs.current.recs});
      /*
      pMap(this.chairs.current.recs,(r)=>{
        return this.props.createSeat({
          variables: {
            zone_id:"5b4623fc6d9ae163b67d7463",
            ...r
          }
        });
      },{concurrency:1}).then(done=>{
        console.log(done)
      })
      */

    }
  }
  render() {
    const { classes,hack } = this.props;
    const { zoomLevel,seat_recs,pointer_x,pointer_y} = this.state;

    let width = window.innerWidth*0.9;
    let height = window.innerHeight*0.9-100;


    return (
      <React.Fragment>
      <div> {pointer_x} : {pointer_y} </div>
      {hack && (<Button onClick={()=>this.showSeats()}>show seats</Button>)}
      {seat_recs && (
        <React.Fragment>
          {seat_recs.map((s,idx)=>{
            return (<div key={idx}>{JSON.stringify(s)}</div>)
          })}
        </React.Fragment>
      )}
      <Paper className={classes.root} style={{width:width,height:height}}>
         <Stage width={width} height={height} onMouseMove={(e)=>this.onMouseMove(e)} >
            <Layer ref={this.plan_layer} draggable={true} scaleX={zoomLevel} scaleY={zoomLevel} >
              <Chairs ref={this.chairs} onChange={(d)=>{this.onChairsChange(d)}}>


                {this.props.children}

              </Chairs>
            </Layer>
            <Layer>
              <ButtonZoomIn x={10} y={10} onClick={(x)=>this.handleClickZoomIn(x)} />
              <ButtonZoomOut x={40} y={10} onClick={(x)=>this.handleClickZoomOut(x)} />
              <ButtonZoomReset x={70} y={10} onClick={(x)=>this.handleClickZoomReset(x)} />
            </Layer>
         </Stage>
      </Paper>
      </React.Fragment>
    );
  }
}

FloorPlanView.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  refetch: PropTypes.func,
  hack:PropTypes.bool,
  createSeat: PropTypes.func.isRequired
};
FloorPlanView.defaultProps = {
  hack:false
};


export default compose(
  withStyles(styles),
  graphql(CREATE_SEAT,{
    name: "createSeat",
  }),
)(FloorPlanView)
