import React from 'react';
import PropTypes from 'prop-types';

import { Rect, Path, Group } from 'react-konva';

class ButtonZoomOut extends React.Component {
  
  
    render() {
      const {x,y,color,bg_color} = this.props;
      return (
        <Group x={x} y={y} onClick={(x)=>this.props.onClick(x)} onTap={(x)=>this.props.onClick(x)}>
          <Rect 
            x={-2}
            y={-2}
            width={28}
            height={28}
            fill={bg_color}
          />
          <Path
            data={"M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"} 
            fill={color}
            stroke={color}
            strokeWidth={1}
          />

          <Path
            data={"M0 0h24v24H0V0z"} 
            stroke={color}
            strokeWidth={1}
          />

         
        </Group>
      )
    }
  }
  ButtonZoomOut.propTypes = {
    onClick: PropTypes.func.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    color: PropTypes.any,
    bg_color: PropTypes.any,
  }
  ButtonZoomOut.defaultProps = {
    x:0,
    y:0,
    color:'black',
    bg_color:'lightgray',
  }
  

  export default ButtonZoomOut;