import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
 
});


class SexView extends React.PureComponent {

  render() {
   // const { classes } = this.props;
    var s;
    switch(this.props.sex) {
      case "MALE": s="P"; break;
      case "FEMALE": s="D"; break;
      default: s="?"; 
    }
    return (
      <span style={{marginRight:8,marginLeft:4}}>
        {s}
      </span>
    );
  }
}

SexView.propTypes = {
  classes: PropTypes.object.isRequired,
  sex: PropTypes.oneOf(["MALE","FEMALE"]).isRequired
};
SexView.defaultProps = {
  
};

export default withStyles(styles)(SexView);