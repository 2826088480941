import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import PdfViewDialog from './PdfViewDialog';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import gql from "graphql-tag";
import { compose, graphql} from "react-apollo";



const PRINT_COURSE = gql`
mutation printCourse($course_key:String!,$mode:CoursePrintMode!) {
  printCourse(course_key:$course_key,mode:$mode) {
    url
    file_name
    content_type
    error
    error_message
  }
}`;

const PRINT_COURSE_CMD_CARDS = gql`
mutation printCourseCmdCards($course_key:String!) {
  printCourseCmdCards(course_key:$course_key) {
    url
    file_name
    content_type
    error
    error_message
  }
}`;


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  tab_root: {
    minWidth:100
  }
});


class CourseHeaderTabs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      printMenuAnchorEl: null,
      printCourseDlgOpen:false,
      printCourseDlgUrl:null,
    };
  }
 

  handleActionPrintCourse(mode) {
    this.setState({printCourseDlgOpen:true,printCourseDlgUrl:null,printMenuAnchorEl: null},()=>{
        this.props.printCourse({variables:{
          course_key:this.props.course.key,
          mode:mode
        }}).then(res=>{
          if (res.data.printCourse.error) {
            this.setState({printCourseDlgOpen:false});
            console.log("PRINT",res.data);
            alert("chyba:"+res.data.printCourse.error_message);
          } else {
            this.setState({printCourseDlgOpen:true,printCourseDlgUrl:res.data.printCourse.url});
          }
        }).catch(err=>{
          this.setState({printCourseDlgOpen:false});
          alert("chyba:"+err);
        })
    }); 
  }

  handleActionPrintCourseCmdCards(mode) {
    this.setState({printCourseDlgOpen:true,printCourseDlgUrl:null,printMenuAnchorEl: null},()=>{
        this.props.printCourseCmdCards({variables:{
          course_key:this.props.course.key,
        }}).then(res=>{
          if (res.data.printCourseCmdCards.error) {
            this.setState({printCourseDlgOpen:false});
            console.log("PRINT",res.data);
            alert("chyba:"+res.data.printCourseCmdCards.error_message);
          } else {
            this.setState({printCourseDlgOpen:true,printCourseDlgUrl:res.data.printCourseCmdCards.url});
          }
        }).catch(err=>{
          this.setState({printCourseDlgOpen:false});
          alert("chyba:"+err);
        })
    }); 
  }

  handleClosePrintCourseDlg() {
    this.setState({printCourseDlgOpen:false})
  }

  renderPrintCourseDialog(){
    return (
      <PdfViewDialog caption={"Tisk"} pdfUrl={this.state.printCourseDlgUrl} open={this.state.printCourseDlgOpen} onClose={()=>this.handleClosePrintCourseDlg()}/>
    )
  }


  handlePrintMenu(event) {
    this.setState({ printMenuAnchorEl: event.currentTarget });
  };

  handlePrintMenuClose(){
    this.setState({ printMenuAnchorEl: null });
  };
  
  render() {
    const { classes,course,auth,activeTab,evi_group} = this.props;
    const printMenuOpen = Boolean(this.state.printMenuAnchorEl);
    const printCourseDialog = this.renderPrintCourseDialog();

    const warn = (evi_group!==null) && (evi_group !== course.evi_group);
    return (
      <AppBar position="static" color="default">
          {printCourseDialog}
          {warn && (
            <Typography variant="title" color="error">Pozor, kurz je z jiné evidenční skupiny!</Typography>
          )}
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                Kurz {course.code} - {course.name}
            </Typography>
          
            <Tabs className={classes.toolBar_item} value={activeTab}>
              {auth.is_admin && (
                 <Tab classes={{root:classes.tab_root}} value="INFO" label="Přehled" component={Link} to={"/spa/course/"+course.key+"/info"}/>
              )}
              <Tab classes={{root:classes.tab_root}} value="IN" label="Zapsaní" component={Link} to={"/spa/course/"+course.key+"/in"}/>
              <Tab classes={{root:classes.tab_root}} value="FILED" label="Přihlášky" component={Link} to={"/spa/course/"+course.key+"/filed"}/>
              <Tab classes={{root:classes.tab_root}} value="SPARE" label="Náhradníci" component={Link} to={"/spa/course/"+course.key+"/spare"}/>
              {auth.is_admin && (
              <Tab classes={{root:classes.tab_root}} value="KICKED" label="Smazaní" component={Link} to={"/spa/course/"+course.key+"/kicked"}/>
              )}
              {auth.is_admin && (
              <Tab classes={{root:classes.tab_root}} value="CASH" label="Peníze" component={Link} to={"/spa/course/"+course.key+"/cash"}/>
              )}
              <Tab classes={{root:classes.tab_root}} value="ATTENDANCE" label="Docházka" component={Link} to={"/spa/course/"+course.key+"/attendance"}/>
            </Tabs>

            <Button color="inherit" onClick={(e)=>this.handlePrintMenu(e)}> TISK </Button>
            <Menu
                  id="menu-appbar"
                  anchorEl={this.state.printMenuAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={printMenuOpen}
                  onClose={()=>this.handlePrintMenuClose()}
                >
                  <MenuItem onClick={()=>this.handleActionPrintCourse("BASIC")}>Základní</MenuItem>
                  <MenuItem onClick={()=>this.handleActionPrintCourse("CONTACT")}>Kontakty</MenuItem>
                  {auth.is_admin && (
                    <MenuItem onClick={()=>this.handleActionPrintCourseCmdCards()}>Ovládací karty</MenuItem>
                  )}
                </Menu>
          </Toolbar>
      </AppBar>
    );
  }
}

CourseHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  activeTab: PropTypes.oneOf(["INFO","IN","FILED","SPARE","KICKED","ATTENDANCE","CASH"]).isRequired,
  printCourse: PropTypes.func.isRequired,
  printCourseCmdCards: PropTypes.func.isRequired,
  evi_group: PropTypes.string
};
CourseHeaderTabs.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  graphql(PRINT_COURSE,{
    name:"printCourse",
  }),
  graphql(PRINT_COURSE_CMD_CARDS,{
    name:"printCourseCmdCards",
  }),
)(CourseHeaderTabs);
