import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import FolderForm from './FolderForm';
/*
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});
*/



const GET_FOLDER = gql`
  query Folder($folder_key:String!){
      folder(key: $folder_key) {
        key
        name
        no_stat
        access_check
        order_value
        evi_group
        enroll_group
      }
  }
`;

const UPDATE_FOLDER = gql`
mutation updateFolder($key:String!,$name:String, $no_stat:Boolean, $access_check:Boolean, $order_value:Int, $evi_group: EviGroup, $enroll_group:Int) {
  folderUpdate(key:$key,name:$name,no_stat:$no_stat, access_check:$access_check,order_value:$order_value,evi_group:$evi_group,enroll_group:$enroll_group) {
    key
    name
    no_stat
    access_check
    order_value
    evi_group
    enroll_group
  }
}
`;

class FolderEdit extends React.Component {


  constructor(props) {
    super(props);
    
    this.state = {  
      dirty:false,
      valid:true
    }
    this.form = React.createRef();
  }


  doSave() {
    console.log("FolderEdit doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:UPDATE_FOLDER,
      variables:this.form.current.state.doc,
      refetchQueries:["Folders","Folder","Catalog"]
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
   
  }
 
  onDocChange(d,valid) {
    console.log("FolderEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 
 


 
  render() {
    const {folder_key} = this.props;
    return (
      <div className="foo">
      <Query query={GET_FOLDER} variables={{ folder_key }}  fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
            <FolderForm innerRef={this.form} doc={data.folder} onDocChange={(d,v)=>this.onDocChange(d,v)} />
          )
        }}
      </Query>
      </div>
    );
  }
}

FolderEdit.propTypes = {
  client: PropTypes.object.isRequired,
  folder_key: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
FolderEdit.defaultProps = {
  
};

export default withApollo(FolderEdit,{withRef:true});