import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lodash from 'lodash';
import EventZoneMap from './EventZoneMap';
import EventZoneSeatChooseMap from './EventZoneSeatChooseMap';

import EventTicketVariantList from './EventTicketVariantList';
import EventTicketVariantOne from './EventTicketVariantOne';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import TicketIcon from '@material-ui/icons/Receipt';


const styles = theme => ({
  root: {
   // marginTop: 20
   // width: '90%',
  },
  ticketicon: {
    marginRight: 20
  },
  grow: {
    flexGrow: 1,
  },
  res_info: {
    marginLeft: theme.spacing.unit*3,
    //border: "1px solid green",
  },

  sale_info: {
    marginLeft: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*2,
    paddingLeft: theme.spacing.unit*1,
    paddingRight: theme.spacing.unit*1,
    border: "2px solid red",
  },

  cont: {
    padding: theme.spacing.unit,
  }
});

function getZonesWithPrices(zoneprices,zones) {
  return Lodash.filter(zones,z=>{
    return Lodash.find(zoneprices,{event_zone_id:z.id}) != undefined;
  });
}

function getZoneSeats(allseats,zone) {
  return Lodash.filter(allseats,(s)=>{
    return s.event_zone_id == zone.id;
  })
}

function getFreeZones(zones) {
  return Lodash.filter(zones,z=>{
    return z.free_zone == true;
  })
}

function getNonFreeZones(zones) {
  return Lodash.filter(zones,z=>{
    return z.free_zone != true;
  })
}

function getZoneSeatMarkers(zonesmarker,zone) {
  const zm = Lodash.find(zonesmarker,{event_zone_id:zone.id})
  if (zm == undefined) {
    return [];
  }
  return zm.markers;
}

function isSeatFree(markers,seat,seat_idx) {
  return Lodash.find(markers,{event_seat_id:seat.id,event_seat_idx:seat_idx}) == undefined;
}



class SaleEventChooseTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_id:null,
      step:null,
      zone:null,
      seat:null,
      seat_idx:null,
      price:null,
      zonePrices:[],
      zoneSeats:[],
      freeZones:[],
      nonFreeZones:[],
    }
    console.log("SaleEventChooseTicket.constructor",this.props,this.state);
  }
  static getDerivedStateFromProps(props, state) {
    console.log("SaleEventChooseTicket.getDerivedStateFromProps",props,state);
    if (props.event.id != state.event_id) {
      console.log("SaleEventChooseTicket.getDerivedStateFromProps", "new state");

      const zones = getZonesWithPrices(props.event.activezoneprices,props.event.zones);

      return  {
        step:-1,
        event_id:props.event.id,
        nonFreeZones: getNonFreeZones(zones),
        freeZones: getFreeZones(zones)
      }
    }
    return null ;
  }



  handleAddTicket(zone,seat,seat_idx,price) {
    this.props.onCreateNewTicket({zone,seat,seat_idx,price},(t)=>{
      //console.log("new ticket created",t);
      if (t.ok) {
        if (zone.free_zone) {
          this.setState({step:-1,zone:null,price:null,seat:null,seat_idx:null});
        } else {
          this.setState({step:-2,seat:null,seat_idx:null});
        }
        
      } else {
        alert("Nepodařilo se přidat vstupenku do košíku");
      }
    })
  }

 
  handleSelectTicketVariant(zone,price) {
    const { event } = this.props;
    const zoneSeats = getZoneSeats(event.seats,zone);
    this.setState({zoneSeats,zone,price,seat:null,seat_idx:null,step:zone.free_zone?-3:-2});
  }

  handleResetTicketVariant() {
    this.setState({zoneSeats:[],zone:null,price:null,seat:null,seat_idx:null,step:-1});
  }

  handleSelectTicketPlace(seat,seat_idx) {
    const {event} = this.props;
    const zoneSeatMarkers = getZoneSeatMarkers(event.capacity_info.zone_infos,{id:seat.event_zone_id});
    const free = isSeatFree(zoneSeatMarkers,seat,seat_idx);
    if (!free) {
      alert("Místo není volné");
    } else {
      this.setState({seat,seat_idx,step:-3});
    }
  }



  renderChooseTicket() {
    const { classes, event } = this.props;
    const { nonFreeZones , freeZones} = this.state;
    const with_map = (nonFreeZones.length>0);
    return (
      <React.Fragment>
          <Toolbar variant={"dense"}  className={classes.toolbar}>
            <TicketIcon  className={classes.ticketicon} />
            <Typography className={classes.grow} variant="title" color="inherit"> 
              Výběr vstupenky 
            </Typography>
          </Toolbar>
          <Divider/>

          <Grid className={classes.cont} container spacing={32} justify="space-between" alignItems="flex-start" >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <EventTicketVariantList label={"Nabídka vstupenek"} zone_capacity_infos={event.capacity_info.zone_infos} prices={event.activezoneprices} zones={[...freeZones,...nonFreeZones]} onSelect={(z,p)=>this.handleSelectTicketVariant(z,p)}/>
              </Grid>
              {with_map && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <EventZoneMap event_place={event.place} label={"Plán rozmístění zón"} list={nonFreeZones}/>
                </Grid>
              )}
          </Grid>
          <Divider/>
          <Typography variant={"body2"} className={classes.res_info}> 
              {"Vyberte variantu vstupenky z nabídky vstupenek."}
              {with_map && " V plánku prostor je vidět umístění jednotlivých zón."} 
          </Typography>
          <Divider/>
      </React.Fragment>
    );
  }

  renderChooseTicketPlace() { 

    const { classes, event } = this.props;
    const { zone,price,zoneSeats} = this.state;
    const zoneSeatMarkers = getZoneSeatMarkers(event.capacity_info.zone_infos,zone);
   // console.log("zoneSeatMarkers:",zoneSeatMarkers)
    return (
      <React.Fragment>
          <Toolbar variant={"dense"}  className={classes.toolbar}>
            <TicketIcon  className={classes.ticketicon} />
            <Typography className={classes.grow} variant="title" color="inherit"> 
              Výběr místa k sezení 
            </Typography>
          </Toolbar>
          <Divider/>

          <Grid className={classes.cont} container spacing={32} justify="space-between" alignItems="flex-start" >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <EventTicketVariantOne label={"Zvolený typ vstupenky"} price={price} zone={zone} onReset={()=>this.handleResetTicketVariant()}/>
                { price.sale_student && (
                  <Typography variant={"dense" } className={classes.sale_info} > Slevněná vstupenka pro žáky TŠ Starlet (při vstupu je třeba předložit platnou legitimaci kurzu pro dospělé probíhající sezóny, 1 legitimace = 1 slevněná vstupenka) </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <EventZoneSeatChooseMap label={"Mapa míst pro vstupenku"} event_place={event.place} event_zone={zone} markers={zoneSeatMarkers} list={zoneSeats} onSelect={(s,si)=>this.handleSelectTicketPlace(s,si)} />
              </Grid>
          </Grid>
          <Divider/>
          <Typography variant={"body2"} className={classes.res_info}> Vyberte místo kliknutím do mapy míst. </Typography>
          <Divider/>
      </React.Fragment>
    );

  }

  renderConfirmTicket() {
    const { classes, event } = this.props;
    const { zone,price,zoneSeats,seat,seat_idx} = this.state;
    let zoneSeatMarkers = zone.free_zone?[]:getZoneSeatMarkers(event.capacity_info.zone_infos,zone);
    if (zone && seat && seat_idx!=null) {
      zoneSeatMarkers = [...zoneSeatMarkers,{type:"SELECTED",my_booking:true,event_seat_id:seat.id,event_zone_id:zone.id,event_seat_idx:seat_idx}];
    }
    return (
      <React.Fragment>
          <Toolbar variant={"dense"}  className={classes.toolbar}>
            <TicketIcon  className={classes.ticketicon} />
            <Typography className={classes.grow} variant="title" color="inherit"> 
               Vstupenka do košíku 
            </Typography>
          </Toolbar>
          <Divider/>

          <Grid className={classes.cont} container spacing={32} justify="space-between" alignItems="flex-start" >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <EventTicketVariantOne label={"Zvolená vstupenka"} seat={seat} seat_idx={seat_idx} price={price} zone={zone} onReset={()=>this.handleResetTicketVariant()}/>

                <Toolbar variant={"dense"}  className={classes.toolbar}>
                  <Button variant="contained" onClick={()=>this.handleAddTicket(zone,seat,seat_idx,price)}> do košíku </Button>
                </Toolbar>

              </Grid>
              { !zone.free_zone && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <EventZoneSeatChooseMap label={"Mapa míst pro vstupenku"} event_place={event.place} event_zone={zone} list={zoneSeats}  markers={zoneSeatMarkers} onSelect={(s,si)=>this.handleSelectTicketPlace(s,si)} />
                </Grid>
              )}
          
          </Grid>
          <Divider/>
          <Typography variant={"body2"} className={classes.res_info}> Vstupenka je vybraná, přidejte ji do košíku. Na jeden nákup můžete zakoupit maximálně 5 vstupenek. </Typography>
          <Divider/>
      </React.Fragment>
    );
  }
 

  render() {
    const { classes } = this.props;
    const { step } = this.state;
    console.log("SaleEventChooseTicket.render",this.props,this.state)
    let main = null;
    switch(step) {
      case -3: main=this.renderConfirmTicket(); break;
      case -2: main=this.renderChooseTicketPlace(); break;
      case -1: main=this.renderChooseTicket(); break;
    }

    return (
      <div className={classes.root}>
        {main}
      </div>
    );
  }
}

SaleEventChooseTicket.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  onCreateNewTicket: PropTypes.func.isRequired,
};
SaleEventChooseTicket.defaultProps = {
  
};


export default withStyles(styles)(SaleEventChooseTicket);