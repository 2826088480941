import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query,compose, graphql} from "react-apollo";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FioTransactionTypeView from './Fields/FioTransactionTypeView';
import DateView from './DateView';


const styles = theme => ({
  dialog: {
    width:600
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 400,
  },
});

const GET_BANKTRS = gql`
  query BankTrs($firmKey: String!){    
    bankFioToReview(firmKey:$firmKey) {
      id
      ps
      psRef
      fioId
      fioAccountId
      date
      amount
      currency
      type
      fAccountId
      fBankId
      fAccountName
      fBankName
      ks
      vs
      ss
      userRef
      userMsg
      comment
    }
  }
`;

const HIDE_TR = gql`
mutation bankFioTrIgnore($id: ID!) {
  bankFioTrIgnore(id:$id) 
}`;


class BankTrs extends React.Component {

  constructor(props) {
    super(props);
    this.refetch_trs = null;
    this.state = {
      mark:null,
    }
  }

  handleMarkReq(e){
    this.setState({mark:e});
  }
  handleCancelMark() {
    this.setState({mark:null});
  }
  handleDoMark() {
    this.props.bankFioTrIgnore({variables:{id:this.state.mark.id}}).then(r=>{
      this.setState({mark:null});
      if (r.data && r.data.bankFioTrIgnore) {
        
      } else {
        alert('chyba');
      }
      this.refetch_trs();
    }).catch(err=>{
      alert('chyba');
      this.refetch_trs();
    });
  }
  
 
 renderMarkDialog() {
//   const classes = this.props.classes;
   return (
       <Dialog open={!!this.state.mark} onClose={(e)=>this.handleCancelMark()}>
           <DialogTitle>Označit transakci za vyřešenou</DialogTitle>
           <DialogContent>
                Bankovní transakce bude označena jako ručně vyřešená a bude odebrána z přehledu.
           </DialogContent>
           <DialogActions>
               <Button onClick={(e)=>this.handleCancelMark()} color="primary">
                   Storno
               </Button>
               <Button onClick={(e)=>this.handleDoMark()} color="primary">
                   Označit
               </Button>
           </DialogActions>
       </Dialog>
   )
}

  render() {
    const { auth, evi_group } = this.props;
    const dlg = this.renderMarkDialog()
    const firmKey = evi_group?evi_group.toLowerCase():"";

    return (
      <div className="foo">
      {dlg}
       <Query query={GET_BANKTRS}  variables={{  firmKey:firmKey}}> 
        {({ loading, error, data, refetch }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          this.refetch_trs = refetch;
          return (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> {"Typ"} </TableCell>
                    <TableCell> {"Datum"} </TableCell>
                    <TableCell> {"Částka"} </TableCell>
                    <TableCell> {"Název účtu"} </TableCell>
                    <TableCell> {"Číslo účtu"} </TableCell>
                    <TableCell> {"VS"} </TableCell>
                    <TableCell> {"Zpráva"} </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.bankFioToReview.map(e=>{
                    return (
                      <TableRow key={e.id}>
                          <TableCell> <FioTransactionTypeView value={e.type}/> </TableCell>
                          <TableCell> <DateView  only_date={true} date={ e.date}/> </TableCell>
                          <TableCell> {e.amount} </TableCell>
                          <TableCell> {e.fAccountName} </TableCell>
                          <TableCell> {e.fAccountId}/{e.fBankId}  </TableCell>
                          <TableCell> {e.vs} </TableCell>
                          <TableCell> {e.userMsg} </TableCell>
                          <TableCell> 

                          {auth.is_admin &&(
                            <IconButton style={{height:24}} color={'primary'} onClick={()=>this.handleMarkReq(e)}>
                              <DoneIcon/>
                            </IconButton>
                          )}
                              

                          </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
          )
        }}
      </Query>
      </div>
    );
  }
}

BankTrs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  bankFioTrIgnore: PropTypes.func.isRequired,
  evi_group: PropTypes.string,
};
BankTrs.defaultProps = {
  
};




export default compose(
  withStyles(styles),
  graphql(HIDE_TR,{
    name:"bankFioTrIgnore",
  }),
)(BankTrs);

