import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
 
});


class BooleanView extends React.PureComponent {

  render() {
   // const { classes } = this.props;
    var s;
    switch(this.props.value) {
      case true: s="ano"; break;
      case false: s="ne"; break;
      default: s=""; 
    }
    return (
      <span>
        {s}
      </span>
    );
  }
}

BooleanView.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool
};
BooleanView.defaultProps = {
  
};

export default withStyles(styles)(BooleanView);