import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
//import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
//import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client'

import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import {store,persistor} from './store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import moment from 'moment';
import 'moment/locale/cs';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';


import { startCron } from './cron';
import Raven from 'raven-js';
import LogRocket from 'logrocket';
import version from './../version.json';


console.log("NODE_ENV",process.env.NODE_ENV);

if (process.env.NODE_ENV !== 'production') {

    console.log("DEVELOPMENT VERSION:",version)
   /* 
    LogRocket.init('evmn92/tsv',{
        release: "DEV"
    });
    

    Raven.setDataCallback(function (data) {
        data.extra.sessionURL = LogRocket.sessionURL;
        return data;
    });
    Raven.config('https://bf40fd1ef3c849cea57958d3259b6266@sentry.io/1208129').install();
*/
} else {
    console.log("PRODUCTION VERSION:",version)
    LogRocket.init('evmn92/tsv',{
        release: version.commit,
     });

    
    Raven.setDataCallback(function (data) {
        data.extra.sessionURL = LogRocket.sessionURL;
        return data;
    });
    Raven.config('https://bf40fd1ef3c849cea57958d3259b6266@sentry.io/1208129',{
        release: version.commit,
        environment: process.env.NODE_ENV
    }).install();
    
}




const apolloClient = new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    link: new createUploadLink({credentials: 'same-origin'}),
    cache: new InMemoryCache({
        dataIdFromObject: object => {
            //console.log("dataIdFromObject",object)
            switch (object.__typename) {
                case 'Course': return object.key;
                default: return defaultDataIdFromObject(object);
            }
        }
    })
  });
  

let app = document.getElementById('app_embed')
if (app !== null) {
    console.log("render app");
    render( (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter>
                        <MuiPickersUtilsProvider utils={MomentUtils} moment={moment} locale="cs">
                            <App />
                        </MuiPickersUtilsProvider>
                    </BrowserRouter>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    ), app );
    startCron();
}





console.log("reactbundle done")