
import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { clearErrorMessage, clearInfoMessage, setEviGroup , setDebugView} from './../actions';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CoursesBoard from './CoursesBoard';
import CourseBoard from './CourseBoard';
import SearchBoard from './SearchBoard';
import StudentInvoiceBoard from './StudentInvoiceBoard';
import StudentNewCardBoard from './StudentNewCardBoard';
import StudentDebugBoard from './StudentDebugBoard';
import StudentPaymentBoard from './StudentPaymentBoard';
import EmailsBoard from './EmailsBoard';
import BanksBoard from './BanksBoard';
import TicketsBoard from './TicketsBoard';
import EnrollBoard from './EnrollBoard';
import AssistantsBoard from './AssistantsBoard';
//import FoldersBoard from './FoldersBoard';
//import SeasonsBoard from './SeasonsBoard';
//import CGroupsBoard from './CGroupsBoard';
import CGroupBoard from './CGroupBoard';

import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import MenuEnumField from './MenuEnumField';

import Login from "./Login";
import {isAuth,doLogout} from './../auth';
import Version from "./Version";
import SnackbarContent from '@material-ui/core/SnackbarContent';
import FloorPlanView from './FloorPlanView';
import SetupEventBoard from './SetupEventBoard';
import SetupEventZoneBoard from './SetupEventZoneBoard';
import SaleEventBoard from './SaleEventBoard';
import ConfigBoard from './ConfigBoard';
import AuthOnly from './Utils/AuthOnly';
import MUSwitch from '@material-ui/core/Switch';
//var axios = require('axios');

const mytheme_warn = createMuiTheme({
  palette: {
    primary: pink,
    secondary: indigo,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const mytheme = createMuiTheme({
  //palette: {
    //primary: indigo,
    //secondary: pink,
    //error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    //contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    //tonalOffset: 0.2,
  //},
});


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  name: {
    paddingRight: theme.spacing.unit,
  },
  toolBar_root: {
    minHeight:48
  },
  snackbar: {
    margin: theme.spacing.unit,
  },
});

/* eslint-disable react/prop-types */

const PageNoMatch = ({match}) => (
  <div>
     zvol z menu
  </div>
);
/*
const PageCourse = ({match}) => (
  <div>
      page course {match.params.course_key}
  </div>
);
*/

const PageTest = ({match}) => (
  <div>
      <FloorPlanView />
  </div>
);
/*
const PageMyCourses = ({match,route}) => (
  <div>
  </div>
);
*/
//<MyCourses auth={auth} list_key={auth.course_list}  />

/* eslint-enable react/prop-types */


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: null
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.current_auth_user) {
        const role = nextProps.current_auth_user.role;
        return {
          auth: {
            is_view:["GUEST"].indexOf(role)!==-1, 
            is_admin:["ADMIN","SUPER_ADMIN"].indexOf(role)!==-1,
            is_clerk:["ADMIN","SUPER_ADMIN","CLERK"].indexOf(role)!==-1,
            is_super:["SUPER_ADMIN"].indexOf(role)!==-1,
            is_lector:["TEACHER"].indexOf(role)!==-1,
            is_admin_or_view: ["ADMIN","SUPER_ADMIN","GUEST"].indexOf(role)!==-1,
            course_list:nextProps.current_auth_user.cgroup_key,
            user_name:nextProps.current_auth_user.name,
            role:nextProps.current_auth_user.role,
          }
        }
    } else {
        return {
          auth:null
        }
    }
  }

  checkAuth() {
    return /*this.props.auth && */isAuth();
  }

  handleLogout() {
    doLogout();
    /*
    axios.post("/log-me-out").then(r=>{
      window.location.href = "/"
    }*/
    /*
    form(action="/log-me-out", method="POST")
          button.dropdown-item() odhlasit
    */
  }

  handleChangeEviGroup(v) {
    console.log("new evi group",v)
    this.props.onChangeEviGroup(v);
  }
 
  renderMenuBar() {

    const {classes,evi_group} = this.props;
    const {auth} = this.state; 
    return (
      <AppBar position="static">
          <Toolbar classes={{ root: classes.toolBar_root }}>
          <Typography className={classes.name} variant="title" color="inherit">
              TŠ
          </Typography>
          
           { auth && auth.is_admin_or_view && (
            <MenuEnumField width={120} id="global_evi_group"  values={[{key:"VB",label:"VB"},{key:"LB",label:"LB"},{key:"SRO",label:"sro"},{key:"AB",label:"AB"},{key:"ST",label:"stadion"},{key:"ZS",label:"Spolek"},{key:"NO",label:"nezařazeno"}]} value={evi_group}  onChange={(v)=>this.handleChangeEviGroup(v)} />
           )}

          { auth && auth.is_lector && (
              <Button color="inherit" component={Link} to="/spa">Moje kurzy</Button>
          )}

          { auth && auth.is_admin_or_view && (
              <React.Fragment>
                <Button color="inherit" component={Link} to="/spa">Kurzy</Button>
                <Button color="inherit" component={Link} to="/spa/srch">Hledání</Button>
                <Button color="inherit" component={Link} to="/spa/emails/in">Pošta</Button>
                <Button color="inherit" component={Link} to="/spa/banks/trs">Banka</Button>
                <Button color="inherit" component={Link} to="/spa/enroll/preview">Zápis</Button>
                <Button color="inherit" component={Link} to="/spa/asistants/list">Asistenti</Button>
              </React.Fragment>
          )}

          { auth && auth.is_clerk && (
              <React.Fragment>
                  <Button color="inherit" component={Link} to="/spa/tickets/events">Akce</Button>
              </React.Fragment>
          )}

          { auth && auth.is_admin && (
              <React.Fragment>
                <Button color="inherit" component={Link} to="/spa/config/info">Nastavení</Button>
              </React.Fragment>
          )}
          
          <div className={classes.flex}/>
          { auth && (
            <React.Fragment>
              <AuthOnly super>
                <MUSwitch checked={this.props.debug_view}  onChange={(e,checked)=>this.props.onChangeDebugView(checked)}  value="debug_view"/>
                <div>*</div>
              </AuthOnly>
              <div> {auth.user_name} </div>
              <Button color="inherit" onClick={()=>this.handleLogout()}>odhlásit</Button>
            </React.Fragment>
          )}
          </Toolbar>
        </AppBar>
    );
  }

  appreload() {
    window.location.reload(true);
  }

  renderServerState() {
    const {classes,offline,new_version}  = this.props;
    if (offline) {
      return (
        <SnackbarContent className={classes.snackbar} message="Server není dostupný. Vyčkejte pár minut nebo můžete zkusit Evidenci přenačíst." action={(<Button color="secondary" size="small" onClick={()=>this.appreload()}> přenačíst </Button>)} />
      );
    }
    if (new_version) {
      return (
        <SnackbarContent className={classes.snackbar} message="Nová verze Evidence je připravena." action={(<Button color="secondary" size="small" onClick={()=>this.appreload()}> přenačíst </Button>)} />
      );
    }
    return null;
  }

  render() {
    console.log("MASTER RENDER");
    const {classes} = this.props;
    const {auth} = this.state;
    if (auth && this.checkAuth()) {
      return this.renderApp();
    } else {
      const ss = this.renderServerState();
      return (
        <div className={classes.root}>
        <CssBaseline />
        <Version />
        {ss}
        <Login/>
        </div>      
      )
    }
  }
  
  renderApp() {
    const {classes,evi_group} = this.props;
    const {auth} = this.state;
    const evi_warn = (auth.is_admin) && (evi_group===null);
    const menuBar = this.renderMenuBar();
    const ss = this.renderServerState();
    return (
      <MuiThemeProvider theme={evi_warn?mytheme_warn:mytheme}>
      <div className={classes.root}>
        <CssBaseline />
        <Version />
        {ss}
        {evi_warn && (
          <Typography variant="title" color="error">Není zvolena evidenční skupina!</Typography>
        )}
        {menuBar}
        
        <Switch>
          <Route path="/spa/test/" component={PageTest}/>
          <Route path="/spa/course/:course_key/:mode" component={({match})=><CourseBoard auth={auth} course_key={match.params.course_key} mode={match.params.mode}/>}/>
          <Route path="/spa/course/:course_key" component={({match})=><CourseBoard auth={auth} course_key={match.params.course_key} mode={"info"}/>}/>

          <Route path="/spa/student/invoice/:student_key" component={({match})=><StudentInvoiceBoard auth={auth} student_key={match.params.student_key}/>}/>
          <Route path="/spa/student/newcard/:student_key" component={({match})=><StudentNewCardBoard auth={auth} student_key={match.params.student_key}/>}/>
          <Route path="/spa/student/payment/:student_key" component={({match})=><StudentPaymentBoard auth={auth} student_key={match.params.student_key}/>}/>

          <Route path="/spa/student/debug/:student_key" component={({match})=><StudentDebugBoard auth={auth} student_key={match.params.student_key}/>}/>


          <Route path="/spa/emails/:page" component={({match})=><EmailsBoard page={match.params.page} auth={auth}/>}/>

          <Route path="/spa/banks/:page" component={({match})=><BanksBoard page={match.params.page} auth={auth}/>}/>

          <Route path="/spa/enroll/preview/:enroll_group(\d+)" component={({match})=><EnrollBoard enroll_group={match.params.enroll_group} auth={auth}/>}/>
          <Route path="/spa/enroll/preview" component={({match})=><EnrollBoard  auth={auth}/>}/>
  
          <Route path="/spa/asistants/:page" component={({match})=><AssistantsBoard page={match.params.page} auth={auth}/>}/>
          <Route path="/spa/cgroups/detail/:cgroup_key" component={({match})=><CGroupBoard auth={auth} cgroup_key={match.params.cgroup_key}/>}/>
          <Route path="/spa/tickets/:page" component={({match})=><TicketsBoard page={match.params.page} auth={auth}/>}/>
          <Route path="/spa/config/:page" component={({match})=><ConfigBoard page={match.params.page} auth={auth}/>}/>

          <Route path="/spa/setup_event_zone/:event_id/:event_zone_id/:page" component={({match})=><SetupEventZoneBoard event_id={match.params.event_id} event_zone_id={match.params.event_zone_id} page={match.params.page} auth={auth}/>}/>
          <Route path="/spa/setup_event/:event_id/:page" component={({match})=><SetupEventBoard event_id={match.params.event_id} page={match.params.page} auth={auth}/>}/>
        
          <Route path="/spa/sale_event/:event_id/sale/:act?/:act_p?" component={({match})=><SaleEventBoard event_id={match.params.event_id} page={"sale"} action={match.params.act} action_param={match.params.act_p} auth={auth}/>}/>
        
          <Route path="/spa/sale_event/:event_id/:page" component={({match})=><SaleEventBoard event_id={match.params.event_id} page={match.params.page} auth={auth}/>}/>

          <Route path="/spa/srch" component={({match})=><SearchBoard auth={auth}/>}/>
       
          <Route path="/spa" exact component={() => <CoursesBoard auth={auth} />}/>
        
        
        
          <Route component={PageNoMatch}/>
        </Switch>  
        
      </div>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  //auth: PropTypes.object,
  onChangeEviGroup: PropTypes.func.isRequired,
  onChangeDebugView: PropTypes.func.isRequired,
  evi_group: PropTypes.string,
  offline: PropTypes.bool,
  new_version: PropTypes.bool,
  debug_view: PropTypes.bool,

  current_auth_user:  PropTypes.object.isRequired
};
App.defaultProps = {
  
};

const mapDispatchToProps = dispatch => {
  return {
    onClearError: () => {
      dispatch(clearErrorMessage())
    },
    onClearInfo: () => {
      dispatch(clearInfoMessage())
    },
    onChangeEviGroup: key => {
      dispatch(setEviGroup(key))
    },
    onChangeDebugView: value => {
      dispatch(setDebugView(value))
    },
  }
}

function mapStateToProps(state) {
  return { 
      error_message:state.notify.error, 
      info_message:state.notify.info,
      evi_group: state.select.evi_group,
      current_auth_user: state.auth ? state.auth.user : null,
      new_version: state.server.new_version,
      offline: state.server.offline,
      debug_view: state.persistent_opts.debug_view
   }
}



export default withRouter(compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
  DragDropContext(HTML5Backend)
)(App));

