import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EnumViewField from './../EnumViewField';

import {OrderOwner_field_enum } from './../../../graphql/enums/orderowner';


const styles = theme => ({
 
});


class OrderOwnerViewField extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { value,required} = this.props;
     
    return (
      <EnumViewField required={required} error={this.props.error} width={250} id="order_owner" label="Prodej" values={OrderOwner_field_enum} value={value} />
    );
  }
}

OrderOwnerViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool
};
OrderOwnerViewField.defaultProps = {
  value:null,
  error:false,
  required:false
};

export default withStyles(styles)(OrderOwnerViewField);