import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ZoneIDViewField from './Fields/ZoneIDViewField';
import StringViewField from './StringViewField';
const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class SeatViewDialog extends React.Component {

  render() {
    const {seat,open,onClose,caption} = this.props;
    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {seat && (
            <React.Fragment>
              <StringViewField label="Název" value={seat.name} />
              <ZoneIDViewField value={seat.zone_id} />
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

SeatViewDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  seat:PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
SeatViewDialog.defaultProps = {
  caption:"Náhled sezení"
};

export default withStyles(styles)(SeatViewDialog);