import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
var lodash = require('lodash');

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const propTypes = {
  classes: PropTypes.object.isRequired,
  native: PropTypes.bool,
  selected_cgroup_id: PropTypes.string,
  cgroups: PropTypes.array,
  onSelect: PropTypes.func
};

const defaultProps = {
  native: false,
  selected_cgroup_id:"",
  cgroups: [],
};

class CGroupNav extends React.Component {
 
  selectionChange(cgroup_id) {
    //if ((season != "") && (folder != "") ) {
      if (this.props.onSelect) {
        this.props.onSelect(cgroup_id);
      }
    //}
  }

  handleChangeCGroup(cgroup_id) {
    this.selectionChange(cgroup_id);
  }

  renderOption(s) { 
    if (this.props.native) {
      return (
        <option key={s.id} value={s.id}>{s.name}</option>
      )
    } else {
      return (
        <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>
      )
    }
  }

  renderNullOption() { 
    if (this.props.native) {
      return (
        <option value="" />
      )
    } else {
      return (
        <MenuItem value=""><em>----</em></MenuItem>
      )
    }
  }

  render() {
    const { classes } = this.props;
    const id_ci = lodash.uniqueId("cgroup-input");
    const co = this.props.cgroups.map((s=>this.renderOption(s)));
    const con = this.renderNullOption();
    const menuProps = { PaperProps: {style: {
      maxHeight: 300,
      //width: 200,
    }}};

    return (
        <form className={classes.container} autoComplete="off">
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor={id_ci}>Skupina kurzů</InputLabel>
            <Select
              native={this.props.native}
              value={this.props.selected_cgroup_id}
              onChange={(e)=>this.handleChangeCGroup(e.target.value)}
              input={<Input id={id_ci} />}
              MenuProps = {menuProps}
            >
              {this.props.selected_cgroup_id ==="" && con}
              {co}
            </Select>
          </FormControl>
        </form>
    );
  }
}

CGroupNav.propTypes = propTypes;
CGroupNav.defaultProps = defaultProps;

export default withStyles(styles)(CGroupNav);