import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { compose, graphql} from "react-apollo";

import Button from '@material-ui/core/Button';
import StudentView from './StudentView';


const EMAIL_SEND_NOTIFICATION = gql`
mutation emailSendTest($student_key:String!){
  emailSendTest(student_key:$student_key){
    ok
    emailout_id
    email {
      text
      text_as_html
      subject
    }
  }
}
`;


const styles = theme => ({
 
});





class StudentDebug extends React.Component {


  sendTestNotification() {
    const { student_key } = this.props;
    this.props.sendTestNotification({variables:{
      student_key:student_key,
    }}).then(d=>{
      console.log("sendTestNotification result",d)
     
    })
  }

  render() {
    const { student_key } = this.props;
    return (
      <div className="foo">
        <StudentView debug student_key={student_key} />
        <Button variant="raised" onClick={()=>this.sendTestNotification()}> send test notification </Button>
      </div>
    );
  }
}

StudentDebug.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  student_key: PropTypes.string.isRequired,
  sendTestNotification: PropTypes.func.isRequired,

};
StudentDebug.defaultProps = {
  
};

export default compose(
  withStyles(styles),
  graphql(EMAIL_SEND_NOTIFICATION,{
    name:"sendTestNotification"
  }),
)(StudentDebug);
