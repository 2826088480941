import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
const styles = theme => ({
  root:{
    overflow: "auto"
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

function safeS(a) {
  if ((a===null) || (a===undefined)) {
    return "";
  }
  return a;
}


class StudentChips extends React.Component {

  render() {
    const { classes,height,students } = this.props;
    return (
      <div className={classes.root} style={{height:height}}>
      {students.map(s=>{
        const l = safeS(s.surname)+" "+safeS(s.name);
        return (
          <Chip key={s.key} label={l} className={classes.chip} />
        );
      })}
      </div>
    );
  }
}

StudentChips.propTypes = {
  classes: PropTypes.object.isRequired,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  done_student_keys: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.number
};
StudentChips.defaultProps = {
  height:200
};

export default withStyles(styles)(StudentChips);