import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlacesTable from './PlacesTable';
import { Query } from "react-apollo";
import PlaceViewDialog from './PlaceViewDialog';
import PlaceDeleteDialog from './PlaceDeleteDialog';
import PlaceEditDialog from './PlaceEditDialog';
import ActionButton from './ActionButton';

import {GET_PLACES } from './Fields/Queries';
/*
const GET_PLACES = gql`
  query Places {
    places {
      id
      name
      long_name
      map
      address
    }
  }
`;
*/

const SAVE_ORDERING = gql`
    mutation places_ordering($ids: [ID]!) {
        places_ordering(ids:$ids) 
    }
`;

const HIDE_PLACE = gql`
mutation hidePlace($id:ID!) {
  placeHide(id:$id) {
    id
  }
}
`;

const UPDATE_PLACE = gql`
mutation updatePlace($id:ID!,$name:String,$long_name:String,$address:String,$map:PlaceMap) {
  placeUpdate(id:$id,name:$name,long_name:$long_name,map:$map,address:$address) {
    id
  }
}
`;

const CREATE_PLACE = gql`
mutation createPlace($name:String!,$long_name:String!,$address:String!,$map:PlaceMap!) {
  placeCreate(name:$name,long_name:$long_name,address:$address,map:$map) {
    id
  }
}
`;

const styles = theme => ({
 
});


class Places extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_place:null,
      delete_place:null,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
  }



  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
  }

  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({name:null,long_name:null,map:null,address:null});
    }
  }


  handleEditPlace(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSavePlace() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("place update",doc)
        this.props.updatePlace({
          variables: doc
        }).then(res=>{
          console.log("update ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("place create",doc)
        this.props.createPlace({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }

  handleShowPlace(item) {
    this.setState({show_place:item});
  }
  closeShowPlace() {
    this.setState({show_place:null});
  }

  handleDeletePlace(item) {
    this.setState({delete_place:item});
  }
  closeDeletePlace() {
    this.setState({delete_place:null});
  }

  doDeletePlace() {
    const id = this.state.delete_place.id;
    this.setState({delete_place:null});
    this.props.hidePlace({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        ids:ids
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }


  render() {
    const { auth} = this.props;
   
    return (
      <div className="foo">
      
        <ActionButton label={"Nové místo"} onClick={()=>this.handleNew()}/>

       
        <PlaceViewDialog place={this.state.show_place}  open={this.state.show_place!==null} onClose={()=>this.closeShowPlace()} /> 
        <PlaceDeleteDialog place={this.state.delete_place}  open={this.state.delete_place!==null} onDelete={()=>this.doDeletePlace()} onClose={()=>this.closeDeletePlace()} /> 
        <PlaceEditDialog innerRef = {this.edit} onSave={()=>this.doSavePlace()} /> 
       
        <Query query={GET_PLACES} fetchPolicy={"network-only"}>
          {({ loading, error, data, refetch }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <PlacesTable 
                  innerRef = {this.table}
                  auth={auth}
                  data={data.places} 
                  refetch={refetch}
                  saveOrdering={(ids)=>this.saveOrder(ids)}
                  onEdit={(item)=>this.handleEditPlace(item)}
                  onShow={(item)=>this.handleShowPlace(item)}
                  onDelete={(item)=>this.handleDeletePlace(item)}
              />
            );
          }}
      </Query>

      </div>
    );
  }
}

Places.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hidePlace: PropTypes.func.isRequired,
  updatePlace: PropTypes.func.isRequired,
  createPlace: PropTypes.func.isRequired,
};
Places.defaultProps = {
  
};


export default compose(
  withStyles(styles),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_PLACE,{
    name: "hidePlace",
  }),
  graphql(UPDATE_PLACE,{
    name: "updatePlace",
  }),
  graphql(CREATE_PLACE,{
    name: "createPlace",
  }),
)(Places)
