import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 3,
      width: '100%',
    },
    textfield: {
        margin: theme.spacing.unit,
    }

});
  
function null2empty(v) {
    if ((v === null) || (v === undefined)) {return "NONE"}
    return v;
  }
  function empty2null(v) {
    if (v === "NONE") { return null} 
    return v;
  }
  


class EnumField extends React.Component {

    handleChange(e) {
        this.props.onChange(empty2null(e.target.value));
    }

    render() {
        const { classes, disabled } = this.props;
        const val = null2empty(this.props.value)
        const style = this.props.width?{width:this.props.width}:{};
        return (
            <FormControl  disabled={disabled} className={classes.textfield} style={style} required={this.props.required} error={this.props.error}>
                {this.props.label && (<InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>)}
                <Select
                    value={val}
                    onChange={(e)=>this.handleChange(e)}
                    input={<Input name={this.props.name} id={this.props.id} />}
                >
                    <MenuItem value={"NONE"}>{"-"}</MenuItem>
                    {this.props.values.map(e=>{
                        return (
                            <MenuItem key={e.key} value={e.key}>{e.label}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }
}


EnumField.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    width: PropTypes.number,
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool
};
  
EnumField.defaultProps = {
    autoFocus: false,
    name: "enum",
    error:false,
    required:false,
    disabled:false,
}


export default compose(
    withStyles(styles),
)(EnumField)