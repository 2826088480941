import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BooleanViewField from './BooleanViewField';
import StringViewField  from './StringViewField';
import NumberViewField  from './NumberViewField';
import EnumViewField from './EnumViewField';
const styles = theme => ({
  
});




class CourseViewForm extends React.Component {



  render() {
    const { doc,detail } = this.props;
    return (
      <div className="foo">
          <div>
          <EnumViewField  width={200}  label="Evidence" values={[{key:"VB",label:"VB"},{key:"LB",label:"LB"},{key:"SRO",label:"sro"},{key:"ST", label:"Stadion"},{key:"AB",label:"AB"},{key:"ZS",label:"Spolek"},{key:"NO",label:"nezařazeno"}]} value={doc.evi_group}  />
          <NumberViewField  width={150}  label="Účetní rok" value={doc.accounting_year}  />
          <NumberViewField  width={150}  label="První číslo v.s." value={doc.var_sym_prefix}  />
          <BooleanViewField  width={100}  label="online zápis" value={doc.active} />
          {detail && (
            <React.Fragment>
              <StringViewField  width={50}  label="Kód" value={doc.code}  />
              <StringViewField  width={300}  label="Název" value={doc.name}  />
            </React.Fragment>
          )}
            <NumberViewField  label="Kapacita" value={doc.capacity}  />
          </div>
          <div>
          {detail && (<EnumViewField  width={200} label="Řazení" values={[{key:"SCHOOL",label:"po školách"},{key:"PAIR",label:"po párech"},{key:"SINGLE",label:"po jménech"}]} value={doc.group_mode} />)}
          <StringViewField  label="Termín" value={doc.period}  />
          <StringViewField  label="Termín zahájení" value={doc.first_period}  />
          <StringViewField  label="Lektor" value={doc.teacher}  />
          <StringViewField  label="Místo" value={doc.place} />
          </div>
          <div>
          <EnumViewField width={150} label="Typ ceny" values={[{key:"NORMAL",label:"student/dospělý"},{key:"PERIOD",label:"polo/celo-roční"},{key:"FIX",label:"pevná"}]} value={doc.cost_mode}  />
          <BooleanViewField  width={100} label="Aktivní sleva" value={doc.cost_sale} />
          <NumberViewField width={70} label="Cena A" value={doc.cost_a} />
          <NumberViewField width={70} label="Cena B" value={doc.cost_b} />
          <NumberViewField width={100} label="Cena A sleva" value={doc.cost_sa} />
          <NumberViewField width={100} label="Cena B sleva" value={doc.cost_sb} />
          </div>
          <div>
          {detail && (  
            <React.Fragment>
              <StringViewField width={300} label="text karty 1" value={doc.card_line_1} />
              <StringViewField width={300} label="text karty 2" value={doc.card_line_2} />
            </React.Fragment>
          )}
              <StringViewField width={500} label="poznámka" value={doc.comment} />
          </div>
      </div>
    );
  }
}

CourseViewForm.propTypes = {
  classes: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  detail: PropTypes.bool
};
CourseViewForm.defaultProps = {
  detail:false
};

export default withStyles(styles)(CourseViewForm);