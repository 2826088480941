import React from 'react';
import PropTypes from 'prop-types';
import {PlanZoneRenderer} from './Utils';


class Zone extends React.Component {

    render() {
      const id_string = JSON.stringify({type:"Zone",zone_id:this.props.id});
      const fill_color =  this.props.primary_color;
      const stroke_color =  this.props.primary_color;
      const text_color = "black";
      const render = PlanZoneRenderer(this.props.place_map,this.props.map_zone_key);
      if (render) {
        return render({id_string:id_string,colors:{fill:fill_color,line:stroke_color,text:text_color}});
      } 
      return null;
    }

}

Zone.propTypes = {
  id: PropTypes.string.isRequired,
  place_map: PropTypes.string.isRequired,
  map_zone_key: PropTypes.string.isRequired,
  hover: PropTypes.bool,
  primary_color: PropTypes.string,
  secondary_color: PropTypes.string,
};
Zone.defaultProps = {
  hover:false,
  primary_color: "rgba(255, 0, 0, 0.5)",
  secondary_color: "rgba(255, 0, 0, 0.5)",
};

export default Zone;