import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';


import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import {GET_SCHOOLS} from './Fields/Queries';
import ActionButton from './ActionButton';
import { compose } from 'react-apollo';
import gql from 'graphql-tag';
import StringField from './StringField';
import StringViewField from './StringViewField';


const styles = theme => ({
 
});

const CREATE_SCHOOL= gql`
mutation schoolCreate($name:String!) {
  schoolCreate(name:$name) {
    id
  }
}
`;


const UPDATE_SCHOOL= gql`
mutation schoolUpdate($id:ID!,$name:String) {
  schoolUpdate(id:$id,name:$name) {
    id
  }
}
`;


const HIDE_SCHOOL = gql`
mutation hideSchool($id:ID!) {
  schoolHide(id:$id) {
    id
  }
}
`;

const SAVE_ORDERING = gql`
    mutation schools_ordering($ids: [ID]!) {
        schools_ordering(ids:$ids) 
    }
`;



class ConfigTabSchools extends React.Component {

  constructor(props) {
    super(props);
    this.school_table = React.createRef();
  }

  handleNew() {
    if (this.school_table.current) {
      this.school_table.current.edit({name:""})
    }
  }

  handleSort() {
    if (this.school_table.current) {
      this.school_table.current.sortBy(["name"],true);
    }
  }

  render() {
    //const { classes} = this.props;

    return (
      <React.Fragment>
          <ActionButton label={"Nová škola"} onClick={()=>this.handleNew()}/>
          <ActionButton label={"Setřídit podle abecedy"} onClick={()=>this.handleSort()}/>
          <SimpleGQLTable  innerRef={this.school_table}
            sortable editable deletable viewable
            query={GET_SCHOOLS} variables={{}} 
            getData={(r)=>{return r.schools}}
            renderHeader={()=>(
              <React.Fragment>
                {/*<TableCell>id</TableCell>*/}
                <TableCell>název</TableCell>
              </React.Fragment>
            )}
            renderRow={(item,connectDragPreview)=>(
              <React.Fragment>
                {/*<TableCell>{item.id}</TableCell>*/}
                <TableCell>{connectDragPreview((<span>{item.name}</span>))}</TableCell>
              </React.Fragment>
            )}

            renderRowExtraActions={(item)=>(
              <span>  </span>
            )}
            renderEmptyInfo={()=>(<span> žádna skola </span>)}
            renderEditForm={(doc,doc_err,handleChange)=>{
              return (
                <React.Fragment>
                    <StringField required width={400} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>handleChange("name",v)} />
                </React.Fragment>
              )
            }}
            renderDeleteForm={(doc)=>{
              return (
                <div> opravdu smazat školu <b>{doc.name}</b>? </div>
              )
            }}
            renderViewForm={(doc)=>{
              return (
                <React.Fragment> 
                    <StringViewField width={400} label="Název" value={doc.name} />
                </React.Fragment>
              )
            }}

            formFieldValidator={(name,value)=>{
                if (["name"].includes(name) && value===null) {
                  return false;
                }
                return true;
            }}
            gqlUpdate={UPDATE_SCHOOL}
            gqlCreate={CREATE_SCHOOL}
            gqlDelete={HIDE_SCHOOL}
            gqlSaveOrder={SAVE_ORDERING}
          />

      </React.Fragment>
    );
  }
}

ConfigTabSchools.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
ConfigTabSchools.defaultProps = {
  
};



export default compose(
  withStyles(styles),
)(ConfigTabSchools)
