import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CourseView from './CourseView';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    minWidth:800
  }
});


class CourseViewDialog extends React.Component {

  render() {
    const {course_key,open,onClose,caption} = this.props;
    return (
      <Dialog maxWidth={"md"} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {course_key && (<CourseView course_key={course_key} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

CourseViewDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
CourseViewDialog.defaultProps = {
  caption:"Detail kurzu"
};

export default withStyles(styles)(CourseViewDialog);