import { combineReducers } from 'redux'

import auth from './auth';
import notify from './notify';
import select from './select';
import server from './server';
import opts from './opts';
import persistent_opts from './persistent_opts';

const reducers = combineReducers({
    auth,notify,select,server,opts,persistent_opts
})
  
export default reducers