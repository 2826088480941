import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventStrip from './EventStrip';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
//import Lodash from 'lodash';
//import EventCapacityView from './EventCapacityView';
import DateView from './DateView';
import BooleanView from './BooleanView';
import OrderOwnerView from './Fields/OrderOwnerView';
import OrderStatusView from './Fields/OrderStatusView';
import PaymentTypeView from './Fields/PaymentTypeView';
import PaymentStatusView from './Fields/PaymentStatusView';
//import DebugObjectView from './Utils/DebugObjectView';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import ActionLink from './ActionLink';
import Lodash from 'lodash';
import gql from 'graphql-tag';


const GET_EVENT_ORDERS = gql`
query EventOrders($event_id:ID!) {
  eventOrders(event_id:$event_id) {
    id
    public_key
    type
    status
    test_mode
    owner_mode
    customer_name
    customer_surname
    customer_email
    customer_phone
    comment
    only_reservation
    reservation_due_date
    price
    event_ticket_keys
    booking_ref
    last_payment_status
    last_payment_type
    created_at
    updated_at
  }
}
`;



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});





class SaleEventTabReservations extends React.Component {

  constructor(props) {
    super(props);
    this.orders_table = React.createRef();
  }
 
  render() {
    const { classes,event} = this.props;

    return (
      <React.Fragment>
            <Paper className={classes.paper}>
              <EventStrip show_debug_info={false} variant="sale" event={event}/>
            </Paper>
            <Typography variant="subheading"> Přehled rezervací  </Typography>
            <SimpleGQLTable  innerRef={this.orders_table}
              query={GET_EVENT_ORDERS} variables={{event_id:event.id}} 
              getData={(r)=>{
                  return Lodash.filter(r.eventOrders,(o)=>{
                    return (o.status == "RESERVATION") || (o.only_reservation && o.status=="INCOMPLETE");
                  })
              }}
              renderHeader={()=>(
                <React.Fragment>
                  <TableCell debug_only>order_id</TableCell>
                  <TableCell debug_only>type</TableCell>
                  <TableCell debug_only>test_mode</TableCell>
                  <TableCell>ID objednávky</TableCell>
                  <TableCell>stav</TableCell>
                  <TableCell>prodej</TableCell>
                  <TableCell>ID objednavatele</TableCell>
                  <TableCell>rezervace</TableCell>
                  <TableCell>platba</TableCell>
                  <TableCell>cena</TableCell>
                  <TableCell>kusů</TableCell>
                  <TableCell>jméno</TableCell>
                  <TableCell>přijmení</TableCell>
                  <TableCell>telefon</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>poznámka</TableCell>
                  <TableCell>datum vzniku</TableCell>
                  <TableCell>datum změny</TableCell>
          
                </React.Fragment>
              )}
              renderRow={(item)=>(
                <React.Fragment>
                  <TableCell debug_only>{item.id}</TableCell>
                  <TableCell debug_only>{item.type}</TableCell>
                  <TableCell debug_only><BooleanView value={item.test_mode}/></TableCell>
                  <TableCell>{item.public_key}</TableCell>
                  <TableCell><OrderStatusView value={item.status}/></TableCell>
                  <TableCell><OrderOwnerView value={item.owner_mode}/></TableCell>
                  <TableCell>{item.booking_ref}</TableCell>
                  <TableCell><BooleanView value={item.only_reservation}/></TableCell>
                  <TableCell><PaymentTypeView value={item.last_payment_type}/>{"/"}<PaymentStatusView value={item.last_payment_status}/></TableCell>
                  <TableCell>{item.price}</TableCell>
                  <TableCell>{item.event_ticket_keys?item.event_ticket_keys.length:"?"}</TableCell>
                  <TableCell>{item.customer_name}</TableCell>
                  <TableCell>{item.customer_surname}</TableCell>
                  <TableCell>{item.customer_phone}</TableCell>
                  <TableCell>{item.customer_email}</TableCell>
                  <TableCell>{item.comment}</TableCell>
                  <TableCell> <DateView date={item.created_at}/> </TableCell>
                  <TableCell> <DateView date={item.updated_at}/> </TableCell>
                </React.Fragment>
              )}
              renderRowExtraActions={(item)=>{
                if (item.status && item.status != 'INCOMPLETE') {
                  return (
                    ///spa/sale_event/5bbbab9d21ddf5fa0d91ba85/sale/order/OE2X-XCXR-YL8P-73HF
                    <ActionLink useRouter href={"/spa/sale_event/"+event.id+"/sale/order/"+item.public_key} icon='view'/>
                  )
                }
                return (
                <span>  </span>
                )
              }}
              renderEmptyInfo={()=>(<div> nejsou žádné rezervace pro tuto akci </div>)}
            />

            
            

      </React.Fragment>
    );
  }
}

SaleEventTabReservations.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
};
SaleEventTabReservations.defaultProps = {
  
};


export default withStyles(styles)(SaleEventTabReservations);