import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import ActionButton from './ActionButton';
import ActionLink from './ActionLink';
/*
const styles = theme => ({
 
});
*/

class StudentsTableRowCmds extends React.Component {

  shouldComponentUpdate(nextProps) {
    var ret = false;
    const { is_admin, is_super, student_key} = this.props;
    if (is_admin !== nextProps.is_admin) {
      console.log("StudentsTableRowCmds changed is_admin");
      ret = true;
    }
    if (is_super !== nextProps.is_super) {
      console.log("StudentsTableRowCmds changed is_admin");
      ret = true;
    }

    if (student_key !== nextProps.student_key) {
      console.log("StudentsTableRowCmds changed student_key");
      ret = true;
    }
    //console.log("StudentsTableRowCmds, update",ret);
    return ret;
  }
  render() {
    const {  is_admin, is_super, student_key, onAction} = this.props;
    //console.log("StudentsTableRowCmds, rerender");
    return (
      <React.Fragment>
      <ActionButton xtooltip={"zobrazit"} icon={'view'} onClick={()=>onAction("VIEW",student_key)}/>
      {is_admin &&(
          <ActionButton xtooltip={"editovat"} icon={'edit'} onClick={()=>onAction("EDIT",student_key)}/>
      )}
      {is_super &&(
          <ActionButton xtooltip={"platba"} icon={'pay2'} onClick={()=>onAction("PAY",student_key)}/>
      )}
      {is_admin &&(
          <ActionLink useRouter xtooltip={"platba2"} icon={'pay'} href={"/spa/student/payment/"+student_key}/>
      )}
      {is_super &&(
          <ActionLink useRouter xtooltip={"debug"} icon={'debug'} href={"/spa/student/debug/"+student_key}/>
      )}
      </React.Fragment>
    );
  }
}

StudentsTableRowCmds.propTypes = {
 // classes: PropTypes.object.isRequired,
  is_admin: PropTypes.bool.isRequired,
  is_super: PropTypes.bool.isRequired,
  student_key: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
};
StudentsTableRowCmds.defaultProps = {
  
};

export default StudentsTableRowCmds;