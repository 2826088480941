import React from 'react';
//import PropTypes from 'prop-types';
import {  Rect } from 'react-konva';
import PlacePlanUni from './PlacePlanUni';
import {PlanZoneRenderer} from './Utils';

/* eslint-disable react/prop-types */

const PlanBackground = ({colors}) => (
  <React.Fragment>
    <Rect
      x={0}
      y={0}
      width={1000}
      height={1000}
      stroke={colors.line}
    />
  </React.Fragment>
);


/* eslint-enable react/prop-types */

class PlacePlanTesting extends React.Component {
    render() {
        const renderBG=PlanBackground;
        const renderZones=[
          {key:"#z1",render:PlanZoneRenderer("TEST","#z1")},
          {key:"#z2",render:PlanZoneRenderer("TEST","#z2")},
       ];
        return React.createElement(PlacePlanUni,{...this.props,renderBG,renderZones});
    }
}


export default PlacePlanTesting;