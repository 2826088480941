import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux'



class DebugOnly extends React.PureComponent {
  
  render() {
    if (this.props.visible) {
      return ( 
        <React.Fragment>
            {this.props.children}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

DebugOnly.propTypes = {
  children: PropTypes.any,
  visible: PropTypes.bool,
};
DebugOnly.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      visible: state.persistent_opts.debug_view,
  }
}


export default compose(
  connect(mapStateToProps,{}),
)(DebugOnly)
