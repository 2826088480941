import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import DebugObjectView from './Utils/DebugObjectView';
import Typography from '@material-ui/core/Typography';
import SimpleDataTable from './Tables/SimpleDataTable';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import {GET_ZONE_PRICES } from './Fields/Queries';
import ActionButton from './ActionButton';
//import ActionLink from './ActionLink';
import BooleanView from './BooleanView';

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Lodash from 'lodash';

const CREATE_EVENT_ZONE_PRICE = gql`

mutation eventZonePriceCreate($event_id:ID!,$zone_price_id:ID!,$event_zone_id:ID!,$event_price_plan_id:ID!) {
  eventZonePriceCreate(event_id:$event_id, zone_price_id:$zone_price_id,event_zone_id:$event_zone_id,event_price_plan_id:$event_price_plan_id ) {
    id
  }
}
`;
const DELETE_EVENT_ZONE_PRICE = gql`
mutation eventZonePriceDelete($event_zone_price_id:ID!) {
  eventZonePriceDelete(id:$event_zone_price_id) {
    id
  }
}
`;



const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
  sep: {
    marginTop: theme.spacing.unit*3
  }
});


class SetupEventZoneTabPrices extends React.Component {

  constructor(props) {
    super(props);
  }

  filterout_used_zone_prices(zone_prices) {
    const { event_zone } = this.props;
    const used_zone_price_ids = event_zone.prices.map(z=>z.zone_price_id);
    const unused = zone_prices.filter(z=>{
        const un = (used_zone_price_ids.indexOf(z.id)==-1)
        return un;
      });
    return unused;
  }

  reload() {
    this.props.refetch();
  }

  addPrice(zone_price,event_price_plan) {
    console.log("addPrice",zone_price,event_price_plan);

    const { event,event_zone } = this.props;
    this.props.createEventZonePrice({
      variables: {
        event_id:event.id,
        event_zone_id:event_zone.id,
        zone_price_id: zone_price.id,
        event_price_plan_id:event_price_plan.id
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
    
  }

  removePrice(event_zone_price) {
    //const { event } = this.props;
    
    this.props.deleteEventZonePrice({
      variables: {
        event_zone_price_id:event_zone_price.id,
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
    
  }


  

  render() {
    const { event,event_zone,auth,classes} = this.props;
    const editable = (event.status == "NEW" || event.test_mode);
    return (
      <React.Fragment>
            
            <DebugObjectView name="event" object={event} />
            <DebugObjectView name="event_zone" object={event_zone} />
            
           
            {event.priceplans.map((epp,idx)=>{
              return(
                <React.Fragment key={epp.id}>
                  <DebugObjectView name="event_price_plan" object={epp}/>
                  <Typography variant="title" className={classes.sep}> Nastavené typy vstupenek zóny pro cenový plán: {epp.name} </Typography>
                  {!editable &&(
                    <Typography variant="subheading">nastavené typy vstupenek již nelze odebrat, protoze akce se již prodává nebo prodávala</Typography>
                  )}
                    <SimpleDataTable data={Lodash.filter(event_zone.prices,{event_price_plan_id:epp.id})} 
                      renderHeader={()=>(
                        <React.Fragment>
                          <TableCell debug_only>id</TableCell>
                          <TableCell>název</TableCell>
                          <TableCell>cena</TableCell>
                          <TableCell>žákovská sleva</TableCell>
                          <TableCell></TableCell>
                        </React.Fragment>
                      )}
                      renderRow={(item)=>(
                        <React.Fragment>
                          <TableCell debug_only>{item.id}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.cost}</TableCell>
                          <TableCell><BooleanView value={item.sale_student} /></TableCell>
         
                          <TableCell>
                            {auth.is_admin &&editable && (<ActionButton tooltip={"odbrat cenu"} icon={'delete'} onClick={()=>this.removePrice(item)}/>)}
                          </TableCell>
                      
                        </React.Fragment>
                      )}
                      renderEmptyInfo={()=>(<div>žádné vstupenky pro cenový plán</div>)}
                    />
                  <Typography variant="title"> Typy vstupenek zóny, které je možné přidat pro cenový plán: {epp.name} </Typography>

                  <SimpleGQLTable 
                    query={GET_ZONE_PRICES} variables={{zone_id:event_zone.zone_id,price_plan_id:epp.price_plan_id}} 
                    getData={(r)=>{return this.filterout_used_zone_prices(r.zonePrices)}}
                    renderHeader={()=>(
                      <React.Fragment>
                        <TableCell debug_only>id</TableCell>
                        <TableCell debug_only>price_plan_id</TableCell>
                        <TableCell>název</TableCell>
                        <TableCell>cena</TableCell>
                        <TableCell>žákovská sleva</TableCell>
                        <TableCell></TableCell>
                      </React.Fragment>
                    )}
                    renderRow={(item)=>(
                      <React.Fragment>
                        <TableCell debug_only>{item.id}</TableCell>
                        <TableCell debug_only>{item.price_plan_id}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.cost}</TableCell>
                        <TableCell><BooleanView value={item.sale_student} /></TableCell>
                        <TableCell>
                          {auth.is_admin && (<ActionButton tooltip={"přidat cenu"} icon={'add'} onClick={()=>this.addPrice(item,epp)}/>)}
                        </TableCell>
                      </React.Fragment>
                    )}
                    renderEmptyInfo={()=>(<div> žádný typ vstupenky k přidání (jsou přidané všechny typy, nebo není žádný další typ vstupenky pro zónu a cenový plán nastavená) </div>)}
                  />




                </React.Fragment>
              )
            })}
            {event.priceplans.length == 0 && (
              <div> není nastaven žádný cenový plán pro akci</div>
            )}
          


      </React.Fragment>
    );
  }
}

SetupEventZoneTabPrices.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  event_zone: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  createEventZonePrice: PropTypes.func.isRequired,
  deleteEventZonePrice: PropTypes.func.isRequired,

};
SetupEventZoneTabPrices.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CREATE_EVENT_ZONE_PRICE,{
    name: "createEventZonePrice",
  }),
  graphql(DELETE_EVENT_ZONE_PRICE,{
    name: "deleteEventZonePrice",
  }),
)(SetupEventZoneTabPrices)
