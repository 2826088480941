import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import SeasonForm from './SeasonForm';

/*
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

*/


const GET_SEASON = gql`
  query Season($season_key:String!){
      season(key: $season_key) {
        key
        name
        online_enroll
        access_check
        order_value
      }
  }
`;

const UPDATE_SEASON = gql`
mutation updateSeason($key:String!,$name:String, $online_enroll:Boolean, $access_check:Boolean, $order_value:Int) {
  seasonUpdate(key:$key,name:$name,online_enroll:$online_enroll, access_check:$access_check,order_value:$order_value) {
    key
    name
    online_enroll
    access_check
    order_value
  }
}
`;

class SeasonEdit extends React.Component {


  constructor(props) {
    super(props);
    
    this.state = {  
      dirty:false,
      valid:true
    }
    this.form = React.createRef();
  }


  doSave() {
    console.log("SeasonEdit doSave");
    const {client,onClose} = this.props;
    
    client.mutate({
      mutation:UPDATE_SEASON,
      variables:this.form.current.state.doc,
      refetchQueries:["Seasons","Season","Catalog"]
    }).then(res=>{
      onClose(true);
    }).catch((err)=>{
      alert("chyba ukladani");
    })
    
   
  }
 
  onDocChange(d,valid) {
    console.log("SeasonEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 
 

 
  render() {
    const {season_key} = this.props;
    return (
      <Query query={GET_SEASON} variables={{ season_key }} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
              <SeasonForm innerRef={this.form} doc={data.season} onDocChange={(d,v)=>this.onDocChange(d,v)}/>
          )
        }}
      </Query>
    );
  }
}

SeasonEdit.propTypes = {
  client: PropTypes.object.isRequired,
  season_key: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
SeasonEdit.defaultProps = {
  
};


export default withApollo(SeasonEdit,{withRef:true});
