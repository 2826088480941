import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SaleEventShoppingCartAdminViewList from './SaleEventShoppingCartAdminViewList';
import Paper from '@material-ui/core/Paper';



const styles = theme => ({
  root: {
    flexGrow:1
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});




class SaleEventShoppingCartAdminView extends React.Component {

  constructor(props) {
    super(props);
  }

  

  
  render() {
    const { event , classes, tickets} = this.props;

    return (
      <Paper className={classes.root}>
        <SaleEventShoppingCartAdminViewList list={tickets} event_seats={event.seats} event_zones={event.zones}/>
      </Paper>
   );
  }
}

SaleEventShoppingCartAdminView.propTypes = {
  classes: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired, 
  tickets: PropTypes.array.isRequired,
};
SaleEventShoppingCartAdminView.defaultProps = {
  
};


export default withStyles(styles)(SaleEventShoppingCartAdminView);