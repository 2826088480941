import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import SeasonsTable from './SeasonsTable';
import { Query } from "react-apollo";
import Paper from '@material-ui/core/Paper';
import SeasonEditDialog from './SeasonEditDialog';

const styles = theme => ({
  root: {
    width: '100%',
  },
});

const GET_SEASONS = gql`
  query Seasons {
    seasons {
      key
      name
      access_check
      online_enroll
      order_value
    }
  }
`;




class Seasons extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      editSeason_key:null,
    }
  }
 
 
  handleCloseSeasonEditDialog() {
    this.setState({editSeason_key:null});
  }

  handleEditSeason(season_key) {
    console.log("handleEditSeason");
    this.setState({editSeason_key:season_key});
  }

  renderSeasonEditDialog() {
    const {editSeason_key} = this.state;
    return (
      <SeasonEditDialog open={editSeason_key!=null} season_key={editSeason_key} onClose={()=>this.handleCloseSeasonEditDialog()}/>
    )
  }

  render() {
    const { classes, auth } = this.props;
  
    const sed = this.renderSeasonEditDialog();

    return (
      <Paper className={classes.root}>
       {sed} 
      <Query query={GET_SEASONS} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <SeasonsTable 
                  auth={auth}
                  seasons={data.seasons} 
                  onEditSeason={(key)=>this.handleEditSeason(key)}
              />
            );
          }}
      </Query>
      </Paper>
    );
  }
}

Seasons.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

Seasons.defaultProps = {
 
};



export default compose(
  withStyles(styles),
)(Seasons)
