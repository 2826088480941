import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import Lodash from 'lodash';
import CoursesTableRow from './CoursesTableRow';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';

import Typography from '@material-ui/core/Typography';

//import HTML5Backend from 'react-dnd-html5-backend';
//import { DragDropContext } from 'react-dnd';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
  },
  tablebody: {
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },

  cell2: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    borderBottom: 0
  },

  cell2b: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    borderBottom: 0,
    borderLeft: `1px solid
    ${
      theme.palette.type === 'light'
        ? lighten(fade(theme.palette.divider, 1), 0.88)
        : darken(fade(theme.palette.divider, 1), 0.8)
    }`
  },

  row: {
    height:30,
  }
});


const SaveOrdering = gql`
    mutation courses_ordering($folder_key: String!, $season_key:String!,$evi_group:EviGroup, $keys: [String]!) {
        courses_ordering(folder_key:$folder_key, season_key:$season_key, evi_group:$evi_group, keys:$keys) 
    }
`;

function cloneObject(obj) {
  var clone = {};
  for(var i in obj) {
      if(obj[i] !== null &&  typeof(obj[i])==="object")
          clone[i] = cloneObject(obj[i]);
      else
          clone[i] = obj[i];
  }
  return clone;
}


function dictAdd(a,b) {
  for (var k in b) {
    if (a.hasOwnProperty(k)) {
      if (Object.getPrototypeOf(a[k]) === Object.getPrototypeOf({})) {
        a[k] = dictAdd(a[k],b[k]);
      } else {
        a[k]+=b[k];
      }
    } else {
      if (Object.getPrototypeOf(b[k])===Number()) {
        a[k] = Number(b[k]);
      } else {
        a[k] = cloneObject(b[k]);//Object.assign({},b[k]);
      }
    }
  }
  return a
}


class CoursesTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      order:[],
      activeDrag:false
    }
  }
 
  onDrag(active) {
    this.setState({activeDrag:active})
  }  

  reorder(items) {
      const { order } = this.state;
      if (order.length !== items.length) {
        console.log("missing order")
        return items;
      }
      console.log("reodering");
      return order.map((k)=>{return Lodash.find(items,{key:k}) });
  }

  findRow(key) {
      const items = this.reorder(this.props.courses);
      const item = items.filter(c => c.key === key)[0]
      const index = items.indexOf(item);
      const r =  {
        course:item,
        index: index,
      };
      console.log("findRow",key,r)
      return r;
  }

  moveRow(key, atIndex) {
    console.log("moveRow",key,atIndex);
    const { index } = this.findRow(key)
    let order = []
    if (this.state.order.length !== this.props.courses.length) {
       order = this.props.courses.map(i=>{return i.key});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,key);
    this.setState({order:order});
  }
  
  saveOrder() {
    
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        keys:this.state.order,
        folder_key:this.props.folder_key,
        season_key:this.props.season_key,
        evi_group:this.props.evi_group
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
    
  }

  renderRows(items) {
    const {auth} = this.props;
    return  this.reorder(items).map((i,idx)=>{
                return  (
                  <CoursesTableRow 
                      auth={auth}
                      onShowCourse={this.props.onShowCourse} 
                      onDeleteCourse={this.props.onDeleteCourse} 
                      onEditCourse={this.props.onEditCourse} 
                      save={()=>this.saveOrder()} 
                      moveRow={(k,at)=>this.moveRow(k,at)} 
                      findRow={(k)=>this.findRow(k)}
                      activeDrag={this.state.activeDrag} 
                      id={i.key} 
                      key={i.key} 
                      course={i} 
                      onDrag={(active)=>this.onDrag(active)} 
                    />
                )
          });
  }

  renderFooter(items) {
    const { classes } = this.props;

    if (items.length===0) {
      return (
        <TableFooter>
          <TableRow className={classes.row}>
          <TableCell className={classes.cell2} colSpan={1000}>
              <Typography>{"žádný kurz"}</Typography>
          </TableCell>
          </TableRow>
        </TableFooter>
      )
    }

    const stats = Lodash.reduce(items.map(i=>{return i.stats}),function(sum,i){
      return dictAdd(sum,i);
    },{});
    return (
      <TableFooter>
        <TableRow className={classes.row}>
          <TableCell className={classes.cell2} colSpan={10}>
          </TableCell>
        
          <TableCell className={classes.cell2b}>{stats.in.male.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.in.female.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.in.total.count}</TableCell>


          <TableCell className={classes.cell2b}>{stats.filed.male.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.filed.female.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.filed.total.count}</TableCell>

          <TableCell className={classes.cell2b}>{stats.spare.male.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.spare.female.count}</TableCell>
          <TableCell className={classes.cell2}>{stats.spare.total.count}</TableCell>

          <TableCell className={classes.cell2b}>{stats.total.paid_amount}</TableCell>
        </TableRow>
      </TableFooter>
    )
  }

  render() {
    const { classes } = this.props;
   
    const rows = this.renderRows(this.props.courses);
    const footer = this.renderFooter(this.props.courses);
    return (
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                <TableCell className={classes.cell2} colSpan={10}>
                </TableCell>
               
                <TableCell className={classes.cell2} colSpan={3}>
                {"zapsaní"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={3}>
                {"přihlášky"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={3}>
                {"náhradníci"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={2}>
                </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>řazení</TableCell>
                    <TableCell className={classes.cell}>online</TableCell>
                    <TableCell className={classes.cell}>kód</TableCell>
                    <TableCell className={classes.cell}>název</TableCell>
                    <TableCell className={classes.cell}>evidence</TableCell>
                    <TableCell className={classes.cell}>účetní rok</TableCell>
                    <TableCell className={classes.cell}>zahájení</TableCell>
                    <TableCell className={classes.cell}>termín</TableCell>
                    <TableCell className={classes.cell}>místo</TableCell>
                    <TableCell className={classes.cell}>lektor</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}>kurzovné</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {rows}
            </TableBody>
            {footer}
          </Table>
    );
  }
}

CoursesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  season_key: PropTypes.string.isRequired,
  folder_key: PropTypes.string.isRequired,
  evi_group: PropTypes.string,
  saveOrdering: PropTypes.func.isRequired,
  onShowCourse: PropTypes.func.isRequired,
  onEditCourse: PropTypes.func.isRequired,
  onDeleteCourse: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

CoursesTable.defaultProps = {
 
};



export default compose(
  withStyles(styles),
  graphql(SaveOrdering,{
    name: "saveOrdering",
  }),
 // DragDropContext(HTML5Backend)
)(CoursesTable)
