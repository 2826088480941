
/*
export enum FioTransactionType {
  UNKNOWN = 'UNKNOWN',
  IN = 'IN',
  OUT = 'OUT',
  CARD_OUT = 'CARD_OUT',
}
*/


const  FioTransactionType_schema_enum = ['UNKNOWN','IN','OUT','CARD_OUT'];
const  FioTransactionType_gql_values =  { 
    UNKNOWN:{value:"UNKNOWN"},
    IN:{value:"IN"},
    OUT:{value:"OUT"},
    CARD_OUT:{value:"CARD_OUT"},
    OTHER:{value:"OTHER"},
};

const FioTransactionType_field_enum = [
    {key:"UNKNOWN",label:"neznámá"},
    {key:"IN",label:"příjem"},
    {key:"OUT",label:"výdaj"},
    {key:"CARD_OUT",label:"výdaj kartou"},
    {key:"OTHER",label:"jiný"},
];
const FioTransactionType_props_enum = ['UNKNOWN','IN','OUT','CARD_OUT','OTHER'];


module.exports = {
    FioTransactionType_schema_enum,
    FioTransactionType_gql_values,
    FioTransactionType_field_enum,
    FioTransactionType_props_enum
};