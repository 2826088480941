import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import CourseViewForm from './CourseViewForm';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
 
});

const GET_COURSE = gql`
  query Course($course_key:String!){    
      course(key: $course_key) {
        key
        evi_group
        folder_key
        season_key
        name
        code
        active
        suspend
        cost_sa
        cost_sb
        cost_a
        cost_b
        cost_mode
        cost_sale
        accounting_year
        group_mode
        var_sym_prefix
        capacity
        card_line_1
        card_line_2
        period
        first_period
        teacher
        place
        comment    
      }
  }
`;

class CourseView extends React.Component {

  render() {
    const {course_key,detail} = this.props;
    return (
      <div>
        <Query query={GET_COURSE} variables={{ course_key }}>
        {({ loading, error, data }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          return (
            <CourseViewForm detail={detail} doc={data.course} />
          )
        }}
      </Query>
      </div>
    );
  }
}

CourseView.propTypes = {  
  classes: PropTypes.object.isRequired,
  course_key:PropTypes.string.isRequired,
  detail:PropTypes.bool
};
CourseView.defaultProps = {
  detail:true
};

export default withStyles(styles)(CourseView);