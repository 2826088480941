import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lodash from 'lodash';
import MyCoursesRow from './MyCoursesRow';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';


import CourseViewDialog from './CourseViewDialog';


//import HTML5Backend from 'react-dnd-html5-backend';
//import { DragDropContext } from 'react-dnd';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
  },
  tablebody: {
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },

  cell2: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    borderBottom: 0
  },

  cell2b: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
    borderBottom: 0,
    borderLeft: `1px solid
    ${
      theme.palette.type === 'light'
        ? lighten(fade(theme.palette.divider, 1), 0.88)
        : darken(fade(theme.palette.divider, 1), 0.8)
    }`
  },

  row: {
    height:30,
  }
});

const CurrentCourses = gql`
  query MyCourses($list_key: String!) {
    mycourses(list_key:$list_key) {
        key
        evi_group
        accounting_year
        name
        code
        first_period
        period
        teacher
        place
        stats {
          in {
            ...fs
          }
          filed {
            ...fs
          }
          spare {
            ...fs
          }
          total {
            ...fs
          }
        }
    }
  }
  fragment fs on CourseStatsGenRecord {
    male {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    female {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    total {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
  }
`;


const SaveOrdering = gql`
    mutation MyCoursesOrdering($list_key: String!,$keys: [String]!) {
        mycourses_ordering(list_key:$list_key,keys:$keys) 
    }
`;



class MyCourses extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      order:[],
      activeDrag:false,
      showCourse_key:null
    }
  }


  handleCloseCourseViewDialog() {
    this.setState({showCourse_key:null});
  }

  handleShowCourse(course_key) {
    this.setState({showCourse_key:course_key});
  }

 
  onDrag(active) {
    this.setState({activeDrag:active})
  }  

  reorder(items) {
      const { order } = this.state;
      if (order.length !== items.length) {
        console.log("missing order")
        return items;
      }
      console.log("reodering");
      return order.map((k)=>{return Lodash.find(items,{key:k}) });
  }

  findRow(key) {
      const items = this.reorder(this.props.courses.mycourses);
      const item = items.filter(c => c.key === key)[0]
      const index = items.indexOf(item);
      const r =  {
        course:item,
        index: index,
      };
      console.log("findRow",key,r)
      return r;
  }

  moveRow(key, atIndex) {
    console.log("moveRow",key,atIndex);
    const { index } = this.findRow(key)
    let order = []
    if (this.state.order.length !== this.props.courses.mycourses.length) {
       order = this.props.courses.mycourses.map(i=>{return i.key});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,key);
    this.setState({order:order});
  }
  
  saveOrder() {
    
    console.log("saveOrder");
    this.props.saveOrdering({
      variables: {
        keys:this.state.order,
        list_key:this.props.list_key,
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
    
  }

  renderLoading() {
    return (
     
      <TableRow>
        <TableCell colSpan={1000}>
        <CircularProgress />
        </TableCell>
      </TableRow>
     
    )
  }
  renderRows(items) {
    return  this.reorder(items).map((i,idx)=>{
                return  (<MyCoursesRow onShowCourse={(key)=>this.handleShowCourse(key)} save={()=>this.saveOrder()} moveRow={(k,at)=>this.moveRow(k,at)} findRow={(k)=>this.findRow(k)}activeDrag={this.state.activeDrag} id={i.key} key={i.key} course={i} onDrag={(active)=>this.onDrag(active)} />)
          });
  }

  
  renderCourseViewDialog() {
    const {showCourse_key} = this.state;
    return (
      <CourseViewDialog open={showCourse_key!=null} course_key={showCourse_key} caption={"Detail kurzu"} onClose={()=>this.handleCloseCourseViewDialog()}/>
    )
  }


  render() {
    const { classes } = this.props;
    const rows = this.props.courses.mycourses?this.renderRows(this.props.courses.mycourses):this.renderLoading();
    const cvd = this.renderCourseViewDialog();
    return (
      <Paper className={classes.root}>
        {cvd}
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                <TableCell className={classes.cell2} colSpan={7}>
                </TableCell>
               
                <TableCell className={classes.cell2} colSpan={3}>
                {"zapsaní"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={3}>
                {"přihlášky"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={3}>
                {"náhradníci"}
                </TableCell>

                <TableCell className={classes.cell2} colSpan={1}>
                </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>řazení</TableCell>
                    <TableCell className={classes.cell}>kód</TableCell>
                    <TableCell className={classes.cell}>název</TableCell>
                    <TableCell className={classes.cell}>zahájení</TableCell>
                    <TableCell className={classes.cell}>termín</TableCell>
                    <TableCell className={classes.cell}>místo</TableCell>
                    <TableCell className={classes.cell}>lektor</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}>p</TableCell>
                    <TableCell className={classes.cell}>d</TableCell>
                    <TableCell className={classes.cell}>c</TableCell>

                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {rows}
            </TableBody>
          </Table>
      </Paper>
    );
  }
}

MyCourses.propTypes = {
  classes: PropTypes.object.isRequired,
  list_key: PropTypes.string.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  courses: PropTypes.object
};

MyCourses.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CurrentCourses,{
      name: "courses",
      options: ({list_key})=>({variables:{list_key:list_key}})
  }),
  graphql(SaveOrdering,{
    name: "saveOrdering",
  }),
  //DragDropContext(HTML5Backend)
)(MyCourses)
