import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';


const styles = theme => ({
  flex: {
    flex: 1,
  },
  appBar: {
    //position: 'relative',
  },
  embed_root: {
    //paddingTop: '64px',
    width: '100%',
    height: '100%',
  },
});


class PdfViewDialog extends React.Component {

  render() {
    const { classes,open,onClose,caption } = this.props;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
      >
      <AppBar position="static" className={classes.appBar}>
          <Toolbar>
              <Typography variant="title" color="inherit" className={classes.flex}>
                 {caption}
              </Typography>
              <IconButton color="inherit" onClick={onClose} aria-label="Close">
                  <CloseIcon />
               </IconButton>
           </Toolbar>
      </AppBar>
          {this.props.pdfUrl?(
            <div className={classes.embed_root}>
              <embed width="100%" height="100%" name="plugin" id="plugin" src={this.props.pdfUrl} type="application/pdf"/>
            </div>
          ):(<CircularProgress/>)}
      </Dialog>
    );
  }
}

PdfViewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  pdfUrl: PropTypes.string,
  caption: PropTypes.string.isRequired
};
PdfViewDialog.defaultProps = {
  
};

export default withStyles(styles)(PdfViewDialog);