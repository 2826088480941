import React from 'react';
import PropTypes from 'prop-types';
import {Inspector} from 'react-inspector';
import Lodash from 'lodash';

import DebugOnly from './DebugOnly';

class DebugObjectView extends React.PureComponent {

  constructor(props) {
    super(props);
  }


  render() {
    const { object,name,force } = this.props;
    if (force) {
      return (
        <span>
        {name}
        {object && (<Inspector data={ Lodash.clone(object) } />)}
        </span>
      )
    } else {
      return ( 
        <DebugOnly>
          <span>
          {name}
          {object && (<Inspector data={ Lodash.clone(object) } />)}
          </span>
        </DebugOnly>
      );
    }
  }
}

DebugObjectView.propTypes = {
  object: PropTypes.object,
  name: PropTypes.string,
  force: PropTypes.bool,
};
DebugObjectView.defaultProps = {
  force:false
};

export default DebugObjectView;