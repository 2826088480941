import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';

import SetupEventZoneHeaderTabs from './SetupEventZoneHeaderTabs';
import SetupEventZoneTabInfo from './SetupEventZoneTabInfo';
import SetupEventZoneTabSeats from './SetupEventZoneTabSeats';
import SetupEventZoneTabPrices from './SetupEventZoneTabPrices';

const GET_EVENT_ZONE = gql`
  query EventZone($event_id:ID!,$event_zone_id:ID!) {
    eventZone(id:$event_zone_id) {
      id
      zone_id
      map_zone_key
      free_zone
      name
      primary_color
      secondary_color
      seats {
        id
        name
        type
        capacity
        event_zone_id
        event_id
        seat_id
        zone_id
        name
        type
        capacity
        row_label_base
        col_label_base
        box_capacity_left
        box_capacity_right
        box_capacity_top
        box_capacity_bottom
        map_position_x
        map_position_y
        map_position_angle
        map_sector_angle
      }
      prices {
        id
        name
        cost
        sale_student
        event_price_plan_id
        event_zone_id
        zone_price_id
      }
      tickets {
        id
        ticket_key
        status
        event_id
        event_zone_id
        event_seat_id
        event_seat_idx
        name
        cost
        sale_student
        booking_ref
        payment_id
        updated_at
      }
    }

    event(id:$event_id) {
      id
      name
      status
      
      image_id
      image {
        url
        s3key
        name
        width
        height
      }
      place_id
      place {
        name
        long_name
        map
        address
      }

      zones {
        id
        zone_id
        name
      }

      priceplans {
        id
        name
        price_plan_id
      }  

      comment
      date
      auto_open_date
      auto_suspend_date

    }
  }
`;



const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});



class SetupEventZoneBoard extends React.Component {

  

  renderTabInfo(event,event_zone,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventZoneTabInfo auth={auth} event={event} event_zone={event_zone} refetch={refetch}/>
    )
  }

  renderTabSeats(event,event_zone,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventZoneTabSeats auth={auth} event={event} event_zone={event_zone} refetch={refetch}/>
    )
  }

  renderTabPrices(event,event_zone,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventZoneTabPrices auth={auth} event={event} event_zone={event_zone} refetch={refetch}/>
    )
  }

  refresh() {
    //do headertabs nacpat refresh callback a tady to pred ref zavolat
    //taby pak dostavaji tuhle funkci na zavolani
  }

  render() {
    const { classes , auth,page,event_id,event_zone_id} = this.props;
    


    return (
        <Query query={GET_EVENT_ZONE} variables={{event_id,event_zone_id}} fetchPolicy={"network-only"}>
            {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;

              let tab=null;
              if (data.event && data.eventZone) {
                switch(page) {
                  case 'info': tab = this.renderTabInfo(data.event,data.eventZone,refetch); break;
                  case 'seats': tab = this.renderTabSeats(data.event,data.eventZone,refetch); break;
                  case 'prices': tab = this.renderTabPrices(data.event,data.eventZone,refetch); break;
                  default:
                  break;
                }
              }
              return (
                <React.Fragment>
                  <SetupEventZoneHeaderTabs event_id={event_id} event_zone_id={event_zone_id} event={data.event} event_zone={data.eventZone} activeTab={page} auth={auth} />
                  <div className={classes.tab_root}>{tab}</div>
                </React.Fragment>
              );
            }}
        </Query>
    );
  }
}

SetupEventZoneBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  event_zone_id: PropTypes.string.isRequired,
  page: PropTypes.oneOf(["info","seats","prices"]).isRequired
};
SetupEventZoneBoard.defaultProps = {
  
};

export default withStyles(styles)(SetupEventZoneBoard);