import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EventStripAdmin from './EventStripAdmin';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});


const UPDATE_EVENT_STATUS = gql`
mutation updateEventStatus($id:ID!$status:EventStatus) {
  eventUpdate(id:$id,status:$status) {
    id
  }
}
`;


class SetupEventTabInfo extends React.Component {

 
  changeStatus(new_status) {
  //  alert("setting new event status:"+new_status);
    this.props.updateEventStatus({
      variables: {
        id:this.props.event.id,
        status:new_status
      }
    }).then(res=>{
      //alert("ok"+JSON.stringify(res));
      this.props.refetch();
    }).catch(err=>{
      alert(err);
    })
  }

  render() {
    const { classes,event,auth} = this.props;

    return (
      <React.Fragment>
            <Paper className={classes.paper}>
              <EventStripAdmin show_debug_info={false} auth={auth} event={event} onChangeStatus={(new_status)=>this.changeStatus(new_status)}/>
            </Paper>
            <Typography variant="subheading"> Přehled zón v akci </Typography>
            {event.zones.map(ev=>{
              return (<Chip key={ev.id} label={ev.name} className={classes.chip} />);
            })}
            <Typography variant="subheading"> Přehled cenových plánů v akci </Typography>
            {event.priceplans.map(pp=>{
              return (<Chip key={pp.id} label={pp.name} className={classes.chip} />);
            })}


      </React.Fragment>
    );
  }
}

SetupEventTabInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  updateEventStatus: PropTypes.func.isRequired,
};
SetupEventTabInfo.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(UPDATE_EVENT_STATUS,{
    name: "updateEventStatus",
  }),
)(SetupEventTabInfo)
