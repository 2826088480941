import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
/*
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventsTable from './EventsTable';
import { Query } from "react-apollo";
import EventViewDialog from './EventViewDialog';
import EventDeleteDialog from './EventDeleteDialog';
import EventEditDialog from './EventEditDialog';
import ActionButton from './ActionButton';
import {Redirect} from 'react-router-dom'

import {GET_EVENTS } from './Fields/Queries';
*/


const styles = theme => ({
 
});


class EventsSearch extends React.Component {

  constructor(props) {
    super(props);
  }



 
  render() {
    // const { auth} = this.props;
    

    
    return (
      <React.Fragment>
      
 
        <div> tady bude hledani podle cisla vstupenky, objednavky, platby z brany </div>
    
      </React.Fragment>
    );
  }
}

EventsSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
EventsSearch.defaultProps = {
  
};


export default compose(
  withStyles(styles),
)(EventsSearch)
