


const  PaymentStatus_schema_enum = ['paid','pending','cancelled','refunded','declined','incomplete'];
const  PaymentStatus_gql_values =  { 
    PAID:{value:"paid"},
    PENDING:{value:"pending"},
    CANCELLED:{value:"cancelled"},
    REFUNDED:{value:"refunded"},
    DECLINED:{value:"declined"},
    INCOMPLETE:{value:"incomplete"},
};

const PaymentStatus_field_enum = [
    {key:"PAID",label:"zaplacená"},
    {key:"PENDING",label:"čekající"},
    {key:"CANCELLED",label:"zrušená"},
    {key:"REFUNDED",label:"vrácená"},
    {key:"DECLINED",label:"zamítnutá"},
    {key:"INCOMPLETE",label:"nedokončená"},
];
const PaymentStatus_props_enum = ['PAID','PENDING','CANCELLED','REFUNDED','DECLINED','INCOMPLETE'];


module.exports = {
    PaymentStatus_schema_enum,
    PaymentStatus_gql_values,
    PaymentStatus_field_enum,
    PaymentStatus_props_enum
};