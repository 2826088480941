import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { compose } from 'react-apollo';
import DebugObjectView from './Utils/DebugObjectView';
import { setDebugView } from './../actions';
import BooleanField from './BooleanField';

const styles = theme => ({
 
});


class ConfigTabInfo extends React.Component {

 
  

  render() {
    //const { classes} = this.props;

    return (
      <React.Fragment>
            <div> config info tab </div>
            <DebugObjectView name={"redux state"} object={this.props.redux_state} />
            <BooleanField force required width={200} id="debug_view" label="debug_view" value={this.props.debug_view} onChange={(v)=>this.props.onChangeDebugView(v)} />

      </React.Fragment>
    );
  }
}

ConfigTabInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  redux_state:PropTypes.object,
  debug_view:PropTypes.bool.isRequired,
  onChangeDebugView: PropTypes.func.isRequired,
};
ConfigTabInfo.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      redux_state: state,
      debug_view: state.persistent_opts.debug_view
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onChangeDebugView: value => {
      dispatch(setDebugView(value))
    },
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
)(ConfigTabInfo)
