import React from 'react';
import PropTypes from 'prop-types';

import {  Group,Circle,Text,Rect } from 'react-konva';

const dist_w = 60;
const text_off = 7;
const text_y = -5;

class PlanLegendPanel extends React.Component {
  
  
    render() {
      const {x,y,color,bg_color} = this.props;
      return (
        <Group x={x} y={y}>


          <Rect listening={false}
            x={-15}
            y={-10}
            width={260}
            height={20}
            fill={bg_color}
          />

          <Circle listening={false}
              x={0}
              y={0}
              radius={5}
              stroke={color}
              fill={"white"}
          />
          <Text listening={false}  
              fill={color}
              x={text_off} y={text_y} width={50} 
              fontSize={10} align={'left'} text={"volno"} 
          />

          <Circle listening={false}
              x={dist_w}
              y={0}
              radius={5}
              stroke={color}
              fill={"red"}
          />
          <Text listening={false}  
              fill={color}
              x={text_off+dist_w} y={text_y} width={50} 
              fontSize={10} align={'left'} text={"obsazeno"} 
          />

          <Circle listening={false}
              x={dist_w*2}
              y={0}
              radius={5}
              stroke={color}
              fill={"blue"}
          />
          <Text listening={false}  
              fill={color}
              x={text_off+dist_w*2} y={text_y} width={50} 
              fontSize={10} align={'left'} text={"v košíku"} 
          />

          <Circle listening={false}
              x={dist_w*3}
              y={0}
              radius={5}
              stroke={color}
              fill={"purple"}
          />
          <Text listening={false}  
              fill={color}
              x={text_off+dist_w*3} y={text_y} width={50} 
              fontSize={10} align={'left'} text={"vybráno"} 
          />

        </Group>
      )
    }
  }
  PlanLegendPanel.propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    color: PropTypes.any,
    bg_color: PropTypes.any,
  }
  PlanLegendPanel.defaultProps = {
    x:0,
    y:0,
    color:'black',
    bg_color:'lightgray',
  }
  

  export default PlanLegendPanel;