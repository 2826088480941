import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  cont: {
    margin: theme.spacing.unit,
    border: 0,
    display: "inline-flex",
    padding: 0,
    position: "relative",
   // minWidth: 50,
    flexDirection: "column"
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    top: 0,
    left: 0,
    position: "absolute",
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "1rem",
    lineHeight: 1,
    display: "inline-block",
    marginBottom: ".5rem"
  },
  value_c: {
    display: 'inline-flex',
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : theme.palette.common.white,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '1.1875em', // Reset (19px), match the native input line-height
    'label + &': {
      marginTop: theme.spacing.unit * 2,
    },
    '&:after': {
      backgroundColor: theme.palette.primary[theme.palette.type === 'light' ? 'dark' : 'light'],
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      height: 2,
      position: 'absolute',
      right: 0,
      transform: 'scaleX(0)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
    '&:before': {
      backgroundColor: theme.palette.type === 'light'? 'rgba(0, 0, 0, 0.42)' : 'rgba(255, 255, 255, 0.7)',
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      height: 1,
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shorter,
      }),
      pointerEvents: 'none', // Transparent to the hover style.
    },
  },
  value: {
    font: 'inherit',
      color: 'currentColor',
      padding: `${theme.spacing.unit - 2}px 0 ${theme.spacing.unit - 1}px`,
      border: 0,
      boxSizing: 'content-box',
      verticalAlign: 'middle',
      background: 'none',
      margin: 0, // Reset for Safari
      // Remove grey highlight
      WebkitTapHighlightColor: 'transparent',
      display: 'block',
      // Make the flex item shrink with Firefox
      minWidth: 0,
      flexGrow: 1,
      height: '1.1875em',
      overflowX: 'auto'
  },
  textfield: {
    margin: theme.spacing.unit,
  }
});


class StringViewField extends React.Component {

  render() {
    const { classes,label,value } = this.props;
    const style = this.props.width?{width:this.props.width}:{};
    return (
        <div className={classes.cont} style={style}>
            <label className={classes.label}>{label}</label>
            <div className={classes.value_c}>
              {value?(<div className={classes.value}>{value}</div>):(<div className={classes.value}>&nbsp;</div>)}
            </div>
        </div>
    );
  }
}

StringViewField.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
};
StringViewField.defaultProps = {
  width: 150
};

export default withStyles(styles)(StringViewField);