import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';


const styles = theme => ({
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  tab_root: {
    minWidth:50 
  }
});


class EnrollHeaderTabs extends React.Component {

  


  
  render() {
    const { classes,activeTab} = this.props;

    return (
      <AppBar position="static" color="default">
          <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
            <Typography className={classes.toolBar_item} variant="title" color="inherit">
                Náhled zápisu
            </Typography>
          
            <Tabs className={classes.toolBar_item} value={activeTab}>
              <Tab classes={{root:classes.tab_root}} value={0} label="Vše" component={Link} to={"/spa/enroll/preview/"}/>
              {[1,2,3,4,5,6,7,8,9].map((n)=>{
               return (<Tab classes={{root:classes.tab_root}} key={"tab_"+n} value={n} label={"Sk. #"+n} component={Link} to={"/spa/enroll/preview/"+n}/>)
              })}
            </Tabs>

          </Toolbar>
      </AppBar>
    );
  }
}

EnrollHeaderTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
};
EnrollHeaderTabs.defaultProps = {
  
};


export default withStyles(styles)(EnrollHeaderTabs);