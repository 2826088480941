import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import CoursesTable from './CoursesTable';
import { Query } from "react-apollo";
import Paper from '@material-ui/core/Paper';
import CourseViewDialog from './CourseViewDialog';
import CourseEditDialog from './CourseEditDialog';
import CourseDelDialog from './CourseDelDialog';
import CoursesCash from './CoursesCash';

const styles = theme => ({
  root: {
    // width: '100%',
    margin: theme.spacing.unit
  },
});

const CurrentCourses = gql`
  query Courses($folder_key: String!, $season_key:String!, $evi_group:EviGroup) {
    courses(folder_key:$folder_key, season_key:$season_key, evi_group:$evi_group) {
        key
        active
        capacity
        evi_group
        accounting_year
        var_sym_prefix
        name
        code
        first_period
        period
        teacher
        place
        stats {
          in {
            ...fs
          }
          filed {
            ...fs
          }
          spare {
            ...fs
          }
          total {
            ...fs
          }
        }
    }
  }
  fragment fs on CourseStatsGenRecord {
    male {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    female {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
    total {
      count
      not_paid
      deposit
      paid
      paid_amount
    }
  }
`;




class Courses extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      showCourse_key: null,
      editCourse_key: null,
      delCourse_key: null,
    }
  }


  handleCloseCourseViewDialog() {
    this.setState({showCourse_key:null});
  }

  handleShowCourse(course_key) {
    console.log("handleShowCourse");
    this.setState({showCourse_key:course_key});
  }


  handleCloseCourseEditDialog() {
    this.setState({editCourse_key:null});
  }

  handleEditCourse(course_key) {
    console.log("handleEditCourse");
    this.setState({editCourse_key:course_key});
  }


  handleCloseCourseDeleteDialog() {
    this.setState({delCourse_key:null});
  }

  handleDeleteCourse(course_key) {
    console.log("handleDeleteCourse");
    this.setState({delCourse_key:course_key});
  }



  renderCourseViewDialog() {
    const {showCourse_key} = this.state;
    return (
      <CourseViewDialog open={showCourse_key!=null} course_key={showCourse_key} caption={"Detail kurzu"} onClose={()=>this.handleCloseCourseViewDialog()}/>
    )
  }


  renderCourseEditDialog() {
    const {evi_group} = this.props;
    const {editCourse_key} = this.state;
    return (
      <CourseEditDialog evi_group={evi_group} open={editCourse_key!=null} course_key={editCourse_key} caption={"Úprava kurzu"} onClose={()=>this.handleCloseCourseEditDialog()}/>
    )
  }

  renderCourseDeleteDialog() {
    const {delCourse_key} = this.state;
    return (
      <CourseDelDialog open={delCourse_key!=null} course_key={delCourse_key} caption={"Smazání kurzu"} onClose={()=>this.handleCloseCourseDeleteDialog()}/>
    )
  }


  render() {
    const { classes, folder_key, season_key , evi_group, auth} = this.props;
    const cvd = this.renderCourseViewDialog();
    const ced = this.renderCourseEditDialog();
    const cdd = this.renderCourseDeleteDialog();
    return (
      <React.Fragment>
      {cvd} {ced} {cdd}
      <Query query={CurrentCourses} variables={{ folder_key,  season_key, evi_group}} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <React.Fragment>
              <Paper className={classes.root}>
              <CoursesTable 
                  auth={auth}
                  courses={data.courses} 
                  evi_group={evi_group}
                  season_key={season_key} 
                  folder_key={folder_key} 
                  onShowCourse={(key)=>this.handleShowCourse(key)}
                  onEditCourse={(key)=>this.handleEditCourse(key)}
                  onDeleteCourse={(key)=>this.handleDeleteCourse(key)}
              />
              </Paper>
              {auth.is_admin && (
                  <div className={classes.root}>
                  <CoursesCash courses={data.courses}/>
                  </div>
              )}
              </React.Fragment>
            );
          }}
      </Query>
      </React.Fragment>
    );
  }
}

Courses.propTypes = {
  classes: PropTypes.object.isRequired,
  season_key: PropTypes.string.isRequired,
  folder_key: PropTypes.string.isRequired,
  evi_group: PropTypes.string,
  auth: PropTypes.object.isRequired,
};

Courses.defaultProps = {
 
};



export default compose(
  withStyles(styles),
)(Courses)
