import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lodash from "lodash";
import {SeatType_field_enum } from './../../../graphql/enums/seattype';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class SeatTypeView extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,seat_type } = this.props;
    const seatt = Lodash.find(SeatType_field_enum,{key:seat_type});
    return ( 
      <span className={classes.root}>
      { seatt?seatt.label:(<span> ? {seat_type} </span>) }
      </span>
    );
  
  }
}

SeatTypeView.propTypes = {
  classes: PropTypes.object.isRequired,
  seat_type: PropTypes.string
};
SeatTypeView.defaultProps = {
  
};

export default withStyles(styles)(SeatTypeView);