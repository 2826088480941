import React from 'react';
import PropTypes from 'prop-types';

import { Rect, Path, Group } from 'react-konva';

class ButtonZoomReset extends React.Component {
  
  
    render() {
      const {x,y,color,bg_color} = this.props;
      return (
        <Group x={x} y={y} onClick={(x)=>this.props.onClick(x)} onTap={(x)=>this.props.onClick(x)}>
          <Rect 
            x={-2}
            y={-2}
            width={28}
            height={28}
            fill={bg_color}
          />
          <Path
            data={"M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6z"} 
            fill={color}
            stroke={color}
            strokeWidth={1}
          />

          <Path
            data={"M0 0h24v24H0V0z"} 
            stroke={color}
            strokeWidth={1}
          />

        
        </Group>
      )
    }
  }
  ButtonZoomReset.propTypes = {
    onClick: PropTypes.func.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    color: PropTypes.any,
    bg_color: PropTypes.any
  }
  ButtonZoomReset.defaultProps = {
    x:0,
    y:0,
    color:'black',
    bg_color:'lightgray',
  }
  

  export default ButtonZoomReset;