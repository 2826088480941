import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';

import SetupEventHeaderTabs from './SetupEventHeaderTabs';
import SetupEventTabInfo from './SetupEventTabInfo';
import SetupEventTabPlan from './SetupEventTabPlan';
import SetupEventTabZones from './SetupEventTabZones';
import SetupEventTabPricePlans from './SetupEventTabPricePlans';

const GET_EVENT = gql`
  query Event($event_id:ID!) {
    event(id:$event_id) {
      id
      name
      status
      test_mode
      image_id
      image {
        url
        s3key
        name
        width
        height
      }
      place_id
      place {
        name
        long_name
        map
        address
      }

      zones {
        id
        zone_id
        map_zone_key
        free_zone
        name
        long_name
        primary_color
        secondary_color
      }

      priceplans {
        id
        active
        name
        price_plan_id
      }  

      zoneprices {
        id
        name
        cost
        event_price_plan_id
        event_zone_id
        zone_price_id
      }

      seats {
        id
        name
        type
        capacity
        event_zone_id
        seat_id
        zone_id
        name
        type
        capacity
        row_label_base
        col_label_base
        box_capacity_left
        box_capacity_right
        box_capacity_top
        box_capacity_bottom
        map_position_x
        map_position_y
        map_position_angle
        map_sector_angle
      }
 
      comment
      date
      auto_open_date
      auto_suspend_date

    }
  }
`;



const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  tab_root: {
    margin: theme.spacing.unit*2
  }
});




class SetupEventBoard extends React.Component {

  

  renderTabInfo(event,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventTabInfo auth={auth} event={event} refetch={refetch}/>
    )
  }

  renderTabPlan(event) {
    const {auth} = this.props;
    return (
      <SetupEventTabPlan auth={auth} event={event}/>
    )
  }

  renderTabZones(event,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventTabZones auth={auth} event={event} refetch={refetch}/>
    )
  }

  renderTabPricePlans(event,refetch) {
    const {auth} = this.props;
    return (
      <SetupEventTabPricePlans auth={auth} event={event} refetch={refetch}/>
    )
  }

  refresh() {
    //do headertabs nacpat refresh callback a tady to pred ref zavolat
    //taby pak dostavaji tuhle funkci na zavolani
  }




  render() {
    const { classes , auth,page,event_id} = this.props;
    


    return (
        <Query query={GET_EVENT} variables={{event_id}} fetchPolicy={"network-only"}>
            {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;

              let tab=null;
              if (data.event) {
                switch(page) {
                  case 'info': tab = this.renderTabInfo(data.event,refetch); break;
                  case 'plan': tab = this.renderTabPlan(data.event); break;
                  case 'zones': tab = this.renderTabZones(data.event,refetch); break;
                  case 'priceplans': tab = this.renderTabPricePlans(data.event,refetch); break;
                  default:
                  break;
                }
              }
              return (
                <React.Fragment>
                  <SetupEventHeaderTabs event_id={event_id} event={data.event} activeTab={page} auth={auth} />
                  <div className={classes.tab_root}>{tab}</div>
                </React.Fragment>
              );
            }}
        </Query>
    );
  }
}

SetupEventBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event_id: PropTypes.string.isRequired,
  page: PropTypes.oneOf(["info","plan","zones","priceplans"]).isRequired
};
SetupEventBoard.defaultProps = {
  
};

export default withStyles(styles)(SetupEventBoard);