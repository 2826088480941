import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo'

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    root: {
      marginTop: theme.spacing.unit * 3,
      width: '100%',
    },
    textfield: {
        margin: theme.spacing.unit,
        width: 75
    }

});
  

class BooleanField extends React.Component {

    handleChange(e) {
        switch(e.target.value) {
            case "YES": this.props.onChange(true); return;
            case "NO": this.props.onChange(false); return;
            default: this.props.onChange(null);
        }
    }

    render() {
        const { classes ,disabled,force} = this.props;
        const style = this.props.width?{width:this.props.width}:{};
        const val = (this.props.value===null)?"NONE":(this.props.value?"YES":"NO");
        return (
            <FormControl disabled={disabled} className={classes.textfield} style={style} required={this.props.required} error={this.props.error}>
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
                <Select
                    value={val}
                    onChange={(e)=>this.handleChange(e)}
                    input={<Input name={this.props.name} id={this.props.id} />}
                >
                    {!force && (<MenuItem value={"NONE"}>{"-"}</MenuItem>)}
                    <MenuItem value={"YES"}>{"ano"}</MenuItem>
                    <MenuItem value={"NO"}>{"ne"}</MenuItem>
                </Select>
            </FormControl>
        )
    }
}


BooleanField.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.number,
    error: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    force: PropTypes.bool,
};
  
BooleanField.defaultProps = {
    autoFocus: false,
    name: "bool",
    error:false,
    required:false,
    disabled:false,
    force:false
}


export default compose(
    withStyles(styles),
)(BooleanField)