import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SeasonEdit from './SeasonEdit';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';


const styles = theme => ({
  root: {
    minWidth:800
  }
});


class SeasonEditDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dirty:false,
      valid:true
    }
    this.edit = React.createRef();
  }

  onSave() {
    if (this.edit.current) {
      this.edit.current.wrappedInstance.doSave();
    }
  }

  onEnter() {
    this.setState({dirty:false,valid:true});
    //TODO reload?
  }

  onChange(d,v) {
    this.setState({dirty:d,valid:v});
  } 



  

  render() {
    const {season_key,open,onClose,caption} = this.props;
    return (
      <Dialog disableBackdropClick maxWidth={false} open={open} onClose={onClose} onEnter={()=>this.onEnter()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {season_key && (<SeasonEdit ref={this.edit} season_key={season_key} onClose={onClose}  onChange={(d,v)=>this.onChange(d,v)} />)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Neukládat
        </Button>
        <Button disabled={!this.state.valid} onClick={()=>this.onSave()} color="primary">
          Uložit
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

SeasonEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  season_key:PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
SeasonEditDialog.defaultProps = {
  caption:"Úprava sezóny"
};

export default withStyles(styles)(SeasonEditDialog);