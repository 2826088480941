import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query } from "react-apollo";
import Lodash from "lodash";
import {GET_PLACES } from './Queries';

const styles = theme => ({
  root: {
    //marginRight:8,
    //marginLeft:4
  }
});



class PlaceIDView extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { classes,place_id } = this.props;
    return (
      <Query query={GET_PLACES} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (loading) return null; 
        if (error) return `Error!: ${error}`;
        const place = Lodash.find(data.places,{id:place_id});
        return ( 
          <span className={classes.root}>
          { place?place.name:(<span> ? {place_id} </span>) }
          </span>
        );
      }}
      </Query>
    );
  }
}

PlaceIDView.propTypes = {
  classes: PropTypes.object.isRequired,
  place_id: PropTypes.string
};
PlaceIDView.defaultProps = {
  
};

export default withStyles(styles)(PlaceIDView);