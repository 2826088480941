import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SimpleDataTable from './Tables/SimpleDataTable';
import SimpleGQLTable from './Tables/SimpleGQLTable';
import TableCell from './Tables/SimpleDataTableCell';
import {GET_PRICE_PLANS} from './Fields/Queries';
import ActionButton from './ActionButton';
//import ActionLink from './ActionLink';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';

const CREATE_EVENT_PRICE_PLAN = gql`
mutation eventPricePlanCreate($event_id:ID!,$price_plan_id:ID!) {
  eventPricePlanCreate(event_id:$event_id,price_plan_id:$price_plan_id) {
    id
  }
}
`;
const DELETE_EVENT_PRICE_PLAN = gql`
mutation eventPricePlanDelete($event_price_plan_id:ID!) {
  eventPricePlanDelete(id:$event_price_plan_id) {
    id
  }
}
`;


const ACTIVATE_EVENT_PRICE_PLAN = gql`
mutation eventPricePlanActivate($event_price_plan_id:ID!) {
  eventPricePlanActivate(id:$event_price_plan_id) {
    id
  }
}
`;


const styles = theme => ({
  sep: {
    marginTop: theme.spacing.unit*3
  }
});



class SetupEventTabPricePlans extends React.Component {

 
  constructor(props) {
    super(props);
    this.price_plan_table = React.createRef();
  }

  filterout_used_priceplans(priceplans) {
    const { event } = this.props;
    const used_priceplan_ids = event.priceplans.map(z=>z.price_plan_id);
    const unused = priceplans.filter(z=>{
        const un = (used_priceplan_ids.indexOf(z.id)==-1)
        return un;
      });
    return unused;
  }


  reload() {
    this.props.refetch();
    if (this.price_plan_table.current) {
      this.price_plan_table.current.refetch();
    }
  }

  activatePricePlan(priceplan) {
    //const { event } = this.props;
    this.props.activateEventPricePlan({
      variables: {
        event_price_plan_id:priceplan.id,
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }

  addPricePlan(priceplan) {
    const { event } = this.props;
    this.props.createEventPricePlan({
      variables: {
        event_id:event.id,
        price_plan_id:priceplan.id
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }

  removePricePlan(event_price_plan) {
    //const { event } = this.props;
    this.props.deleteEventPricePlan({
      variables: {
        event_price_plan_id:event_price_plan.id,
      }
    }).then(res=>{
      this.reload();
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }



  render() {
    const {event,auth,classes} = this.props;
    const editable = (event.status == "NEW" || event.test_mode);
    return (
      <React.Fragment>
          <Typography variant="title">Seznam cenových plánů, které jsou nastavené pro akci</Typography>
          {!editable &&(
            <Typography variant="subheading">nastavené plány již nelze odebrat, protoze akce se již prodává nebo prodávala</Typography>
          )}
          <Typography variant="subheading">jeden z plánu musí být vždy zvolen jako aktivní</Typography>
          
   
          <SimpleDataTable data={event.priceplans} 
            renderHeader={()=>(
              <React.Fragment>
                <TableCell debug_only>event_price_plan_id</TableCell>
                <TableCell debug_only>price_plan_id</TableCell>
                <TableCell>název</TableCell>
                <TableCell>aktivní</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell debug_only>{item.id}</TableCell>
                <TableCell debug_only>{item.price_plan_id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.active?"ano":"ne"}</TableCell>
                <TableCell>
                  {auth.is_admin && editable && (<ActionButton tooltip={"odebrat z události"} icon={'delete'} onClick={()=>this.removePricePlan(item)}/>)}
                  {auth.is_admin && (<ActionButton tooltip={"aktivovat"} icon={'play'} onClick={()=>this.activatePricePlan(item)}/>)}
               
                  {/*
                  <ActionLink useRouter tooltip={"nastavit cenový plán"} icon={'setup'} href={'/spa/setup_event/'+event.id+'/priceplan/'+item.id}/>
                  */}
                </TableCell>
             
              </React.Fragment>
            )}
          />


          <Typography className={classes.sep} variant="title">Seznam cenových plánů, které je možné do akce přidat</Typography>

            
          <SimpleGQLTable  innerRef={this.price_plan_table}
            query={GET_PRICE_PLANS} variables={{place_id:event.place_id}} 
            getData={(r)=>{return this.filterout_used_priceplans(r.pricePlans)}}
            renderHeader={()=>(
              <React.Fragment>
                <TableCell  debug_only>price_plan_id</TableCell>
                <TableCell>název</TableCell>
                <TableCell></TableCell>
              </React.Fragment>
            )}
            renderRow={(item)=>(
              <React.Fragment>
                <TableCell  debug_only>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>
                  {auth.is_admin && (<ActionButton tooltip={"přidat do události"} icon={'add'} onClick={()=>this.addPricePlan(item)}/>)}
                </TableCell>
              </React.Fragment>
            )}
            renderEmptyInfo={()=>(<div> žádny cenovy plan k přidání (jsou přidané všechny, nebo není žádny pro lokalitu nastaveny) </div>)}
          />
  
      </React.Fragment>
    );
  }
}

SetupEventTabPricePlans.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  createEventPricePlan: PropTypes.func.isRequired,
  deleteEventPricePlan: PropTypes.func.isRequired,
  activateEventPricePlan: PropTypes.func.isRequired,
};
SetupEventTabPricePlans.defaultProps = {
  
};



export default compose(
  withStyles(styles),
  graphql(CREATE_EVENT_PRICE_PLAN,{
    name: "createEventPricePlan",
  }),
  graphql(DELETE_EVENT_PRICE_PLAN,{
    name: "deleteEventPricePlan",
  }),
  graphql(ACTIVATE_EVENT_PRICE_PLAN,{
    name: "activateEventPricePlan",
  }),
)(SetupEventTabPricePlans)
