import React from 'react';
import PropTypes from 'prop-types';
import PlacePlanBoby from './PlacePlanBoby';
import PlacePlanBvv from './PlacePlanBvv';
import PlacePlanBabylon from './PlacePlanBabylon';
import PlacePlanTesting from './PlacePlanTesting';


class PlacePlan extends React.Component {

  
  render() {
    const {place_map} =  this.props;
    

    const cprops = {
      ...this.props
    }
    delete cprops["place_map"];

    switch (place_map) {
      case "BABYLON": return React.createElement(PlacePlanBabylon,cprops);
      case "BOBY": return React.createElement(PlacePlanBoby,cprops);
      case "BVV": return React.createElement(PlacePlanBvv,cprops);
      case "TEST": return React.createElement(PlacePlanTesting,cprops);

      default: return null;

    }
  }
}

PlacePlan.propTypes = {
  place_map: PropTypes.string,
};
PlacePlan.defaultProps = {

};

export default PlacePlan;