import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import FoldersTable from './FoldersTable';
import { Query } from "react-apollo";
import Paper from '@material-ui/core/Paper';
import FolderEditDialog from './FolderEditDialog';


const styles = theme => ({
  root: {
    width: '100%',
  },
});

const CurrentFolders = gql`
  query Folders {
    folders {
        key
        name
        no_stat
        access_check
        order_value
        evi_group
        enroll_group
    }
  }
`;

/*
const SaveOrdering = gql`
    mutation folders_ordering($keys: [String]!) {
        folders_ordering(keys:$keys) 
    }
`;
*/

class Folders extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      editFolder_key: null,
    }
  }
 
  handleCloseFolderEditDialog() {
    this.setState({editFolder_key:null});
  }

  handleEditFolder(folder_key) {
    console.log("handleEditFolder");
    this.setState({editFolder_key:folder_key});
  }

  renderFolderEditDialog() {
    const {editFolder_key} = this.state;
    return (
      <FolderEditDialog open={editFolder_key!=null} folder_key={editFolder_key} onClose={()=>this.handleCloseFolderEditDialog()}/>
    )
  }


  render() {
    const { classes, auth } = this.props;
  
    const fed = this.renderFolderEditDialog();

    return (
      <Paper className={classes.root}>
       {fed} 
      <Query query={CurrentFolders} fetchPolicy={"network-only"}>
          {({ loading, error, data }) => {
            if (loading) return (<CircularProgress/>);
            if (error) return `Error!: ${error}`;
            return (
              <FoldersTable 
                  auth={auth}
                  folders={data.folders} 
                  onEditFolder={(key)=>this.handleEditFolder(key)}
              />
            );
          }}
      </Query>
      </Paper>
    );
  }
}

Folders.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

Folders.defaultProps = {
 
};



export default compose(
  withStyles(styles),
)(Folders)
