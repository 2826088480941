import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { compose } from 'react-apollo';

const styles = theme => ({
    root: {
        width: "100%"
    },
    rootError: {
        width: "100%",
        color: theme.palette.error.main
    },
    button: {
        margin: theme.spacing.unit,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    textFieldLong: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300 + theme.spacing.unit,
    },

    textFieldMid: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 150 + theme.spacing.unit,
    },

    textFieldShort: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 100 - theme.spacing.unit,
    },
    textFieldInput: {
        color: theme.palette.text.primary
    }

});



function status2text(s) {
    switch(s) {
        case 'IN': return "Zapsán";
        case 'SPARE': return "Náhradník";
        case 'FILED': return "Přihláška";
        case 'KICKED': return "Vyřazen";
        default: 
    }
    return "?";
}


class StudentCardView extends React.Component {

  renderStringField(id,label,value) {
      const classes = this.props.classes;
      return ( 
            <TextField id={id} disabled className={classes.textField}
                label={label}
                value={value?value:""}
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    classes: {
                      disabled: classes.textFieldInput,
                    },
                }}
            />
      );
  }
  renderStringFieldShort(id,label,value) {
    const classes = this.props.classes;
    return ( 
          <TextField id={id} disabled className={classes.textFieldShort}
              label={label}
              value={value?value:""}
              margin="dense"
              InputLabelProps={{
                  shrink: true,
              }}
              InputProps={{
                  classes: {
                    disabled: classes.textFieldInput,
                  },
              }}
          />
        );
    }
    renderStringFieldLong(id,label,value) {
        const classes = this.props.classes;
        return ( 
              <TextField id={id} disabled className={classes.textFieldLong}
                  label={label}
                  value={value?value:""}
                  margin="dense"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  InputProps={{
                      classes: {
                        disabled: classes.textFieldInput,
                      },
                  }}
              />
        );
    
    }

    renderStringFieldMid(id,label,value) {
        const classes = this.props.classes;
        return ( 
              <TextField id={id} disabled className={classes.textFieldMid}
                  label={label}
                  value={value?value:""}
                  margin="dense"
                  InputLabelProps={{
                      shrink: true,
                  }}
                  InputProps={{
                      classes: {
                        disabled: classes.textFieldInput,
                      },
                  }}
              />
        );
    
    }

  renderNumberField(id,label,value) {
    const classes = this.props.classes;
    return ( 
          <TextField id={id} disabled className={classes.textFieldShort}
              label={label}
              value={value?""+value:""}
              margin="dense"
              InputLabelProps={{
                  shrink: true,
              }}
              InputProps={{
                  classes: {
                    disabled: classes.textFieldInput,
                  },
              }}
          />
        );
    }



    render() {
        const { classes, student, old_pay } = this.props;
        if (old_pay) {
            return (
                <div className={classes.root}>
                    <div>
                        {this.renderNumberField("paid","Zaplaceno",student.paid)}
                        {this.renderStringFieldLong("pay_info","Platební info",student.pay_info)}
                    </div>
                </div>     
            )
        }
        return (
        <div className={classes.root}>
        <div> 
            {this.renderStringField("status","Stav",status2text(student.status))}
            {this.renderStringField("surname","Přijmení",student.surname)}
            {this.renderStringField("name","Jméno",student.name)}
            {this.renderStringFieldLong("email","Email",student.email)}
        </div>
        <div>
            {this.renderStringFieldShort("code","Č. kurzu",student.course.code)}
            {this.renderStringFieldShort("cname","Název kurzu",student.course.name)}
            {this.renderStringFieldLong("folder","Složka",student.course.folder.name)}
            {this.renderStringFieldShort("season","Sezóna",student.course.season.name)}
        </div>
        <div>
            {this.renderNumberField("course_cost","Kurzovné",student.course_cost)}
            {this.renderNumberField("paid_amount","Zaplaceno",student.paid_amount)}
            {this.renderNumberField("var_sym","Var. sym",student.var_sym)}
            {this.renderStringFieldMid("discount","Sleva",student.discount)}
            {this.renderStringFieldMid("paid_ok","Zaplaceno vše",student.paid_ok?"Ano":"Ne")}
        </div>
        </div>
        );
    }
}

StudentCardView.propTypes = {
    student: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    old_pay: PropTypes.bool
};

export default compose(
    withStyles(styles),
)(StudentCardView)


