import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
//import { compose, graphql } from 'react-apollo'
//import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import Lodash from 'lodash';
import ZonePricesTableRow from './ZonePricesTableRow';


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
  },
  tablebody: {
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  row: {
    //height:24,
   
  }
});



class ZonePricesTable extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
      order:[],
      activeDrag:false,
    }
  }
 
  onDrag(active) {
    this.setState({activeDrag:active})
  }  

 reorder(items) {
    const { order } = this.state;
    if (order.length !== items.length) {
      console.log("missing order")
      return items;
    }
    console.log("reodering");
    return order.map((id)=>{return Lodash.find(items,{id:id}) });
 }

  findRow(id) {
      const items = this.reorder(this.props.data);
      const item = items.filter(c => c.id === id)[0]
      const index = items.indexOf(item);
      const r =  {
        item:item,
        index: index,
      };
      console.log("findRow",id,r)
      return r;
  }

  moveRow(id, atIndex) {
    console.log("moveRow",id,atIndex);
    const { index } = this.findRow(id)
    let order = []
    if (this.state.order.length !== this.props.data.length) {
       order = this.props.data.map(i=>{return i.id});
    } else {
       order = this.state.order.slice();
    }

    order.splice(index,1);
    order.splice(atIndex,0,id);
    this.setState({order:order});
  }

  saveOrder() {
    this.props.saveOrdering(this.state.order);
  }
  
  renderRows(items) {
    const {auth} = this.props;
    return  this.reorder(items).map((i,idx)=>{
                return  (<ZonePricesTableRow save={()=>this.saveOrder()} moveRow={(k,at)=>this.moveRow(k,at)} findRow={(k)=>this.findRow(k)}activeDrag={this.state.activeDrag} id={i.id} key={i.id} data={i} onDrag={(active)=>this.onDrag(active)} 
                onEdit={this.props.onEdit} onShow={this.props.onShow} onDelete={this.props.onDelete}
                auth={auth} />)
          });
  }

  render() {
    const { classes } = this.props;

    const rows = this.renderRows(this.props.data);
    return (
      <Paper className={classes.root}>
        <Table>
            <TableHead>
                <TableRow className={classes.row}>
                    <TableCell className={classes.cell}>řazení</TableCell>
                    <TableCell className={classes.cell}>název</TableCell>
                    <TableCell className={classes.cell}>cenový plán</TableCell>
                    <TableCell className={classes.cell}>zóna</TableCell>
                    <TableCell className={classes.cell}>cena</TableCell>
                    <TableCell className={classes.cell}>zákovská sleva</TableCell>
                    <TableCell className={classes.cell}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
            {rows}
            </TableBody>
          </Table>
      </Paper>
    );
  }
}

ZonePricesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  onDelete: PropTypes.func,
  auth: PropTypes.object.isRequired,
};

ZonePricesTable.defaultProps = {
 
};



export default withStyles(styles)(ZonePricesTable)
