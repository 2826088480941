import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import PlaceIDViewField from './Fields/PlaceIDViewField';
import StringViewField from './StringViewField';
import BooleanViewField from './BooleanViewField';
const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class ZoneViewDialog extends React.Component {

  render() {
    const {zone,open,onClose,caption} = this.props;
    return (
      <Dialog maxWidth={false} open={open} onClose={onClose}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {zone && (
            <React.Fragment>
              <StringViewField label="Název" value={zone.name} />
              <StringViewField label="Dlouhý název" value={zone.long_name} />
              <StringViewField label="klíč zóny v mapce" value={zone.map_zone_key} />
              <BooleanViewField label="zóna stání" value={zone.free_zone} />
              <PlaceIDViewField value={zone.place_id} />
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Zavřít
        </Button>
      </DialogActions>
    </Dialog>
    );
  }
}

ZoneViewDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  zone:PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  caption: PropTypes.string
};
ZoneViewDialog.defaultProps = {
  caption:"Náhled zóny"
};

export default withStyles(styles)(ZoneViewDialog);