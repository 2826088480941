import React from 'react';
import PropTypes from 'prop-types';
import {SeatContext} from './Utils';


class Chairs extends React.Component {
    constructor(props){
      super(props);
      this.seats = [];
      this.recs = [];
    }
  
    reg(k) {
        //console.log("reg",k);
        let rec = null;
        switch(k.constructor.name) {
          case 'CircleTable': rec={type:"CIRCLE",name:k.props.label,capacity:k.props.chairs,map_position_x:Math.round(k.props.x),map_position_y:Math.round(k.props.y),map_position_angle:Math.round(180*(k.props.chairBaseAngle/Math.PI))}; break;


          case 'RectTable': 
            const cl=k.props.chairs[0];
            const ct=k.props.chairs[1];
            const cr=k.props.chairs[2];
            const cb=k.props.chairs[3];

            rec={type:"BOX",name:k.props.label,capacity:cl+ct+cr+cb,
            map_position_x:Math.round(k.props.x),
            map_position_y:Math.round(k.props.y),
            box_capacity_left: cl,
            box_capacity_right: cr,
            box_capacity_top: ct,
            box_capacity_bottom: cb
            }; break;
          case 'GridTable':
            const c_w=k.props.chairs_w;
            const c_h=k.props.chairs_h;
            if (c_w == 1) {
              rec={type:"LINE_V",name:k.props.prefix_id,capacity:c_h,
              map_position_x:Math.round(k.props.x),
              map_position_y:Math.round(k.props.y),
              }; break;
            } else if (c_h == 1) {
              rec={type:"LINE_H",name:k.props.prefix_id,capacity:c_w,
              map_position_x:Math.round(k.props.x),
              map_position_y:Math.round(k.props.y),
              }; break;
            }

            
          default: rec={type:"?????"+k.constructor.name};

        }
        if (rec) {
          this.recs.push(rec);
        }
    }
    unreg(k) {
        //console.log("unreg",k);  
    }
    render() {
      return (
        <React.Fragment>
          <SeatContext.Provider value={{reg:(k)=>this.reg(k),unreg:(k)=>this.unreg(k)}}>
          {this.props.children}
          </SeatContext.Provider>
        </React.Fragment>
      )
    }
  }
  Chairs.propTypes = {
    children: PropTypes.any
  }
  

  export default Chairs;