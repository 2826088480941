import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TicketIcon from '@material-ui/icons/Receipt';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Lodash from "lodash";
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'
import ShopIcon from '@material-ui/icons/ShoppingCart';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Divider from '@material-ui/core/Divider'
import Moment from 'moment';
import DateView from './DateView';
import Tooltip from '@material-ui/core/Tooltip';

import {getTicketPlaceText,getTicketPrimaryText} from "../libs/Tickets";

const styles = theme => ({
  toolbar: {
    //border: "1px solid green",
  },
  root: {
    flexGrow: 1,
    maxWidth: 500,
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    height: 300,
  },
  grow: {
    flexGrow: 1,
  },
  button: {

  },
  res_info: {
    marginLeft: theme.spacing.unit*3,
    //border: "1px solid green",
  },
  sale_info: {
    marginLeft: theme.spacing.unit*2,
    marginRight: theme.spacing.unit*2,
    paddingLeft: theme.spacing.unit*1,
    paddingRight: theme.spacing.unit*1,
    border: "2px solid red",
  },

  shopicon: {
    marginRight: 20
  },
  helpicon: {
    marginLeft: theme.spacing.unit,
  },
  leftm: {
    marginLeft: theme.spacing.unit,
  }
});






class SaleEventShoppingCartList extends React.Component {


  render() {
    const { classes,list,dense, as_order,has_order,event_seats,event_zones,info_booking_ref ,clerk_mode} = this.props;
    const total_cost = Lodash.reduce(list,(s,t)=>{return s+t.cost},0);
    const last_ticket_m = Lodash.reduce(list,(m,t)=>{
      const um = Moment(t.updated_at).add(1,"h");
      if (m==null) {
        return um;
      } else {
        return um.isAfter(m)?um:m; 
      }
    },null);
    const empty = list.length==0;

    // nejaky se slevou?
   // const sale_any = Lodash.find(list,{sale_student:true});
   // console.log("SALE_ANY",sale_any,list);
    const sale = !!( Lodash.find(list,{sale_student:true}));
    return (
      <div className={classes.root}>
        <Toolbar variant={"dense"}  className={classes.toolbar}>
          <ShopIcon  className={classes.shopicon} />
          <Typography variant="title" color="inherit"> {as_order?"Objednávané vstupenky":"Nákupní košík"} </Typography>
          { info_booking_ref && (<Typography className={classes.leftm} variant="title" color="inherit">{"- "+info_booking_ref}</Typography>)}
        </Toolbar>
        <Divider/>
        <div className={classes.list}>
        <List dense={dense}>
          {list.map((t,idx)=>{
            return (
              <ListItem key={idx}>
                <ListItemAvatar>
                  <Avatar>
                    <TicketIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={getTicketPrimaryText(t,event_zones)}
                  secondary={getTicketPlaceText(t,event_seats)}
                />
                {(!as_order && !has_order)  &&(
                  <ListItemSecondaryAction>
                    <IconButton aria-label="Delete" onClick={()=>this.props.onDelete(t)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            )
          })}
        </List>
        </div>
        <Divider/>

        {sale && (
          <React.Fragment>
            <Typography variant={"body2"} className={classes.sale_info}> 
              Košík obsahuje slevněnou vstupenku pro žáky TŠ Starlet (při vstupu je třeba předložit platnou legitimaci kurzu a doklad totožnosti).
            </Typography>
            <Divider/>
          </React.Fragment>
        )}

        {empty?(
          <Typography variant={"body2"} className={classes.res_info}> &nbsp; </Typography>
        ):(
          <Typography variant={"body2"} className={classes.res_info}> 
              vstupenky rezervovány do <DateView date={last_ticket_m}/> 
              <Tooltip title={"pokud nezakoupíte vstupenky do uvedeného času, budou uvolněny dalším zájemcům"}>
                <HelpIcon className={classes.helpicon} fontSize={"inherit"}/>
              </Tooltip>
              
          </Typography>
        )}
        <Divider/>
        <Toolbar variant={"dense"}  className={classes.toolbar}> 
          {empty?(
            <Typography className={classes.grow} variant="title" color="inherit"> košík je prázdný </Typography>
          ):(
            <Typography className={classes.grow} variant="title" color="inherit"> Celková cena: {total_cost}kč </Typography>
          )}

          {(as_order && !has_order) && (
            <Button variant="contained" disabled={empty} onClick={this.props.onModify}> upravit </Button>
          )}
          {!as_order && !has_order &&(
            <Button variant="contained" disabled={empty} onClick={this.props.onBuy}> {clerk_mode?"prodat":"koupit"}</Button>
          )}
          {!as_order && !has_order && clerk_mode &&(
            <Button variant="contained" disabled={empty} onClick={this.props.onReserve}> {"rezervovat"}</Button>
          )}
        </Toolbar>
      </div>
    );
  }
}

SaleEventShoppingCartList.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  event_seats: PropTypes.array.isRequired,
  event_zones: PropTypes.array.isRequired,
  dense: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onBuy: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onReserve: PropTypes.func,
  as_order: PropTypes.bool,
  has_order: PropTypes.bool,
  info_booking_ref: PropTypes.string,
  clerk_mode: PropTypes.bool,
};
SaleEventShoppingCartList.defaultProps = {
  dense:false,
  as_order:false,
  has_order:false,
  clerk_mode:false,
};


export default withStyles(styles)(SaleEventShoppingCartList);