import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import Typography from '@material-ui/core/Typography';
import EventStrip from './EventStrip';
//import DateView from './DateView';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SaleEventShoppingCart from './SaleEventShoppingCart';
import SaleEventShoppingCartAdminView from './SaleEventShoppingCartAdminView';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import SaleEventChooseTicket from './SaleEventChooseTicket';
import SaleEventOrder from './SaleEventOrder';
import { withRouter } from 'react-router';
import DebugObjectView from './Utils/DebugObjectView';

const styles = theme => ({
  grid_root: {
      flexGrow: 1,
      marginTop: theme.spacing.unit,
  },
  paper_root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

const CREATE_EVENT_TICKET_BOOKING = gql`
mutation eventTicketCreateBooking($event_seat_id:ID,$event_zone_id:ID!,$event_seat_idx:Int,$event_zone_price_id:ID!) {
  eventTicketCreateBooking(event_zone_id:$event_zone_id,event_seat_id:$event_seat_id,event_seat_idx:$event_seat_idx,event_zone_price_id:$event_zone_price_id) {
    ok
    error_code
    error_msg
  }
}
`;

const DELETE_EVENT_TICKET_BOOKING = gql`
mutation eventTicketDeleteBooking($event_ticket_key:String!) {
  eventTicketDeleteBooking(event_ticket_key:$event_ticket_key) {
    ok
    error_code
    error_msg
  }
}
`;

const CREATE_EVENT_TICKET_ORDER = gql`
mutation eventTicketCreateOrder($event_id: ID!, $customer_name: ValidatedStringShort,$customer_surname: ValidatedStringShort,$customer_email: ValidatedStringShort,$customer_phone: ValidatedStringShort, $comment:String, $only_reservation:Boolean) {
  eventTicketCreateOrder(event_id:$event_id,customer_name:$customer_name,customer_surname:$customer_surname,customer_email:$customer_email,customer_phone:$customer_phone,comment:$comment,only_reservation:$only_reservation) {
    ok
    error_code
    error_msg
    order_public_key
  }
}
`;

const CONFIRM_ORDER= gql`
mutation eventTicketConfirmOrder($event_id: ID!,$public_order_key:String!) {
  eventTicketConfirmOrder(event_id:$event_id,public_order_key:$public_order_key) {
    ok
    error_code
    error_msg
  }
}
`;




class SaleEventTabSale extends React.Component {

  constructor(props) {
      super(props);
      this.cart = React.createRef();
  } 

  reload() {
    this.props.refetch();
  }

 

  handleCreateTicket(ticket,cb) {
    console.log("handleCreateTicket",ticket)
    this.props.createEventTicketBooking({
      variables: {
        event_seat_id:ticket.seat?ticket.seat.id:null,
        event_seat_idx:ticket.seat?ticket.seat_idx:null,
        event_zone_id:ticket.zone.id,
        event_zone_price_id:ticket.price.id,
      }
    }).then(res=>{
      if (!res.data.eventTicketCreateBooking.ok) {
        alert(res.data.eventTicketCreateBooking.error_msg);
      }
      cb(res.data.eventTicketCreateBooking);
      this.reload();
    }).catch(err=>{
      alert(err);
      cb({ok:false,error_msg:"interní chyba"});
      this.reload();
    })
  }
  
 
  

  deleteBooking(ticket) {
      this.props.deleteEventTicketBooking({
        variables: {
          event_ticket_key:ticket.ticket_key,
        }
      }).then(res=>{
        if (!res.data.eventTicketDeleteBooking.ok) {
          alert(res.data.eventTicketDeleteBooking.error_msg);
        }
        this.reload();
      }).catch(err=>{
        alert(err);
        this.reload();
      })
  }


  handlePrepReservation(){
    this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale/new_reservation');
  }
  handlePrepOrder() {
    this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale/new_order');
  }
  handleModify() {
    this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale');
  }
 
  handleBuy(cb) {
    this.props.confirmEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        public_order_key:this.props.order.public_key
      }
    }).then(res=>{
      if (!res.data.eventTicketConfirmOrder.ok) {
        alert(res.data.eventTicketConfirmOrder.error_msg);
        this.reload();
      } else {
        this.reload();
        //this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale');
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    })

  }
  handleReserve() {
    this.props.confirmEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        public_order_key:this.props.order.public_key
      }
    }).then(res=>{
      if (!res.data.eventTicketConfirmOrder.ok) {
        alert(res.data.eventTicketConfirmOrder.error_msg);
        this.reload();
      } else {
        this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale');
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    })

  }


  handleCreateOrder(doc) {
    console.log("handleCreateOrder",doc);
    this.props.createEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        only_reservation:false,
        ...doc
      }
    }).then(res=>{
      if (!res.data.eventTicketCreateOrder.ok) {
        alert(res.data.eventTicketCreateOrder.error_msg);
        this.reload();
      } else {
        this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale/order/'+res.data.eventTicketCreateOrder.order_public_key);
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }
  handleCreateReservation(doc) {
    console.log("handleCreateReservation",doc);
    this.props.createEventTicketOrder({
      variables:{
        event_id:this.props.event.id,
        only_reservation:true,
        ...doc
      }
    }).then(res=>{
      if (!res.data.eventTicketCreateOrder.ok) {
        alert(res.data.eventTicketCreateOrder.error_msg);
        this.reload();
      } else {
        this.props.history.push('/spa/sale_event/'+this.props.event.id+'/sale/order/'+res.data.eventTicketCreateOrder.order_public_key);
      }
    }).catch(err=>{
      alert(err);
      this.reload();
    })
  }
 

  render() {
    const { classes,event,cartTickets, order,action,action_param} = this.props;
    const new_order = action == "new_order";
    const new_reservation = action == "new_reservation";
    const has_order = (action == "order" && order!=null);
    const use_order_tickets = (has_order && order.status != 'INCOMPLETE');
    return (
      <React.Fragment>
            <Paper className={classes.paper}>
              <EventStrip show_debug_info={true} variant="sale" event={event}/>
            </Paper>
            <div className={classes.grid_root}>
            <DebugObjectView object={{event,cartTickets,order,has_order,new_order,new_reservation}} name="data"/>
         
          
           
            
            <Grid container spacing={24}>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.paper_root}>
                {!(new_order || new_reservation || has_order)?(
                  <SaleEventChooseTicket event={event} onCreateNewTicket={(t,cb)=>this.handleCreateTicket(t,cb)}/>
                ):(
                  <SaleEventOrder 
                    as_reservation={new_reservation} 
                    event={event} 
                    order={order} 
                    auth={this.props.auth} 
                    refetch={this.props.refetch} 
                    public_order_id={action_param} 
                    onBackOrder={()=>this.handlePrepOrder()}
                    onBackReservation={()=>this.handlePrepReservation()}
                    onCreateOrder={(c)=>this.handleCreateOrder(c)} 
                    onCreateReservation={(c)=>this.handleCreateReservation(c)} 
                    onConfirmSales={(cb)=>this.handleBuy(cb)}
                    onConfirmReservation={(cb)=>this.handleReserve(cb)} 
                    />
                )}
                </Paper>
              
              </Grid>
              <Grid item xs={12} sm={4}>
                 {use_order_tickets?(
                    <SaleEventShoppingCartAdminView  innerRef={this.cart} event={event} auth={this.props.auth}  tickets={order.tickets}/>
                 ):(
                    <SaleEventShoppingCart has_order={has_order} as_new={new_order || new_reservation} onReserve={()=>this.handlePrepReservation()} onModify={()=>this.handleModify()} onBuy={()=>this.handlePrepOrder()} onDelete={(t)=>this.deleteBooking(t)} innerRef={this.cart} event={event} auth={this.props.auth} booking_ref={this.props.auth.user_name} my_tickets={cartTickets}/>
                 )} 
              </Grid>
            </Grid>
               
            </div>
      </React.Fragment>
    );
  }
}

SaleEventTabSale.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  cartTickets: PropTypes.array.isRequired,
  order: PropTypes.object,
  createEventTicketBooking: PropTypes.func.isRequired,
  deleteEventTicketBooking: PropTypes.func.isRequired,
  createEventTicketOrder: PropTypes.func.isRequired,
  confirmEventTicketOrder: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  action: PropTypes.string,
  action_param: PropTypes.string,
  history: PropTypes.object.isRequired,
};
SaleEventTabSale.defaultProps = {
  
};



export default withRouter(compose(
  withStyles(styles),
  graphql(CREATE_EVENT_TICKET_BOOKING,{
    name: "createEventTicketBooking",
  }),
  graphql(DELETE_EVENT_TICKET_BOOKING,{
    name: "deleteEventTicketBooking",
  }),
  graphql(CREATE_EVENT_TICKET_ORDER,{
    name: "createEventTicketOrder",
  }),
  graphql(CONFIRM_ORDER,{
    name: "confirmEventTicketOrder",
  }), 
)(SaleEventTabSale))
