import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DragSource, DropTarget } from 'react-dnd';
import classNames from 'classnames';
import ActionButton from './ActionButton';
import DragHandle from './DragHandle';
import BooleanView from './BooleanView';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
   // maxWidth: 1000,
   // background: theme.palette.background.paper,
  },
  dragging: {
    opacity:0,
    //color: "rgba(0, 0, 0, 0)",
    //backgroundColor:"red"
  },
  cell: {
    height:30,
    paddingTop:1,
    paddingBottom:1,
    paddingLeft:2,
    paddingRight:2,
  },
  row: {
    height:24,
  }
});


class FoldersTableRow extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {  
    
    }
  }
 


 
  
  

  render() {
    const { auth,activeDrag, isDragging, connectDragSource, connectDragPreview,connectDropTarget, folder, classes } = this.props;
    const className = classNames([
      { [classes.dragging]: isDragging },
      { [classes.hover]: !activeDrag},
      classes.row
    ]);
    return (
      
      <TableRow  hover={!activeDrag} className={className}>
          <TableCell className={classes.cell}> {connectDropTarget(connectDragSource(
            <div><DragHandle/></div>
            ))} </TableCell>
          <TableCell className={classes.cell}> {connectDragPreview(<div>{folder.name}</div>)} </TableCell>
          <TableCell className={classes.cell}> {folder.enroll_group} </TableCell>
          <TableCell className={classes.cell}> {folder.evi_group} </TableCell>
          <TableCell className={classes.cell}> <BooleanView value={folder.access_check}/> </TableCell>
          <TableCell className={classes.cell}> <BooleanView value={folder.no_stat}/> </TableCell>
          <TableCell className={classes.cell}>  
              {auth.is_admin && (<ActionButton tooltip={"editovat"} icon={'edit'} onClick={()=>this.props.onEditFolder(folder.key)}/>)}
          </TableCell>
      </TableRow>
      );
  }
}

FoldersTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  folder: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
//  onSwap: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  activeDrag: PropTypes.bool.isRequired,
  findRow: PropTypes.func.isRequired,
  moveRow: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  onEditFolder: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

FoldersTableRow.defaultProps = {
 
};


const rowSource = {

  canDrag(props) {
    return props.auth.is_admin;
  },

  beginDrag(props) {
    props.onDrag(true);
    const r = {
      folder: props.folder,
      id: props.id,
      originalIndex: props.findRow(props.id).index,
    };
  
    return r;
  },
  endDrag(props, monitor, component) {
    props.onDrag(false);

    const { id: droppedId, originalIndex } = monitor.getItem()
		const didDrop = monitor.didDrop()

		if (!didDrop) {
			props.moveRow(droppedId, originalIndex)
    } else {
      props.save();
    }
    
    return;
  }
};

const rowTarget = {
  //canDrop() {
//		return false
//  },
  
  hover(props, monitor, component) {
//    console.log("HHHOOOOOVER")
   // const over = props.folder;
   // const me = monitor.getItem().folder;
  //  if (over.key === me.key) {
  //      return;
   // }


    const draggedId  = monitor.getItem().id;
		const overId  = props.id;

		if (draggedId !== overId) {
			const overIndex  = props.findRow(overId).index;
			props.moveRow(draggedId, overIndex);
		}
    //console.log(component)

   // const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
//		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
//		const clientOffset = monitor.getClientOffset()
//		const hoverClientY = clientOffset.y - hoverBoundingRect.top
		//if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)  || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) ){
	//		return;
//		}
   // console.log("swap",me,over) 
  //  props.onSwap(me.key,over.key);
    return;
  }
}

function dnd_src(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}
function dnd_trg(connect) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}

export default compose(
  withStyles(styles),
  DropTarget('row', rowTarget, dnd_trg),
  DragSource('row', rowSource, dnd_src)
  
)(FoldersTableRow)
