import React from 'react';
import PropTypes from 'prop-types';
//import { withStyles } from 'material-ui/styles';
import gql from "graphql-tag";
import { Query, withApollo } from "react-apollo";
import StudentPayForm from './StudentPayForm';
//import { compose, graphql} from "react-apollo";
/*
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  textfield: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
});

*/


const GET_STUDENT = gql`
  query Student($student_key:String!){
      student(key: $student_key) {
        key
        course_key
        status
        addressing
        sex
        ref_gid
        card_id
        reg_datetime
        ref_key
        partner_ref_key
        name
        surname
        email
        email2
        phone
        year
        school
        school_class
        street
        street_no
        city
        post_code
        comment
        course_cost
        paid
        paid_ok
        long_period
        student_check
        student
        card_out
        var_sym
        pay_info
        discount
      }
  }
`;


//addressing, surname, name, email, phone, year, student, student_check, card_out, comment, course_cost
//long_period, paid, pay_info, discount, paid_ok, school, school_class, street, city, post_code

const UPDATE_STUDENT = gql`
mutation updateStudent($key:String!,$status:StudentStatus, $addressing:String,$surname:String,$name:String, $email:String,  $email2:String, $phone:String, $year:Int, $student:Boolean, $student_check:Boolean, $card_out:Boolean, $comment:String, $course_cost:Int, $long_period:Boolean, $paid:Int, $pay_info:String, $discount:String, $paid_ok:Boolean, $school:String, $school_class:String, $street:String, $city:String, $post_code:String) {
  studentUpdate(key:$key,status:$status,addressing:$addressing,surname:$surname,name:$name, email:$email,  email2:$email2, phone:$phone, year:$year, student:$student, student_check:$student_check, card_out:$card_out, comment:$comment, course_cost:$course_cost,long_period:$long_period, paid:$paid, pay_info:$pay_info, discount:$discount, paid_ok:$paid_ok, school:$school, school_class:$school_class, street:$street, city:$city, post_code:$post_code) {
    key
    course_key
    status
    addressing
    sex
    ref_gid
    card_id
    reg_datetime
    ref_key
    partner_ref_key
    name
    surname
    email
    email2
    phone
    year
    school
    school_class
    street
    street_no
    city
    post_code
    comment
    course_cost
    paid
    paid_ok
    long_period
    student_check
    student
    card_out
    var_sym
    pay_info
    discount
  }
}
`;



const EMAIL_SEND_NOTIFICATION = gql`
mutation emailSend($type: EmailNotificationType!, $student_key:String!){
  emailSend(type:$type,student_key:$student_key){
    ok
  }
}
`;


class StudentPayEdit extends React.Component {


  constructor(props) {
    super(props);
    
    this.state = {  
      dirty:false,
      valid:true,
      wait:false
    }
    this.form = React.createRef();

  }

  doSave() {
    console.log("StudentPayEdit doSave");
    const {client,onClose,student_key} = this.props;
    if (this.state.wait) {
      alert("nelze ulozit, jine ukladani prave probiha");
      return;
    }

    this.setState({wait:true});
    let d = Object.assign({},this.form.current.state.doc);
    if (this.form.current.state.do_enroll) {
      d.status="IN";
    }
    const send_email = this.form.current.state.send_email;

    client.mutate({
      mutation:UPDATE_STUDENT,
      variables:d,
      refetchQueries:["CourseWithStats","Students","Student"]
    }).then(res=>{
      if (send_email) {
        client.mutate({
          mutation: EMAIL_SEND_NOTIFICATION,
          variables:{
            type: "PAYMENT_INFO",
            student_key:student_key,
          }
        }).then(en=>{
          this.setState({wait:false});
          onClose(true);
        }).catch((err)=>{
          this.setState({wait:false});
          alert("chyba odesilani emailu");
        })

      } else {
        this.setState({wait:false});
        onClose(true);
      }

    }).catch((err)=>{
      this.setState({wait:false});
      alert("chyba ukladani");
    })
    
  }
 
  onDocChange(d,valid) {
    console.log("StudentPayEdit onDocChange");
    this.setState({dirty:true,valid:valid});
    this.props.onChange(true,valid);
  } 
 
 
  render() {
    const {student_key,mode} = this.props;
    return (
     
      <Query query={GET_STUDENT} variables={{ student_key }} fetchPolicy={"network-only"}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          return (
            <StudentPayForm innerRef={this.form} wait={this.state.wait} doc={data.student} mode={mode} onDocChange={(d,v)=>this.onDocChange(d,v)}/>    
          )
        }}
      </Query>
    
    );
  }
}

StudentPayEdit.propTypes = {
  client: PropTypes.object.isRequired,
  student_key: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["IN","FILED","SPARE","KICKED"]).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
StudentPayEdit.defaultProps = {
  
};


/*
export default compose(
  withStyles(styles),
  graphql(EMAIL_SEND_NOTIFICATION,{
    name:"sendNotification"
  }),
)(StudentPayEdit);
*/
export default withApollo(StudentPayEdit,{withRef:true});
