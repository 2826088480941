import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

var moment = require('moment');
moment.locale('cs');

const cs_months=["ledna","února","března","dubna","května","června","července","srpna","září","října","listopadu","prosince"];
const cs_days=["neděle","pondělí","úterý","středa","čtvrtek","pátek","sobota"];

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
});

const propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment)
  ]),
  only_date: PropTypes.bool,
  long: PropTypes.bool
};

const defaultProps = {
  only_date: false,
  long:false
};

class DateView extends React.Component {
 
  render() {
    let str = this.props.date?moment(this.props.date).format(this.props.only_date?'L':'L LT'):"";
 
    if (this.props.date && this.props.long) {
      const m = moment(this.props.date);
    
      str = cs_days[m.day()]+" "+m.date()+". "+cs_months[m.month()]+" "+m.format("YYYY")+" v "+m.format("HH:mm")
      //str = moment(this.props.date).format("LLLL") +" ## "+loc_str;
    }
    return (
      <span>
        {str}
      </span>
    );
  }
}

DateView.propTypes = propTypes;
DateView.defaultProps = defaultProps;

export default withStyles(styles)(DateView);