import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import { compose, graphql} from "react-apollo";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import Lodash from 'lodash';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CourseSelectField from './CourseSelectField';
import ActionButton from './ActionButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = theme => ({
  root: {
    //marginBottom: theme.spacing.unit*2,
  },
  toolBar_root: {
    minHeight:48
  },
  toolBar_item: {
    marginLeft: theme.spacing.unit*3,
    marginRight: theme.spacing.unit*3,
  },
  cont: {
    padding: theme.spacing.unit*3,
  },
  cell: {
    padding:0
  },
  cellH: {
    padding:0,
    fontWeight: "bold"
  }
});


const GET_CGROUP = gql`
  query CourseGroup($cgroup_key:String!){   
    cgroup(key:$cgroup_key) {
      key
      name
      type
      course_keys
      courses {
        key code name folder {name} season {name}
      }
    }
  }
`;


const UPDATE_CGROUP = gql`
mutation updateCGroup($key:String!,$name:String,$type:CGroupType,$course_keys:[String]) {
  cgroupUpdate(key:$key,name:$name,type:$type,course_keys:$course_keys) {
    key
    name 
    type
    course_keys
    courses {
      key code name folder {name} season {name}
    }
  }
}
`



class CGroupBoard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      target_season_key:null,
      target_folder_key:null,
      target_course_key:null,
    }
  }




  handleChangeTargetCourse(s,f,c) {
    this.setState({
      target_season_key:s,
      target_folder_key:f,
      target_course_key:c
    })
  }

  handleActionAddCourse(refetch,cgroup) {
    const course_key = this.state.target_course_key;
    const up = Lodash.uniq(Lodash.concat(cgroup.course_keys,[course_key]));
    this.props.updateCGroup({variables:{key:cgroup.key,course_keys:up}}).then(res=>{
      console.log(res);
      refetch();
    })
  }
  
  handleActionDelCourse(refetch,cgroup,course_key) {
    const up = Lodash.filter(cgroup.course_keys,(k)=>{return k!==course_key})
    this.props.updateCGroup({variables:{key:cgroup.key,course_keys:up}}).then(res=>{
      console.log(res);
      refetch();
    })
  }
  
  render() {
    const { classes , cgroup_key} = this.props;
 
    return (
      <div>
         <Query query={GET_CGROUP} variables={{ cgroup_key }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return (<CircularProgress/>);
          if (error) return `Error!: ${error}`;
          if (data.cgroup===null) return `Error!: 404`;
          
          return (
            <React.Fragment>
              <Toolbar disableGutters classes={{ root: classes.toolBar_root }}>
                <Typography className={classes.toolBar_item} variant="title" color="inherit">
                  Skupina kurzů - {data.cgroup.name}
                </Typography>
              </Toolbar>

              <div className={classes.cont}>
                
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cell}>#</TableCell>
                      <TableCell className={classes.cell}>kód</TableCell>
                      <TableCell className={classes.cell}>název</TableCell>
                      <TableCell className={classes.cell}>složka</TableCell>
                      <TableCell className={classes.cell}>sezóna</TableCell>
                      <TableCell className={classes.cell}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.cgroup.courses.length===0 && (<TableRow><TableCell className={classes.cell} colSpan={1000}>žádný kurz</TableCell></TableRow>)}
                     {data.cgroup.courses.map((c,idx)=>{
                       return (
                         <TableRow key={c.key}>
                          <TableCell className={classes.cell}>{idx+1}</TableCell>
                          <TableCell className={classes.cell}>{c.code}</TableCell>
                          <TableCell className={classes.cell}>{c.name}</TableCell>
                          <TableCell className={classes.cell}>{c.folder.name}</TableCell>
                          <TableCell className={classes.cell}>{c.season.name}</TableCell>
                          <TableCell className={classes.cell}>
                             <ActionButton tooltip={"odstranit se seznamu"} icon={'delete'} onClick={()=>this.handleActionDelCourse(refetch,data.cgroup,c.key)}/>
                          </TableCell>
                         </TableRow>
                       )
                     })}
                  </TableBody>  
                </Table>
              </div>
                <Typography className={classes.toolBar_item} variant="title" color="inherit">
                  Přidání dalšího kurzu do seznamu
                </Typography>
              <div className={classes.cont}> 
                <CourseSelectField 
                    //disabled = {this.state.actionDlgWait}
                    season_key={this.state.target_season_key}
                    folder_key={this.state.target_folder_key}
                    course_key={this.state.target_course_key}
                    onChange={(s,f,c)=>this.handleChangeTargetCourse(s,f,c)}
                />
                <ActionButton disabled={this.state.target_course_key===null} tooltip={"Přidat zvolený kurz do seznamu"} label={"Přidat"} onClick={()=>this.handleActionAddCourse(refetch,data.cgroup)}/>
              </div>
            </React.Fragment>
          )
        }}
        </Query>
        </div>
    );
  }
}

CGroupBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  cgroup_key:PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  updateCGroup: PropTypes.func.isRequired
};
CGroupBoard.defaultProps = {
  
};




export default compose(
  withStyles(styles),
  graphql(UPDATE_CGROUP,{
    name:"updateCGroup",
  }),
)(CGroupBoard);
