import React from 'react';
import PropTypes from 'prop-types';
import {  Group,Circle,Text,Rect } from 'react-konva';
import {planStyles,planColorEnum,getSeatStrokeColor,getSeatFillColor,getSeatTextColor} from './Utils';
import Lodash from 'lodash';
import {SeatType_props_enum } from './../../../graphql/enums/seattype';

/*
const PropTypesMarker = PropTypes.shape({
    seat_id: PropTypes.string.isRequired,
    color:PropTypes.string,
    idxs:PropTypes.object,
    seat_colors: PropTypes.array, //of idx:color
});
*/

//'CHAIR','LINE_H','LINE_V','BOX','CIRCLE','FREE'

class SeatWrongType extends React.Component {
  onMouseMove(e) {
    console.log("GROUP mm",e);
  }
  render() {
    const {map_position_x,map_position_y,id,zone_id} = this.props;
    return (
      <Group x={map_position_x} y={map_position_y}>
      <Circle
        
        x={0}
        y={0}
        radius={10}
        listening={false}
        //stroke={planStyles.tableBorderColor}
        fill={"red"}
      />

      <Circle
        id={JSON.stringify({type:"SeatWrongType",seat_id:id,zone_id:zone_id})}
        listening={true}
        opacity={0}
        x={0}
        y={0}
        radius={20}
        //stroke={planStyles.tableBorderColor}
        //fill={planStyles.tableColor}
      />
      </Group>
    )
  }
}
SeatWrongType.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
}

class SeatCircle extends React.PureComponent {
 
  render() {
    const {id,zone_id,map_position_x,map_position_y,map_position_angle,map_sector_angle,capacity,name,color,hover,hover_idx,markers_dict,disableTableSelection} = this.props;
    
    const chairBaseAngle = (map_position_angle /180)*Math.PI;
    //TODO map_sector_angle
    let sector_angle = Math.PI*2;
    if ((map_sector_angle) && (map_sector_angle>0) && (map_sector_angle<=360)) {
      sector_angle = (Math.PI*2)*map_sector_angle/360;
    }
    const chairStep = (sector_angle)/capacity;
    const chairsPos = Lodash.map(Lodash.range(0,sector_angle,chairStep),(a,idx)=>{
      return {id:idx+1,x:Math.cos(a+chairBaseAngle) * (planStyles.chairDistance+planStyles.tableRadius+planStyles.chairRadius),y:Math.sin(a+chairBaseAngle) * (planStyles.chairDistance+planStyles.tableRadius+planStyles.chairRadius) };
    });


    return (
      <Group x={map_position_x} y={map_position_y}>
        <Circle
            listening={false}
            x={0}
            y={0}
            radius={planStyles.tableRadius}
            stroke={getSeatStrokeColor(color,hover)}
            fill={getSeatFillColor(color,hover)}
        />

        <Text listening={false}  fill={getSeatTextColor(color,hover)}
          x={-planStyles.tableRadius} y={-planStyles.labelSize/2} width={planStyles.tableRadius*2} 
          fontSize={planStyles.labelSize} align={'center'} text={name} />

        
        <Circle
            id={JSON.stringify({type:"SeatCircle",seat_id:id,zone_id:zone_id})}
            listening={!disableTableSelection}
            x={0}
            y={0}
            radius={planStyles.tableRadius+planStyles.chairDistance+planStyles.chairRadius*2}
            //stroke={planStyles.tableBorderColor}
            opacity={0}
            //fill={"red"}
        />


        {chairsPos.map((p,idx)=>{
            const hover = idx==hover_idx;
            let marker = null;
            let marker_color = null;
            if (markers_dict && (marker=markers_dict[idx])) {
              marker_color = marker.color;
            }
            return (
              <Circle key={idx}
                id={JSON.stringify({type:"SeatCircle",seat_id:id,zone_id:zone_id,seat_idx:idx})}
                listening={true}
                x={p.x}
                y={p.y}
                radius={planStyles.chairRadius}
                stroke={getSeatStrokeColor(color,hover)}
                fill={getSeatFillColor(color,hover,marker_color)}
              />
            )
          })}


      </Group>
    )
  }
}

SeatCircle.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  map_position_angle: PropTypes.number,
  map_sector_angle: PropTypes.number,
  capacity: PropTypes.number.isRequired,
  name: PropTypes.string,
  hover: PropTypes.bool.isRequired,
  hover_idx: PropTypes.number.isRequired,
  color: PropTypes.oneOf(planColorEnum).isRequired,
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
}

SeatCircle.defaultProps = {
  map_position_angle:0,
  map_sector_angle:0,
  disableTableSelection: false
};


class SeatBox extends React.PureComponent {
 
  render() {
    const {id,zone_id,map_position_x,map_position_y,box_capacity_left,box_capacity_right,box_capacity_top,box_capacity_bottom,name,color,hover,hover_idx,markers_dict,disableTableSelection} = this.props;



    const minSize = planStyles.tableRadius*2;

    const chairs_wc = Math.max(box_capacity_top,box_capacity_bottom);
    const chairs_hc = Math.max(box_capacity_left,box_capacity_right);
    
    const table_w = Math.max(chairs_wc*(planStyles.chairDistance+planStyles.chairRadius*2),minSize);
    const table_h = Math.max(chairs_hc*(planStyles.chairDistance+planStyles.chairRadius*2),minSize);

    const step_top = box_capacity_top?table_w/box_capacity_top:null;
    const step_bottom = box_capacity_bottom?table_w/box_capacity_bottom:null;
    const step_left = box_capacity_left?table_h/box_capacity_left:null;
    const step_right = box_capacity_right?table_h/box_capacity_right:null;
    

    const bound_d = planStyles.chairDistance+planStyles.chairRadius*2;
    const bound_w = table_w + (box_capacity_left?bound_d:0) + (box_capacity_right?bound_d:0);
    const bound_h = table_h + (box_capacity_top?bound_d:0) + (box_capacity_bottom?bound_d:0);
    const bound_x = (box_capacity_left?-bound_d:0);
    const bound_y = (box_capacity_top?-bound_d:0);

    const pos_top = box_capacity_top?Lodash.map(Lodash.range(0,table_w,step_top),(p)=>{
      return {x:p+step_top/2,y:0-(planStyles.chairDistance+planStyles.chairRadius)};
    }):[];

    const pos_bottom =box_capacity_bottom?Lodash.map(Lodash.range(0,table_w,step_bottom),(p)=>{
      return {x:p+step_bottom/2,y:table_h+(planStyles.chairDistance+planStyles.chairRadius)};
    }):[];

    const pos_left =box_capacity_left?Lodash.map(Lodash.range(0,table_h,step_left),(p)=>{
      return {y:p+step_left/2,x:0-(planStyles.chairDistance+planStyles.chairRadius)};
    }):[];

    const pos_right = box_capacity_right?Lodash.map(Lodash.range(0,table_h,step_right),(p)=>{
      return {y:p+step_right/2,x:table_w+(planStyles.chairDistance+planStyles.chairRadius)};
    }):[];

    const chairsPos = Lodash.map([...pos_top,...pos_right,...(Lodash.reverse(pos_bottom)),...(Lodash.reverse(pos_left))],(s,idx)=>{
        return {id:idx+1,x:s.x,y:s.y};
    });

   
    return (
      <Group x={map_position_x} y={map_position_y}>

        <Rect 
            listening={false}
            x={0} 
            y={0} 
            width={table_w}
            height={table_h}
            stroke={getSeatStrokeColor(color,hover)}
            fill={getSeatFillColor(color,hover)}
        />



  
        <Text listening={false} fill={getSeatTextColor(color,hover)}
          x={0} y={(-planStyles.labelSize/2)+(table_h/2)} width={table_w} 
          fontSize={planStyles.labelSize} align={'center'} text={name} />

        <Rect
            id={JSON.stringify({type:"SeatBox",seat_id:id,zone_id:zone_id})}
            listening={!disableTableSelection}
            x={bound_x}
            y={bound_y}
            width={bound_w}
            height={bound_h}
            opacity={0}
        />

        {chairsPos.map((p,idx)=>{
            const hover = idx==hover_idx;
            let marker = null;
            let marker_color = null;
            if (markers_dict && (marker=markers_dict[idx])) {
              marker_color = marker.color;
            }
            return (
              <Circle key={idx}
                id={JSON.stringify({type:"SeatBox",seat_id:id,zone_id:zone_id,seat_idx:idx})}
                listening={true}
                x={p.x}
                y={p.y}
                radius={planStyles.chairRadius}
                stroke={getSeatStrokeColor(color,hover)}
                fill={getSeatFillColor(color,hover,marker_color)}
              />
            )
          })}


      </Group>
    )
  }
}

SeatBox.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  capacity: PropTypes.number.isRequired,
  box_capacity_left:PropTypes.number,
  box_capacity_right:PropTypes.number,
  box_capacity_top:PropTypes.number,
  box_capacity_bottom:PropTypes.number,
  name: PropTypes.string,
  hover: PropTypes.bool.isRequired,
  hover_idx: PropTypes.number.isRequired,
  color: PropTypes.oneOf(planColorEnum).isRequired,
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
}

SeatBox.defaultProps = {
  box_capacity_left:0,
  box_capacity_right:0,
  box_capacity_top:0,
  box_capacity_bottom:0,
  disableTableSelection: false
};


class SeatLineH extends React.PureComponent {
 
  render() {
    const {id,zone_id,map_position_x,map_position_y,map_position_angle,capacity,hover_idx,color,markers_dict} = this.props;


  
    const chairBaseAngle = (map_position_angle /180)*Math.PI;

//    const chairsPos = Lodash.map(Lodash.range(0,sector_angle,chairStep),(a,idx)=>{
//      return {id:idx+1,x:Math.cos(a+chairBaseAngle) * (planStyles.chairDistance+planStyles.tableRadius+planStyles.chairRadius),y:Math.sin(a+chairBaseAngle) * (planStyles.chairDistance+planStyles.tableRadius+planStyles.chairRadius) };
//    });

    const chairsPos = Lodash.flatten(Lodash.map(Lodash.range(0,capacity),(i)=>{
      const d = i*(planStyles.chairDistance+planStyles.chairRadius*2);
      return {id:i, x:Math.cos(chairBaseAngle)*d,y:Math.sin(chairBaseAngle)*d}
    }));

    return (
      <Group x={map_position_x} y={map_position_y}>

        {chairsPos.map((p,idx)=>{
            const hover = idx==hover_idx;
            let marker = null;
            let marker_color = null;
            if (markers_dict && (marker=markers_dict[idx])) {
              marker_color = marker.color;
            }
            return (
              <Circle key={idx}
                id={JSON.stringify({type:"SeatLineH",seat_id:id,zone_id:zone_id,seat_idx:idx})}
                listening={true}
                x={p.x}
                y={p.y}
                radius={planStyles.chairRadius}
                stroke={getSeatStrokeColor(color,hover)}
                fill={getSeatFillColor(color,hover,marker_color)}
              />
            )
          })}
        {/*
        <Rect
            id={JSON.stringify({type:"SeatLineH",id:id})}
            listening={true}
            x={bound_x}
            y={bound_y}
            width={bound_w}
            height={bound_h}
            opacity={0}
            fill={"red"}
        />
        */}
      </Group>
    )
  }
}

SeatLineH.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  map_position_angle: PropTypes.number,
  capacity: PropTypes.number.isRequired,
  name: PropTypes.string,
  hover_idx: PropTypes.number.isRequired,
  color: PropTypes.oneOf(planColorEnum).isRequired,
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
}

SeatLineH.defaultProps = {
  disableTableSelection: false,
  map_position_angle:0
};



class SeatLineV extends React.PureComponent {
 
  render() {
    const {id,zone_id,map_position_x,map_position_y,capacity,map_position_angle,hover_idx,color,markers_dict} = this.props;


    const chairBaseAngle = (map_position_angle /180)*Math.PI;

    
    const chairsPos = Lodash.flatten(Lodash.map(Lodash.range(0,capacity),(i)=>{
          const d = i*(planStyles.chairDistance+planStyles.chairRadius*2);
          return {id:i, y:Math.cos(chairBaseAngle)*d,x:Math.sin(chairBaseAngle)*d}
        }));
    
  
  
    return (
      <Group x={map_position_x} y={map_position_y}>

        {chairsPos.map((p,idx)=>{
            const hover = idx==hover_idx;
            let marker = null;
            let marker_color = null;
            if (markers_dict && (marker=markers_dict[idx])) {
              marker_color = marker.color;
            }
            return (
              <Circle key={idx}
                id={JSON.stringify({type:"SeatLineV",seat_id:id,zone_id:zone_id,seat_idx:idx})}
                listening={true}
                x={p.x}
                y={p.y}
                radius={planStyles.chairRadius}
                stroke={getSeatStrokeColor(color,hover)}
                fill={getSeatFillColor(color,hover,marker_color)}
              />
            )
          })}
        {/*
        <Rect
            id={JSON.stringify({type:"SeatLineV",id:id})}
            listening={true}
            x={bound_x}
            y={bound_y}
            width={bound_w}
            height={bound_h}
            opacity={0}
            fill={"red"}
        />
        */}
        
      </Group>
    )
  }
}

SeatLineV.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  map_position_angle: PropTypes.number,
  capacity: PropTypes.number.isRequired,
  name: PropTypes.string,
  hover_idx: PropTypes.number,
  color: PropTypes.oneOf(planColorEnum).isRequired,
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
}

SeatLineV.defaultProps = {
 hover_idx:-1,
 disableTableSelection: false,
 map_position_angle:0
};




class SeatChair extends React.PureComponent {
 
  render() {
    const {id,zone_id,map_position_x,map_position_y,hover,color,markers_dict} = this.props;


    const chairPos = {id:0, y:0,x:0};
  


    let marker = null;
    let marker_color = null;
    if (markers_dict && (marker=markers_dict[0])) {
      marker_color = marker.color;
    }
    
   // const bound_h = planStyles.chairRadius*2;
   // const bound_w = planStyles.chairRadius*2;
   // const bound_x = -planStyles.chairRadius;
   // const bound_y = -planStyles.chairRadius; 

   
    return (
      <Group x={map_position_x} y={map_position_y}>

       
        <Circle 
          id={JSON.stringify({type:"SeatChair",seat_id:id,zone_id:zone_id,seat_idx:0})}
          listening={true}
          x={chairPos.x}
          y={chairPos.y}
          radius={planStyles.chairRadius}
          stroke={getSeatStrokeColor(color,hover)}
          fill={getSeatFillColor(color,hover,marker_color)}
        />

        {/*
        <Rect
            id={id}
            listening={true}
            x={bound_x}
            y={bound_y}
            width={bound_w}
            height={bound_h}
            opacity={0}
            fill={"red"}
        />
        */}
      </Group>
    )
  }
}

SeatChair.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  name: PropTypes.string,
  hover: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(planColorEnum).isRequired,
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
}

SeatChair.defaultProps = {
  disableTableSelection: false
};



class Seat extends React.Component {
    render() {
        const {type} = this.props;
        let seatcomp = SeatWrongType;
        switch(type) {
          case 'CHAIR': seatcomp = SeatChair; break;
          case 'LINE_H': seatcomp = SeatLineH; break;
          case 'LINE_V': seatcomp = SeatLineV; break;
          case 'BOX': seatcomp = SeatBox; break;
          case 'CIRCLE': seatcomp = SeatCircle; break;
         // case 'FREE': seatcomp = SeatFree; break;
          default: break;
        }


        return React.createElement(seatcomp,this.props);
    }
}

Seat.propTypes = {
  id: PropTypes.string.isRequired,
  zone_id: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.oneOf(SeatType_props_enum),
  capacity: PropTypes.number.isRequired,
  box_capacity_left:PropTypes.number,
  box_capacity_right:PropTypes.number,
  box_capacity_top:PropTypes.number,
  box_capacity_bottom:PropTypes.number,
  map_position_x:PropTypes.number.isRequired,
  map_position_y:PropTypes.number.isRequired,
  map_position_angle:PropTypes.number,

  hover: PropTypes.bool,
  hover_idx: PropTypes.number,
  color: PropTypes.oneOf(planColorEnum),
  markers_dict: PropTypes.object,
  disableTableSelection: PropTypes.bool
};
Seat.defaultProps = {
  hover:false,
  hover_idx: -1,
  color: planColorEnum[0],
  disableTableSelection:false
};

export default Seat;