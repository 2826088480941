import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import StringField from './StringField';
import EnumField from './EnumField';
import Lodash from 'lodash';

const styles = _theme => ({
  root: {
    minWidth:800
  }
});


class PlaceEditDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      doc:null,
      open:false,
      dirty:false,
      valid:false,
    }
  }

  doEdit(doc) {
    const dc = Object.assign({},doc);
    this.setState({
        doc:dc,
        doc_err: this.getDocErr(dc),
        open:true,
        dirty:false,
        valid:this.checkDoc(dc)
    })
  }
  getDoc() {
    return this.state.doc;
  }

  doClose() {
    this.setState({doc:null,doc_err:null,open:false})
  }


  handleDocChange(name,value){
    let { doc, doc_err } = this.state;
    doc[name]=value;
    doc_err[name]=!this.checkDocField(name,value);
    this.setState({
      doc:doc,
      doc_err:doc_err,
      dirty:true,
      valid:this.checkDoc(doc)
    });
  }

  checkDocField(name,value) {
    if (["name","long_name","map","address"].includes(name)  && value===null) {
      return false;
    }
    return true;
  }

  getDocErr(doc) {
    const me = this;
    const doc_err = Lodash.reduce(Lodash.keys(doc),(acc,n)=>{
      var di = {};
      di[n]=!me.checkDocField(n,doc[n]);
      return Object.assign(acc,di);
    },{})
    return doc_err;
  }

  checkDoc(doc) {
    return Lodash.every(Lodash.values(this.getDocErr(doc)),(v)=>!v);
  }

  

  render() {
    const {onSave,caption_edit,caption_new} = this.props;
    const {open,doc,doc_err,valid,dirty} = this.state;
    const caption = (doc && doc.id)?caption_edit:caption_new;
    return (
      <Dialog maxWidth={false} open={open} onClose={()=>this.doClose()}>
      <DialogTitle>{caption}</DialogTitle>
      <DialogContent >
          {doc && (
            <React.Fragment>
                <StringField required width={300} id="name" label="Název" value={doc.name} error={doc_err.name} onChange={(v)=>this.handleDocChange("name",v)} />
                <StringField required width={300} id="long_name" label="Dlouhý název" value={doc.long_name} error={doc_err.long_name} onChange={(v)=>this.handleDocChange("long_name",v)} />
                <StringField required width={300} id="address" label="Adresa" value={doc.address} error={doc_err.address} onChange={(v)=>this.handleDocChange("address",v)} />
                <EnumField required width={200} id="map" label="Mapa" values={[{key:"BOBY",label:"Bobycentrum"},{key:"BVV",label:"Bvv"}, {key:"BABYLON",label:"KC Babylon"},{key:"TEST",label:"testovaci"},{key:"NO",label:"bez mapy"}]} value={doc.map} error={doc_err.map} onChange={(v)=>this.handleDocChange("map",v)} />
            </React.Fragment>
          )}
      </DialogContent>
      <DialogActions>

        <Button onClick={()=>this.doClose()} color="primary">
          Zavřít
        </Button>
        <Button disabled={!valid || !dirty} onClick={onSave} color="primary">
          Uložit
        </Button>

      </DialogActions>
    </Dialog>
    );
  }
}

PlaceEditDialog.propTypes = {  
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  caption_edit: PropTypes.string,
  caption_new: PropTypes.string
};
PlaceEditDialog.defaultProps = {
  caption_edit:"Editace místa",
  caption_new:"Nové místo"
};

export default withStyles(styles)(PlaceEditDialog);