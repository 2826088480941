


const  PaymentType_schema_enum = ['dummy','cash','card','bank'];
const  PaymentType_gql_values =  { 
    DUMMY:{value:"dummy"},
    CASH:{value:"cash"},
    CARD:{value:"card"},
    BANK:{value:"bank"},
};

const PaymentType_field_enum = [
    {key:"DUMMY",label:"testovací"},
    {key:"CASH",label:"hotovost"},
    {key:"CARD",label:"karta"},
    {key:"BANK",label:"banka"},
];
const PaymentType_props_enum = ['DUMMY','CASH','CARD','BANK'];


module.exports = {
    PaymentType_schema_enum,
    PaymentType_gql_values,
    PaymentType_field_enum,
    PaymentType_props_enum
};