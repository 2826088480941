import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import SeatsTable from './SeatsTable';
import { Query } from "react-apollo";
import SeatViewDialog from './SeatViewDialog';
import SeatDeleteDialog from './SeatDeleteDialog';
import SeatEditDialog from './SeatEditDialog';
import ActionButton from './ActionButton';
import ZoneIDField from './Fields/ZoneIDField';
//import FloorPlanPreview from './FloorPlanPreview';
//import { Typography } from '@material-ui/core';

import PlaceIDField from './Fields/PlaceIDField';
import { connect } from 'react-redux'
import { setZoneIDForSeat, setPlaceIDForZone } from './../actions';

import {GET_SEATS_WITH_ZONE_AND_PLACE } from './Fields/Queries';
import Grid from '@material-ui/core/Grid';
import EventPlanView from './EventPlanView';
import DebugObjectView from './Utils/DebugObjectView'

/*
const GET_SEATS = gql`
  query Seats($zone_id:ID) {
    seats(zone_id:$zone_id) {
      id
      zone_id
      name
      type
      capacity
      box_capacity_left
      box_capacity_right
      box_capacity_top
      box_capacity_bottom
      map_position_x
      map_position_y
      map_position_angle
      map_sector_angle
    }
  }
`;
*/
const SAVE_ORDERING = gql`
    mutation seats_ordering($ids: [ID]!,$zone_id:ID) {
        seats_ordering(ids:$ids,zone_id:$zone_id) 
    }
`;

const HIDE_SEAT = gql`
mutation hideSeat($id:ID!) {
  seatHide(id:$id) {
    id
  }
}
`;



const UPDATE_SEAT= gql`
mutation updateSeat($id:ID!,$name:String,$type:SeatType, $capacity:Int, $row_label_base:Int, $col_label_base:Int, $box_capacity_left:Int, $box_capacity_right:Int, $box_capacity_top:Int, $box_capacity_bottom:Int, $map_position_x:Int, $map_position_y:Int, $map_position_angle:Int,$map_sector_angle:Int) {
  seatUpdate(id:$id,name:$name,type:$type,capacity:$capacity,row_label_base:$row_label_base,col_label_base:$col_label_base,box_capacity_left:$box_capacity_left,box_capacity_right:$box_capacity_right,box_capacity_top:$box_capacity_top,box_capacity_bottom:$box_capacity_bottom,map_position_x:$map_position_x,map_position_y:$map_position_y,map_position_angle:$map_position_angle,map_sector_angle:$map_sector_angle) {
    id
  }
}
`;

const CREATE_SEAT = gql`
mutation createSeat($zone_id:ID!,$place_id:ID!,$name:String!,$type:SeatType!, $capacity:Int!, $row_label_base:Int, $col_label_base:Int, $box_capacity_left:Int, $box_capacity_right:Int, $box_capacity_top:Int, $box_capacity_bottom:Int, $map_position_x:Int!, $map_position_y:Int!, $map_position_angle:Int,$map_sector_angle:Int) {
  seatCreate(zone_id:$zone_id,place_id:$place_id,name:$name,type:$type,capacity:$capacity,row_label_base:$row_label_base,col_label_base:$col_label_base,box_capacity_left:$box_capacity_left,box_capacity_right:$box_capacity_right,box_capacity_top:$box_capacity_top,box_capacity_bottom:$box_capacity_bottom,map_position_x:$map_position_x,map_position_y:$map_position_y,map_position_angle:$map_position_angle,map_sector_angle:$map_sector_angle) {
    id
  }
}
`;

const styles = theme => ({
  title: {
    marginTop: theme.spacing.unit * 3,
  }
});



class Seats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show_seat:null,
      delete_seat:null,
    }
    this.table = React.createRef();
    this.edit = React.createRef();
    this.preview = React.createRef();
  }


  reload() {
    if (this.table.current) {
      this.table.current.props.refetch();
    }
    if (this.preview.current) {
      this.preview.current.reload();
    }
  }


  handleNew() {
    if (this.edit.current) {
      this.edit.current.doEdit({place_id:this.props.place_id,zone_id:this.props.zone_id,name:null,type:null,capacity:null,row_label_base:null,col_label_base:null,box_capacity_left:null,box_capacity_right:null,box_capacity_top:null,box_capacity_bottom:null,map_position_x:null,map_position_y:null,map_position_angle:null,map_sector_angle:null});
    }
  }


  handleEdit(item) {
    if (this.edit.current) {
      this.edit.current.doEdit(item);
    }
  }

  doSave() {
    if (this.edit.current) {
      const doc = this.edit.current.getDoc();
      this.edit.current.doClose();
      if (doc.id) {
        console.log("seat update",doc)
        this.props.updateSeat({
          variables: doc
        }).then(res=>{
          console.log("seat ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      } else {
        console.log("seat create",doc)
        this.props.createSeat({
          variables: doc
        }).then(res=>{
          console.log("create ok",res);
          this.reload();
        }).catch(err=>{
          console.error(err);
        })
      }
     
    }
  }

  handleShow(item) {
    this.setState({show_seat:item});
  }
  closeShow() {
    this.setState({show_seat:null});
  }

  handleDelete(item) {
    this.setState({delete_seat:item});
  }
  closeDelete() {
    this.setState({delete_seat:null});
  }

  doDelete() {
    const id = this.state.delete_seat.id;
    this.setState({delete_seat:null});
    this.props.hideSeat({
      variables: {
        id:id
      }
    }).then(res=>{
      console.log("hide ok",res);
      this.reload();
    }).catch(err=>{
      console.error(err);
    })
  }


  saveOrder(ids) {
    console.log("saveOrder");
    const { zone_id } = this.props; 
    this.props.saveOrdering({
      variables: {
        ids:ids,
        zone_id
      }
    }).then(res=>{
      console.log("order save ok",res);
    }).catch(err=>{
      console.error(err);
    })
  }

  showSeat(seat,seat_idx) {
    alert("seat: "+seat.name+" idx:"+seat_idx);
  }


  render() {
    const { auth, zone_id, place_id} = this.props;
   
    return (
      <React.Fragment>
        <PlaceIDField required error={place_id===null} value={place_id} onChange={(v)=>{ this.props.onChangeZoneID(null); this.props.onChangePlaceID(v)} }/>
        <ZoneIDField place_id={place_id} required error={zone_id===null} value={zone_id} onChange={(v)=>this.props.onChangeZoneID(v)}/>
        {place_id && zone_id && (<ActionButton label={"Nové sezení"} onClick={()=>this.handleNew()}/>)}

        
        <SeatViewDialog seat={this.state.show_seat}  open={this.state.show_seat!==null} onClose={()=>this.closeShow()} /> 
        <SeatDeleteDialog seat={this.state.delete_seat}  open={this.state.delete_seat!==null} onDelete={()=>this.doDelete()} onClose={()=>this.closeDelete()} /> 
        <SeatEditDialog innerRef = {this.edit} onSave={()=>this.doSave()} /> 
        
      
        {place_id && zone_id && (
          <Query query={GET_SEATS_WITH_ZONE_AND_PLACE} variables={{zone_id,place_id}} fetchPolicy={"network-only"}>
            {({ loading, error, data, refetch }) => {
              if (loading) return (<CircularProgress/>);
              if (error) return `Error!: ${error}`;
              return (
                <React.Fragment>
                <DebugObjectView object={data}/>
                <Grid container justify="center" alignContent="center" >
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                    <SeatsTable 
                        innerRef = {this.table}
                        auth={auth}
                        data={data.seats} 
                        refetch={refetch}
                        saveOrdering={(ids)=>this.saveOrder(ids)}
                        onEdit={(item)=>this.handleEdit(item)}
                        onShow={(item)=>this.handleShow(item)}
                        onDelete={(item)=>this.handleDelete(item)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <EventPlanView 
                        show_pointer
                        place={data.place} 
                        zones={[data.zone]}
                        zoomZone={data.zone}
                        seats={data.seats}
                        onSeatClick={(seat,seat_idx)=>this.showSeat(seat,seat_idx)}
                      />
                  </Grid>
                </Grid>
                </React.Fragment>
              );
            }}
        </Query>
        )}
      {/*
      { place_id && zone_id && (
        <React.Fragment>
          <Typography className={classes.title} variant="title"> Náhled místa a zón </Typography>
          <FloorPlanPreview innerRef={this.preview} place_id={place_id} active_zone_id={zone_id} />
        </React.Fragment>
      )} 
    */}

      </React.Fragment>
    );
  }
}

Seats.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  saveOrdering: PropTypes.func.isRequired,
  hideSeat: PropTypes.func.isRequired,
  updateSeat: PropTypes.func.isRequired,
  createSeat: PropTypes.func.isRequired,
  zone_id: PropTypes.string,
  place_id: PropTypes.string,
  onChangeZoneID: PropTypes.func.isRequired,
  onChangePlaceID: PropTypes.func.isRequired
};
Seats.defaultProps = {
  
};


function mapStateToProps(state) {
  return { 
      place_id: state.select.place_id_for_zones,
      zone_id: state.select.zone_id_for_seats,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangePlaceID: id => {
      dispatch(setPlaceIDForZone(id))
    },
    onChangeZoneID: id => {
      dispatch(setZoneIDForSeat(id))
    },
  }
}


export default compose(
  withStyles(styles),
  connect(mapStateToProps,mapDispatchToProps),
  graphql(SAVE_ORDERING,{
    name: "saveOrdering",
  }),
  graphql(HIDE_SEAT,{
    name: "hideSeat",
  }),
  graphql(UPDATE_SEAT,{
    name: "updateSeat",
  }),
  graphql(CREATE_SEAT,{
    name: "createSeat",
  }),
)(Seats)
